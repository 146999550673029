import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import {
  Box,
  Button,
  Grid,
  Icon,
  Modal,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/button/custom-button.component";
import FormPickerDate from "../../../components/forms/form-picker-date.component";
import routes from "../../../components/navigation/routes";
import PaddedView from "../../../components/utils/padded-view.component";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import Spacer from "../../../components/utils/spacer.component";
import TopTitleBox from "../../../components/utils/top-title-box.component";
import { hourlyBookingSelector } from "../../../services/hourly-booking/hourly-booking-slice.service";
import { exclusiveReferralSelector } from "../../../services/exclusive-referral/exclusive-referral-slice.service";

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "absolute",
  top: !isMobile && "50%",
  left: !isMobile && "50%",
  transform: !isMobile && "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.white,
  borderRadius: !isMobile && theme.shape.borderRadius[2],
  boxShadow: 24,
  maxWidth: !isMobile && "550px",
  width: isMobile ? "100%" : "90%",
  height: isMobile && "100%",
  outline: "none",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: "100%",
  border: "1px solid",
  borderColor: theme.palette.colors.ui.border,
  borderRadius: `${theme.shape.borderRadius[1]}px`,
  color: theme.palette.colors.text.primary,
}));

function AvailableSessionModal({ showModal, setShowModal }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { getAvailableHourlyBookingObj } = useSelector(hourlyBookingSelector);
  const { setFieldValue, values } = useFormikContext();
  const { validateExclusiveReferralCode } = useSelector(exclusiveReferralSelector);
  const navigate = useNavigate();

  const renderLoader = () => {
    const loaders = [];
    for (let i = 0; i < 24; i += 1) {
      loaders.push(
        <Grid item xs={4} key={i} position="relative">
          <StyledButton disabled>
            <CustomSkeleton width="100%" />
          </StyledButton>
        </Grid>,
      );
    }
    return loaders;
  };

  useEffect(() => {
    setFieldValue("slots", []);
  }, [values.date]);

  useEffect(() => {
    setFieldValue("exclusiveReferralCode", validateExclusiveReferralCode.exclusiveReferralCode);
  }, [validateExclusiveReferralCode.exclusiveReferralCode]);

  const handleSelectSlot = (slot) => {
    if (values.slots.some((item) => item.startAt === slot.startAt)) {
      setFieldValue(
        "slots",
        values.slots.filter((item) => item.startAt !== slot.startAt),
      );
    } else {
      setFieldValue("slots", [...values.slots, { startAt: slot.startAt, endAt: slot.endAt }]);
    }
  };

  const renderAvailableSession = () => {
    if (getAvailableHourlyBookingObj.status === "succeeded") {
      if (getAvailableHourlyBookingObj.data.length > 0) {
        return (
          <>
            <Grid container item justifyContent="center">
              {getAvailableHourlyBookingObj.data[0].businessOffDay ? (
                <>
                  <Grid item xs={12}>
                    <Typography textAlign="center">Selected date is unavailable. </Typography>
                  </Grid>
                  <Grid item>
                    <Typography textAlign="center">Please select another date.</Typography>
                  </Grid>
                </>
              ) : (
                <Spacer />
              )}
            </Grid>

            <Grid container item columnSpacing={1} rowSpacing={1.5}>
              {getAvailableHourlyBookingObj.data.map((item) => (
                <Grid item xs={4} key={item.startAt} position="relative">
                  <StyledButton
                    onClick={() => handleSelectSlot(item)}
                    disabled={!item.available || item.booked}
                  >
                    <Typography>{format(new Date(item.startAt), "hh:00a")}</Typography>
                    {(!item.available || item.booked) && (
                      <Icon
                        sx={{
                          height: "100%",
                          position: "absolute",
                          margin: "auto",
                          left: 0,
                          right: 0,
                        }}
                      >
                        <DoDisturbAltIcon sx={{ color: theme.palette.colors.text.secondary }} />
                      </Icon>
                    )}
                  </StyledButton>
                  {values.slots.some((selected) => selected.startAt === item.startAt) && (
                    <Icon
                      sx={{
                        position: "absolute",
                        right: -5,
                        top: 0,
                        backgroundColor: theme.palette.colors.bg.white,
                        borderRadius: "50%",
                      }}
                    >
                      <CheckCircleRoundedIcon sx={{ color: theme.palette.colors.text.success }} />
                    </Icon>
                  )}
                </Grid>
              ))}
            </Grid>
            {!getAvailableHourlyBookingObj.data[0].businessOffDay && (
              <Grid container item justifyContent="center">
                <Grid item xs={6}>
                  {isMobile && <Spacer size="l" />}
                  <CustomButton
                    disabled={values.slots.length === 0}
                    fontSize={theme.fonts.fontSizes.size18}
                    onClick={() => navigate(routes.HOURLYBOOKINGSUMMARY, { state: { ...values } })}
                  >
                    Book
                  </CustomButton>
                </Grid>
              </Grid>
            )}
          </>
        );
      }
      return (
        <Grid container item justifyContent="center" alignContent="center" height="400px">
          <Typography>No available sessions.</Typography>
        </Grid>
      );
    }

    return (
      <Grid container item columnSpacing={1} rowSpacing={1.5} marginTop="10px">
        {renderLoader()}
      </Grid>
    );
  };

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <ModalBox isMobile={isMobile}>
        <PaddedView multiples={2}>
          <TopTitleBox
            title="Available Session"
            requireCloseButton={true}
            setShowModal={setShowModal}
          />
          <Spacer position="top" size="l" />
          <Grid container item xs={12} justifyContent="center" rowSpacing={1.5} padding="10px">
            <Grid item xs={8}>
              <FormPickerDate name="date" submitOnChange={true} />
            </Grid>
            {renderAvailableSession()}
          </Grid>
        </PaddedView>
      </ModalBox>
    </Modal>
  );
}

AvailableSessionModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default AvailableSessionModal;
