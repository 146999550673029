import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq } from "../../api.services";

export const getPurchasedPackages = createAsyncThunk(
  "packages/plans?businessId={businessId}",
  async (payload) => {
    const { status, page } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/packages/purchases/transactions?status=${status}&page=${page}`,
    );
    return response;
  },
);

const purchasedPackageSlice = createSlice({
  name: "purchasedPackage",
  initialState: {
    reduxStorePurchasedPackages: [],
    getPurchasedPackagesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getPurchasedPackages.pending]: (state) => {
      state.getPurchasedPackagesObj.status = "pending";
    },
    [getPurchasedPackages.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getPurchasedPackagesObj.status = "succeeded";
      state.getPurchasedPackagesObj.data = data;
      state.getPurchasedPackagesObj.successMessage = message;
    },
    [getPurchasedPackages.rejected]: (state, action) => {
      const { message } = action.error;

      state.getPurchasedPackagesObj.status = "failed";
      state.getPurchasedPackagesObj.errorMessage = message;
    },
  },
});

export default purchasedPackageSlice.reducer;

// state
export const purchasedPackageSelector = (state) => state.purchasedPackage;
