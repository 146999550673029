import { Box, Grid, Modal, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import CustomButton from "../../../components/button/custom-button.component";
import Spacer from "../../../components/utils/spacer.component";
import TopTitleBox from "../../../components/utils/top-title-box.component";

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.white,
  borderRadius: theme.shape.borderRadius[2],
  boxShadow: 24,
  width: isMobile ? "90%" : "450px",
  height: isMobile ? "300px" : "280px",
  outline: "none",
  padding: "24px",
}));

export function RequireMembershipModal({ showModal, setShowModal }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <ModalBox isMobile={isMobile}>
        <Box
          sx={{
            overflowY: !isMobile && "auto",
          }}
        >
          <TopTitleBox
            title="This facility is only for members"
            requireCloseButton={true}
            setShowModal={setShowModal}
          />
          <Spacer position="top" size="l" />
          <Grid container item xs={12} textAlign="center">
            Only members can purchase products from this business.
          </Grid>
          <Grid container item xs={12} paddingTop="50px" justifyContent="center">
            <CustomButton
              fontSize={theme.fonts.fontSizes.size16}
              paddingTop="20px"
              width="60%"
              onClick={() => {
                setShowModal(false);
              }}
            >
              Ok
            </CustomButton>
          </Grid>
        </Box>
      </ModalBox>
    </Modal>
  );
}

RequireMembershipModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};
