import React from "react";
import PropTypes from "prop-types";
import { Box, FormHelperText, TextField } from "@mui/material";
import { useFormikContext } from "formik";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { format } from "date-fns";

function FormPickerDate({ name, disabled, disablePast, submitOnChange }) {
  const { setFieldValue, values, touched, errors, handleSubmit } = useFormikContext();
  const theme = useTheme();
  const showError = touched[name] && typeof errors[name] === "string";

  const handleChange = (date) => {
    setFieldValue(name, format(date, "yyyy-MM-dd"));

    if (submitOnChange) {
      handleSubmit();
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <DatePicker
          disablePast={disablePast}
          disabled={disabled}
          maxDate={new Date().setDate(new Date().getDate() + 90)}
          inputFormat="dd/MM/yyyy"
          value={values[name]}
          onChange={(date) => {
            handleChange(date);
          }}
          renderInput={(params) => (
            <TextField
              size="small"
              {...params}
              error={showError}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  borderTopLeftRadius: theme.shape.borderRadius[1],
                  borderTopRightRadius: theme.shape.borderRadius[1],
                  borderBottomLeftRadius: theme.shape.borderRadius[1],
                  borderBottomRightRadius: theme.shape.borderRadius[1],
                  backgroundColor: theme.palette.colors.bg.primary,
                },
              }}
            />
          )}
        />
        {showError && <FormHelperText error>{errors[name]}</FormHelperText>}
      </Box>
    </LocalizationProvider>
  );
}

FormPickerDate.defaultProps = {
  disabled: false,
  disablePast: true,
  submitOnChange: false,
};

FormPickerDate.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  disablePast: PropTypes.bool,
  submitOnChange: PropTypes.bool,
};

export default FormPickerDate;
