import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq, postReq, putReq } from "../api.services";

export const createTopUpBill = createAsyncThunk("credits/create_top_up", async (payload) => {
  const response = postReq(`${process.env.REACT_APP_API_PRIVATE_V2}/credits/top_up`, payload);
  return response;
});

export const checkTopUpBill = createAsyncThunk("credits/check_bill", async (payload) => {
  const response = getReq(
    `${process.env.REACT_APP_API_PRIVATE_V2}/credits/bills/${payload.remoteBillId}`,
    payload,
  );
  return response;
});

export const cancelTopUpBill = createAsyncThunk("credits/cancel_bill", async (payload) => {
  const response = putReq(
    `${process.env.REACT_APP_API_PRIVATE_V2}/credits/bills/${payload.billId}/cancel`,
    payload,
  );
  return response;
});
export const getCreditTransactions = createAsyncThunk("credits/transactions", async (payload) => {
  const response = getReq(
    `${process.env.REACT_APP_API_PRIVATE_V2}/credits/transactions?type=${payload.type}&page=${payload.page}`,
  );
  return response;
});

const creditSlice = createSlice({
  name: "credits",
  initialState: {
    createTopUpBillObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    checkTopUpBillObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getCreditTransactionsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    cancelTopUpBillObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [createTopUpBill.pending]: (state) => {
      state.createTopUpBillObj.status = "pending";
    },
    [createTopUpBill.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createTopUpBillObj.status = "succeeded";
      state.createTopUpBillObj.data = data;
      state.createTopUpBillObj.successMessage = message;
    },
    [createTopUpBill.rejected]: (state, action) => {
      const { message } = action.error;

      state.createTopUpBillObj.status = "failed";
      state.createTopUpBillObj.errorMessage = message;
    },
    [checkTopUpBill.pending]: (state) => {
      state.checkTopUpBillObj.status = "pending";
    },
    [checkTopUpBill.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.checkTopUpBillObj.status = "succeeded";
      state.checkTopUpBillObj.data = data;
      state.checkTopUpBillObj.successMessage = message;
    },
    [checkTopUpBill.rejected]: (state, action) => {
      const { message } = action.error;

      state.checkTopUpBillObj.status = "failed";
      state.checkTopUpBillObj.errorMessage = message;
    },
    [getCreditTransactions.pending]: (state) => {
      state.getCreditTransactionsObj.status = "pending";
    },
    [getCreditTransactions.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getCreditTransactionsObj.status = "succeeded";
      state.getCreditTransactionsObj.data = data;
      state.getCreditTransactionsObj.successMessage = message;
    },
    [cancelTopUpBill.pending]: (state) => {
      state.checkTopUpBillObj.status = "pending";
    },
    [cancelTopUpBill.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.cancelTopUpBillObj.status = "succeeded";
      state.cancelTopUpBillObj.data = data;
      state.cancelTopUpBillObj.successMessage = message;
    },
    [cancelTopUpBill.rejected]: (state, action) => {
      const { message } = action.error;

      state.cancelTopUpBillObj.status = "failed";
      state.cancelTopUpBillObj.errorMessage = message;
    },
  },
});

export default creditSlice.reducer;

// state
export const creditSelector = (state) => state.credits;
