import { Grid, Typography, useTheme, styled } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const StyledFirstRowTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size16,
}));

const StyledSecondRowTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size16,
}));

function TransactionRow({ itemDetails }) {
  const theme = useTheme();

  const getPrice = (price, type) => {
    let tempPrice = "";
    if (price.includes("-") || type === "Payment") {
      tempPrice = price === "0.00" ? `- RM${price}` : `- RM${price.substring(1)}`;
    } else {
      tempPrice = `+ RM${price}`;
    }
    return tempPrice;
  };

  return (
    <>
      <Grid item>
        <StyledFirstRowTypography fontSize={theme.fonts.fontSizes.size16}>
          {itemDetails.description}
        </StyledFirstRowTypography>
        <StyledSecondRowTypography fontSize={theme.fonts.fontSizes.size14}>
          {itemDetails.createdAt}
        </StyledSecondRowTypography>
      </Grid>
      <Grid item textAlign="right">
        <StyledFirstRowTypography fontSize={theme.fonts.fontSizes.size16}>
          {getPrice(itemDetails.amount, itemDetails.description)}
        </StyledFirstRowTypography>
        <StyledSecondRowTypography
          fontSize={theme.fonts.fontSizes.size14}
          sx={{ color: theme.palette.colors.text.success }}
        >
          Success
        </StyledSecondRowTypography>
      </Grid>
    </>
  );
}

TransactionRow.defaultProps = {
  itemDetails: PropTypes.shape({
    id: null,
    description: "",
    type: "",
    amount: "",
    createdAt: "",
  }),
};

TransactionRow.propTypes = {
  itemDetails: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    type: PropTypes.string,
    amount: PropTypes.string,
    createdAt: PropTypes.string,
  }),
};

export default TransactionRow;
