import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";
import "react-horizontal-strip-datepicker/dist/ReactHorizontalDatePicker.css";
import { useSearchParams } from "react-router-dom";
import FormAddressAutoComplete from "../../../components/forms/form-address-auto-complete.component";
import FormFieldTextWithIcon from "../../../components/forms/form-field-text-with-icon.component";
import FormPickerDate from "../../../components/forms/form-picker-date.component";
import FormSelectFieldText from "../../../components/forms/form-select-field-text.component";
import CategoryFilter from "./category-filter.component";
import TimeFilter from "./time-filter.component";

const FilterOptionsButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  color: theme.palette.colors.text.secondary,
  width: "100%",
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size15,
  padding: "0px",
}));

function Filters({ searchFor, setSearchFor, setPage, setNearbyPage }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [urlParams] = useSearchParams();
  const { values, handleSubmit } = useFormikContext();

  return (
    <Box sx={{ position: "relative" }}>
      <Grid container spacing={2}>
        <Grid item container xs={12} alignItems="center" rowSpacing={2}>
          <Grid container item xs={12} alignItems="center" columnSpacing={1} rowSpacing={2}>
            {searchFor !== "event" && (values.tag === "fitness" || values.tag === "") && (
              <Grid item xs={isMobile ? 12 : 2.7}>
                <Box
                  sx={{
                    display: "flex",
                    border: "1px solid",
                    borderRadius: `${theme.shape.borderRadius[1]}px`,
                    borderColor: theme.palette.colors.ui.border,
                  }}
                >
                  <FilterOptionsButton
                    size="large"
                    onClick={() => {
                      setSearchFor("businesses");
                      values.page = 1;
                      setPage(1);
                      setNearbyPage(1);
                      handleSubmit();
                    }}
                  >
                    <StyledTypography
                      fontWeight={searchFor === "businesses" && "bold"}
                      color={searchFor === "businesses" && "primary"}
                    >
                      Businesses
                    </StyledTypography>
                  </FilterOptionsButton>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ borderColor: theme.palette.colors.ui.border }}
                  />
                  <FilterOptionsButton
                    size="large"
                    onClick={() => {
                      setSearchFor("classes");
                      values.page = 1;
                      setPage(1);
                      setNearbyPage(1);
                      // handleSubmit();
                    }}
                  >
                    <StyledTypography
                      fontWeight={searchFor === "classes" && "bold"}
                      color={searchFor === "classes" && "primary"}
                    >
                      Classes
                    </StyledTypography>
                  </FilterOptionsButton>
                </Box>
              </Grid>
            )}

            <Grid item xs={isMobile ? 7 : 2.4}>
              <CategoryFilter searchFor={searchFor} />
            </Grid>
            <Grid item xs={isMobile ? 5 : 2.1}>
              <FormSelectFieldText
                submitOnChange={true}
                backgroundColor={theme.palette.colors.bg.lightGreen}
                name="distance"
                selections={[
                  { type: "5 km", value: 5 },
                  { type: "15 km", value: 15 },
                  { type: "30 km", value: 30 },
                  { type: "50 km (Auto)", value: 50 },
                  { type: "100 km", value: 100 },
                ]}
              />
            </Grid>
            {searchFor !== "businesses" && (
              <>
                <Grid item xs={isMobile ? 7 : 2.4}>
                  <TimeFilter searchFor={searchFor} />
                </Grid>
                <Grid item xs={isMobile ? 5 : 2.4}>
                  <FormPickerDate name="selectedDate" label="date" />
                </Grid>
              </>
            )}
            <Grid item xs={isMobile ? 12 : 2.7}>
              <FormSelectFieldText
                submitOnChange={true}
                placeholder="Sort By"
                backgroundColor="#FFF"
                name="sortBy"
                selections={[
                  { type: "Session (earliest to latest)", value: "session_earliest_to_latest" },
                  {
                    type: "Distance (nearest to farthest)",
                    value: "distance_nearest_to_farthest",
                  },
                ]}
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} alignItems="center" columnSpacing={1} rowSpacing={2}>
            <Grid item xs={isMobile ? 12 : 6}>
              <FormFieldTextWithIcon
                chip={values.tag && <Chip label={urlParams.get("tag")} variant="outlined" />}
                name="q"
                placeholder="Search for anything"
                icon={<SearchOutlinedIcon color="primary" />}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <FormAddressAutoComplete name="location" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

Filters.propTypes = {
  searchFor: PropTypes.string.isRequired,
  setSearchFor: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setNearbyPage: PropTypes.func.isRequired,
};

export default Filters;
