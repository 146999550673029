import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq } from "../../api.services";
import { getAPI } from "../../../infrastructure/utils";

export const getPackagePlans = createAsyncThunk(
  "packages/plans?businessId={businessId}",
  async (payload) => {
    const { businessId, page } = payload;
    const response = await getReq(
      `${getAPI()}/packages/plans?businessId=${businessId}&page=${page}`,
    );
    return response;
  },
);

export const getPackagePlanDetails = createAsyncThunk(
  "packages/plans/{planId}",
  async (payload) => {
    const { planId } = payload;
    const response = await getReq(`${getAPI()}/packages/plans/${planId}`);
    return response;
  },
);

const packagePlanSlice = createSlice({
  name: "packagePlan",
  initialState: {
    reduxStorePackagePlans: [],
    getPackagePlansObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getPackagePlanDetailsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getPackagePlans.pending]: (state) => {
      state.getPackagePlansObj.status = "pending";
    },
    [getPackagePlans.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getPackagePlansObj.status = "succeeded";
      state.getPackagePlansObj.data = data;
      state.getPackagePlansObj.successMessage = message;
    },
    [getPackagePlans.rejected]: (state, action) => {
      const { message } = action.error;

      state.getPackagePlansObj.status = "failed";
      state.getPackagePlansObj.errorMessage = message;
    },
    [getPackagePlanDetails.pending]: (state) => {
      state.getPackagePlanDetailsObj.status = "pending";
    },
    [getPackagePlanDetails.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getPackagePlanDetailsObj.status = "succeeded";
      state.getPackagePlanDetailsObj.data = data;
      state.getPackagePlanDetailsObj.successMessage = message;
    },
    [getPackagePlanDetails.rejected]: (state, action) => {
      const { message } = action.error;

      state.getPackagePlanDetailsObj.status = "failed";
      state.getPackagePlanDetailsObj.errorMessage = message;
    },
  },
});

export default packagePlanSlice.reducer;

// state
export const packagePlanSelector = (state) => state.packagePlan;
