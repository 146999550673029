import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq, postReq, putReq } from "../api.services";

export const getTokenizedPayments = createAsyncThunk("tokenized_payments/index", async () => {
  const response = await getReq(`${process.env.REACT_APP_API_PRIVATE_V1}/tokenized_payments`);
  return response;
});

export const createTokenizedPayments = createAsyncThunk("tokenized_payments/create", async () => {
  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/tokenized_payments`, {
    'platform': 'web'
  });
  return response;
});

export const showTokenizedPayment = createAsyncThunk("tokenized_payments/show", async (payload) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/tokenized_payments/${payload}`,
  );
  return response;
});

export const updateTokenizedPayment = createAsyncThunk(
  "tokenized_payments/update",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/tokenized_payments/${payload}`,
    );
    return response;
  },
);

export const chargeTokenizedPayments = createAsyncThunk(
  "tokenized_payments/charge",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/tokenized_payments/charge`,
      payload,
    );
    return response;
  },
);

const tokenizedPaymentSlice = createSlice({
  name: "tokenizedPayment",
  initialState: {
    getTokenizedPaymentsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createTokenizedPaymentsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    showTokenizedPaymentObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateTokenizedPaymentObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    chargeTokenizedPaymentsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getTokenizedPayments.pending]: (state) => {
      state.getTokenizedPaymentsObj.status = "pending";
    },
    [getTokenizedPayments.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getTokenizedPaymentsObj.status = "succeeded";
      state.getTokenizedPaymentsObj.data = data;
      state.getTokenizedPaymentsObj.successMessage = message;
    },
    [getTokenizedPayments.rejected]: (state, action) => {
      const { message } = action.error;

      state.getTokenizedPaymentsObj.status = "rejected";
      state.getTokenizedPaymentsObj.errorMessage = message;
    },
    [createTokenizedPayments.pending]: (state) => {
      state.createTokenizedPaymentsObj.status = "pending";
    },
    [createTokenizedPayments.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createTokenizedPaymentsObj.status = "succeeded";
      state.createTokenizedPaymentsObj.data = data;
      state.createTokenizedPaymentsObj.successMessage = message;
    },
    [createTokenizedPayments.rejected]: (state, action) => {
      const { message } = action.error;

      state.createTokenizedPaymentsObj.status = "rejected";
      state.createTokenizedPaymentsObj.errorMessage = message;
    },
    [showTokenizedPayment.pending]: (state) => {
      state.showTokenizedPaymentObj.status = "pending";
    },
    [showTokenizedPayment.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.showTokenizedPaymentObj.status = "succeeded";
      state.showTokenizedPaymentObj.data = data;
      state.showTokenizedPaymentObj.successMessage = message;
    },
    [showTokenizedPayment.rejected]: (state, action) => {
      const { message } = action.error;

      state.showTokenizedPaymentObj.status = "rejected";
      state.showTokenizedPaymentObj.errorMessage = message;
    },
    [updateTokenizedPayment.pending]: (state) => {
      state.updateTokenizedPaymentObj.status = "pending";
    },
    [updateTokenizedPayment.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateTokenizedPaymentObj.status = "succeeded";
      state.updateTokenizedPaymentObj.data = data;
      state.updateTokenizedPaymentObj.successMessage = message;
    },
    [updateTokenizedPayment.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateTokenizedPaymentObj.status = "rejected";
      state.updateTokenizedPaymentObj.errorMessage = message;
    },
    [chargeTokenizedPayments.pending]: (state) => {
      state.chargeTokenizedPaymentsObj.status = "pending";
    },
    [chargeTokenizedPayments.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.chargeTokenizedPaymentsObj.status = "succeeded";
      state.chargeTokenizedPaymentsObj.data = data;
      state.chargeTokenizedPaymentsObj.successMessage = message;
    },
    [chargeTokenizedPayments.rejected]: (state, action) => {
      const { message } = action.error;

      state.chargeTokenizedPaymentsObj.status = "rejected";
      state.chargeTokenizedPaymentsObj.errorMessage = message;
    },
  },
});

export default tokenizedPaymentSlice.reducer;

// state
export const tokenizedPaymentSelector = (state) => state.tokenizedPayment;
