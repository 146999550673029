import PlaceIcon from "@mui/icons-material/Place";
import { Avatar, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { format } from "date-fns";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../../components/button/custom-button.component";
import routes from "../../../../components/navigation/routes";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";
import { isLogin } from "../../../../infrastructure/utils";
import { profileSelector } from "../../../../services/profile/profile-slice.service";

function ClassResultCard({ itemDetails }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const { getProfileDetailObj } = useSelector(profileSelector);

  const handleClick = (event, buttonClick) => {
    if (!buttonClick) {
      navigate({ pathname: routes.FITNESSCLASS, search: `?sessionId=${itemDetails.id}` });
    } else {
      if (
        isLogin() &&
        getProfileDetailObj.status === "succeeded" &&
        getProfileDetailObj.data &&
        getProfileDetailObj.data.status === "verified"
      ) {
        navigate(routes.FITNESSCLASSSESSIONBOOKINGSUMMARY, {
          state: { sessionId: itemDetails.id },
        });
      } else {
        navigate({ pathname: routes.FITNESSCLASS, search: `?sessionId=${itemDetails.id}` });
      }

      event.stopPropagation();
    }
  };

  return (
    <Grid
      onClick={(event) => handleClick(event, false)}
      item
      sx={{
        backgroundColor: theme.palette.colors.bg.primary,
        borderRadius: `${theme.shape.borderRadius[2]}px`,
        minHeight: "265px",
        display: "flex",
        alignItems: "center",
        objectFit: "cover",
        cursor: "pointer",
        "&:hover .fitnessTitle": { color: theme.palette.colors.brand.primary },
      }}
    >
      <PaddedView multiples={2}>
        <Grid container rowSpacing={1}>
          <Grid item>
            <Typography fontSize={theme.fonts.fontSizes.size14}>
              {itemDetails.class.category.label}
            </Typography>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography
                fontSize={theme.fonts.fontSizes.size20}
                fontWeight="bold"
                className="fitnessTitle"
              >
                {itemDetails.class.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Spacer />
              {itemDetails.memberPrice && (
                <Grid item display="flex" alignContent="center">
                  <Typography fontWeight="bold" color="primary">
                    RM {itemDetails.memberPrice} (MEMBER)
                  </Typography>
                </Grid>
              )}

              <Grid item display="flex" alignContent="center">
                <Typography fontWeight="bold" color={itemDetails.memberPrice ? "grey" : "primary"}>
                  RM {itemDetails.price} {itemDetails.memberPrice && "(NON-MEMBER)"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Spacer size="m" />
            <Typography>
              {itemDetails.startTime} ({itemDetails.durationMins}mins)
            </Typography>
          </Grid>
          <Grid
            container
            item
            display="flex"
            columnSpacing={2}
            flexDirection={isMobile ? "column" : "row"}
          >
            {itemDetails.staffs.map((item) => (
              <Grid item key={item.id}>
                <Grid container alignItems="center">
                  <Avatar
                    src={item.imagePath}
                    sx={{
                      width: "25px",
                      height: "25px",
                    }}
                  />
                  <Spacer position="left" />
                  <Typography>w/ {item.name}</Typography>
                </Grid>
                {isMobile && <Spacer />}
              </Grid>
            ))}
          </Grid>
          <Grid item container justifyContent="space-between" alignItems="center">
            <Grid item display="flex" alignContent="center">
              <PlaceIcon color="primary" />
              <Spacer position="left" />
              <Typography>
                {itemDetails.business.name} - {itemDetails.distanceKM}KM
              </Typography>
            </Grid>
            {format(new Date(), "yyyy-MM-dd HH:mm:ss") < itemDetails.startAt && (
              <Grid item xs={isMobile ? 12 : 2}>
                {isMobile && <Spacer size="l" />}

                {itemDetails.business.requireVerification &&
                isLogin() &&
                getProfileDetailObj.status === "succeeded" &&
                getProfileDetailObj.data &&
                getProfileDetailObj.data.status !== "verified" ? (
                  <Typography
                    textAlign="center"
                    fontWeight="bold"
                    sx={{ color: theme.palette.colors.ui.error }}
                  >
                    Verification Required
                  </Typography>
                ) : (
                  itemDetails.maxCapacity && (
                    <Typography
                      textAlign="center"
                      fontWeight="bold"
                      sx={{ color: theme.palette.colors.ui.error }}
                    >
                      Fully Booked
                    </Typography>
                  )
                )}
                <CustomButton
                  disabled={
                    (itemDetails.business.requireVerification &&
                      isLogin() &&
                      getProfileDetailObj.status === "succeeded" &&
                      getProfileDetailObj.data &&
                      getProfileDetailObj.data.status !== "verified") ||
                    itemDetails.booked ||
                    itemDetails.maxCapacity
                  }
                  fontSize={theme.fonts.fontSizes.size16}
                  onClick={(event) => handleClick(event, true)}
                >
                  {itemDetails.booked ? "Booked" : "Book"}
                </CustomButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </PaddedView>
    </Grid>
  );
}

ClassResultCard.defaultProps = {
  itemDetails: PropTypes.shape({
    id: null,
    date: "",
    durationMins: 0,
    startTime: "",
    startAt: "",
    maxCapacity: false,
    price: "",
    memberPrice: "",
    class: PropTypes.shape({
      name: "",
      category: PropTypes.shape({ label: "" }),
    }),
    staffs: PropTypes.arrayOf(
      PropTypes.shape({
        id: null,
        name: "",
        imagePath: "",
      }),
    ),
    business: PropTypes.shape({
      id: null,
      name: "",
      city: "",
      requireVerification: false,
      reviews: PropTypes.shape({
        averageRatings: 0,
      }),
      images: PropTypes.arrayOf(
        PropTypes.shape({
          imagePath: "",
        }),
      ),
    }),
    booked: false,
  }),
};

ClassResultCard.propTypes = {
  itemDetails: PropTypes.shape({
    id: PropTypes.number,
    distanceKM: PropTypes.string,
    date: PropTypes.string,
    durationMins: PropTypes.number,
    startTime: PropTypes.string,
    startAt: PropTypes.string,
    maxCapacity: PropTypes.bool,
    price: PropTypes.string,
    memberPrice: PropTypes.string,
    class: PropTypes.shape({
      name: PropTypes.string,
      category: PropTypes.shape({ label: PropTypes.string }),
    }),
    staffs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        imagePath: PropTypes.string,
      }),
    ),
    business: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      city: PropTypes.string,
      requireVerification: PropTypes.bool,
      reviews: PropTypes.shape({
        averageRatings: PropTypes.string,
      }),
      images: PropTypes.arrayOf(
        PropTypes.shape({
          imagePath: PropTypes.string,
        }),
      ),
    }),
    booked: PropTypes.bool,
  }),
};

export default ClassResultCard;
