import { Box, Grid, Modal, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cancelAccountDeletion } from "../../services/account-deletion/account-deletion-slice.service";
import { logout, resetUser } from "../../services/auth/auth-slice.service";
import { resetProfile } from "../../services/profile/profile-slice.service";
import CustomButton from "../button/custom-button.component";
import routes from "../navigation/routes";
import Spacer from "../utils/spacer.component";
import TopTitleBox from "../utils/top-title-box.component";
import { SnackbarContext } from "./snackbar.context";

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.brand.white,
  borderTopLeftRadius: theme.shape.borderRadius[2],
  borderTopRightRadius: theme.shape.borderRadius[2],
  borderBottomLeftRadius: theme.shape.borderRadius[2],
  borderBottomRightRadius: theme.shape.borderRadius[2],
  boxShadow: 24,
  paddingTop: "25px",
  paddingBottom: "25px",
  paddingLeft: "50px",
  paddingRight: "50px",
  width: isMobile ? "350px" : "450px",
  outline: "none",
}));

export default function AccountRestorationBox({ isShow, setIsShowAlert, deleteID }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const createSnackBar = useContext(SnackbarContext);

  const handleLogout = () => {
    dispatch(logout()).then(({ meta }) => {
      dispatch(resetProfile());
      if (meta.requestStatus === "fulfilled") {
        navigate(routes.LOGIN);
      }
      if (meta.requestStatus === "rejected") {
        dispatch(resetUser());
        navigate(routes.LOGIN);
      }
    });
  };

  const handleRestore = () => {
    dispatch(cancelAccountDeletion({ deleteId: deleteID })).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
        setIsShowAlert(false);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  return (
    <Modal open={isShow} onClose={() => {}}>
      <ModalBox isMobile={isMobile}>
        <Grid container>
          <TopTitleBox
            title="Restore your account"
            requireCloseButton={false}
            setShowModal={setIsShowAlert}
          />
          <Grid item xs={12}>
            <Spacer size="l" />
            You have a pending account deletion request with this account. Proceeding would cancel
            the request.
          </Grid>
          <Grid container item columnSpacing={2}>
            <Grid item xs={6} paddingTop="50px">
              <CustomButton
                fontSize="16px"
                backgroundColor={theme.palette.colors.ui.secondary}
                onClick={handleLogout}
              >
                Logout
              </CustomButton>
            </Grid>
            <Grid item xs={6} paddingTop="50px">
              <CustomButton fontSize="16px" onClick={handleRestore}>
                Restore
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

AccountRestorationBox.defaultProps = {
  deleteID: "",
};

AccountRestorationBox.propTypes = {
  isShow: PropTypes.bool.isRequired,
  setIsShowAlert: PropTypes.func.isRequired,
  deleteID: PropTypes.number,
};
