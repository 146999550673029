import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq } from "../api.services";
import { getAPI } from "../../infrastructure/utils";

export const getRecentSearches = createAsyncThunk("searches/recents", async () => {
  const response = await getReq(`${process.env.REACT_APP_API_PRIVATE_V1}/searches/recents`);
  return response;
});

export const getPopularSearches = createAsyncThunk("searches/popular", async () => {
  const response = await getReq(`${getAPI()}/searches/popular`);
  return response;
});

const searchSlice = createSlice({
  name: "search",
  initialState: {
    reduxStoreTimeIndex: [
      { value: 0, label: "4:00:00 AM", time: "04:00:00" },
      { value: 1, label: "4:30:00 AM", time: "04:30:00" },
      { value: 2, label: "5:00:00 AM", time: "05:00:00" },
      { value: 3, label: "5:30:00 AM", time: "05:30:00" },
      { value: 4, label: "6:00:00 AM", time: "06:00:00" },
      { value: 5, label: "6:30:00 AM", time: "06:30:00" },
      { value: 6, label: "7:00:00 AM", time: "07:00:00" },
      { value: 7, label: "7:30:00 AM", time: "07:30:00" },
      { value: 8, label: "8:00:00 AM", time: "08:00:00" },
      { value: 9, label: "8:30:00 AM", time: "08:30:00" },
      { value: 10, label: "9:00:00 AM", time: "09:00:00" },
      { value: 11, label: "9:30:00 AM", time: "09:30:00" },
      { value: 12, label: "10:00:00 AM", time: "10:00:00" },
      { value: 13, label: "10:30:00 AM", time: "10:30:00" },
      { value: 14, label: "11:00:00 AM", time: "11:00:00" },
      { value: 15, label: "11:30:00 AM", time: "11:30:00" },
      { value: 16, label: "12:00:00 PM", time: "12:00:00" },
      { value: 17, label: "12:30:00 PM", time: "12:30:00" },
      { value: 18, label: "1:00:00 PM", time: "13:00:00" },
      { value: 19, label: "1:30:00 PM", time: "13:30:00" },
      { value: 20, label: "2:00:00 PM", time: "14:00:00" },
      { value: 21, label: "2:30:00 PM", time: "14:30:00" },
      { value: 22, label: "3:00:00 PM", time: "15:00:00" },
      { value: 23, label: "3:30:00 PM", time: "15:30:00" },
      { value: 24, label: "4:00:00 PM", time: "16:00:00" },
      { value: 25, label: "4:30:00 PM", time: "16:30:00" },
      { value: 26, label: "5:00:00 PM", time: "17:00:00" },
      { value: 27, label: "5:30:00 PM", time: "17:30:00" },
      { value: 28, label: "6:00:00 PM", time: "18:00:00" },
      { value: 29, label: "6:30:00 PM", time: "18:30:00" },
      { value: 30, label: "7:00:00 PM", time: "19:00:00" },
      { value: 31, label: "7:30:00 PM", time: "19:30:00" },
      { value: 32, label: "8:00:00 PM", time: "20:00:00" },
      { value: 33, label: "8:30:00 PM", time: "20:30:00" },
      { value: 34, label: "9:00:00 PM", time: "21:00:00" },
      { value: 35, label: "9:30:00 PM", time: "21:30:00" },
      { value: 36, label: "10:00:00 PM", time: "22:00:00" },
      { value: 37, label: "10:30:00 PM", time: "22:30:00" },
      { value: 38, label: "11:00:00 PM", time: "23:00:00" },
      { value: 39, label: "11:30:00 PM", time: "23:30:00" },
    ],
    reduxStoreTimeFrames: [
      {
        label: "Morning",
        value: "morning",
        startIndex: 0,
        endIndex: 16,
        timeIndex: [0, 16],
      },
      {
        label: "Afternoon",
        value: "afternoon",
        startIndex: 16,
        endIndex: 24,
        timeIndex: [16, 24],
      },
      {
        label: "Evening",
        value: "evening",
        startIndex: 24,
        endIndex: 30,
        timeIndex: [24, 30],
      },
      {
        label: "Night",
        value: "night",
        startIndex: 30,
        endIndex: 39,
        timeIndex: [30, 39],
      },
    ],
    getRecentSearchesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getPopularSearchesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getRecentSearches.pending]: (state) => {
      state.getRecentSearchesObj.status = "pending";
    },
    [getRecentSearches.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getRecentSearchesObj.status = "succeeded";
      state.getRecentSearchesObj.data = data;
      state.getRecentSearchesObj.successMessage = message;
    },
    [getRecentSearches.rejected]: (state, action) => {
      const { message } = action.error;

      state.getRecentSearchesObj.status = "failed";
      state.getRecentSearchesObj.errorMessage = message;
    },
    [getPopularSearches.pending]: (state) => {
      state.getPopularSearchesObj.status = "pending";
    },
    [getPopularSearches.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getPopularSearchesObj.status = "succeeded";
      state.getPopularSearchesObj.data = data;
      state.getPopularSearchesObj.successMessage = message;
    },
    [getPopularSearches.rejected]: (state, action) => {
      const { message } = action.error;

      state.getPopularSearchesObj.status = "failed";
      state.getPopularSearchesObj.errorMessage = message;
    },
  },
});

export default searchSlice.reducer;

// state
export const searchSelector = (state) => state.search;
