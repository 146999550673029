import { Box, Grid, Pagination, Typography, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import NoSubscriptionImage from "../../../../assets/images/no-subscription.png";
import CustomButton from "../../../../components/button/custom-button.component";
import CustomSkeleton from "../../../../components/utils/skeleton.component";
import Spacer from "../../../../components/utils/spacer.component";
import { purchasedSubscriptionSelector } from "../../../../services/subscription/purchased/purchased-subscription-slice.service";
import SubscriptionDetailsCard from "./subscription-details-card.component";

function SubscriptionTabPanel({ getSubscriptions }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { getPurchasedSubscriptionTransactionsObj } = useSelector(purchasedSubscriptionSelector);
  const [status, setStatus] = useState("ongoing");
  const [page, setPage] = useState(1);

  const onPageChange = (event, newPage) => {
    setPage(newPage);
    getSubscriptions(status, newPage);
  };

  return (
    <Box>
      <Grid container columnSpacing={2}>
        <Grid item xs={isMobile ? 6 : 2}>
          <CustomButton
            buttonColor={status === "ongoing" ? "primary" : "grey"}
            textColor={status === "ongoing" ? "primary" : "grey"}
            variant="outlined"
            fontSize={theme.fonts.fontSizes.size16}
            onClick={() => {
              setStatus("ongoing");
              getSubscriptions("ongoing", 1);
            }}
          >
            Ongoing
          </CustomButton>
        </Grid>
        <Grid item xs={isMobile ? 6 : 2}>
          <CustomButton
            buttonColor={status === "completed" ? "primary" : "grey"}
            textColor={status === "completed" ? "primary" : "grey"}
            variant="outlined"
            fontSize={theme.fonts.fontSizes.size16}
            onClick={() => {
              setStatus("completed");
              getSubscriptions("completed", 1);
            }}
          >
            Completed
          </CustomButton>
        </Grid>
      </Grid>
      <Spacer size="l" />
      {getPurchasedSubscriptionTransactionsObj.status === "succeeded" ? (
        <Grid container columnSpacing={1} rowSpacing={2}>
          {getPurchasedSubscriptionTransactionsObj.data.items.length > 0 ? (
            <>
              {getPurchasedSubscriptionTransactionsObj.data.items.map((item) => (
                <Grid item xs={isMobile ? 12 : 4} key={item.id}>
                  <SubscriptionDetailsCard itemDetails={item} getSubscriptions={getSubscriptions} />
                </Grid>
              ))}
              <Grid item container justifyContent="center">
                <Pagination
                  page={page}
                  onChange={onPageChange}
                  count={getPurchasedSubscriptionTransactionsObj.data.pagination.totalPages}
                  variant="outlined"
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Spacer size="m" />
              <Typography textAlign="center">No Subscriptions</Typography>
              <Spacer size="m" />
              <Grid container display="flex" justifyContent="center">
                <img
                  width={400}
                  src={NoSubscriptionImage}
                  alt="no-subscription"
                  style={{ objectFit: "contain" }}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid container columnSpacing={1} rowSpacing={1}>
          <Grid item xs={isMobile ? 12 : 4}>
            <CustomSkeleton width="100%" height={139} />
          </Grid>
          <Grid item xs={isMobile ? 12 : 4}>
            <CustomSkeleton width="100%" height={139} />
          </Grid>
          <Grid item xs={isMobile ? 12 : 4}>
            <CustomSkeleton width="100%" height={139} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

SubscriptionTabPanel.propTypes = {
  getSubscriptions: PropTypes.func.isRequired,
};

export default SubscriptionTabPanel;
