import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import KatchLogo from "../../../assets/images/katch_logo";
import TopLeftLink from "../../../components/button/top-left-link.component";
import routes from "../../../components/navigation/routes";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import { login } from "../../../services/auth/auth-slice.service";
import LoginForm from "../components/login/login-form.component";

function LoginScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isLoading, setIsLoading] = useState(false);
  const createSnackBar = useContext(SnackbarContext);

  const handleSubmit = (values) => {
    setIsLoading(true);
    if (values.isRemember) {
      localStorage.setItem("isRemember", true);
      localStorage.setItem("userEmail", values.email);
    } else {
      localStorage.removeItem("isRemember");
      localStorage.removeItem("userEmail");
    }
    values.identifier = values.method === "email" ? values.email : values.phone;
    dispatch(login(values)).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        if (location?.state?.pathname === "/event") {
          navigate(`${location.state.pathname}${location.state.search}`);
        } else navigate(routes.HOME);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.colors.bg.primary,
        minHeight: "100vh",
        opacity: 1,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <TopLeftLink label="< Back to home" pushTo={routes.HOME} />

      <Grid
        container
        sx={{
          width: "100%",
          flexDirection: "column",
        }}
      >
        <PaddedView multiples={isMobile ? 2 : 4}>
          <Grid item>
            <Grid
              container
              sx={{
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Grid item xs={12} sx={{ height: isMobile ? 45 : 60, textAlign: "center" }}>
                <KatchLogo color="green" />
              </Grid>
              <Spacer size="m" />
              <LoginForm isLoading={isLoading} handleSubmit={handleSubmit} />
            </Grid>
          </Grid>
        </PaddedView>
      </Grid>
    </Box>
  );
}

export default LoginScreen;
