import {
  Box,
  Button,
  Grid,
  Modal,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import CustomButton from "../../../../components/button/custom-button.component";
import FormFieldTextWithIcon from "../../../../components/forms/form-field-text-with-icon.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";
import TopTitleBox from "../../../../components/utils/top-title-box.component";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.white,
  borderRadius: theme.shape.borderRadius[2],
  boxShadow: 24,
  padding: "20px",
  maxWidth: "550px",
  width: "90%",
  outline: "none",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: "90px",
  height: "40px",
  boxShadow: 0,
  borderRadius: theme.shape.borderRadius[1],
  textTransform: "none",
  paddingLeft: "0px",
  paddingRight: "0px",
  "&:hover": {
    boxShadow: "none",
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size15,
}));

function TopUpModal({ showModal, setShowModal }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { setFieldValue, values } = useFormikContext();
  const [gettingAmount, setGettingAmount] = useState(true);

  useEffect(() => {
    setGettingAmount(true);
  }, [showModal]);

  const renderAmountSelection = () => (
    <Grid container justifyContent="center" marginTop="40px">
      <Grid item>
        <StyledButton
          sx={{
            width: isMobile ? "90px" : "130px",
            border: values.amount === 50 ? "2px solid" : "1px solid",
            borderColor:
              values.amount === 50
                ? theme.palette.colors.brand.primary
                : theme.palette.colors.ui.border,
          }}
          onClick={() => setFieldValue("amount", 50)}
        >
          <StyledTypography
            sx={{
              color:
                values.amount === 50
                  ? theme.palette.colors.brand.primary
                  : theme.palette.colors.text.secondary,
            }}
            fontWeight={values.amount === 50 && "bold"}
          >
            RM 50
          </StyledTypography>
        </StyledButton>
      </Grid>
      <Spacer position="left" />
      <Grid item>
        <StyledButton
          sx={{
            width: isMobile ? "90px" : "130px",
            border: values.amount === 100 ? "2px solid" : "1px solid",
            borderColor:
              values.amount === 100
                ? theme.palette.colors.brand.primary
                : theme.palette.colors.ui.border,
          }}
          onClick={() => setFieldValue("amount", 100)}
        >
          <StyledTypography
            sx={{
              color:
                values.amount === 100
                  ? theme.palette.colors.brand.primary
                  : theme.palette.colors.text.secondary,
            }}
            fontWeight={values.amount === 100 && "bold"}
          >
            RM 100
          </StyledTypography>
        </StyledButton>
      </Grid>
      <Spacer position="left" />
      <Grid item>
        <StyledButton
          sx={{
            width: isMobile ? "90px" : "130px",
            border: values.amount === 150 ? "2px solid" : "1px solid",
            borderColor:
              values.amount === 150
                ? theme.palette.colors.brand.primary
                : theme.palette.colors.ui.border,
          }}
          onClick={() => setFieldValue("amount", 150)}
        >
          <StyledTypography
            sx={{
              color:
                values.amount === 150
                  ? theme.palette.colors.brand.primary
                  : theme.palette.colors.text.secondary,
            }}
            fontWeight={values.amount === 150 && "bold"}
          >
            RM 150
          </StyledTypography>
        </StyledButton>
      </Grid>
    </Grid>
  );

  const renderGettingAmount = () => (
    <>
      {renderAmountSelection()}
      <Spacer position="top" size="l" />
      <Grid item margin="40px">
        <Typography>Top-up Amount</Typography>
        <Spacer position="top" size="s" />
        <FormFieldTextWithIcon
          name="amount"
          placeholder="Amount"
          icon={<Typography>RM</Typography>}
        />
      </Grid>
      <Grid item margin="30px" xs={12} textAlign="center">
        <CustomButton
          fontSize={theme.fonts.fontSizes.size14}
          fontPadding="4px"
          width="60%"
          onClick={() => {
            if (values.amount >= 50) {
              setGettingAmount(false);
            }
          }}
        >
          Continue
        </CustomButton>
      </Grid>
    </>
  );

  const renderConfirmSubmit = () => (
    <Grid container>
      <PaddedView multiples={2}>
        <Grid item container justifyContent="space-between">
          <Grid item>
            <Typography>Top-up amount:</Typography>
            <Spacer />
            <Typography
              fontSize={theme.fonts.fontSizes.size13}
              sx={{ color: theme.palette.colors.text.secondary }}
            >
              By Online Banking / E-Wallet
            </Typography>
          </Grid>
          <Grid item>
            <Typography>RM {values.amount}</Typography>
          </Grid>
        </Grid>
        <Spacer size="xl" />

        <Grid item textAlign="center" xs={12}>
          <FormSubmitButton width="60%" borderRadius={1}>
            <Typography>Continue</Typography>
          </FormSubmitButton>
        </Grid>
      </PaddedView>
    </Grid>
  );

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <ModalBox>
        <TopTitleBox title="Top Up" requireCloseButton={true} setShowModal={setShowModal} />
        <Spacer position="top" size="l" />
        {gettingAmount ? renderGettingAmount() : renderConfirmSubmit()}
      </ModalBox>
    </Modal>
  );
}

TopUpModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default TopUpModal;
