import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import PlaceIcon from "@mui/icons-material/Place";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";
import routes from "../../../components/navigation/routes";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";

function HighlightCard({ itemDetails }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const highlightOnClick = () => {
    if (itemDetails.type === "event_session") {
      navigate({ pathname: routes.EVENT, search: `?eventSessionId=${itemDetails.session.id}` });
    }
  };

  return (
    <Grid
      item
      sx={{
        backgroundColor: theme.palette.colors.bg.primary,
        borderRadius: `${theme.shape.borderRadius[2]}px`,
        objectFit: "cover",
        height: "100%",
        cursor: "pointer",
      }}
      onClick={highlightOnClick}
    >
      <PaddedView multiples={1}>
        <Grid container columnSpacing={2} alignContent="center">
          <Grid item xs={4} alignSelf="center" paddingY="5px" alignItems="center" display="flex">
            <img
              src={itemDetails.session.event.images[0].imagePath}
              alt="hightlight"
              width="100%"
              height="120px"
              style={{ borderRadius: `${theme.shape.borderRadius[2]}px`, objectFit: "cover" }}
            />
          </Grid>
          <Grid
            item
            xs={8}
            display="grid"
            flexDirection="column"
            alignContent="space-between"
            marginY="10px"
          >
            <Grid item>
              <Grid item>
                <Typography
                  fontWeight="bold"
                  maxWidth={isMobile ? "180px" : "240px"}
                  sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
                >
                  {itemDetails.session.title}
                </Typography>
              </Grid>
              <Spacer />
              <Grid item display="flex" alignContent="center">
                <CalendarMonthOutlinedIcon color="primary" />
                <Spacer position="left" />
                <Typography>{itemDetails.session.date}</Typography>
              </Grid>
              <Grid item display="flex" alignContent="center">
                <AccessTimeOutlinedIcon color="primary" />
                <Spacer position="left" />
                <Typography>{itemDetails.session.time}</Typography>
              </Grid>
            </Grid>
            <Grid item display="flex" alignContent="center">
              <PlaceIcon color="primary" />
              <Spacer position="left" />
              <Typography
                width="100%"
                maxWidth={isMobile ? "180px" : "240px"}
                sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
              >
                {itemDetails.session.event.location.name}
              </Typography>
            </Grid>
            {itemDetails.session.multipleCategories && (
              <Grid item display="flex" alignContent="center" paddingTop="10px">
                <Typography width="100%" textAlign="right" fontWeight="bold" color="primary">
                  Multiple Categories
                </Typography>
              </Grid>
            )}
            {(itemDetails.session.booked || itemDetails.session.maxCapacity) &&
              !itemDetails.session.multipleCategories && (
                <Grid item display="flex" alignContent="center" paddingTop="10px">
                  {itemDetails.session.booked && (
                    <Typography width="100%" textAlign="right" fontWeight="bold" color="primary">
                      Booked
                    </Typography>
                  )}
                  {!itemDetails.session.booked && itemDetails.session.maxCapacity && (
                    <Typography
                      width="100%"
                      textAlign="right"
                      fontWeight="bold"
                      sx={{ color: theme.palette.colors.ui.error }}
                    >
                      Fully Booked
                    </Typography>
                  )}
                </Grid>
              )}
          </Grid>
        </Grid>
      </PaddedView>
    </Grid>
  );
}

HighlightCard.defaultProps = {
  itemDetails: PropTypes.shape({
    id: null,
    type: "",
    session: PropTypes.shape({
      id: null,
      booked: false,
      title: "",
      price: "",
      unlimited: false,
      multipleCategories: false,
      date: "",
      time: "",
      maxCapacity: false,
      event: PropTypes.shape({
        id: null,
        title: "",
        location: PropTypes.shape({
          name: "",
        }),
        images: PropTypes.arrayOf(
          PropTypes.shape({
            id: null,
            imagePath: "",
          }),
        ),
      }),
    }),
  }),
};

HighlightCard.propTypes = {
  itemDetails: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    session: PropTypes.shape({
      id: PropTypes.number,
      booked: PropTypes.bool,
      title: PropTypes.string,
      price: PropTypes.string,
      unlimited: PropTypes.bool,
      multipleCategories: PropTypes.bool,
      date: PropTypes.string,
      time: PropTypes.string,
      maxCapacity: PropTypes.bool,
      event: PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        location: PropTypes.shape({
          name: PropTypes.string,
        }),
        images: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            imagePath: PropTypes.string,
          }),
        ),
      }),
    }),
  }),
};

export default HighlightCard;
