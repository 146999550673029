import { Box } from "@mui/material";
import React from "react";
import Routes from "./components/navigation/index";

function App() {
  // const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  if (process.env.REACT_APP_MAINTENANCE === "true") {
    return (
      <Box
        sx={{
          display: "flex",
          height: "100vh",
        }}
      >
        <img alt="Maintenance" width="100%" />
      </Box>
    );
  }
  return <Routes />;
}

export default App;
