import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { SnackbarContext } from "../../../../components/notification/snackbar.context";
import {
  getTokenizedPayments,
  tokenizedPaymentSelector,
} from "../../../../services/tokenized-payment/tokenized-payment-slice.service";

const LabelContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: "5px",
});

const LabelText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontWeight: "lighter",
  marginLeft: "15px",
}));

const StyledRadio = styled(Radio)(({ theme }) => ({
  color: theme.palette.colors.brand.primary,
  "&.Mui-checked": {
    color: theme.palette.colors.brand.primary,
  },
}));

function PaymentMethodCheckbox() {
  const { values, setFieldValue } = useFormikContext();
  const [value, setValue] = useState(values.tokenizedRemoteCustomerId);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { getTokenizedPaymentsObj } = useSelector(tokenizedPaymentSelector);

  const handleChange = (event) => {
    setValue(event.target.value);
    setFieldValue("tokenizedRemoteCustomerId", event.target.value);
  };

  const getCreditCards = () => {
    dispatch(getTokenizedPayments()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  useEffect(() => {
    getCreditCards();
  }, []);

  return (
    <FormControl sx={{ width: "100%" }}>
      <RadioGroup name="controlled-radio-buttons-group" value={value} onChange={handleChange}>
        {getTokenizedPaymentsObj.status === "succeeded" &&
          getTokenizedPaymentsObj.data.map((item) => (
            <FormControlLabel
              key={uuidv4()}
              value={item.remoteCustomerId}
              control={<StyledRadio />}
              label={
                <LabelContainer>
                  <CreditCardOutlinedIcon />
                  <LabelText>{item.label}</LabelText>
                </LabelContainer>
              }
            />
          ))}
        {getTokenizedPaymentsObj.status === "succeeded" &&
          getTokenizedPaymentsObj.data.length === 0 && (
            <Typography textAlign="center">No Credit Cards.</Typography>
          )}
      </RadioGroup>
    </FormControl>
  );
}

export default PaymentMethodCheckbox;
