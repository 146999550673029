import { Grid, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Spacer from "../../../../components/utils/spacer.component";
import SubscriptionDetailsModal from "./subscription-details-modal.component";

function SubscriptionDetailsCard({ getSubscriptions, itemDetails }) {
  const theme = useTheme();
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <SubscriptionDetailsModal
        getSubscriptions={getSubscriptions}
        itemDetails={itemDetails}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <Grid
        container
        sx={{
          backgroundColor: theme.palette.colors.bg.primary,
          borderRadius: `${theme.shape.borderRadius[2]}px`,
          cursor: "pointer",
          height: "100%",
        }}
        onClick={() => {
          setShowModal(true);
        }}
      >
        <Grid item xs={4}>
          <img
            src={itemDetails.plan.imagePath}
            width="100%"
            height="100%"
            alt="subscription_img"
            style={{
              borderRadius: `${theme.shape.borderRadius[2]}px`,
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
              objectFit: "cover",
            }}
          />
        </Grid>
        <Grid item xs={8} padding="15px">
          <Grid
            item
            display="grid"
            flexDirection="column"
            alignContent="space-between"
            height="100%"
          >
            <Grid item>
              <Grid item>
                <Typography fontWeight="bold">{itemDetails.plan.title}</Typography>
              </Grid>
              <Spacer />
              <Grid item display="flex">
                {itemDetails.status === "expired_non_activated" ? (
                  <Typography
                    textTransform="capitalize"
                    sx={{
                      border: "1px solid",
                      padding: "2px",
                      paddingX: "10px",
                      borderRadius: `${theme.shape.borderRadius[2]}px`,
                      backgroundColor: "#000",
                      color: "#fff",
                    }}
                  >
                    Not Activated
                  </Typography>
                ) : (
                  <Typography
                    textTransform="capitalize"
                    sx={{
                      border: "1px solid",
                      padding: "2px",
                      paddingX: "10px",
                      borderRadius: `${theme.shape.borderRadius[2]}px`,
                      borderColor:
                        itemDetails.status === "active"
                          ? theme.palette.colors.ui.success
                          : theme.palette.colors.ui.error,
                      color:
                        itemDetails.status === "active"
                          ? theme.palette.colors.ui.success
                          : theme.palette.colors.ui.error,
                    }}
                  >
                    {itemDetails.status.replace("_", " ")}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item>
              <Spacer size="xs" />
              {itemDetails.package ? (
                <Typography fontSize={theme.fonts.fontSizes.size14} paddingY="5px">
                  Bundle purchased with <b>{itemDetails.package.title}</b>
                </Typography>
              ) : (
                <Typography fontSize={theme.fonts.fontSizes.size14}>
                  Price:{" "}
                  {itemDetails.plan.strikePrice && (
                    <span style={{ textDecoration: "line-through", color: "grey" }}>
                      RM {itemDetails.plan.strikePrice}
                    </span>
                  )}{" "}
                  <span style={{ color: "#1A7B64", fontWeight: "600" }}>
                    RM {itemDetails.plan.price}
                  </span>
                </Typography>
              )}
              <Spacer size="xs" />
              <Typography fontSize={theme.fonts.fontSizes.size14}>
                Tenure: {itemDetails.plan.numberOfDays} days
              </Typography>
              {itemDetails.activationExpiryDate && (
                <>
                  <Spacer size="xs" />
                  <Typography fontSize={theme.fonts.fontSizes.size14}>
                    Activate Before: {itemDetails.activationExpiryDate}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

SubscriptionDetailsCard.propTypes = {
  itemDetails: PropTypes.shape({
    id: PropTypes.number,
    activationExpiryDate: PropTypes.string,
    startAt: PropTypes.string,
    endAt: PropTypes.string,
    earlierEndAt: PropTypes.string,
    status: PropTypes.string,
    package: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
    paymentTransaction: PropTypes.shape({
      price: PropTypes.string,
    }),
    plan: PropTypes.shape({
      imagePath: PropTypes.string,
      price: PropTypes.string,
      strikePrice: PropTypes.string,
      pricePerDay: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      shortDescription: PropTypes.string,
      numberOfDays: PropTypes.number,
      business: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          city: PropTypes.string,
          reviews: PropTypes.shape({
            averageRatings: PropTypes.string,
            totalRatings: PropTypes.number,
          }),

          images: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number,
              imagePath: PropTypes.string,
            }),
          ),
        }),
      ),
    }),
  }).isRequired,
  getSubscriptions: PropTypes.func.isRequired,
};

export default SubscriptionDetailsCard;
