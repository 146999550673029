import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq, putReq } from "../../api.services";

export const cancelSubscriptionBill = createAsyncThunk(
  "subscriptions/bills/{billId}/cancel",
  async (payload) => {
    const { billId } = payload;
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/subscriptions/bills/${billId}/cancel`,
    );
    return response;
  },
);

export const getSubscriptionBillViaRemoteBillId = createAsyncThunk(
  "subscriptions/bills/{billId}",
  async (payload) => {
    const { remoteBillId } = payload;
    const response = getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/subscriptions/bills/${remoteBillId}`,
      payload,
    );
    return response;
  },
);

const subscriptionBillSlice = createSlice({
  name: "subscriptionBill",
  initialState: {
    cancelSubscriptionBillObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getSubscriptionBillViaRemoteBillIdObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [cancelSubscriptionBill.pending]: (state) => {
      state.cancelSubscriptionBillObj.status = "pending";
    },
    [cancelSubscriptionBill.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.cancelSubscriptionBillObj.status = "succeeded";
      state.cancelSubscriptionBillObj.data = data;
      state.cancelSubscriptionBillObj.successMessage = message;
    },
    [cancelSubscriptionBill.rejected]: (state, action) => {
      const { message } = action.error;

      state.cancelSubscriptionBillObj.status = "failed";
      state.cancelSubscriptionBillObj.errorMessage = message;
    },
    [getSubscriptionBillViaRemoteBillId.pending]: (state) => {
      state.getSubscriptionBillViaRemoteBillIdObj.status = "pending";
    },
    [getSubscriptionBillViaRemoteBillId.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getSubscriptionBillViaRemoteBillIdObj.status = "succeeded";
      state.getSubscriptionBillViaRemoteBillIdObj.data = data;
      state.getSubscriptionBillViaRemoteBillIdObj.successMessage = message;
    },
    [getSubscriptionBillViaRemoteBillId.rejected]: (state, action) => {
      const { message } = action.error;

      state.getSubscriptionBillViaRemoteBillIdObj.status = "failed";
      state.getSubscriptionBillViaRemoteBillIdObj.errorMessage = message;
    },
  },
});

export default subscriptionBillSlice.reducer;

// state
export const subscriptionBillSelector = (state) => state.subscriptionBill;
