import BusinessIcon from "@mui/icons-material/Business";
import DoneIcon from "@mui/icons-material/Done";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import { Grid, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";

import { createLead, leadSelector } from "../../../services/lead/lead-slice.service";

import CustomButton from "../../../components/button/custom-button.component";
import FormFieldTextWithIcon from "../../../components/forms/form-field-text-with-icon.component";
import Form from "../../../components/forms/form.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import PaddedView from "../../../components/utils/padded-view.component";

const AppBarTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "25px",
  color: theme.palette.colors.text.white,
}));

function MerchantPricing() {
  const theme = useTheme();
  const SearchFormRef = useRef();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { createLeadObj } = useSelector(leadSelector);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#merchant-pricing") {
      document.getElementById("merchant-pricing")?.scrollIntoView();
    }
  }, []);

  const renderCheckListItem = (text) => (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={2}>
          <DoneIcon sx={{ color: theme.palette.colors.brand.primary }} />
        </Grid>
        <Grid item xs={10}>
          {text}
        </Grid>
      </Grid>
    </Grid>
  );

  const renderWideCheckListItem = (text) => (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={isMobile ? 2 : 1}>
          <DoneIcon sx={{ color: theme.palette.colors.brand.primary }} />
        </Grid>
        <Grid item xs={isMobile ? 10 : 11}>
          {text}
        </Grid>
      </Grid>
    </Grid>
  );

  const handleSend = (values, { resetForm }) => {
    dispatch(createLead({ ...values, dismissed: false })).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
        resetForm();
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        background: theme.palette.colors.brand.primary,
      }}
      paddingX={theme.dimensions.ScreenPaddingX}
      paddingY="25px"
    >
      <Grid item lg={7} sm={12}>
        <section id="merchant-pricing">
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Grid item paddingBottom="15px">
                <AppBarTitle>Be Our Merchant, Grow Your Business</AppBarTitle>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent="space-between" spacing={{ xs: 0, sm: 2 }}>
              <Grid item sm={7} xs={12}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      background: theme.palette.colors.bg.lightGreen,
                      borderTopLeftRadius: `${theme.shape.borderRadius[1]}px`,
                      borderTopRightRadius: `${theme.shape.borderRadius[1]}px`,
                      textAlign: "center",
                    }}
                  >
                    <PaddedView>
                      <Typography variant="h6" fontWeight="bold">
                        Merchant Subscription
                      </Typography>
                    </PaddedView>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      background: theme.palette.colors.bg.white,
                      borderBottomLeftRadius: `${theme.shape.borderRadius[1]}px`,
                      borderBottomRightRadius: `${theme.shape.borderRadius[1]}px`,
                    }}
                  >
                    <Grid container paddingY="8px">
                      <Grid item xs={12}>
                        <Grid container justifyContent="center">
                          <Grid item>
                            <Typography variant="h4">RM250</Typography>
                          </Grid>
                          <Grid item sx={{ marginTop: "13px", marginLeft: "6px" }}>
                            <Typography>per month</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} paddingX={theme.dimensions.ScreenPaddingX}>
                        <Typography>+ 12% per transaction fee</Typography>
                      </Grid>
                      <Grid item xs={12} paddingX={theme.dimensions.ScreenPaddingX}>
                        <Typography>+ RM200 one-off onboarding fee</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          borderBottom: "1px solid black",
                          marginBottom: "8px",
                          marginTop: "8px",
                        }}
                      />
                      <Grid
                        item
                        xs={12}
                        paddingX={theme.dimensions.ScreenPaddingX}
                        sx={{ marginBottom: "8px" }}
                      >
                        <Typography textAlign="center" fontWeight="bold">
                          Business Management Tools
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        md={6}
                        sm={12}
                        xs={12}
                        paddingX={theme.dimensions.ScreenPaddingX}
                        paddingY="8px"
                      >
                        <Grid container>
                          <Grid item xs={12} paddingBottom="8px">
                            <Typography fontWeight="bold">Booking Management</Typography>
                          </Grid>
                          {renderCheckListItem("Listing of subscriptions")}
                          {renderCheckListItem("Listing of packages")}
                          {renderCheckListItem("Listing of classes")}
                          {renderCheckListItem("Listing of events")}
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        md={6}
                        sm={12}
                        xs={12}
                        paddingX={theme.dimensions.ScreenPaddingX}
                        paddingY="8px"
                      >
                        <Grid container>
                          <Grid item xs={12} paddingBottom="8px">
                            <Typography fontWeight="bold">Analytics</Typography>
                          </Grid>
                          {renderCheckListItem("Sales analysis")}
                          {renderCheckListItem("Booking analysis and reports")}
                          {renderCheckListItem("Analysis and reports export")}
                          {renderCheckListItem("Staff management")}
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        md={6}
                        sm={12}
                        xs={12}
                        paddingX={theme.dimensions.ScreenPaddingX}
                        paddingY="8px"
                      >
                        <Grid container>
                          <Grid item xs={12} paddingBottom="8px">
                            <Typography fontWeight="bold">Tracking & Check In</Typography>
                          </Grid>
                          {renderCheckListItem("Calendar tracking")}
                          {renderCheckListItem("QR code check-in")}
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        md={6}
                        sm={12}
                        xs={12}
                        paddingX={theme.dimensions.ScreenPaddingX}
                        paddingY="8px"
                      >
                        <Grid container>
                          <Grid item xs={12} paddingBottom="8px">
                            <Typography fontWeight="bold">Payment</Typography>
                          </Grid>
                          {renderCheckListItem("Payment management")}
                          {renderCheckListItem("Payment gateway")}
                          {renderCheckListItem("Statement viewing")}
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        md={6}
                        sm={12}
                        xs={12}
                        paddingX={theme.dimensions.ScreenPaddingX}
                        paddingY="8px"
                      >
                        <Grid container>
                          <Grid item xs={12} paddingBottom="8px">
                            <Typography fontWeight="bold">Rewards</Typography>
                          </Grid>
                          {renderCheckListItem("Promo code generation")}
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        md={6}
                        sm={12}
                        xs={12}
                        paddingX={theme.dimensions.ScreenPaddingX}
                        paddingY="8px"
                      >
                        <Grid container>
                          <Grid item xs={12} paddingBottom="8px">
                            <Typography fontWeight="bold">Events</Typography>
                          </Grid>
                          {renderCheckListItem("Event creation")}
                          {renderCheckListItem("Event analytics")}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={5} xs={12} sx={{ marginTop: { xs: "15px", sm: "0px" } }}>
                <Grid
                  container
                  sx={{
                    height: "100%",
                    background: theme.palette.colors.bg.white,
                    borderRadius: `${theme.shape.borderRadius[1]}px`,
                  }}
                >
                  <Grid item xs={12}>
                    <Form
                      innerRef={SearchFormRef}
                      initialValues={{ email: "", name: "", phone: "", company: "" }}
                      validationSchema={Yup.object().shape({
                        email: Yup.string().nullable().label("Email"),
                        name: Yup.string().nullable().label("Name"),
                        phone: Yup.string().nullable().label("Phone"),
                        company: Yup.string().nullable().label("Business"),
                      })}
                      onSubmit={handleSend}
                    >
                      <Grid
                        item
                        xs={12}
                        paddingX={theme.dimensions.ScreenPaddingX}
                        paddingTop="15px"
                      >
                        <Grid container>
                          <Grid item>
                            <Typography fontWeight="bold">For the first 100 merchants</Typography>
                          </Grid>
                          <Grid item xs={12} sx={{ marginTop: "8px" }}>
                            <Grid container>
                              <Grid item>
                                <Typography variant="h4">FOC</Typography>
                              </Grid>
                              <Grid item sx={{ marginTop: "13px", marginLeft: "6px" }}>
                                <Typography>subscription fee</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography>No onboarding fee</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography>+ 6% per transaction fee</Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        paddingX={theme.dimensions.ScreenPaddingX}
                        paddingTop={{ sm: "60px", xs: "20px" }}
                      >
                        <Grid container justifyContent="center">
                          <Grid item>
                            <Typography fontWeight="bold">Get in touch</Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} paddingY="15px">
                        <Grid container justifyContent="center">
                          <Grid item xs={11}>
                            <FormFieldTextWithIcon
                              name="email"
                              placeholder="Enter your email address"
                              showIcon={true}
                              icon={<MailOutlineIcon color="primary" />}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} paddingBottom="15px">
                        <Grid container justifyContent="center">
                          <Grid item xs={11}>
                            <FormFieldTextWithIcon
                              name="name"
                              placeholder="Enter your name"
                              showIcon={true}
                              icon={<PersonIcon color="primary" />}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} paddingBottom="15px">
                        <Grid container justifyContent="center">
                          <Grid item xs={11}>
                            <FormFieldTextWithIcon
                              name="company"
                              placeholder="Enter your business name"
                              showIcon={true}
                              icon={<BusinessIcon color="primary" />}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container justifyContent="center">
                          <Grid item xs={11}>
                            <FormFieldTextWithIcon
                              name="phone"
                              placeholder="Enter your phone number"
                              showIcon={true}
                              icon={<PhoneIcon color="primary" />}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} paddingY="25px">
                        <Grid container justifyContent="center">
                          <Grid item xs={11}>
                            <CustomButton
                              variant="contained"
                              textColor="white"
                              fontSize={theme.fonts.fontSizes.size13}
                              width="100%"
                              height="40px"
                              disabled={createLeadObj.status === "pending"}
                              onClick={() => SearchFormRef.current.handleSubmit()}
                            >
                              Submit
                            </CustomButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Form>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item sm={12} xs={12} marginTop="15px">
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      background: theme.palette.colors.bg.lightGreen,
                      borderTopLeftRadius: `${theme.shape.borderRadius[1]}px`,
                      borderTopRightRadius: `${theme.shape.borderRadius[1]}px`,
                      textAlign: "center",
                    }}
                  >
                    <PaddedView>
                      <Typography variant="h6" fontWeight="bold">
                        Upcoming Tools
                      </Typography>
                    </PaddedView>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      background: theme.palette.colors.bg.white,
                      borderBottomLeftRadius: `${theme.shape.borderRadius[1]}px`,
                      borderBottomRightRadius: `${theme.shape.borderRadius[1]}px`,
                    }}
                  >
                    <Grid container paddingY="8px">
                      <Grid
                        item
                        md={6}
                        sm={12}
                        xs={12}
                        paddingX={theme.dimensions.ScreenPaddingX}
                        paddingY="8px"
                      >
                        <Grid container>
                          <Grid item xs={12} paddingBottom="8px">
                            <Typography fontWeight="bold">E-Commerce</Typography>
                          </Grid>
                          {renderWideCheckListItem("Selling products")}
                          {renderWideCheckListItem("Buy now pay later")}
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        md={6}
                        sm={12}
                        xs={12}
                        paddingX={theme.dimensions.ScreenPaddingX}
                        paddingY="8px"
                      >
                        <Grid container>
                          <Grid item xs={12} paddingBottom="8px">
                            <Typography fontWeight="bold">Rewards</Typography>
                          </Grid>
                          {renderWideCheckListItem("Loyalty program")}
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        md={6}
                        sm={12}
                        xs={12}
                        paddingX={theme.dimensions.ScreenPaddingX}
                        paddingY="8px"
                      >
                        <Grid container>
                          <Grid item xs={12} paddingBottom="8px">
                            <Typography fontWeight="bold">Referral</Typography>
                          </Grid>
                          {renderWideCheckListItem("Referral program")}
                          {renderWideCheckListItem("WOD (Workout Of the Day) management")}
                          {renderWideCheckListItem("KATCH your workout partner")}
                          {renderWideCheckListItem("Social Media")}
                          {renderWideCheckListItem("CRM system")}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </section>
      </Grid>
    </Grid>
  );
}

export default MerchantPricing;
