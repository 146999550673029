import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import WbTwilightOutlinedIcon from "@mui/icons-material/WbTwilightOutlined";
import {
  Box,
  ButtonBase,
  Grid,
  Slider,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as CoffeeLogo } from "../../../assets/images/coffee.svg";
import CustomButton from "../../../components/button/custom-button.component";
import BottomBarContainer from "../../../components/utils/bottom-bar-container.component";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import { searchSelector } from "../../../services/search/search-slice.service";

const TimeFrameButton = styled(ButtonBase)(({ theme }) => ({
  padding: "25px",
  width: "95%",
  minWidth: "90px",
  border: `1px solid ${theme.palette.colors.ui.border}`,
  borderRadius: `${theme.shape.borderRadius[1]}px`,
}));

function TimeFilterContent({
  showPhoneViewTimeFilter,
  setShowPhoneViewTimeFilter,
  setShowTimeFilterContent,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { reduxStoreTimeIndex, reduxStoreTimeFrames } = useSelector(searchSelector);
  const { setFieldValue, values, handleSubmit } = useFormikContext();
  let currentTime = format(new Date(), "HH:mm:ss");
  const [timeOffset, setTimeOffset] = useState(
    values.selectedDate === format(new Date(), "yyyy-MM-dd")
      ? reduxStoreTimeIndex.find((item) => item.time > currentTime).value
      : 0,
  );

  const getIcon = (label) => {
    switch (label) {
      case "morning":
        return <CoffeeLogo width={35} height={35} />;
      case "afternoon":
        return (
          <LightModeOutlinedIcon sx={{ fontSize: 35, color: theme.palette.colors.ui.border }} />
        );
      case "evening":
        return (
          <WbTwilightOutlinedIcon sx={{ fontSize: 35, color: theme.palette.colors.ui.border }} />
        );
      case "night":
        return (
          <DarkModeOutlinedIcon sx={{ fontSize: 35, color: theme.palette.colors.ui.border }} />
        );
      default:
        return false;
    }
  };

  const handleTimeSliderChange = (event, newValue, activeThumb) => {
    setFieldValue("selectedTimeLabel", "");
    let selectedArr = [];
    if (activeThumb === 0) {
      selectedArr = [Math.min(newValue[0], newValue[1] - 1), newValue[1]];
      setFieldValue("selectedStartTime", selectedArr);
    } else {
      selectedArr = [newValue[0], Math.max(newValue[1], newValue[0] + 1)];
      setFieldValue("selectedStartTime", selectedArr);
    }

    const startTimeLastColonIndex = reduxStoreTimeIndex[selectedArr[0]].label.lastIndexOf(":");
    const endTimeLastColonIndex = reduxStoreTimeIndex[selectedArr[1]].label.lastIndexOf(":");

    const startOutput =
      reduxStoreTimeIndex[selectedArr[0]].label.slice(0, startTimeLastColonIndex) +
      reduxStoreTimeIndex[selectedArr[0]].label.slice(startTimeLastColonIndex + 3);

    const endOutput =
      reduxStoreTimeIndex[selectedArr[1]].label.slice(0, endTimeLastColonIndex) +
      reduxStoreTimeIndex[selectedArr[1]].label.slice(endTimeLastColonIndex + 3);

    const period = `${startOutput} - ${endOutput}`;
    setFieldValue("selectedTimePeriod", period);
  };

  const timeFrameIsSelected = (timeFrameIndex) => {
    if (
      values.selectedStartTime[0] >= reduxStoreTimeFrames[timeFrameIndex].startIndex &&
      values.selectedStartTime[1] <= reduxStoreTimeFrames[timeFrameIndex].endIndex
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!showPhoneViewTimeFilter) {
      const today = format(new Date(), "yyyy-MM-dd");
      if (values.selectedDate === today) {
        currentTime = format(new Date(), "HH:mm:ss");
        const newOffset = reduxStoreTimeIndex.find((item) => item.time > currentTime).value;
        setTimeOffset(newOffset);
        values.selectedStartTime = [newOffset, 39];
        values.tempSelectedStartTime = [newOffset, 39];
        values.tempSelectedTimeLabel = "";
        values.tempSelectedTimePeriod = "";
        values.startAt = `${values.selectedDate} ${reduxStoreTimeIndex[newOffset].time}`;
        values.endAt = `${values.selectedDate} ${reduxStoreTimeIndex[39].time}`;
      } else {
        setTimeOffset(0);
        values.selectedStartTime = [0, 39];
        values.tempSelectedStartTime = [0, 39];
        values.tempSelectedTimeLabel = "";
        values.tempSelectedTimePeriod = "";
        values.startAt = `${values.selectedDate} ${reduxStoreTimeIndex[0].time}`;
        values.endAt = `${values.selectedDate} ${reduxStoreTimeIndex[39].time}`;
      }
      values.selectedTimeLabel = "";
      values.selectedTimePeriod = "";
      handleSubmit();
    }
  }, [values.selectedDate]);

  return (
    <>
      <PaddedView multiples={3}>
        <Grid item>
          <Typography>Activities by time period :</Typography>
        </Grid>
        <Spacer />
        <Box sx={{ width: "100%", overflowX: isMobile && "auto" }}>
          <Grid container width={isMobile ? "450px" : "100%"}>
            {reduxStoreTimeFrames.map((item, index) => {
              if (timeOffset < item.endIndex) {
                return (
                  <Grid item xs={3} key={item.value}>
                    <TimeFrameButton
                      onClick={() => {
                        setFieldValue("selectedTimeLabel", item.label);
                        if (timeOffset > item.startIndex && timeOffset < item.endIndex) {
                          setFieldValue("selectedStartTime", [timeOffset, item.endIndex]);
                        } else {
                          setFieldValue("selectedStartTime", item.timeIndex);
                        }
                      }}
                      sx={{
                        border: timeFrameIsSelected(index) && "1px solid",
                        borderColor:
                          timeFrameIsSelected(index) && theme.palette.colors.brand.primary,
                        ".MuiSvgIcon-root": {
                          stroke: timeFrameIsSelected(index) && theme.palette.colors.brand.primary,
                          color: timeFrameIsSelected(index) && theme.palette.colors.brand.primary,
                        },
                      }}
                    >
                      <Grid item>
                        {getIcon(item.value)}
                        <Typography
                          color={
                            timeFrameIsSelected(index)
                              ? theme.palette.colors.brand.primary
                              : theme.palette.colors.text.secondary
                          }
                        >
                          {item.label}
                        </Typography>
                      </Grid>
                    </TimeFrameButton>
                  </Grid>
                );
              }
              return null;
            })}
          </Grid>
        </Box>

        <Spacer size="l" />
        <Grid item>
          <Typography>Activities by start time :</Typography>
          <Spacer />
          <Typography textAlign="right">
            {reduxStoreTimeIndex[values.selectedStartTime[0]].label} -{" "}
            {reduxStoreTimeIndex[values.selectedStartTime[1]].label}
          </Typography>
          <Slider
            value={values.selectedStartTime}
            disableSwap
            min={timeOffset}
            max={39}
            onChange={handleTimeSliderChange}
          />
        </Grid>
      </PaddedView>
      <Spacer size="xxl" />
      <BottomBarContainer>
        <Grid container display="flex" justifyContent="center" columnSpacing={2}>
          <Grid item xs={5}>
            <CustomButton
              variant="outlined"
              fontSize={theme.fonts.fontSizes.size15}
              onClick={() => {
                setFieldValue("selectedStartTime", [timeOffset, 39]);
                setFieldValue("selectedTimeLabel", "");
                setFieldValue("selectedTimePeriod", "");
                setFieldValue("tempSelectedStartTime", [timeOffset, 39]);
                setFieldValue("tempSelectedTimeLabel", "");
                setFieldValue("tempSelectedTimePeriod", "");
                setShowPhoneViewTimeFilter(false);
                setShowTimeFilterContent(false);
                setFieldValue(
                  "startAt",
                  `${values.selectedDate} ${reduxStoreTimeIndex[timeOffset].time}`,
                );
                setFieldValue("endAt", `${values.selectedDate} ${reduxStoreTimeIndex[39].time}`);
                handleSubmit();
              }}
            >
              Reset
            </CustomButton>
          </Grid>
          <Grid item xs={5}>
            <CustomButton
              fontSize={theme.fonts.fontSizes.size15}
              onClick={() => {
                setShowPhoneViewTimeFilter(false);
                setShowTimeFilterContent(false);
                setFieldValue("tempSelectedStartTime", values.selectedStartTime);
                setFieldValue("tempSelectedTimeLabel", values.selectedTimeLabel);
                setFieldValue("tempSelectedTimePeriod", values.selectedTimePeriod);
                setFieldValue(
                  "startAt",
                  `${values.selectedDate} ${reduxStoreTimeIndex[values.selectedStartTime[0]].time}`,
                );
                setFieldValue(
                  "endAt",
                  `${values.selectedDate} ${reduxStoreTimeIndex[values.selectedStartTime[1]].time}`,
                );
                handleSubmit();
              }}
            >
              Apply
            </CustomButton>
          </Grid>
        </Grid>
      </BottomBarContainer>
    </>
  );
}

TimeFilterContent.defaultProps = {
  showPhoneViewTimeFilter: false,
};

TimeFilterContent.propTypes = {
  setShowPhoneViewTimeFilter: PropTypes.func.isRequired,
  setShowTimeFilterContent: PropTypes.func.isRequired,
  showPhoneViewTimeFilter: PropTypes.bool,
};

export default TimeFilterContent;
