import { Box, styled } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import PaddedView from "./padded-view.component";

const Container = styled(Box)(({ theme }) => ({
  zIndex: 1,
  position: "absolute",
  bottom: 0,
  width: "100%",
  paddingTop: "20px",
  paddingBottom: "20px",
  backgroundColor: theme.palette.colors.bg.white,
}));

function BottomBarContainer({ children }) {
  return (
    <PaddedView>
      <Container>{children}</Container>
    </PaddedView>
  );
}

BottomBarContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BottomBarContainer;
