import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import Spacer from "../../../../components/utils/spacer.component";
import TransactionHistory from "../../components/credit/transaction-history.component";
import UserCredits from "../../components/credit/user-credits.component";
import Loading from "../../../../components/notification/backdrop-loading.component";

export default function CreditScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [isLoading, setIsLoading] = useState(false);
  return (
    <Box marginTop="20px" paddingX={isMobile ? "20px" : ""}>
      <Loading isLoading={isLoading} />
      <UserCredits isLoading={isLoading} setIsLoading={setIsLoading} />
      <Spacer position="top" size="l" />
      <TransactionHistory />
    </Box>
  );
}
