import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postReq, setAxiosToken } from "../api.services";

export const login = createAsyncThunk("auth/login", async (payload) => {
  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V2}/sign_in`, payload);
  return response;
});

export const register = createAsyncThunk("auth/register", async (payload) => {
  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V2}/sign_up`, payload);
  return response;
});

export const verifyIdentifier = createAsyncThunk("auth/verify_identifier", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V2}/users/verify_identifier`,
    payload,
  );
  return response;
});

export const resendResetVerificationCode = createAsyncThunk(
  "auth/resend_reset_verification_code",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V2}/resend_reset_verification_code`,
      payload,
    );
    return response;
  },
);

export const resendIdentifierVerificationCode = createAsyncThunk(
  "auth/resend_identifier_verification_code",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V2}/users/resend_identifier_verification_code`,
      payload,
    );
    return response;
  },
);

export const forgotPassword = createAsyncThunk("auth/forgot_password", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V2}/forgot_password`,
    payload,
  );
  return response;
});

export const resetPassword = createAsyncThunk("auth/reset_password", async (payload) => {
  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V2}/reset_password`, payload);
  return response;
});

export const logout = createAsyncThunk("auth/logout", async (payload) => {
  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/logout`, payload);
  return response;
});

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    loginObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    registerObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    forgotPasswordObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    verifyIdentifierObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    resendResetVerificationCodeObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    resendIdentifierVerificationCodeObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    resetPasswordObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    logoutObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {
    resetUser: (state) => {
      state.user = null;
      state.loginObj.data = null;
      setAxiosToken(null);
      localStorage.removeItem("katchUserAuthToken");
      localStorage.removeItem("katchUserExpireAt");
    },
  },
  extraReducers: {
    [login.pending]: (state) => {
      state.loginObj.status = "pending";
    },
    [login.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.loginObj.status = "succeeded";
      state.loginObj.data = data;
      state.user = data;
      localStorage.setItem("katchUserAuthToken", data.auth.token);
      localStorage.setItem("katchUserExpireAt", data.auth.expireAt);
      setAxiosToken(data.auth.token);
      state.loginObj.successMessage = message;
    },
    [login.rejected]: (state, action) => {
      const { message } = action.error;

      localStorage.removeItem("katchUserAuthToken");
      localStorage.removeItem("katchUserExpireAt");
      state.loginObj.status = "failed";
      state.loginObj.errorMessage = message;
    },
    [register.pending]: (state) => {
      state.registerObj.status = "pending";
    },
    [register.fulfilled]: (state, action) => {
      const { data, message } = action.payload;
      localStorage.setItem("katchUserAuthToken", data.auth.token);
      localStorage.setItem("katchUserExpireAt", data.auth.expireAt);
      setAxiosToken(data.auth.token);
      state.registerObj.status = "succeeded";
      state.registerObj.data = data;
      state.registerObj.successMessage = message;
    },
    [register.rejected]: (state, action) => {
      const { message } = action.error;

      localStorage.removeItem("katchUserAuthToken");
      localStorage.removeItem("katchUserExpireAt");
      state.registerObj.status = "failed";
      state.registerObj.errorMessage = message;
    },
    [verifyIdentifier.pending]: (state) => {
      state.verifyIdentifierObj.status = "pending";
    },
    [verifyIdentifier.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.verifyIdentifierObj.status = "succeeded";
      state.verifyIdentifierObj.data = data;
      state.verifyIdentifierObj.successMessage = message;
    },
    [verifyIdentifier.rejected]: (state, action) => {
      const { message } = action.error;

      state.verifyIdentifierObj.status = "failed";
      state.verifyIdentifierObj.errorMessage = message;
    },
    [resendResetVerificationCode.pending]: (state) => {
      state.resendResetVerificationCodeObj.status = "pending";
    },
    [resendResetVerificationCode.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.resendResetVerificationCodeObj.status = "succeeded";
      state.resendResetVerificationCodeObj.data = data;
      state.resendResetVerificationCodeObj.successMessage = message;
    },
    [resendResetVerificationCode.rejected]: (state, action) => {
      const { message } = action.error;

      state.resendResetVerificationCodeObj.status = "failed";
      state.resendResetVerificationCodeObj.errorMessage = message;
    },
    [resendIdentifierVerificationCode.pending]: (state) => {
      state.resendIdentifierVerificationCodeObj.status = "pending";
    },
    [resendIdentifierVerificationCode.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.resendIdentifierVerificationCodeObj.status = "succeeded";
      state.resendIdentifierVerificationCodeObj.data = data;
      state.resendIdentifierVerificationCodeObj.successMessage = message;
    },
    [resendIdentifierVerificationCode.rejected]: (state, action) => {
      const { message } = action.error;

      state.resendIdentifierVerificationCodeObj.status = "failed";
      state.resendIdentifierVerificationCodeObj.errorMessage = message;
    },
    [forgotPassword.pending]: (state) => {
      state.forgotPasswordObj.status = "pending";
    },
    [forgotPassword.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.forgotPasswordObj.status = "succeeded";
      state.forgotPasswordObj.data = data;
      state.forgotPasswordObj.successMessage = message;
    },
    [forgotPassword.rejected]: (state, action) => {
      const { message } = action.error;

      state.resetPasswordObj.status = "failed";
      state.resetPasswordObj.errorMessage = message;
    },
    [resetPassword.pending]: (state) => {
      state.resetPasswordObj.status = "pending";
    },
    [resetPassword.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.resetPasswordObj.status = "succeeded";
      state.resetPasswordObj.data = data;
      state.resetPasswordObj.successMessage = message;
    },
    [resetPassword.rejected]: (state, action) => {
      const { message } = action.error;

      state.resetPasswordObj.status = "failed";
      state.resetPasswordObj.errorMessage = message;
    },
    [logout.pending]: (state) => {
      state.logoutObj.status = "pending";
    },
    [logout.fulfilled]: (state, action) => {
      const { message } = action.payload;

      state.logoutObj.status = "succeeded";
      state.logoutObj.successMessage = message;

      state.user = null;
      state.loginObj.data = null;
      setAxiosToken(null);
      localStorage.removeItem("katchUserAuthToken");
      localStorage.removeItem("katchUserExpireAt");
    },
    [logout.rejected]: (state, action) => {
      const { message } = action.error;

      state.logoutObj.status = "failed";
      state.logoutObj.errorMessage = message;
    },
  },
});

export default authSlice.reducer;

// state
export const authSelector = (state) => state.auth;

export const { resetUser } = authSlice.actions;
