import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq } from "../../api.services";
import { getAPI } from "../../../infrastructure/utils";

export const getSubscriptionPlans = createAsyncThunk(
  "subscriptions/plans?businessId={businessId}",
  async (payload) => {
    const { businessId, page } = payload;
    const response = await getReq(
      `${getAPI()}/subscriptions/plans?businessId=${businessId}&page=${page}`,
    );
    return response;
  },
);

export const getSubscriptionPlanDetails = createAsyncThunk(
  "subscriptions/plans/{planId}",
  async (payload) => {
    const { planId } = payload;
    const response = await getReq(`${getAPI()}/subscriptions/plans/${planId}`);
    return response;
  },
);

const subscriptionPlanSlice = createSlice({
  name: "subscriptionPlan",
  initialState: {
    reduxStoreSubscriptionPlans: [],
    getSubscriptionPlansObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getSubscriptionPlanDetailsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getSubscriptionPlans.pending]: (state) => {
      state.getSubscriptionPlansObj.status = "pending";
    },
    [getSubscriptionPlans.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getSubscriptionPlansObj.status = "succeeded";
      state.getSubscriptionPlansObj.data = data;
      state.getSubscriptionPlansObj.successMessage = message;
    },
    [getSubscriptionPlans.rejected]: (state, action) => {
      const { message } = action.error;

      state.getSubscriptionPlansObj.status = "failed";
      state.getSubscriptionPlansObj.errorMessage = message;
    },
    [getSubscriptionPlanDetails.pending]: (state) => {
      state.getSubscriptionPlanDetailsObj.status = "pending";
    },
    [getSubscriptionPlanDetails.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getSubscriptionPlanDetailsObj.status = "succeeded";
      state.getSubscriptionPlanDetailsObj.data = data;
      state.getSubscriptionPlanDetailsObj.successMessage = message;
    },
    [getSubscriptionPlanDetails.rejected]: (state, action) => {
      const { message } = action.error;

      state.getSubscriptionPlanDetailsObj.status = "failed";
      state.getSubscriptionPlanDetailsObj.errorMessage = message;
    },
  },
});

export default subscriptionPlanSlice.reducer;

// state
export const subscriptionPlanSelector = (state) => state.subscriptionPlan;
