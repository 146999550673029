import { Grid, List, ListItem, styled, Typography } from "@mui/material";
import React from "react";
import HorizontalDivider from "../../../components/utils/horizontal-divider.component";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";

const SectionText = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size18,
  fontWeight: "bold",
}));

const StyledListItem = styled(ListItem)({
  display: "list-item",
});

function PrivacyPolicyScreen() {
  return (
    <PaddedView multiples={2}>
      <Spacer size="xl" />

      <Grid container>
        <Grid item>
          <Grid item>
            <SectionText>Last Updated: 22 September 2023</SectionText>
          </Grid>
          <Spacer size="m" />
          <Grid item>
            <Typography textAlign="justify">
              This Privacy Policy outlines how KATCH collects, uses, maintains and discloses your
              personal data in respect of commercial transactions and how KATCH safeguards the
              personal data. The application of privacy policy will be for our katch.asia and also
              our mobile application.
            </Typography>
            <Spacer />
            <Typography textAlign="justify">
              “KATCH” or “We” in this Privacy Policy refers to KATCH International Sdn Bhd
              (“KATCH”), including its affiliates companies. KATCH’s nature of business is to enable
              the users to find, book and attend a variety of fitness, recreational and wellness
              classes, services, or products offered and operated by fitness studios, gyms,
              trainers, venues or other third parties through our platform (&quot;Partners&quot;).
            </Typography>
          </Grid>
        </Grid>
        <HorizontalDivider spacerSize="l" />
        <Grid item>
          <Grid item>
            <SectionText>1. Your consent is important</SectionText>
          </Grid>
          <Spacer size="m" />
          <Grid item>
            <Typography textAlign="justify">
              When you request information or sign up for any of our products and services or when
              you enter into any commercial transactions with KATCH, you may be required to provide
              KATCH with your personal data. In doing so, you consent to its use by KATCH in
              accordance with this Privacy Policy. Your personal data may have otherwise been
              provided to KATCH by a third party (for example your spouse, a company in which you
              are a director, an officer or a shareholder, or a partnership in which you are a
              partner) for products or services that these third parties have sought from KATCH. In
              this context, the term “you” or “your” in this Privacy Policy extends to any
              individual whose personal data has been provided to KATCH and/or has been collected in
              other circumstances as described in Section 3 of this Privacy Policy.
            </Typography>
            <Spacer />
            <Typography textAlign="justify">
              We may collect your sensitive personal data, if a purchase requires, and which require
              you to disclose such sensitive personal data to us. We will only use your sensitive
              personal data to provide the service(s) you signed up for. If we collect, use,
              maintain or disclose your sensitive personal data, we will ask for your explicit
              consent.
            </Typography>
            <Spacer />
            <Typography textAlign="justify">
              You have the choice, at any time, not to provide your personal data/sensitive personal
              data or to revoke your consent to KATCH processing of your personal data/sensitive
              personal data. However, failure to provide such personal data/sensitive personal data
              or revocation of your consent to process personal data/sensitive personal data
              provided may result in KATCH being unable to provide you with effective and continuous
              products and services.
            </Typography>
          </Grid>
        </Grid>
        <HorizontalDivider spacerSize="l" />
        <Grid item>
          <Grid item>
            <SectionText>2. Types of personal data we collect</SectionText>
          </Grid>
          <Spacer size="m" />
          <Grid item>
            <Typography textAlign="justify">
              Personal data refers to any information that relates directly or indirectly to an
              individual, who is identified or identifiable from that information or from that and
              other information in the possession of KATCH, including any sensitive personal data
              and expression of opinion about the individual. The types of personal data we collect
              may include, but is not limited to your name, address, other contact details, age,
              occupation, marital status, financial information such as your income, or income tax
              particulars your identity card or passport, place of birth, credit history and your
              transaction history. The personal data we collect can be either obligatory or
              voluntary. Obligatory personal data are those that we require in order to provide you
              with our products and services. If you do not provide us with obligatory personal
              data, we would not be able to provide you with our products and services. Voluntary
              personal data are those that are not mandatory in order for us to provide you with our
              products and services. Obligatory and voluntary personal data differ for each products
              and services and will be indicated in the application forms.
            </Typography>
          </Grid>
        </Grid>

        <HorizontalDivider spacerSize="l" />
        <Grid item>
          <Grid item>
            <SectionText>3. Ways we collect your personal data</SectionText>
          </Grid>
          <Spacer size="m" />
          <Grid item>
            <Typography textAlign="justify">
              We obtain your personal data in various ways, such as:
            </Typography>
            <List sx={{ listStyleType: "disc", pl: 4 }}>
              <StyledListItem>
                When you sign up for or use one of the many services we provide or when you register
                an account at any KATCH application or websites.
              </StyledListItem>
              <StyledListItem>
                When you contact KATCH through various methods such as application forms, emails and
                letters, telephone calls and conversations you have with our staff in a branch. If
                you contact us or we contact you using telephone, we may monitor or record the phone
                call for quality assurance, training and security purposes.
              </StyledListItem>
              <StyledListItem>
                From our analysis of your transactions (e.g. payment history, loan, or deposit
                balances, credit or debit card purchases).
              </StyledListItem>
              <StyledListItem>
                We may also obtain your personal data when you participate in customer surveys or
                when you sign up for any of our competitions or promotions.
              </StyledListItem>
              <StyledListItem>
                When we obtain any data and information from third parties (e.g. credit reference
                agencies, regulatory and enforcement agencies, employers, joint account holders,
                guarantors, legal representatives, spouses, parents, guardians, dependents and/or
                companies/partnership that you hold directorships, shareholdings or partnership in).
              </StyledListItem>
              <StyledListItem>
                When you enter into any commercial transactions with the KATCH including but not
                limited to you providing goods and/or services or your professional services;
              </StyledListItem>
              <StyledListItem>From publicly available sources.</StyledListItem>
            </List>
            <Typography textAlign="justify">IP Address</Typography>
            <Spacer size="m" />
            <Typography textAlign="justify">
              An IP address is a number that is automatically assigned to your computer when you
              signed up with an Internet Service Provider. When you visit our website, your IP
              address is automatically logged in our server. We use your IP address to help diagnose
              problems with our server, and to administer our website. From your IP address, we may
              identify the general geographic area from which you are accessing our website.
              Generally we do not link your IP address to anything that can enable us to identify
              you unless it is required by law and regulation.
            </Typography>
            <Spacer size="m" />
            <Typography textAlign="justify">Information on Cookies </Typography>
            <Spacer size="m" />
            <Typography textAlign="justify">
              A cookie is an element of data that a website can send to your browser, which may then
              store it on your system. We use cookies in some of our pages to store visitors’
              preferences and record session information. The information that we collect is then
              used to ensure a more personalised service level for our users. You can adjust
              settings on your browser so that you will be notified when you receive a cookie.
              Please refer to your browser documentation to check if cookies have been enabled on
              your computer or to request not to receive cookies.
            </Typography>
          </Grid>
        </Grid>
        <HorizontalDivider spacerSize="l" />
        <Grid item>
          <Grid item>
            <SectionText>4. Purpose of processing your personal data</SectionText>
          </Grid>
          <Spacer size="m" />
          <Grid item>
            <Typography textAlign="justify">
              We may process your personal data for the following reasons:
            </Typography>
            <List sx={{ listStyleType: "lower-alpha", pl: 4 }}>
              <StyledListItem>Enter into relevant agreement;</StyledListItem>
              <StyledListItem>
                Communication of services, sale and purchase transactions;
              </StyledListItem>
              <StyledListItem>Payment transaction processing;</StyledListItem>
              <StyledListItem>
                Settlement of fund, Refund or Cancellation, Dispute and/or Chargeback of the payment
                transaction;
              </StyledListItem>
              <StyledListItem>Recurring payment service and/or tokenization;</StyledListItem>
              <StyledListItem>General administration;</StyledListItem>
              <StyledListItem>
                Manage and maintain your account(s) and facility(ies) with us;{" "}
              </StyledListItem>
              <StyledListItem>
                Respond to your enquiries and complaints and to generally resolve disputes;{" "}
              </StyledListItem>
              <StyledListItem>
                Update, consolidate and improve the accuracy of our records;
              </StyledListItem>
              <StyledListItem>
                Produce data, reports and statistics which have been aggregated in a manner that
                does not identify you as an individual;
              </StyledListItem>
              <StyledListItem>
                Conduct research and survey, which solely for analytical purposes including but not
                limited to data mining and analysis of your transactions with us;{" "}
              </StyledListItem>
              <StyledListItem>
                Meet the disclosure requirements of any law binding on us;{" "}
              </StyledListItem>
              <StyledListItem>
                For audit, compliance and risk management purposes; or{" "}
              </StyledListItem>
              <StyledListItem>
                Any other purpose that we deem necessary and/or as required or permitted by any law,
                regulations, guidelines and/or relevant regulatory authorities.{" "}
              </StyledListItem>
            </List>
            <Typography textAlign="justify">
              From time to time, we may share your personal data with other entities within KATCH,
              our agents or strategic partners and other third parties (“other entities”) as KATCH
              deems fit and you may receive marketing communication from us or from these other
              entities about products and services that may be of interest to you. If you no longer
              wish to receive these marketing communications, please notify us to withdraw your
              consent and we will stop processing and sharing your personal data with these other
              entities for the purpose of sending you marketing communications.
            </Typography>
            <Spacer />
            <Typography textAlign="justify">
              Please be notified that once we receive confirmation that you wish to withdraw your
              consent for marketing or promotional materials/communication, it may take up to
              fourteen (14) working days for your withdrawal to be reflected in our systems.
              Therefore, you may still receive marketing or promotional materials/communication
              during this period of time. Please note that even if you opt out from receiving
              marketing or promotional materials, KATCH may still contact you for other purposes in
              relation to the accounts, facilities or services that you hold or have subscribed to
              with KATCH.
            </Typography>
          </Grid>
        </Grid>
        <HorizontalDivider spacerSize="l" />
        <Grid item>
          <Grid item>
            <SectionText>5. Security of your personal data</SectionText>
          </Grid>
          <Spacer size="m" />
          <Grid item>
            <Typography textAlign="justify">
              The security of your personal data is our priority. KATCH takes all physical,
              technical and organizational measures needed to ensure the security and
              confidentiality of personal data. If we disclose any of your personal data to our
              authorised agents or service providers, we will require them to appropriately
              safeguard the personal data provided to them.
            </Typography>
            <Spacer />
            <Typography textAlign="justify">
              The disclosure of your data may involve the transfer of your personal data to places
              outside of Malaysia, and by providing us your personal data you agree to such a
              transfer where it is required to provide you the services you have requested, and for
              the performance of any contractual obligations you have with KATCH including for
              storage purposes.
            </Typography>
          </Grid>
        </Grid>
        <HorizontalDivider spacerSize="l" />
        <Grid item>
          <Grid item>
            <SectionText>6. Period to which we may retain your personal data</SectionText>
          </Grid>
          <Spacer size="m" />
          <Grid item>
            <Typography textAlign="justify">
              We will only retain your personal data for as long as necessary to fulfil the
              purpose(s) for which it was collected or to comply with legal, regulatory and internal
              requirements. Afterwards we will destruct or permanently delete your data.
            </Typography>
            <Spacer />
            <Typography textAlign="justify">
              Please note that we may update this Privacy Policy from time to time. If there are
              material changes to this Privacy Policy, we will notify you by posting a notice of
              such changes on our website or by sending you a notification directly. Do periodically
              review this Privacy Policy to stay informed on how we are protecting your information.
            </Typography>
          </Grid>
        </Grid>
        <HorizontalDivider spacerSize="l" />
        <Grid item>
          <Grid item>
            <SectionText>7. Ways to access / correct / update your personal data</SectionText>
          </Grid>
          <Spacer size="m" />
          <Grid item>
            <Typography textAlign="justify">
              We are committed to ensure that the personal data we hold about you is accurate,
              complete, not misleading and up-to-date. If there are any changes to your personal
              data or if you believe that the personal data we have about you is inaccurate,
              incomplete, misleading or not up-to-date, please contact us so that we may take steps
              to update your personal data.
            </Typography>
            <Spacer />
            <Typography textAlign="justify">
              You have the right to access your personal data. If you would like to request access
              to your personal data, please contact us. Please note that depending on the
              information requested we may charge a small fee. We may also take steps to verify your
              identity before fulfilling your request for access to your personal data.
            </Typography>
          </Grid>
        </Grid>
        <HorizontalDivider spacerSize="l" />
        <Grid item>
          <Grid item>
            <SectionText>8. How may you contact us?</SectionText>
          </Grid>
          <Spacer size="m" />
          <Grid item>
            <Typography textAlign="justify">
              If you need to contact us, you may email{" "}
              <a href="mailto:support@katch.asia" target="_top" rel="noopener noreferrer">
                support@katch.asia
              </a>
              . For corporate customers, you may contact your relationship manager directly.
            </Typography>
            <Spacer />
            <Typography textAlign="justify">
              We provide the Privacy Policy in both English and Bahasa. In case of any
              inconsistencies between these two, the English version shall prevail. In case there
              are inconsistencies on how we collect or use your personal data between this Privacy
              Policy and the terms and conditions of your specific product or service or other
              contractual documents, the terms and conditions of your specific product or service or
              other contractual documents shall prevail.
            </Typography>
            <Spacer />
            <Typography textAlign="justify">
              If you have provided KATCH with personal data of a third party, please ensure that you
              have obtained the third party’s consent in relation to the processing and disclosure
              of their personal data and that this Privacy Policy is brought to the attention of any
              such third party.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </PaddedView>
  );
}

export default PrivacyPolicyScreen;
