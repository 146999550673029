import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAPIV2 } from "../../../infrastructure/utils";
import { postReq } from "../../api.services";

export const previewEventBooking = createAsyncThunk("events/booking/preview", async (payload) => {
  const { eventSessionId, promoCode, eventSessionCategoryIds } = payload;
  const response = await postReq(`${getAPIV2()}/events/sessions/bookings/preview`, {
    eventSessionId,
    eventSessionCategoryIds,
    promoCode,
  });
  return response;
});

export const createEventBooking = createAsyncThunk("events/bookings/create", async (payload) => {
  const {
    eventSessionId,
    eventSessionCategoryIds,
    paymentMethod,
    promoCode,
    questions,
    email,
    phone,
    firstName,
    lastName,
  } = payload;
  const response = await postReq(`${getAPIV2()}/events/sessions/bookings`, {
    eventSessionId,
    eventSessionCategoryIds,
    platform: "web",
    paymentMethod,
    promoCode,
    questions,
    guest: {
      email,
      phone,
      firstName,
      lastName,
    },
  });
  return response;
});

const eventBookingSlice = createSlice({
  name: "eventBooking",
  initialState: {
    previewEventBookingObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createEventBookingObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [previewEventBooking.pending]: (state) => {
      state.previewEventBookingObj.status = "pending";
    },
    [previewEventBooking.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.previewEventBookingObj.status = "succeeded";
      state.previewEventBookingObj.data = data;
      state.previewEventBookingObj.successMessage = message;
    },
    [previewEventBooking.rejected]: (state, action) => {
      const { message } = action.error;

      state.previewEventBookingObj.status = "failed";
      state.previewEventBookingObj.errorMessage = message;
    },
    [createEventBooking.pending]: (state) => {
      state.createEventBookingObj.status = "pending";
    },
    [createEventBooking.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createEventBookingObj.status = "succeeded";
      state.createEventBookingObj.data = data;
      state.createEventBookingObj.successMessage = message;
    },
    [createEventBooking.rejected]: (state, action) => {
      const { message } = action.error;

      state.createEventBookingObj.status = "failed";
      state.createEventBookingObj.errorMessage = message;
    },
  },
});

export default eventBookingSlice.reducer;

// state
export const eventBookingSelector = (state) => state.eventBooking;
