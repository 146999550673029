import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAPIV2 } from "../../../../infrastructure/utils";
import { putReq, getReq } from "../../../api.services";

export const cancelEventBookingBill = createAsyncThunk(
  "events/bookings/bill/{billId}/cancel",
  async (payload) => {
    const { billId, token } = payload;
    const response = await putReq(`${getAPIV2()}/events/sessions/bookings/bills/${billId}/cancel`, {
      token,
    });
    return response;
  },
);

export const getEventBillViaRemoteBillId = createAsyncThunk(
  "events/bookings/bills/{billId}",
  async (payload) => {
    const { remoteBillId, token } = payload;
    const response = getReq(
      `${getAPIV2()}/events/sessions/bookings/bills/${remoteBillId}?${
        token ? "token=".concat(token) : ""
      }`,
      payload,
    );
    return response;
  },
);

const eventBookingBillSlice = createSlice({
  name: "eventBookingBill",
  initialState: {
    cancelEventBookingBillObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getEventBillViaRemoteBillIdObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [cancelEventBookingBill.pending]: (state) => {
      state.cancelEventBookingBillObj.status = "pending";
    },
    [cancelEventBookingBill.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.cancelEventBookingBillObj.status = "succeeded";
      state.cancelEventBookingBillObj.data = data;
      state.cancelEventBookingBillObj.successMessage = message;
    },
    [cancelEventBookingBill.rejected]: (state, action) => {
      const { message } = action.error;

      state.cancelEventBookingBillObj.status = "rejected";
      state.cancelEventBookingBillObj.errorMessage = message;
    },
    [getEventBillViaRemoteBillId.pending]: (state) => {
      state.getEventBillViaRemoteBillIdObj.status = "pending";
    },
    [getEventBillViaRemoteBillId.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getEventBillViaRemoteBillIdObj.status = "succeeded";
      state.getEventBillViaRemoteBillIdObj.data = data;
      state.getEventBillViaRemoteBillIdObj.successMessage = message;
    },
    [getEventBillViaRemoteBillId.rejected]: (state, action) => {
      const { message } = action.error;

      state.getEventBillViaRemoteBillIdObj.status = "rejected";
      state.getEventBillViaRemoteBillIdObj.errorMessage = message;
    },
  },
});

export default eventBookingBillSlice.reducer;

// state
export const eventBookingBillSelector = (state) => state.eventBookingBill;
