import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAPI } from "../../../infrastructure/utils";
import { getReq } from "../../api.services";

export const getBusinesses = createAsyncThunk("searches/businesses", async (payload) => {
  const { tag, categoriesId, lat, long, distance, q, page } = payload;
  const response = await getReq(
    `${getAPI()}/searches/businesses?lat=${lat}&long=${long}&distance=${distance}${
      categoriesId.length > 0 ? "&categoriesId=".concat(categoriesId) : ""
    }${q ? "&q=".concat(q) : ""}${tag ? "&tag=".concat(tag) : ""}&page=${page}`,
  );
  return response;
});

export const getNearbyBusinesses = createAsyncThunk(
  "searches/businesses/nearby",
  async (payload) => {
    const { lat, long, page } = payload;
    const response = await getReq(
      `${getAPI()}/searches/businesses/nearby?lat=${lat}&long=${long}&page=${page}`,
    );
    return response;
  },
);

const searchBusinessSlice = createSlice({
  name: "searchBusiness",
  initialState: {
    getBusinessesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getNearbyBusinessesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getBusinesses.pending]: (state) => {
      state.getBusinessesObj.status = "pending";
    },
    [getBusinesses.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getBusinessesObj.status = "succeeded";
      state.getBusinessesObj.data = data;
      state.getBusinessesObj.successMessage = message;
    },
    [getBusinesses.rejected]: (state, action) => {
      const { message } = action.error;

      state.getBusinessesObj.status = "failed";
      state.getBusinessesObj.errorMessage = message;
    },
    [getNearbyBusinesses.pending]: (state) => {
      state.getNearbyBusinessesObj.status = "pending";
    },
    [getNearbyBusinesses.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getNearbyBusinessesObj.status = "succeeded";
      state.getNearbyBusinessesObj.data = data;
      state.getNearbyBusinessesObj.successMessage = message;
    },
    [getNearbyBusinesses.rejected]: (state, action) => {
      const { message } = action.error;

      state.getNearbyBusinessesObj.status = "failed";
      state.getNearbyBusinessesObj.errorMessage = message;
    },
  },
});

export default searchBusinessSlice.reducer;

// state
export const searchBusinessSelector = (state) => state.searchBusiness;
