import React from "react";
import { Box, Grid, IconButton, Modal, styled, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import ReactCodeInput from "react-verification-code-input";
import ResendButton from "../../../components/button/resend-button.component";
import CustomButton from "../../../components/button/custom-button.component";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.brand.white,
  borderRadius: theme.shape.borderRadius[2],
  boxShadow: 24,
  padding: "50px",
  width: "90%",
  maxWidth: "450px",
  outline: "none",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.text.primary,
  strokeWidth: 2,
  stroke: theme.palette.colors.text.primary,
}));

function VerificationModal({
  showModal,
  setShowModal,
  onSubmit,
  message,
  title,
  onResend,
  onCancel,
}) {
  const theme = useTheme();

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <ModalBox>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <CloseIconButton onClick={() => setShowModal(false)}>
            <CloseIcon />
          </CloseIconButton>
        </Box>

        <Grid container spacing={4} sx={{ textAlign: "center" }}>
          {title && (
            <Grid item xs={12}>
              <Typography variant="h5">{title}</Typography>
            </Grid>
          )}

          {message && (
            <Grid item xs={12}>
              <Typography sx={{ color: theme.palette.colors.text.secondary }}>{message}</Typography>
            </Grid>
          )}

          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <ReactCodeInput onComplete={(value) => onSubmit(value)} />
          </Grid>

          <Grid item xs={12}>
            <ResendButton handleSubmit={onResend} />
          </Grid>

          {onCancel && (
            <Grid item xs={12}>
              <CustomButton fontSize={theme.fonts.fontSizes.size18} onClick={onCancel}>
                Cancel Account Deletion
              </CustomButton>
            </Grid>
          )}
        </Grid>
      </ModalBox>
    </Modal>
  );
}

VerificationModal.defaultProps = {
  title: null,
  message: null,
  onCancel: null,
};

VerificationModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onResend: PropTypes.func.isRequired,
  message: PropTypes.string,
  title: PropTypes.string,
  onCancel: PropTypes.func,
};

export default VerificationModal;
