import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  Box,
  Card,
  ClickAwayListener,
  Grid,
  Modal,
  Select,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CustomButton from "../../../components/button/custom-button.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import { categorySelector, getCategory } from "../../../services/category/category-slice.service";
import CategoryFilterTab from "./category-filter-tab.component";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  backgroundColor: theme.palette.colors.brand.white,
  boxShadow: 24,
  width: "100%",
  height: "100%",
  paddingBottom: "140px",
}));

function CategoryFilter({ searchFor }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const dispatch = useDispatch();
  const { getCategoryObj } = useSelector(categorySelector);
  const createSnackBar = useContext(SnackbarContext);
  const [showFilterCategoryTabs, setShowFilterCategoryTabs] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(
    location?.state?.categoriesLabel ? location?.state?.categoriesLabel : [],
  );
  const [tempSelectedCategory, setTempSelectedCategory] = useState(
    location?.state?.categoriesLabel ? location?.state?.categoriesLabel : [],
  );
  const { setFieldValue, values } = useFormikContext();
  const [showPhoneViewCategoryFilter, setShowPhoneViewCategoryFilter] = useState(false);

  useEffect(() => {
    dispatch(getCategory()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  useEffect(() => {
    if (values.categoriesId.length === 0) {
      setSelectedCategory([]);
    }
  }, [values.categoriesId]);

  return getCategoryObj.status === "succeeded" ? (
    <ClickAwayListener
      onClickAway={() => {
        if (showFilterCategoryTabs) {
          setShowFilterCategoryTabs(false);

          setFieldValue("categoriesId", values.tempCategoriesId);
          setFieldValue("fitnessCategoriesId", values.tempCategoriesId);
          setFieldValue("eventCategoriesId", values.tempEventCategoriesId);
          setSelectedCategory(tempSelectedCategory);
        }
      }}
    >
      <div>
        <Select
          IconComponent={showFilterCategoryTabs ? ArrowDropUpIcon : ArrowDropDownIcon}
          onClick={() => {
            if (isMobile) {
              if (showPhoneViewCategoryFilter) {
                setFieldValue("categoriesId", values.tempCategoriesId);
                setFieldValue("fitnessCategoriesId", values.tempCategoriesId);
                setSelectedCategory(tempSelectedCategory);
              }
              setShowPhoneViewCategoryFilter(true);
            } else {
              if (showFilterCategoryTabs) {
                setFieldValue("categoriesId", values.tempCategoriesId);
                setFieldValue("fitnessCategoriesId", values.tempCategoriesId);
                setSelectedCategory(tempSelectedCategory);
              }
              setShowFilterCategoryTabs(!showFilterCategoryTabs);
            }
          }}
          size="small"
          multiple
          displayEmpty
          open={false}
          name="categoriesId"
          value={values.categoriesId.length > 0 ? selectedCategory : []}
          renderValue={(selected) => {
            let output = "Category";

            if (selected.length > 0) {
              output = `${selected[0]} ${selected.length !== 1 ? `+${selected.length - 1}` : ""}`;
            }

            return <Typography color={selected.length > 0 && "primary"}>{output}</Typography>;
          }}
          sx={{
            borderRadius: `${theme.shape.borderRadius[1]}px`,
            display: "flex",
            borderBottomLeftRadius: !isMobile && showFilterCategoryTabs && "0px",
            borderBottomRightRadius: !isMobile && showFilterCategoryTabs && "0px",
            backgroundColor: values.categoriesId.length > 0 && theme.palette.colors.bg.lightGreen,
            ".MuiOutlinedInput-notchedOutline": {
              border: !showFilterCategoryTabs && values.categoriesId.length > 0 && "2px solid",
              borderColor:
                !showFilterCategoryTabs &&
                values.categoriesId.length > 0 &&
                theme.palette.colors.brand.primary,
              borderBottom: !isMobile && showFilterCategoryTabs && "0px",
            },
          }}
        />
        <Card
          sx={{
            width: "520px",
            height: isMobile ? "0px" : "auto",
            visibility: !isMobile && showFilterCategoryTabs ? "visible" : "hidden",
            position: "absolute",
            borderRadius: `${theme.shape.borderRadius[2]}px`,
            borderTopLeftRadius: "0px",
            border: "1px solid #c4c4c4",
            opacity: showFilterCategoryTabs ? "1" : "0",
            transition: "all 0.4s",
            zIndex: 99,
          }}
        >
          <CategoryFilterTab
            searchFor={searchFor}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            setShowPhoneViewCategoryFilter={setShowPhoneViewCategoryFilter}
            setShowFilterCategoryTabs={setShowFilterCategoryTabs}
            setTempSelectedCategory={setTempSelectedCategory}
          />
        </Card>
        <Modal open={showPhoneViewCategoryFilter}>
          <ModalBox>
            <Grid item xs={12}>
              <CustomButton
                width="auto"
                variant="text"
                fontSize={theme.fonts.fontSizes.size15}
                onClick={() => {
                  setShowPhoneViewCategoryFilter(false);
                  setFieldValue("categoriesId", values.tempCategoriesId);
                  setFieldValue("fitnessCategoriesId", values.tempCategoriesId);
                  setFieldValue("eventCategoriesId", values.tempEventCategoriesId);
                  setSelectedCategory(tempSelectedCategory);
                }}
              >
                {"< Back"}
              </CustomButton>
            </Grid>
            <CategoryFilterTab
              showPhoneViewCategoryFilter={showPhoneViewCategoryFilter}
              searchFor={searchFor}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              setShowPhoneViewCategoryFilter={setShowPhoneViewCategoryFilter}
              setShowFilterCategoryTabs={setShowFilterCategoryTabs}
              setTempSelectedCategory={setTempSelectedCategory}
            />
          </ModalBox>
        </Modal>
      </div>
    </ClickAwayListener>
  ) : (
    <CustomSkeleton width="100%" height="35px" />
  );
}

CategoryFilter.defaultProps = {
  searchFor: "businesses",
};

CategoryFilter.propTypes = {
  searchFor: PropTypes.string,
};

export default CategoryFilter;
