import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Grid, IconButton, Link, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import GoogleMapReact from "google-map-react";
import React from "react";
import HorizontalDivider from "../../../components/utils/horizontal-divider.component";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";

const SectionText = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size20,
  fontWeight: "bold",
}));

const MarkerBox = styled(Grid)({
  width: "100px",
  height: "50px",
  flexDirection: "row",
});

const StyledIcon = styled(IconButton)({
  width: "30px",
  height: "30px",
  color: "red",
});

const StyledLocationOnIcon = styled(LocationOnIcon)({
  width: "30px",
  height: "30px",
});

const StyledTypography = styled(Link)(({ theme }) => ({
  color: theme.palette.colors.brand.white,
  backgroundColor: theme.palette.colors.brand.secondary,
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  textAlign: "center",
  marginTop: "-10px",
  borderRadius: theme.shape.borderRadius[0],
  paddingLeft: "5px",
  paddingRight: "5px",
}));

function Marker(mapProps) {
  const { text, lat, lng } = mapProps;
  return (
    <MarkerBox container>
      <Grid item xs={3}>
        <StyledIcon
          onClick={() =>
            window.open(`https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}`, "_blank")
          }
        >
          <StyledLocationOnIcon />
        </StyledIcon>
      </Grid>
      <Grid item xs={9}>
        <StyledTypography
          target="_blank"
          href={`https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}`}
        >
          {text}
        </StyledTypography>
      </Grid>
    </MarkerBox>
  );
}

function ContactScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <PaddedView multiples={2}>
      <Spacer size="xl" />

      <Grid
        container
        display="flex"
        sx={{
          backgroundColor: theme.palette.colors.bg.primary,
          borderRadius: `${theme.shape.borderRadius[2]}px`,
          padding: "30px",
        }}
      >
        <Grid item xs={isMobile ? 12 : 3}>
          <PaddedView multiples={4}>
            <SectionText>Location:</SectionText>
            <Typography>
              L1-01 & L1-02, GM, No. 2 Jalan Robertson Bukit Bintang, 50150 Kuala Lumpur
            </Typography>
          </PaddedView>
        </Grid>
        <Grid item xs={isMobile ? 12 : 3}>
          <PaddedView multiples={4}>
            <SectionText>Email us:</SectionText>
            <Link
              href="mailto:support@katch.asia"
              underline="hover"
              target="_blank"
              rel="noopener noreferrer"
            >
              support@katch.asia
            </Link>
          </PaddedView>
        </Grid>

        <Grid item xs={isMobile ? 12 : 3}>
          <PaddedView multiples={4}>
            <SectionText>Call us:</SectionText>
            <Link
              href="tel:6013-2886285"
              underline="hover"
              target="_blank"
              rel="noopener noreferrer"
            >
              +6013-288 6285
            </Link>
          </PaddedView>
        </Grid>

        <Grid item xs={isMobile ? 12 : 3}>
          <PaddedView multiples={4}>
            <SectionText>WhatsApp us:</SectionText>
            <Link
              href="https://wa.me/60166408326"
              underline="hover"
              target="_blank"
              rel="noopener noreferrer"
            >
              +6016-640 8326
            </Link>
          </PaddedView>
        </Grid>
      </Grid>

      <HorizontalDivider spacerSize="l" />

      <Grid item>
        <div style={{ height: "45vh", width: "100%" }}>
          <GoogleMapReact
            defaultCenter={{
              lat: 3.14536,
              lng: 101.70432,
            }}
            defaultZoom={16}
          >
            <Marker lat="3.145360" lng="101.704320" text="Katch International" />
          </GoogleMapReact>
        </div>
      </Grid>
    </PaddedView>
  );
}

export default ContactScreen;
