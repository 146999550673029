import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, styled, Tab } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import { getBookingSlots } from "../../../services/booking/booking-slice.service";
import { getPurchasedPackages } from "../../../services/package/purchased-package/purchased-package-slice.service";
import { getPurchasedSubscriptionTransactions } from "../../../services/subscription/purchased/purchased-subscription-slice.service";
import BookingTabPanel from "../components/booking/booking-tab-panel.component";
import PackageTabPanel from "../components/package/package-tab-panel.component";
import SubscriptionTabPanel from "../components/subscription/subscription-tab-panel.component";

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  fontSize: theme.fonts.fontSizes.size16,
}));

function PurchasesScreen() {
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [tabs, setTabs] = useState("bookings");

  const getBookings = (type, page) => {
    dispatch(getBookingSlots({ type, page })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const getSubscriptions = (status, page) => {
    dispatch(getPurchasedSubscriptionTransactions({ status, page })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const getPackagesPurchased = (status, page) => {
    dispatch(getPurchasedPackages({ status, page })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleChangeTab = (event, newValue) => {
    setTabs(newValue);
    switch (newValue) {
      case "bookings":
        getBookings("upcoming", 1);
        break;
      case "packages":
        getPackagesPurchased("ongoing", 1);
        break;
      case "subscription":
        getSubscriptions("ongoing", 1);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getBookings("upcoming", 1);
  }, []);

  return (
    <Box>
      <Spacer size="l" />
      <PaddedView multiples={2}>
        <TabContext value={tabs}>
          <Box
            sx={{
              border: "1px solid",
              borderColor: "divider",
            }}
          >
            <TabList onChange={handleChangeTab} variant="fullWidth">
              <StyledTab label="Bookings" value="bookings" />
              <StyledTab label="Packages" value="packages" />
              <StyledTab label="Subscription" value="subscription" />
            </TabList>
          </Box>
          <TabPanel value="bookings" sx={{ paddingX: "0px" }}>
            <BookingTabPanel getBookings={getBookings} />
          </TabPanel>
          <TabPanel value="packages" sx={{ paddingX: "0px" }}>
            <PackageTabPanel getPurchasedPackages={getPackagesPurchased} />
          </TabPanel>
          <TabPanel value="subscription" sx={{ paddingX: "0px" }}>
            <SubscriptionTabPanel getSubscriptions={getSubscriptions} />
          </TabPanel>
        </TabContext>
      </PaddedView>
    </Box>
  );
}

export default PurchasesScreen;
