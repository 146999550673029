import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Box, Grid, Modal, styled, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";
import CustomButton from "../../../components/button/custom-button.component";
import FormAddressAutoComplete from "../../../components/forms/form-address-auto-complete.component";
import FormDebounceFieldText from "../../../components/forms/form-debounce-field-text.component";
import Spacer from "../../../components/utils/spacer.component";
import SearchNameDropDownCard from "./search-name-drop-down-card.component";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  backgroundColor: theme.palette.colors.brand.white,
  boxShadow: 24,
  width: "100%",
  height: "100%",
  padding: "10px",
  overflowY: "auto",
}));

function PhoneViewSearchBarModal({ showModal, setShowModal }) {
  const theme = useTheme();
  const { handleSubmit } = useFormikContext();

  return (
    <Modal open={showModal}>
      <ModalBox>
        <Grid container display="flex" justifyContent="space-between">
          <CustomButton
            width="auto"
            variant="text"
            fontSize={theme.fonts.fontSizes.size15}
            onClick={() => {
              setShowModal(false);
            }}
          >
            {"< Back"}
          </CustomButton>
          <CustomButton
            width="auto"
            variant="text"
            fontSize={theme.fonts.fontSizes.size15}
            onClick={handleSubmit}
          >
            Search
          </CustomButton>
        </Grid>
        <Spacer size="m" />
        <Grid item>
          <FormDebounceFieldText
            name="q"
            placeholder="Search for anything"
            icon={<SearchOutlinedIcon color="primary" />}
          />
        </Grid>
        <Spacer size="m" />
        <Grid item>
          <FormAddressAutoComplete name="location" />
        </Grid>
        <Spacer size="m" />
        <Grid item>
          <SearchNameDropDownCard />
        </Grid>
      </ModalBox>
    </Modal>
  );
}

PhoneViewSearchBarModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default PhoneViewSearchBarModal;
