import { Box, Grid, Modal, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomButton from "../button/custom-button.component";
import Spacer from "../utils/spacer.component";
import TopTitleBox from "../utils/top-title-box.component";
import { logout, resetUser } from "../../services/auth/auth-slice.service";
import { resetProfile } from "../../services/profile/profile-slice.service";
import routes from "../navigation/routes";

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.brand.white,
  borderTopLeftRadius: theme.shape.borderRadius[2],
  borderTopRightRadius: theme.shape.borderRadius[2],
  borderBottomLeftRadius: theme.shape.borderRadius[2],
  borderBottomRightRadius: theme.shape.borderRadius[2],
  boxShadow: 24,
  paddingTop: "25px",
  paddingBottom: "25px",
  paddingLeft: "50px",
  paddingRight: "50px",
  width: isMobile ? "350px" : "450px",
  outline: "none",
}));

export default function SessionExpiredBox({ isShow, setIsShowAlert }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleConfirm = () => {
    dispatch(logout()).then(({ meta }) => {
      dispatch(resetProfile());
      if (meta.requestStatus === "fulfilled") {
        navigate(routes.LOGIN);
      }
      if (meta.requestStatus === "rejected") {
        dispatch(resetUser());
        navigate(routes.LOGIN);
      }
    });
  };

  return (
    <Modal open={isShow} onClose={() => {}}>
      <ModalBox isMobile={isMobile}>
        <Grid container>
          <TopTitleBox
            title="Session Expired"
            requireCloseButton={false}
            setShowModal={setIsShowAlert}
          />
          <Grid item xs={12}>
            <Spacer size="l" />
            Please log back in to continue using the app.
          </Grid>
          <Grid item xs={12} paddingTop="50px">
            <CustomButton fontSize="16px" onClick={handleConfirm}>
              Continue
            </CustomButton>
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

SessionExpiredBox.propTypes = {
  isShow: PropTypes.bool.isRequired,
  setIsShowAlert: PropTypes.func.isRequired,
};
