import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import NearMeIcon from "@mui/icons-material/NearMe";
import { Box, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import { useLocation } from "react-router-dom";
import routes from "../navigation/routes";
import PaddedView from "../utils/padded-view.component";

const searchOptions = {
  componentRestrictions: { country: "MY" },
  types: [
    "locality",
    "administrative_area_level_2",
    "administrative_area_level_1",
    "postal_code",
    "country",
  ],
};

function FormAddressAutoComplete({ name, width }) {
  const { setFieldValue, values, touched, errors, setFieldTouched, handleSubmit } =
    useFormikContext();
  const location = useLocation();
  const [address, setAddress] = useState(
    location.pathname === routes.EXPLORE ? "Petaling Jaya" : values[name],
  );
  const theme = useTheme();
  const showError = touched[name] && typeof errors[name] === "string";
  const [currentLat, setCurrentLat] = useState(0);
  const [currentLong, setCurrentLong] = useState(0);
  navigator.geolocation.getCurrentPosition((position) => {
    setCurrentLat(position.coords.latitude);
    setCurrentLong(position.coords.longitude);
  });

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  const handleSelect = async (value, placeID) => {
    if (!value) {
      handleSubmit();
    }

    const result = await geocodeByAddress(value);
    const latLng = await getLatLng(result[0]);
    const [place] = await geocodeByPlaceId(placeID);

    const { long_name: postcode = "" } =
      place.address_components.find((c) => c.types.includes("postal_code")) || {};
    const { long_name: city = "" } =
      place.address_components.find(
        (c) => c.types.includes("sublocality_level_1") || c.types.includes("locality"),
      ) || {};
    const { long_name: state = "" } =
      place.address_components.find((c) => c.types.includes("administrative_area_level_1")) || {};
    const { long_name: country = "" } =
      place.address_components.find((c) => c.types.includes("country")) || {};

    setAddress(value);
    values[name] = value;
    values.lat = latLng.lat.toString();
    values.long = latLng.lng.toString();
    setFieldValue("state", state);
    setFieldValue("city", city);
    setFieldValue("country", country);
    setFieldValue("postCode", postcode);
    handleSubmit();
  };

  return (
    <PlacesAutocomplete
      value={address || ""}
      onError={(status, clearSuggestions) => clearSuggestions()}
      onChange={(text) => {
        setAddress(text);
        setFieldValue(name, text);
        setFieldValue("lat", 3.150895);
        setFieldValue("long", 101.594938);
        setFieldValue("state", "");
        setFieldValue("city", "");
        setFieldValue("country", "");
        setFieldValue("postCode", "");
      }}
      searchOptions={searchOptions}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
        <Box
          sx={{
            "& .MuiTextField-root": {
              borderRadius: `${theme.shape.borderRadius[1]}px`,
              width,
              "& .MuiOutlinedInput-root": {
                borderTopLeftRadius: `${theme.shape.borderRadius[1]}px`,
                borderTopRightRadius: `${theme.shape.borderRadius[1]}px`,
                borderBottomLeftRadius:
                  suggestions.length > 0 ? "0px" : `${theme.shape.borderRadius[1]}px`,
                borderBottomRightRadius:
                  suggestions.length > 0 ? "0px" : `${theme.shape.borderRadius[1]}px`,
                backgroundColor: theme.palette.colors.bg.white,
                "&:hover fieldset": {
                  borderColor: "#c4c4c4",
                },
                "&.Mui-focused fieldset": {
                  border: "1px solid",
                  borderColor: "#c4c4c4",
                },
              },
            },
            position: "relative",
          }}
        >
          <TextField
            size="small"
            {...getInputProps()}
            onKeyDown={keyPress}
            placeholder="Search location"
            value={values[name] ? address : ""}
            error={showError}
            onBlur={() => setFieldTouched(name)}
            helperText={showError ? errors[name] : null}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationOnOutlinedIcon color="primary" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    if (currentLat !== 0) {
                      values.lat = currentLat;
                      values.long = currentLong;
                      values[name] = "Current Location";
                      setAddress("Current Location");
                      handleSubmit();
                    }
                  }}
                >
                  <Typography
                    color="primary"
                    fontSize={theme.fonts.fontSizes.size14}
                    sx={{ alignItems: "center", display: "flex" }}
                  >
                    <NearMeIcon fontSize="small" sx={{ marginRight: "5px" }} />
                    Use current location
                  </Typography>
                </InputAdornment>
              ),
            }}
          />

          <Box
            sx={{
              position: "absolute",
              left: 0,
              background: "#fff",
              zIndex: 2,
              border: suggestions.length > 0 && "1px solid",
              borderTop: "0px",
              borderColor: "#c4c4c4",
              width,
              borderRadius: "0 0 8px 8px",
            }}
          >
            {suggestions.map((suggestion, index) => {
              const style = {
                backgroundColor: suggestion.active ? "#defce6" : "#ffffff",
                borderRadius: index === suggestions.length - 1 && "0 0 8px 8px",
                cursor: "pointer",
              };
              return (
                <Paper
                  {...getSuggestionItemProps(suggestion, { style })}
                  key={`${suggestion.description}`}
                >
                  <PaddedView>
                    <Typography>{suggestion.description}</Typography>
                  </PaddedView>
                </Paper>
              );
            })}
          </Box>
        </Box>
      )}
    </PlacesAutocomplete>
  );
}
FormAddressAutoComplete.defaultProps = {
  width: "100%",
};

FormAddressAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
};
export default FormAddressAutoComplete;
