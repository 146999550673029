import React from "react";
import * as Yup from "yup";
import { Box, Grid, IconButton, Modal, styled, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import Form from "../../../components/forms/form.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import FormSelectFieldText from "../../../components/forms/form-select-field-text.component";

const validationSchema = Yup.object().shape({
  gender: Yup.string().required().label("Gender"),
});

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.brand.white,
  borderRadius: theme.shape.borderRadius[2],
  boxShadow: 24,
  padding: "50px",
  width: "90%",
  maxWidth: "450px",
  outline: "none",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.text.primary,
  strokeWidth: 2,
  stroke: theme.palette.colors.text.primary,
}));

function EditGenderModal({
  showModal,
  setShowModal,
  onSubmit,
  message,
  buttonText,
  title,
  isDisabled,
  prevGender,
}) {
  const theme = useTheme();

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <ModalBox>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <CloseIconButton onClick={() => setShowModal(false)}>
            <CloseIcon />
          </CloseIconButton>
        </Box>

        <Grid container spacing={4} sx={{ textAlign: "center" }}>
          {title && (
            <Grid item xs={12}>
              <Typography variant="h5">{title}</Typography>
            </Grid>
          )}

          {message && (
            <Grid item xs={12}>
              <Typography sx={{ color: theme.palette.colors.text.secondary }} textAlign="center">
                {message}
              </Typography>
            </Grid>
          )}

          <Form
            initialValues={{ gender: prevGender }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Grid item xs={12}>
              <FormSelectFieldText
                name="gender"
                selected="male"
                selections={[
                  { type: "Male", value: "male" },
                  { type: "Female", value: "female" },
                  { type: "Others", value: "others" },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <FormSubmitButton isLoading={isDisabled} width="60%" borderRadius={1}>
                <Typography>{buttonText}</Typography>
              </FormSubmitButton>
            </Grid>
          </Form>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

EditGenderModal.defaultProps = {
  title: null,
  message: null,
  buttonText: "Confirm",
  isDisabled: false,
  prevGender: null,
};

EditGenderModal.propTypes = {
  isDisabled: PropTypes.bool,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  message: PropTypes.string,
  buttonText: PropTypes.string,
  title: PropTypes.string,
  prevGender: PropTypes.string,
};

export default EditGenderModal;
