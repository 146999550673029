import {
  Box,
  Grid,
  List,
  ListItem,
  Modal,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isLogin } from "../../infrastructure/utils";
import { profileSelector } from "../../services/profile/profile-slice.service";
import CustomButton from "../button/custom-button.component";
import routes from "../navigation/routes";
import Spacer from "../utils/spacer.component";
import TopTitleBox from "../utils/top-title-box.component";
import SampleImage from "../../assets/images/sample-image.jpeg";

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.brand.white,
  borderTopLeftRadius: theme.shape.borderRadius[2],
  borderTopRightRadius: theme.shape.borderRadius[2],
  borderBottomLeftRadius: theme.shape.borderRadius[2],
  borderBottomRightRadius: theme.shape.borderRadius[2],
  boxShadow: 24,
  paddingTop: "25px",
  paddingBottom: "25px",
  paddingLeft: "50px",
  paddingRight: "50px",
  width: isMobile ? "350px" : "450px",
  outline: "none",
}));

export default function UnverifiedUserModal({ cancelAction }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const { getProfileDetailObj } = useSelector(profileSelector);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (
      isLogin() &&
      getProfileDetailObj.status === "succeeded" &&
      getProfileDetailObj.data &&
      getProfileDetailObj.data.status !== "verified"
    ) {
      setShowModal(true);
    }
  });

  return (
    <Modal open={showModal}>
      <ModalBox isMobile={isMobile}>
        <Grid container>
          <TopTitleBox title="Verification Required" requireCloseButton={false} />
          <Grid item xs={12}>
            <Spacer size="l" />
            <Typography textAlign="center" color={theme.palette.colors.ui.error}>
              Secure your profile with a quick selfie! Verify your identity, prevent fraud, and
              ensure smooth account recovery. Your safety is our priority.
            </Typography>
            <Spacer size="l" />
          </Grid>
          <Grid item container xs={12} spacing={1}>
            <Grid item xs={isMobile ? 12 : 4}>
              <Box display="flex" justifyContent="center">
                <img
                  src={SampleImage}
                  alt={SampleImage}
                  width={isMobile ? "100px" : "100%"}
                  style={{ borderRadius: theme.shape.borderRadius[1] }}
                />
              </Box>
            </Grid>
            <Grid item xs={isMobile ? 12 : 8}>
              <List sx={{ listStyleType: "number", pl: 3 }}>
                <ListItem
                  sx={{
                    display: "list-item",
                    textAlign: "justify",
                    fontSize: theme.fonts.fontSizes.size13,
                  }}
                >
                  Ensure that your face is shown clearly
                </ListItem>
                <ListItem
                  sx={{
                    display: "list-item",
                    textAlign: "justify",
                    fontSize: theme.fonts.fontSizes.size13,
                  }}
                >
                  Ensure your hair is not covering your face and remove your face mask if any
                </ListItem>
              </List>
            </Grid>
          </Grid>
          <Grid container item xs={12} paddingTop="50px" columnSpacing={2}>
            <Grid item xs={6}>
              <CustomButton fontSize="16px" onClick={cancelAction} variant="outlined">
                Back
              </CustomButton>
            </Grid>

            <Grid item xs={6}>
              <CustomButton fontSize="16px" onClick={() => navigate(routes.USERPROFILE)}>
                Ok
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

UnverifiedUserModal.propTypes = {
  cancelAction: PropTypes.func.isRequired,
};
