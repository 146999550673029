import { Grid, Typography, useTheme } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import PaddedView from "../../../components/utils/padded-view.component";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import Spacer from "../../../components/utils/spacer.component";
import {
  getMemberships,
  membershipSelector,
} from "../../../services/membership/membership-slice.service";
import MembershipPlanCard from "../../business/components/membership-plan-card.component";

function MembershipList() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { getMembershipsObj } = useSelector(membershipSelector);

  const getMembershipList = () => {
    dispatch(getMemberships()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  useEffect(() => {
    getMembershipList();
  }, []);

  const renderMemberships = () => {
    if (getMembershipsObj.status === "succeeded") {
      if (getMembershipsObj.data.items.length > 0) {
        return getMembershipsObj.data.items.map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item.id}>
            <MembershipPlanCard itemDetails={item.plan} />
          </Grid>
        ));
      }
      return (
        <Grid item xs={12} textAlign="center">
          <Typography fontWeight="bold">No memberships</Typography>
          <Spacer size="m" />
        </Grid>
      );
    }
    return (
      <>
        <Grid item xs={12} sm={6} md={4}>
          <CustomSkeleton width="100%" height={200} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <CustomSkeleton width="100%" height={200} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <CustomSkeleton width="100%" height={200} />
        </Grid>
      </>
    );
  };

  return (
    <Grid
      container
      sx={{
        borderRadius: `${theme.shape.borderRadius[1]}px`,
      }}
    >
      <PaddedView multiples={2}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography fontWeight="bold" fontSize={theme.fonts.fontSizes.size20}>
              Memberships
            </Typography>
          </Grid>
        </Grid>
        <Spacer position="top" size="m" />
        <Grid container spacing={2}>
          {renderMemberships()}
        </Grid>
      </PaddedView>
    </Grid>
  );
}

export default MembershipList;
