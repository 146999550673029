import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq } from "../api.services";

export const getReferral = createAsyncThunk("referral/index", async (payload) => {
  const response = await getReq(`${process.env.REACT_APP_API_PRIVATE_V1}/referrals`, payload);
  return response;
});

export const getReferees = createAsyncThunk("referral/referees", async (payload) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/referrals/referees`,
    payload,
  );
  return response;
});
const referralSlice = createSlice({
  name: "referral",
  initialState: {
    getReferralObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getRefereesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [getReferral.pending]: (state) => {
      state.getReferralObj.status = "pending";
    },
    [getReferral.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getReferralObj.status = "succeeded";
      state.getReferralObj.data = data;
      state.getReferralObj.successMessage = message;
    },
    [getReferral.rejected]: (state, action) => {
      const { message } = action.error;

      state.getReferralObj.status = "failed";
      state.getReferralObj.errorMessage = message;
    },
    [getReferees.pending]: (state) => {
      state.getRefereesObj.status = "pending";
    },
    [getReferees.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getRefereesObj.status = "succeeded";
      state.getRefereesObj.data = data;
      state.getRefereesObj.successMessage = message;
    },
    [getReferees.rejected]: (state, action) => {
      const { message } = action.error;

      state.getRefereesObj.status = "failed";
      state.getRefereesObj.errorMessage = message;
    },
  },
});

export default referralSlice.reducer;

// state
export const referralSelector = (state) => state.referral;
