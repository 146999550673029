import CallIcon from "@mui/icons-material/Call";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  Grid,
  IconButton,
  Link,
  Rating,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import GoogleMapReact from "google-map-react";
import React from "react";
import { useSelector } from "react-redux";
import Spacer from "../../../../components/utils/spacer.component";
import { fitnessClassSessionSelector } from "../../../../services/fitness_class/session/fitness-class-session-slice.services";

const MarkerBox = styled(Grid)({
  width: "100px",
  height: "50px",
  flexDirection: "row",
});

const StyledIcon = styled(IconButton)({
  width: "30px",
  height: "30px",
  color: "red",
});

const StyledLocationOnIcon = styled(LocationOnIcon)({
  width: "30px",
  height: "30px",
});

const StyledTypography = styled(Link)(({ theme }) => ({
  color: theme.palette.colors.brand.white,
  backgroundColor: theme.palette.colors.brand.secondary,
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  textAlign: "center",
  marginTop: "-10px",
  borderRadius: theme.shape.borderRadius[0],
  paddingLeft: "5px",
  paddingRight: "5px",
}));

function Marker(mapProps) {
  const { text, lat, lng } = mapProps;
  return (
    <MarkerBox container>
      <Grid item xs={3}>
        <StyledIcon
          onClick={() =>
            window.open(`https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}`, "_blank")
          }
        >
          <StyledLocationOnIcon />
        </StyledIcon>
      </Grid>
      <Grid item xs={9}>
        <StyledTypography
          target="_blank"
          href={`https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}`}
        >
          {text}
        </StyledTypography>
      </Grid>
    </MarkerBox>
  );
}

function BusinessDetails() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { getFitnessClassSessionObj } = useSelector(fitnessClassSessionSelector);

  return (
    <Grid container flexDirection="column" rowSpacing={2}>
      <Grid item>
        <Typography variant="h6" fontWeight="bold">
          About this studio
        </Typography>
      </Grid>
      <Grid item container columnSpacing={2}>
        <Grid item xs={isMobile ? 12 : 2}>
          <img
            width="100%"
            height={isMobile ? "250px" : "150px"}
            alt="imgs"
            src={getFitnessClassSessionObj.data.business.imagePath}
            style={{ objectFit: "cover", borderRadius: `${theme.shape.borderRadius[3]}px` }}
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 8} alignItems="center" display="flex">
          <Grid
            item
            display="grid"
            flexDirection="column"
            alignContent="space-between"
            height="90%"
          >
            <Grid item>
              <Grid item>
                <Typography fontWeight="bold" variant="h6">
                  {getFitnessClassSessionObj.data.business.name}
                </Typography>
              </Grid>
              <Spacer />
              <Grid item container>
                <Rating
                  value={parseFloat(getFitnessClassSessionObj.data.business.reviews.averageRatings)}
                  readOnly
                  sx={{ color: theme.palette.colors.brand.primary }}
                />
                <Spacer position="left" />
                <Typography>{`(${getFitnessClassSessionObj.data.business.reviews.totalRatings})`}</Typography>
              </Grid>
            </Grid>
            {isMobile && <Spacer size="m" />}

            <Grid item display="flex" alignContent="center">
              <CallIcon color="primary" />
              <Spacer position="left" />
              <Typography>{getFitnessClassSessionObj.data.business.contactNo}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isMobile && <Spacer size="m" />}
      <Grid item>
        <div style={{ height: "40vh", width: "100%" }}>
          <GoogleMapReact
            defaultCenter={{
              lat: parseFloat(getFitnessClassSessionObj.data.business.location.lat),
              lng: parseFloat(getFitnessClassSessionObj.data.business.location.long),
            }}
            defaultZoom={17}
          >
            <Marker
              lat={parseFloat(getFitnessClassSessionObj.data.business.location.lat)}
              lng={parseFloat(getFitnessClassSessionObj.data.business.location.long)}
              text={getFitnessClassSessionObj.data.business.name}
            />
          </GoogleMapReact>
        </div>
      </Grid>
      <Grid item>
        <Typography textAlign="justify">
          {getFitnessClassSessionObj.data.business.location.address}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default BusinessDetails;
