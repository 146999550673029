import LocalParkingIcon from "@mui/icons-material/LocalParking";
import WifiRoundedIcon from "@mui/icons-material/WifiRounded";
import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { ReactComponent as AmenitiesLogo } from "../../../assets/images/amenities.svg";
import { ReactComponent as LockersLogo } from "../../../assets/images/lockers.svg";
import { ReactComponent as ShowerLogo } from "../../../assets/images/shower.svg";
import { ReactComponent as TowelLogo } from "../../../assets/images/towel.svg";
import ItemCarousel from "../../../components/utils/item-carousel.component";
import Spacer from "../../../components/utils/spacer.component";
import { businessSelector } from "../../../services/business/business-slice.service";

const SectionText = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size18,
  fontWeight: "bold",
}));

const getAmenityIcon = (amenity) => {
  switch (amenity) {
    case "parking":
      return <LocalParkingIcon sx={{ fontSize: 40 }} />;

    case "showers":
      return <ShowerLogo width={40} height={40} />;

    case "wifi":
      return <WifiRoundedIcon sx={{ fontSize: 40 }} />;

    case "storage_space":
      return <LockersLogo width={40} height={40} />;

    case "towel_service":
      return <TowelLogo width={40} height={40} />;

    default:
      return <AmenitiesLogo width={40} height={40} />;
  }
};

function AmenitySection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { getBusinessDetailsObj } = useSelector(businessSelector);

  const renderAmenities = () =>
    getBusinessDetailsObj.data.amenities.map((item) => (
      <Box
        key={item.id}
        sx={{
          border: "1px solid",
          borderRadius: `${theme.shape.borderRadius[1]}px`,
          padding: "10px",
          width: isMobile ? "100px" : "auto",
          height: isMobile ? "auto" : "110px",
          minHeight: isMobile ? "120px" : "auto",
          marginRight: "15px",
          flexShrink: 0,
        }}
      >
        <Box display="grid" flexDirection="column" alignContent="space-between" height="100%">
          {getAmenityIcon(item.amenity)}
          <Spacer />
          <Typography textTransform="capitalize">{item.amenity.replace("_", " ")}</Typography>
        </Box>
      </Box>
    ));

  return (
    <Grid item xs={12}>
      <Grid item>
        <SectionText>More Amenities</SectionText>
      </Grid>
      <Spacer size="m" />
      {!isMobile ? (
        <Grid item xs={12}>
          <ItemCarousel slidesToScroll={3} slidesToShow={7} paddingAfterItem="10px">
            {renderAmenities()}
          </ItemCarousel>
        </Grid>
      ) : (
        <Box sx={{ overflow: "auto", display: "flex", flexDirection: "row" }}>
          {renderAmenities()}
        </Box>
      )}

      {getBusinessDetailsObj.data.amenities.length === 0 && (
        <Grid container justifyContent="center">
          No Amenities
        </Grid>
      )}
    </Grid>
  );
}

export default AmenitySection;
