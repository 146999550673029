import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postReq } from "../../../api.services";

export const previewFitnessClassSessionBookingSummary = createAsyncThunk(
  "fitness_classes/sessions/bookings/preview",
  async (payload) => {
    const { fitnessClassSessionId, promoCode } = payload;
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/fitness_classes/sessions/bookings/preview`,
      {
        fitnessClassSessionId,
        promoCode,
      },
    );
    return response;
  },
);

export const createFitnessClassSessionBooking = createAsyncThunk(
  "fitness_classes/sessions/bookings/create",
  async (payload) => {
    const { fitnessClassSessionId, paymentMethod, promoCode } = payload;
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/fitness_classes/sessions/bookings`,
      {
        fitnessClassSessionId,
        paymentMethod,
        promoCode,
        platform: "web",
      },
    );
    return response;
  },
);

export const getFitnessClassBookingPaymentOption = createAsyncThunk(
  "fitness_class/sessions/booking/payment_options",
  async (payload) => {
    const { slotId, promoCode } = payload;
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/fitness_classes/sessions/bookings/payment_options`,
      { slotId, promoCode },
    );
    return response;
  },
);

export const payFitnessClassBooking = createAsyncThunk(
  "fitness_class/sessions/booking/pay",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/fitness_classes/sessions/bookings/pay`,
      { ...payload, platform: "web" },
    );
    return response;
  },
);

export const rejectRequestFitnessClassBooking = createAsyncThunk(
  "fitness_classes/sessions/booking/reject_request",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/fitness_classes/sessions/bookings/reject_request`,
      payload,
    );
    return response;
  },
);

const fitnessClassSessionBookingSlice = createSlice({
  name: "fitnessClassSessionBooking",
  initialState: {
    previewFitnessClassSessionBookingSummaryObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createFitnessClassSessionBookingObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getFitnessClassBookingPaymentOptionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    payFitnessClassBookingObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    rejectRequestFitnessClassBookingObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [previewFitnessClassSessionBookingSummary.pending]: (state) => {
      state.previewFitnessClassSessionBookingSummaryObj.status = "pending";
    },
    [previewFitnessClassSessionBookingSummary.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.previewFitnessClassSessionBookingSummaryObj.status = "succeeded";
      state.previewFitnessClassSessionBookingSummaryObj.data = data;
      state.previewFitnessClassSessionBookingSummaryObj.successMessage = message;
    },
    [previewFitnessClassSessionBookingSummary.rejected]: (state, action) => {
      const { message } = action.error;

      state.previewFitnessClassSessionBookingSummaryObj.status = "rejected";
      state.previewFitnessClassSessionBookingSummaryObj.errorMessage = message;
    },
    [createFitnessClassSessionBooking.pending]: (state) => {
      state.createFitnessClassSessionBookingObj.status = "pending";
    },
    [createFitnessClassSessionBooking.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createFitnessClassSessionBookingObj.status = "succeeded";
      state.createFitnessClassSessionBookingObj.data = data;
      state.createFitnessClassSessionBookingObj.successMessage = message;
    },
    [createFitnessClassSessionBooking.rejected]: (state, action) => {
      const { message } = action.error;

      state.createFitnessClassSessionBookingObj.status = "rejected";
      state.createFitnessClassSessionBookingObj.errorMessage = message;
    },
    [getFitnessClassBookingPaymentOption.pending]: (state) => {
      state.getFitnessClassBookingPaymentOptionObj.status = "pending";
    },
    [getFitnessClassBookingPaymentOption.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getFitnessClassBookingPaymentOptionObj.status = "succeeded";
      state.getFitnessClassBookingPaymentOptionObj.data = data;
      state.getFitnessClassBookingPaymentOptionObj.successMessage = message;
    },
    [getFitnessClassBookingPaymentOption.rejected]: (state, action) => {
      const { message } = action.error;

      state.getFitnessClassBookingPaymentOptionObj.status = "rejected";
      state.getFitnessClassBookingPaymentOptionObj.errorMessage = message;
    },
    [payFitnessClassBooking.pending]: (state) => {
      state.payFitnessClassBookingObj.status = "pending";
    },
    [payFitnessClassBooking.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.payFitnessClassBookingObj.status = "succeeded";
      state.payFitnessClassBookingObj.data = data;
      state.payFitnessClassBookingObj.successMessage = message;
    },
    [payFitnessClassBooking.rejected]: (state, action) => {
      const { message } = action.error;

      state.payFitnessClassBookingObj.status = "rejected";
      state.payFitnessClassBookingObj.errorMessage = message;
    },
    [rejectRequestFitnessClassBooking.pending]: (state) => {
      state.rejectRequestFitnessClassBookingObj.status = "pending";
    },
    [rejectRequestFitnessClassBooking.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.rejectRequestFitnessClassBookingObj.status = "succeeded";
      state.rejectRequestFitnessClassBookingObj.data = data;
      state.rejectRequestFitnessClassBookingObj.successMessage = message;
    },
    [rejectRequestFitnessClassBooking.rejected]: (state, action) => {
      const { message } = action.error;

      state.rejectRequestFitnessClassBookingObj.status = "rejected";
      state.rejectRequestFitnessClassBookingObj.errorMessage = message;
    },
  },
});

export default fitnessClassSessionBookingSlice.reducer;

// state
export const fitnessClassSessionBookingSelector = (state) => state.fitnessClassSessionBooking;
