import React from "react";
import { Box, Link, useTheme } from "@mui/material";
import PropTypes from "prop-types";

function TopLeftLink({ label, pushTo }) {
  const theme = useTheme();
  return (
    <Box sx={{ position: "absolute", left: "25px", top: "15px" }}>
      <Link
        color={theme.palette.colors.brand.primary}
        underline="none"
        key="1"
        variant="body5"
        href={pushTo}
      >
        {label}
      </Link>
    </Box>
  );
}

TopLeftLink.propTypes = {
  label: PropTypes.string.isRequired,
  pushTo: PropTypes.string.isRequired,
};

export default TopLeftLink;
