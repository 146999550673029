import { Box, Grid, Pagination, Typography, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NoBookingImage from "../../../../assets/images/no-booking.png";
import CustomButton from "../../../../components/button/custom-button.component";
import { SnackbarContext } from "../../../../components/notification/snackbar.context";
import CustomSkeleton from "../../../../components/utils/skeleton.component";
import Spacer from "../../../../components/utils/spacer.component";
import {
  payRequestedAppointment,
  rejectAppointment,
} from "../../../../services/appointment/appointment-slice.service";
import {
  bookingSelector,
  cancelBookingSlot,
  updateBookingSlot,
} from "../../../../services/booking/booking-slice.service";
import { rejectRequestFitnessClassBooking } from "../../../../services/fitness_class/session/booking/fitness-class-session-booking-slice.service";
import { getProfileDetail } from "../../../../services/profile/profile-slice.service";
import BookingDetailsCard from "./booking-details-card.component";
import BookingDetailsModal from "./booking-details-modal.component";
import EditSessionModal from "./edit-session-modal.component";
import routes from "../../../../components/navigation/routes";

function BookingTabPanel({ getBookings }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const [type, setType] = useState("upcoming");
  const { getBookingSlotsObj } = useSelector(bookingSelector);
  const [page, setPage] = useState(1);
  const createSnackBar = useContext(SnackbarContext);
  const [showBookingDetailsModal, setShowBookingDetailsModal] = useState(false);
  const [showEditSessionModal, setShowEditSessionModal] = useState(false);
  const [bookingDetails, setBookingDetails] = useState(null);
  const navigate = useNavigate();

  const onPageChange = (event, newPage) => {
    setPage(newPage);
    getBookings(type, newPage);
  };

  const cancelBooking = (bookingId) => {
    dispatch(cancelBookingSlot({ id: bookingId })).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        dispatch(getProfileDetail());
        onPageChange(null, 1);
        setShowBookingDetailsModal(false);
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const cancelMerchantRequest = (slotId) => {
    dispatch(rejectRequestFitnessClassBooking({ slotId })).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        onPageChange(null, 1);
        setShowBookingDetailsModal(false);
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const rejectAppointmentRequest = (slotId) => {
    dispatch(rejectAppointment({ slotId })).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        onPageChange(null, 1);
        setShowBookingDetailsModal(false);
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const acceptRequestedAppointment = (slotId, packageCode) => {
    dispatch(payRequestedAppointment({ slotId, packageCode })).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        onPageChange(null, 1);
        setShowBookingDetailsModal(false);
        navigate(routes.APPOINTMENTPAYMENTSUCCESS, { state: { ...payload.data } });
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const updateBooking = (slotId, slot) => {
    dispatch(updateBookingSlot({ id: slotId, startAt: slot.startAt, endAt: slot.endAt })).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          setShowBookingDetailsModal(false);
          setShowEditSessionModal(false);
          getBookings(type, page);
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  return (
    <Box>
      <BookingDetailsModal
        itemDetails={bookingDetails}
        cancelBooking={cancelBooking}
        cancelMerchantRequest={cancelMerchantRequest}
        rejectAppointmentRequest={rejectAppointmentRequest}
        acceptRequestedAppointment={acceptRequestedAppointment}
        showModal={showBookingDetailsModal}
        setShowModal={setShowBookingDetailsModal}
        setShowEditSessionModal={setShowEditSessionModal}
      />
      <EditSessionModal
        bookingDetails={bookingDetails}
        showModal={showEditSessionModal}
        setShowModal={setShowEditSessionModal}
        updateBooking={updateBooking}
      />
      <Grid container columnSpacing={2}>
        <Grid item xs={isMobile ? 6 : 2}>
          <CustomButton
            buttonColor={type === "upcoming" ? "primary" : "grey"}
            textColor={type === "upcoming" ? "primary" : "grey"}
            variant="outlined"
            fontSize={theme.fonts.fontSizes.size16}
            onClick={() => {
              setType("upcoming");
              getBookings("upcoming", 1);
            }}
          >
            Upcoming
          </CustomButton>
        </Grid>
        <Grid item xs={isMobile ? 6 : 2}>
          <CustomButton
            buttonColor={type === "history" ? "primary" : "grey"}
            textColor={type === "history" ? "primary" : "grey"}
            variant="outlined"
            fontSize={theme.fonts.fontSizes.size16}
            onClick={() => {
              setType("history");
              getBookings("history", 1);
            }}
          >
            History
          </CustomButton>
        </Grid>
      </Grid>
      <Spacer size="l" />
      {getBookingSlotsObj.status === "succeeded" ? (
        <Grid container columnSpacing={1} rowSpacing={2}>
          {getBookingSlotsObj.data.items.length > 0 ? (
            <>
              {getBookingSlotsObj.data.items.map((item) => (
                <Grid
                  item
                  xs={isMobile ? 12 : 4}
                  key={item.id}
                  onClick={() => {
                    setBookingDetails(item);
                    setShowBookingDetailsModal(true);
                  }}
                >
                  <BookingDetailsCard itemDetails={item} />
                </Grid>
              ))}
              <Grid item container justifyContent="center">
                <Pagination
                  page={page}
                  onChange={onPageChange}
                  count={getBookingSlotsObj.data.pagination.totalPages}
                  variant="outlined"
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Spacer size="m" />
              <Typography textAlign="center">
                {type === "upcoming" ? "No upcoming booking" : "No booking history"}
              </Typography>
              <Spacer size="m" />
              <Grid container display="flex" justifyContent="center">
                <img
                  width={300}
                  src={NoBookingImage}
                  alt="no-booking"
                  style={{ objectFit: "contain" }}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid container columnSpacing={1} rowSpacing={1}>
          <Grid item xs={isMobile ? 12 : 4}>
            <CustomSkeleton width="100%" height={230} />
          </Grid>
          <Grid item xs={isMobile ? 12 : 4}>
            <CustomSkeleton width="100%" height={230} />
          </Grid>
          <Grid item xs={isMobile ? 12 : 4}>
            <CustomSkeleton width="100%" height={230} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

BookingTabPanel.propTypes = {
  getBookings: PropTypes.func.isRequired,
};

export default BookingTabPanel;
