import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import PaddedView from "../../../components/utils/padded-view.component";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import Spacer from "../../../components/utils/spacer.component";

const SectionContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.colors.bg.primary,
  borderRadius: `${theme.shape.borderRadius[2]}px`,
}));

function PreviewSummaryPackageLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box>
      <Grid container columnSpacing={2} rowSpacing={2}>
        <Grid container item xs={isMobile ? 12 : 6} flexDirection="column" rowSpacing={2}>
          <Grid item>
            <SectionContainer item>
              <PaddedView multiples={isMobile ? 3 : 4}>
                <Grid item display="flex" alignContent="center" justifyContent="space-between">
                  <Grid item>
                    <CustomSkeleton />
                  </Grid>
                  {!isMobile && (
                    <Grid item>
                      <CustomSkeleton height="42px" width="200px" />
                    </Grid>
                  )}
                </Grid>
                <Spacer size="m" />
                <Grid container columnSpacing={2} alignContent="center">
                  <Grid
                    item
                    alignSelf="center"
                    paddingY="5px"
                    alignItems="center"
                    display="flex"
                    xs={4}
                  >
                    <CustomSkeleton width="100%" height={isMobile ? 90 : 150} />
                  </Grid>
                  <Grid
                    item
                    display="grid"
                    flexDirection="column"
                    alignContent="space-between"
                    marginY="10px"
                    xs={8}
                  >
                    <Grid item>
                      <Grid item>
                        <CustomSkeleton width="200px" />
                      </Grid>
                      <Spacer />
                      <Grid item display="flex" alignContent="center">
                        <CustomSkeleton width="150px" />
                      </Grid>
                      <Spacer />
                      <Grid item>
                        <CustomSkeleton width="250px" />
                      </Grid>
                    </Grid>
                    <Grid item display="flex" alignContent="center">
                      <CustomSkeleton width="200px" />
                    </Grid>
                  </Grid>
                  {isMobile && (
                    <Grid item xs={12}>
                      <Spacer />
                      <CustomSkeleton height="42px" width="200px" />
                    </Grid>
                  )}
                </Grid>
              </PaddedView>
            </SectionContainer>
          </Grid>
        </Grid>
        <Grid item xs={isMobile ? 12 : 6}>
          <SectionContainer item>
            <PaddedView multiples={isMobile ? 3 : 4}>
              <CustomSkeleton height={27} width="200px" />
              <Spacer size="xl" />
              <CustomSkeleton width="100%" />
              <Spacer />
              <CustomSkeleton width="100%" />
              <Spacer />
              <CustomSkeleton width="100%" />
              <Spacer />
              <CustomSkeleton width="100%" />
              <Spacer />
              <CustomSkeleton width="100%" />
              <Spacer />
              <CustomSkeleton width="100%" />
              <Spacer />
            </PaddedView>
          </SectionContainer>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PreviewSummaryPackageLoader;
