import React from "react";
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import logoGreen from "./katch_logo_green.png";
import logoWhite from "./katch_logo_white.png";
import routes from "../../components/navigation/routes";

function KatchLogo({ color }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        "& .MuiButton-root": {
          "&:hover": {
            backgroundColor: "transparent",
            borderColor: "transparent",
            boxShadow: "none",
          },
        },
      }}
    >
      <Link to={routes.HOME}>
        <Button disableRipple>
          <img
            width={isMobile ? 75 : 100}
            src={color === "green" ? logoGreen : logoWhite}
            alt="logo"
          />
        </Button>
      </Link>
    </Box>
  );
}

KatchLogo.propTypes = {
  color: PropTypes.string.isRequired,
};

export default KatchLogo;
