import { Box, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useCookies } from "react-cookie";
import Loader from "react-js-loader";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../components/navigation/routes";
import { SnackbarContext } from "../../components/notification/snackbar.context";
import Spacer from "../../components/utils/spacer.component";
import { isLogin } from "../../infrastructure/utils";
import { checkTopUpBill } from "../../services/credit/credit-slice.service";
import { getEventBillViaRemoteBillId } from "../../services/event/booking/bill/event-booking-bill-slice.service";
import { getFitnessClassSessionBookingViaRemoteBillId } from "../../services/fitness_class/session/booking/bill/fitness-class-session-booking-bill-slice.service";
import { getHourlyBookingViaRemoteBillId } from "../../services/hourly-booking/bill/hourly-booking-bill-slice.service";
import { getPackageBillViaRemoteBillId } from "../../services/package/bill/package-bill-slice.service";
import { getSubscriptionBillViaRemoteBillId } from "../../services/subscription/bill/subscription-bill-slice.service";
import { getMembershipBillViaRemoteBillId } from "../../services/membership/bill/membership-bill-slice.service";

const StyledContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "column",
  paddingBottom: "50px",
});

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.fonts.fontWeights.bold,
  color: theme.palette.colors.text.primary,
  textAlign: "center",
}));

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  textAlign: "center",
}));

export default function BillPaymentCheckScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [cookies, setCookie, removeCookie] = useCookies(["guest-payment-token"]); // eslint-disable-line no-unused-vars
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const createSnackBar = useContext(SnackbarContext);

  const getCheckBillFunction = () => {
    switch (location.pathname) {
      case routes.TOPUPBILLPAYMENTCHECK:
        return checkTopUpBill;
      case routes.HOURLYBOOKINGPAYMENTCHECK:
        return getHourlyBookingViaRemoteBillId;
      case routes.SUBSCRIPTIONPLANPAYMENTCHECK:
        return getSubscriptionBillViaRemoteBillId;
      case routes.MEMBERSHIP_PLAN_PAYMENT_CHECK:
        return getMembershipBillViaRemoteBillId;
      case routes.FITNESSCLASSSESSIONBOOKINGPAYMENTCHECK:
        return getFitnessClassSessionBookingViaRemoteBillId;
      case routes.PACKAGEPAYMENTCHECK:
        return getPackageBillViaRemoteBillId;
      case routes.EVENTBOOKINGPAYMENTCHECK:
        return getEventBillViaRemoteBillId;
      default:
        return false;
    }
  };

  const getPaymentFailedRoute = () => {
    switch (location.pathname) {
      case routes.TOPUPBILLPAYMENTCHECK:
        return routes.TOPUPBILLPAYMENTFAILED;
      case routes.HOURLYBOOKINGPAYMENTCHECK:
        return routes.HOURLYBOOKINGPAYMENTFAILED;
      case routes.SUBSCRIPTIONPLANPAYMENTCHECK:
        return routes.SUBSCRIPTIONPLANPAYMENTFAILED;
      case routes.MEMBERSHIP_PLAN_PAYMENT_CHECK:
        return routes.MEMBERSHIP_PLAN_PAYMENT_FAILED;
      case routes.FITNESSCLASSSESSIONBOOKINGPAYMENTCHECK:
        return routes.FITNESSCLASSSESSIONBOOKINGPAYMENTFAILED;
      case routes.PACKAGEPAYMENTCHECK:
        return routes.PACKAGEPAYMENTFAILED;
      case routes.EVENTBOOKINGPAYMENTCHECK:
        return routes.EVENTBOOKINGPAYMENTFAILED;
      default:
        return false;
    }
  };

  const getPaymentSuccessRoute = () => {
    switch (location.pathname) {
      case routes.TOPUPBILLPAYMENTCHECK:
        return routes.TOPUPBILLPAYMENTSUCCESS;
      case routes.HOURLYBOOKINGPAYMENTCHECK:
        return routes.HOURLYBOOKINGPAYMENTSUCCESS;
      case routes.SUBSCRIPTIONPLANPAYMENTCHECK:
        return routes.SUBSCRIPTIONPLANPAYMENTSUCCESS;
      case routes.MEMBERSHIP_PLAN_PAYMENT_CHECK:
        return routes.MEMBERSHIP_PLAN_PAYMENT_SUCCESS;
      case routes.FITNESSCLASSSESSIONBOOKINGPAYMENTCHECK:
        return routes.FITNESSCLASSSESSIONBOOKINGPAYMENTSUCCESS;
      case routes.PACKAGEPAYMENTCHECK:
        return routes.PACKAGEPAYMENTSUCCESS;
      case routes.EVENTBOOKINGPAYMENTCHECK:
        return routes.EVENTBOOKINGPAYMENTSUCCESS;
      default:
        return false;
    }
  };

  const getBillStatus = (payload) => {
    switch (location.pathname) {
      case routes.TOPUPBILLPAYMENTCHECK:
        return payload.data.bill.remoteState;
      case routes.HOURLYBOOKINGPAYMENTCHECK:
        return payload.data.paymentTransaction.status;
      case routes.SUBSCRIPTIONPLANPAYMENTCHECK:
      case routes.FITNESSCLASSSESSIONBOOKINGPAYMENTCHECK:
        return payload.data.paymentTransaction.status;
      case routes.PACKAGEPAYMENTCHECK:
        return payload.data.paymentTransaction.status;
      case routes.EVENTBOOKINGPAYMENTCHECK:
        return payload.data.paymentTransaction.status;
      case routes.MEMBERSHIP_PLAN_PAYMENT_CHECK:
        return payload.data.paymentTransaction.status;
      default:
        return false;
    }
  };

  const loadDataFromNet = () => {
    const remoteBillId = new URLSearchParams(location.search).get("orderId");
    const checkBillFunction = getCheckBillFunction();
    const paymentFailedRoute = getPaymentFailedRoute();
    const paymentSuccessRoute = getPaymentSuccessRoute();

    if (new URLSearchParams(location.search).get("status") === "CANCELLED") {
      navigate(paymentFailedRoute, { state: { remoteBillId } });
      return;
    }
    dispatch(
      checkBillFunction({ remoteBillId, token: isLogin() ? null : cookies["guest-payment-token"] }),
    ).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        if (getBillStatus(payload) === "paid") {
          navigate(`${paymentSuccessRoute}?remoteBillId=${remoteBillId}`, {
            state: { ...payload.data },
          });
        } else {
          setTimeout(() => {
            loadDataFromNet();
          }, 3000);
        }
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  useEffect(() => {
    loadDataFromNet();
  }, []);

  return (
    <StyledContainer>
      <Spacer size="xxxl" />
      <Loader
        type="bubble-loop"
        bgColor={theme.palette.colors.brand.secondary}
        color="red"
        size={100}
      />
      <Spacer size="xxxl" />
      <StyledTitle variant={isMobile ? "h4" : "h3"}>Payment Processing</StyledTitle>
      <Spacer size="m" />
      <GreyText>Your payment request is being processed.</GreyText>
      <Spacer size="m" />
      <GreyText>Please do not close or refresh the page.</GreyText>
      <Spacer size="xxxl" />
      <Spacer size="xxl" />
    </StyledContainer>
  );
}
