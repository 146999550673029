import { Box, Grid, Pagination, Typography, useMediaQuery, useTheme } from "@mui/material";
import { format } from "date-fns";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import Form from "../../../components/forms/form.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import PaddedView from "../../../components/utils/padded-view.component";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import Spacer from "../../../components/utils/spacer.component";
import {
  getBusinesses,
  getNearbyBusinesses,
  searchBusinessSelector,
} from "../../../services/search/business/search-business-slice.service";
import {
  getEvents,
  getNearbyEvents,
  searchEventSelector,
} from "../../../services/search/event/search-event-slice.service";
import {
  getFitnessClasses,
  getNearbyFitnessClasses,
  searchFitnessClassSelector,
} from "../../../services/search/fitness-class/search-fitness-class-slice.service";
import BusinessResultCard from "../components/business/business-result-card.component";
import ClassResultCard from "../components/class/class-result-card.component";
import EventResultCard from "../components/event/event-result-card.component";
import Filters from "../components/filters.component";

const validationSchema = Yup.object().shape({
  locationLat: Yup.string().label("LocationLat"),
  locationLong: Yup.string().label("LocationLong"),
  q: Yup.string().label("q"),
  distance: Yup.number().required().label("Distance"),
  categoriesId: Yup.array().of(Yup.number()).label("Categories Id"),
  startAt: Yup.date().required().label("Start At"),
  endAt: Yup.date()
    .min(Yup.ref("startAt"), "End At must be after start at")
    .required()
    .label("End at"),
  fitnessCategoriesId: Yup.array().of(Yup.number()).label("Fitness Categories Id"),
  selectedStartTime: Yup.array().of(Yup.number()).label("Selected start time"),
  selectedDate: Yup.date().required().label("Start Date"),
});

function ExploreSearchScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const [urlParams] = useSearchParams();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { getBusinessesObj, getNearbyBusinessesObj } = useSelector(searchBusinessSelector);
  const { getFitnessClassesObj, getNearbyFitnessClassesObj } = useSelector(
    searchFitnessClassSelector,
  );
  const { getEventsObj, getNearbyEventsObj } = useSelector(searchEventSelector);
  const formRef = useRef();
  const [searchFor, setSearchFor] = useState(
    urlParams.get("searchFor") ? urlParams.get("searchFor") : "businesses",
  );
  const [page, setPage] = useState(1);
  const [nearbyPage, setNearbyPage] = useState(1);

  const getNearby = (values) => {
    if (searchFor === "businesses") {
      dispatch(getNearbyBusinesses(values)).then(({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
    } else if (searchFor === "classes") {
      dispatch(getNearbyFitnessClasses(values)).then(({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
    } else if (searchFor === "event") {
      dispatch(getNearbyEvents(values)).then(({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
    }
  };

  const handleSubmitSearch = (values) => {
    getNearby(values);
    if (searchFor === "businesses") {
      dispatch(getBusinesses(values)).then(({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
    } else if (searchFor === "classes") {
      dispatch(getFitnessClasses(values)).then(({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
    } else if (searchFor === "event") {
      dispatch(getEvents(values)).then(({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
    }
  };

  const onPageChange = (e, newPage) => {
    setPage(newPage);
    formRef.current.values.page = newPage;
    handleSubmitSearch(formRef.current.values);
  };

  const onNearbyPageChange = (e, newPage) => {
    setNearbyPage(newPage);
    formRef.current.values.page = newPage;
    getNearby(formRef.current.values);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (searchFor === "businesses") {
      handleSubmitSearch(formRef.current.values);
    }
  }, []);

  const renderSearchResult = () => {
    if (searchFor === "businesses") {
      if (getBusinessesObj.status === "succeeded") {
        if (getBusinessesObj.data.items.length > 0) {
          return getBusinessesObj.data.items.map((item) => (
            <Grid item key={item.id}>
              <BusinessResultCard itemDetails={item} />
              <Spacer />
            </Grid>
          ));
        }

        return (
          <Grid
            item
            display="flex"
            textAlign="center"
            alignItems="center"
            sx={{
              backgroundColor: theme.palette.colors.bg.primary,
              borderRadius: `${theme.shape.borderRadius[2]}px`,
            }}
            height={isMobile ? 142 : 192}
          >
            <PaddedView multiples={4}>
              <Typography fontSize={theme.fonts.fontSizes.size20} fontWeight="bold">
                No results found.
              </Typography>
              <Typography>Reset your filters.</Typography>
            </PaddedView>
          </Grid>
        );
      }
    } else if (searchFor === "classes") {
      if (getFitnessClassesObj.status === "succeeded") {
        if (getFitnessClassesObj.data.items.length > 0) {
          return getFitnessClassesObj.data.items.map((item) => (
            <Grid item key={item.id}>
              <ClassResultCard itemDetails={item} />
              <Spacer />
            </Grid>
          ));
        }

        return (
          <Grid
            item
            display="flex"
            textAlign="center"
            alignItems="center"
            sx={{
              backgroundColor: theme.palette.colors.bg.primary,
              borderRadius: `${theme.shape.borderRadius[2]}px`,
            }}
            height={isMobile ? 142 : 192}
          >
            <PaddedView multiples={4}>
              <Typography fontSize={theme.fonts.fontSizes.size20} fontWeight="bold">
                No results found.
              </Typography>
              <Typography>Reset your filters.</Typography>
            </PaddedView>
          </Grid>
        );
      }
    } else if (searchFor === "event") {
      if (getEventsObj.status === "succeeded") {
        if (getEventsObj.data.items.length > 0) {
          return getEventsObj.data.items.map((item) => (
            <Grid item key={item.id}>
              <EventResultCard itemDetails={item} />
              <Spacer />
            </Grid>
          ));
        }

        return (
          <Grid
            item
            display="flex"
            textAlign="center"
            alignItems="center"
            sx={{
              backgroundColor: theme.palette.colors.bg.primary,
              borderRadius: `${theme.shape.borderRadius[2]}px`,
            }}
            height={isMobile ? 142 : 192}
          >
            <PaddedView multiples={4}>
              <Typography fontSize={theme.fonts.fontSizes.size20} fontWeight="bold">
                No results found.
              </Typography>
              <Typography>Reset your filters.</Typography>
            </PaddedView>
          </Grid>
        );
      }
    }

    return (
      <Grid item>
        <Grid item>
          <CustomSkeleton width="100%" height={isMobile ? 142 : 192} />
        </Grid>
        <Spacer />
        <Grid item>
          <CustomSkeleton width="100%" height={isMobile ? 142 : 192} />
        </Grid>
      </Grid>
    );
  };

  const renderNearbyResult = () => {
    if (searchFor === "businesses") {
      return getNearbyBusinessesObj.data.items.map((item) => (
        <Grid item key={item.id}>
          <BusinessResultCard itemDetails={item} />
          <Spacer />
        </Grid>
      ));
    }
    if (searchFor === "classes") {
      return getNearbyFitnessClassesObj.data.items.map((item) => (
        <Grid item key={item.id}>
          <ClassResultCard itemDetails={item} />
          <Spacer />
        </Grid>
      ));
    }
    if (searchFor === "event") {
      return getNearbyEventsObj.data.items.map((item) => (
        <Grid item key={item.id}>
          <EventResultCard itemDetails={item} />
          <Spacer />
        </Grid>
      ));
    }

    return (
      <Grid item>
        <Grid item>
          <CustomSkeleton width="100%" height={isMobile ? 142 : 192} />
        </Grid>
        <Spacer />
        <Grid item>
          <CustomSkeleton width="100%" height={isMobile ? 142 : 192} />
        </Grid>
      </Grid>
    );
  };

  const renderPagination = (forNearBy, dataObj) => {
    if (dataObj.status === "succeeded" && dataObj.data.items && dataObj.data.items.length > 0) {
      return (
        <Grid item container xs={12} justifyContent="center">
          <Pagination
            page={forNearBy ? nearbyPage : page}
            onChange={forNearBy ? onNearbyPageChange : onPageChange}
            count={dataObj.data.pagination.totalPages}
            variant="outlined"
          />
        </Grid>
      );
    }
    return null;
  };

  return (
    <Box marginTop={isMobile ? 0 : 3}>
      <PaddedView multiples={2}>
        <Grid item>
          <Typography fontWeight="bold" fontSize={theme.fonts.fontSizes.size24}>
            Find your best local fitness, wellness, and beauty services
          </Typography>
        </Grid>
        <Spacer size="l" />
        <Form
          innerRef={formRef}
          initialValues={{
            location: urlParams.get("location") || "Petaling Jaya",
            lat: urlParams.get("lat") || 3.150895,
            long: urlParams.get("long") || 101.594938,
            q: urlParams.get("q") || "",
            distance: 50,
            tag: urlParams.get("tag") || "",
            categoriesId: location.state?.fitnessCategoriesId
              ? location.state.fitnessCategoriesId
              : [],
            tempCategoriesId: location.state?.fitnessCategoriesId
              ? location.state.fitnessCategoriesId
              : [],
            startAt: location.state?.date
              ? format(new Date(location.state.date), "yyyy-MM-dd HH:mm:ss")
              : format(new Date(), "yyyy-MM-dd HH:mm:ss"),
            endAt: location.state?.date
              ? format(new Date(location.state.date), "yyyy-MM-dd 23:59:59")
              : format(new Date(), "yyyy-MM-dd 23:59:59"),
            fitnessCategoriesId: location.state?.fitnessCategoriesId
              ? location.state.fitnessCategoriesId
              : [],
            eventCategoryIds: [],
            tempeventCategoryIds: [],
            selectedTimeLabel: "",
            selectedTimePeriod: "",
            selectedStartTime: [0, 39],
            tempSelectedStartTime: [0, 39],
            tempSelectedTimeLabel: "",
            tempSelectedTimePeriod: "",
            selectedDate: location.state?.date
              ? format(new Date(location.state.date), "yyyy-MM-dd")
              : format(new Date(), "yyyy-MM-dd"),
            page: 1,
            sortBy: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmitSearch}
        >
          <Filters
            searchFor={searchFor}
            setSearchFor={setSearchFor}
            setPage={setPage}
            setNearbyPage={setNearbyPage}
          />
        </Form>
        <Spacer size="l" />
        <Box>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              {renderSearchResult()}
            </Grid>

            {searchFor === "businesses" && renderPagination(false, getBusinessesObj)}
            {searchFor === "classes" && renderPagination(false, getFitnessClassesObj)}
            {searchFor === "event" && renderPagination(false, getEventsObj)}

            {((searchFor === "businesses" &&
              getBusinessesObj.status === "succeeded" &&
              getBusinessesObj.data.items &&
              getBusinessesObj.data.items.length === 0 &&
              getNearbyBusinessesObj.status === "succeeded" &&
              getNearbyBusinessesObj.data.items &&
              getNearbyBusinessesObj.data.items.length > 0) ||
              (searchFor === "classes" &&
                getFitnessClassesObj.status === "succeeded" &&
                getFitnessClassesObj.data.items &&
                getFitnessClassesObj.data.items.length === 0 &&
                getNearbyFitnessClassesObj.status === "succeeded" &&
                getNearbyFitnessClassesObj.data.items &&
                getNearbyFitnessClassesObj.data.items.length > 0) ||
              (searchFor === "event" &&
                getEventsObj.status === "succeeded" &&
                getEventsObj.data.items &&
                getEventsObj.data.items.length === 0 &&
                getNearbyEventsObj.status === "succeeded" &&
                getNearbyEventsObj.data.items &&
                getNearbyEventsObj.data.items.length > 0)) && (
              <Grid item alignItems="center" xs={12}>
                <Grid item>
                  <Typography fontSize={theme.fonts.fontSizes.size20} fontWeight="bold">
                    Nearby {searchFor}
                  </Typography>
                </Grid>
                <Spacer size="m" />
                <Grid item xs={12}>
                  {renderNearbyResult()}
                </Grid>
                <Spacer size="m" />
                {searchFor === "businesses" && renderPagination(true, getNearbyBusinessesObj)}
                {searchFor === "classes" && renderPagination(true, getNearbyFitnessClassesObj)}
                {searchFor === "event" && renderPagination(true, getNearbyEventsObj)}
              </Grid>
            )}
          </Grid>
        </Box>
      </PaddedView>
    </Box>
  );
}

export default ExploreSearchScreen;
