import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

function CategoryFilterBox({ itemDetails, onClick }) {
  const theme = useTheme();
  const { values } = useFormikContext();

  return (
    <Box
      onClick={onClick}
      sx={{
        width: "150px",
        height: "150px",
        backgroundImage: `url(${itemDetails.imagePath})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        borderRadius: `${theme.shape.borderRadius[2]}px`,
        cursor: "pointer",
        opacity: values.categoriesId.includes(itemDetails.categoryId) ? 1 : 0.5,
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography textAlign="center" sx={{ color: theme.palette.colors.text.white }}>
        {itemDetails.label}
      </Typography>
    </Box>
  );
}

CategoryFilterBox.defaultProps = {
  itemDetails: PropTypes.shape({
    id: null,
    categoryId: null,
    label: "",
    imagePath: "",
  }),
};

CategoryFilterBox.propTypes = {
  itemDetails: PropTypes.shape({
    id: PropTypes.number,
    categoryId: PropTypes.number,
    label: PropTypes.string,
    imagePath: PropTypes.string,
  }),
  onClick: PropTypes.func.isRequired,
};

export default CategoryFilterBox;
