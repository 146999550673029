import React from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography, Grid, useTheme, IconButton, styled } from "@mui/material";
import Spacer from "./spacer.component";

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
}));

const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
  height: "25px",
  width: "25px",
  strokeWidth: 2,
  stroke: theme.palette.colors.brand.secondary,
}));

function TopTitleBox({ title, requireCloseButton, setShowModal }) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        alignItems: "center",
        width: "100%",
      }}
    >
      {requireCloseButton && (
        <Grid item xs={12} display="flex" justifyContent="flex-end" position="relative">
          <CloseIconButton onClick={() => setShowModal(false)}>
            <StyledCloseIcon />
          </CloseIconButton>
          <Spacer size="xl" />
        </Grid>
      )}
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        marginTop={!requireCloseButton && "20px"}
      >
        <Typography
          sx={{
            textAlign: "center",
            color: theme.palette.colors.text.primary,
            fontWeight: "bold",
            padding: 0,
          }}
          variant="h5"
        >
          {title}
        </Typography>
      </Grid>
    </Box>
  );
}

TopTitleBox.defaultProps = {
  setShowModal: null,
};

TopTitleBox.propTypes = {
  title: PropTypes.string.isRequired,
  requireCloseButton: PropTypes.bool.isRequired,
  setShowModal: (props, propName) => {
    if (
      props.requireCloseButton &&
      (props[propName] === undefined || typeof props[propName] !== "function")
    ) {
      return new Error("setShowModal function is required when requiredCloseButton is true");
    }
    return null;
  },
};

export default TopTitleBox;
