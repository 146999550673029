import { Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
import React from "react";
import CustomSkeleton from "../../../components/utils/skeleton.component";

function UserProfileRowLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid
      container
      sx={{
        justifyContent: "space-between",
        alignItems: isMobile ? "space-between" : "center",
        flexDirection: isMobile ? "column" : "row",
      }}
    >
      <Grid item xs={4}>
        <Typography>
          <CustomSkeleton />
        </Typography>
      </Grid>

      <Grid
        item
        container
        xs={isMobile ? 12 : 8}
        justifyContent={!isMobile && "flex-end"}
        alignItems="center"
      >
        <CustomSkeleton width="200px" />
      </Grid>
    </Grid>
  );
}

export default UserProfileRowLoader;
