import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import {
  Box,
  ButtonBase,
  Grid,
  Icon,
  Modal,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import CustomButton from "../../../../components/button/custom-button.component";
import FormPickerDate from "../../../../components/forms/form-picker-date.component";
import Form from "../../../../components/forms/form.component";
import routes from "../../../../components/navigation/routes";
import { SnackbarContext } from "../../../../components/notification/snackbar.context";
import PaddedView from "../../../../components/utils/padded-view.component";
import CustomSkeleton from "../../../../components/utils/skeleton.component";
import Spacer from "../../../../components/utils/spacer.component";
import TopTitleBox from "../../../../components/utils/top-title-box.component";
import {
  appointmentSelector,
  getAppointmentSchedule,
} from "../../../../services/appointment/appointment-slice.service";

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "absolute",
  top: !isMobile && "50%",
  left: !isMobile && "50%",
  transform: !isMobile && "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.white,
  borderRadius: !isMobile && theme.shape.borderRadius[2],
  boxShadow: 24,
  maxWidth: !isMobile && "550px",
  width: isMobile ? "100%" : "90%",
  height: isMobile && "100%",
  outline: "none",
  overflowY: isMobile && "auto",
}));

const availableSessionValidationSchema = Yup.object().shape({
  date: Yup.date().required().label("Date"),
});

function AvailableScheduleModal({ itemDetails, showModal, setShowModal }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const { getAppointmentScheduleObj } = useSelector(appointmentSelector);
  const createSnackBar = useContext(SnackbarContext);
  const formRef = useRef();
  const navigate = useNavigate();
  const [selectedBusiness, setSelectedBusiness] = useState(
    itemDetails.businesses.length > 1 ? null : itemDetails.businesses[0].id,
  );
  const [selectedStartAt, setSelectedStartAt] = useState(null);

  const getSchedule = (values) => {
    dispatch(
      getAppointmentSchedule({
        date: format(new Date(values.date), "yyyy-MM-dd"),
        categoryType: itemDetails.category.type,
        packageId: itemDetails.id,
        businessId: selectedBusiness,
      }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  useEffect(() => {
    if (selectedBusiness) {
      getSchedule({ date: format(new Date(), "yyyy-MM-dd") });
      if (formRef && formRef.current) {
        formRef.current.setFieldValue("businessId", selectedBusiness);
      }
    }
    setSelectedStartAt(null);
  }, [selectedBusiness]);

  const renderAvailableSession = () => {
    if (getAppointmentScheduleObj.status === "succeeded") {
      if (getAppointmentScheduleObj.data.length > 0) {
        return getAppointmentScheduleObj.data.map((item) => (
          <Grid item xs={6} key={item.startAt}>
            <ButtonBase
              disabled={!item.available}
              sx={{
                width: "100%",
                height: "45px",
                border: "1px solid",
                borderColor:
                  selectedStartAt === item.startAt
                    ? theme.palette.colors.brand.primary
                    : theme.palette.colors.text.disabled,
                borderRadius: `${theme.shape.borderRadius[1]}px`,
              }}
              onClick={() => {
                setSelectedStartAt(item.startAt);
                formRef.current.setFieldValue("startAt", item.startAt);
                formRef.current.setFieldValue("endAt", item.endAt);
              }}
            >
              <Typography
                sx={{
                  color:
                    selectedStartAt === item.startAt
                      ? theme.palette.colors.brand.primary
                      : theme.palette.colors.text.disabled,
                }}
              >
                {format(new Date(item.startAt), "hh:mma")}
              </Typography>
              {(!item.available || item.booked) && (
                <Icon
                  sx={{
                    position: "absolute",
                    margin: "auto",
                  }}
                >
                  <DoDisturbAltIcon sx={{ color: theme.palette.colors.text.secondary }} />
                </Icon>
              )}
            </ButtonBase>
          </Grid>
        ));
      }

      return (
        <Grid
          item
          xs={12}
          display="flex"
          textAlign="center"
          alignItems="center"
          sx={{
            backgroundColor: theme.palette.colors.bg.primary,
            borderRadius: `${theme.shape.borderRadius[2]}px`,
          }}
          height={isMobile ? 142 : 192}
        >
          <PaddedView multiples={4}>
            <Typography fontSize={theme.fonts.fontSizes.size20} fontWeight="bold">
              No results found.
            </Typography>
            <Typography>Please choose another date.</Typography>
          </PaddedView>
        </Grid>
      );
    }
    return (
      <Grid item xs={12}>
        <Grid item>
          <CustomSkeleton width="100%" height={isMobile ? 142 : 192} />
        </Grid>
      </Grid>
    );
  };

  return (
    <Modal
      open={showModal}
      onClose={() => {
        setSelectedBusiness(null);
        setShowModal(false);
      }}
    >
      <ModalBox isMobile={isMobile}>
        <Form
          innerRef={formRef}
          initialValues={{
            date: format(new Date(), "yyyy-MM-dd"),
            startAt: null,
            endAt: null,
            packageId: itemDetails.id,
            businessId: selectedBusiness,
          }}
          validationSchema={availableSessionValidationSchema}
          onSubmit={getSchedule}
        >
          {itemDetails && selectedBusiness ? (
            <PaddedView multiples={2}>
              <TopTitleBox
                title={itemDetails.plan.title}
                requireCloseButton={true}
                setShowModal={setShowModal}
              />
              <Spacer position="top" size="l" />
              <Grid container item xs={12} justifyContent="center" rowSpacing={3} padding="10px">
                <Grid item xs={8}>
                  <FormPickerDate name="date" submitOnChange={true} />
                </Grid>
                <Grid item xs={12} container spacing={1}>
                  {renderAvailableSession()}
                </Grid>
                <Grid item xs={6} sx={{ marginTop: "auto" }}>
                  <Spacer size="xl" />
                  <CustomButton
                    disabled={!selectedStartAt}
                    fontSize={theme.fonts.fontSizes.size16}
                    onClick={() =>
                      navigate(routes.APPOINTMENTSUMMARY, {
                        state: { ...formRef.current.values },
                      })
                    }
                  >
                    Book
                  </CustomButton>
                </Grid>
              </Grid>
            </PaddedView>
          ) : (
            <PaddedView multiples={2}>
              <TopTitleBox
                title={itemDetails.plan.title}
                requireCloseButton={true}
                setShowModal={setShowModal}
              />
              <Spacer position="top" size="l" />
              <Grid container item xs={12} justifyContent="center" rowSpacing={1} padding="10px">
                {itemDetails.businesses.map((item) => (
                  <Grid item xs={12} key={item.id}>
                    <ButtonBase
                      sx={{
                        border: "1px solid",
                        borderColor: theme.palette.colors.brand.primary,
                        borderRadius: `${theme.shape.borderRadius[1]}px`,
                        width: "100%",
                        height: "50px",
                      }}
                      onClick={() => setSelectedBusiness(item.id)}
                    >
                      <Typography color="primary">{item.name}</Typography>
                    </ButtonBase>
                  </Grid>
                ))}
              </Grid>
              <Spacer size="l" />
            </PaddedView>
          )}
        </Form>
      </ModalBox>
    </Modal>
  );
}

AvailableScheduleModal.defaultProps = {
  itemDetails: PropTypes.shape({
    id: null,
    code: "",
    plan: PropTypes.shape({
      title: "",
      imagePath: "",
    }),
    sessions: PropTypes.shape({
      claimedCount: 0,
      noOfSessions: 0,
    }),
    businesses: PropTypes.arrayOf(
      PropTypes.shape({
        id: null,
        name: "",
      }),
    ),
    category: PropTypes.shape({
      type: "",
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: null,
          label: "",
        }),
      ),
    }),
    paymentTransaction: PropTypes.shape({
      method: "",
      paidAt: "",
      total: "",
    }),
  }),
};

AvailableScheduleModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  itemDetails: PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string,
    plan: PropTypes.shape({
      title: PropTypes.string,
      imagePath: PropTypes.string,
    }),
    sessions: PropTypes.shape({
      claimedCount: PropTypes.number,
      noOfSessions: PropTypes.number,
    }),
    businesses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
    category: PropTypes.shape({
      type: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          label: PropTypes.string,
        }),
      ),
    }),
    paymentTransaction: PropTypes.shape({
      method: PropTypes.string,
      paidAt: PropTypes.string,
      total: PropTypes.string,
    }),
  }),
};

export default AvailableScheduleModal;
