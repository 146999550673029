import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Box, Grid, Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import FormCheckBox from "../../../../components/forms/form-checkbox.component";
import FormFieldTextWithIcon from "../../../../components/forms/form-field-text-with-icon.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import FormToggleButton from "../../../../components/forms/form-toggle-button.component";
import Form from "../../../../components/forms/form.component";
import PhoneNumberTextField from "../../../../components/forms/phone-number-text-field.component";
import routes from "../../../../components/navigation/routes";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";
import TopTitleBox from "../../../../components/utils/top-title-box.component";
import { authSelector } from "../../../../services/auth/auth-slice.service";
import LabelLink from "../link-with-label.component";

function LoginForm({ handleSubmit, isLoading }) {
  const validationSchema = Yup.object().shape({
    method: Yup.string().required().label("Method"),
    phone: Yup.string().when("method", {
      is: "phone",
      then: () => Yup.string().required().min(5).label("Phone"),
    }),
    email: Yup.string().when("method", {
      is: "email",
      then: () => Yup.string().required().email().label("Email"),
    }),
    password: Yup.string().required().min(4).label("Password"),
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { loginObj } = useSelector(authSelector);

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: theme.palette.colors.bg.white,
          borderRadius: `${theme.shape.borderRadius[2]}px`,
          flex: 1,
          maxWidth: 500,
          minWidth: isMobile ? null : 450,
          alignItems: "center",
          justifyContent: "center",
          boxShadow: 2,
          overflow: "hidden",
        }}
      >
        <TopTitleBox title="Sign In" requireCloseButton={false} />
        <Form
          initialValues={{
            method: "email",
            phone: "",
            email: localStorage.getItem("userEmail") || "",
            password: localStorage.getItem("userPassword") || "",
            isRemember: localStorage.getItem("isRemember") === "true" || false,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <PaddedView multiples={isMobile ? 3 : 4}>
              <FormToggleButton
                fullWidth={false}
                options={[
                  { label: "Email", value: "email" },
                  { label: "Phone", value: "phone" },
                ]}
                name="method"
              />
              <Spacer size="m" />
              {values.method === "email" ? (
                <FormFieldTextWithIcon
                  name="email"
                  placeholder="Enter your email address"
                  showIcon={true}
                  icon={<MailOutlineIcon color="primary" />}
                />
              ) : (
                <PhoneNumberTextField name="phone" />
              )}

              <Spacer size="m" />
              <FormFieldTextWithIcon
                name="password"
                placeholder="Password"
                showIcon={true}
                icon={<LockOutlinedIcon color="primary" />}
              />

              <Grid container justifyContent="space-between" sx={{ paddingTop: 1 }}>
                <Grid sx={{ width: 150 }}>
                  <FormCheckBox name="isRemember" label="Remember Me" />
                </Grid>
                <Grid alignItems="center" justifyContent="center">
                  <Link
                    sx={{
                      paddingLeft: 1,
                      color: theme.palette.colors.brand.primary,
                    }}
                    underline="none"
                    key="1"
                    variant="body2"
                    href={routes.FORGOTPASSWORD}
                  >
                    Forgot Password?
                  </Link>
                </Grid>
              </Grid>
              {loginObj.status === "failed" && loginObj.errorMessage === "Invalid credentials" && (
                <Grid container justifyContent="center">
                  <Typography variant="body2" color="error">
                    Invalid credentials, please proceed with Forgot Password
                  </Typography>
                </Grid>
              )}

              <Spacer size="xl" />
              <LabelLink label=" Don't have an account? " link="Sign Up" pushTo={routes.SIGNUP} />

              <Spacer size="s" />
              <Grid item textAlign="center" xs={12}>
                <FormSubmitButton isLoading={isLoading} width="60%" borderRadius={3}>
                  <Typography>Sign In</Typography>
                </FormSubmitButton>
              </Grid>
            </PaddedView>
          )}
        </Form>
      </Box>
    </Box>
  );
}

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default LoginForm;
