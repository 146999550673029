import PlaceIcon from "@mui/icons-material/Place";
import {
  Box,
  Grid,
  Modal,
  Rating,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import CustomButton from "../../../components/button/custom-button.component";
import FormFieldTextWithCounter from "../../../components/forms/form-field-text-with-counter.component";
import Form from "../../../components/forms/form.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Spacer from "../../../components/utils/spacer.component";
import { reviewSelector, updateReview } from "../../../services/review/review-slice.service";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.white,
  borderRadius: theme.shape.borderRadius[2],
  boxShadow: 24,
  maxWidth: "400px",
  width: "90%",
  maxHeight: "90%",
  outline: "none",
  padding: "20px",
  overflowY: "auto",
}));

const validationSchema = Yup.object().shape({
  rating: Yup.number().required().label("Rating"),
  comment: Yup.string().nullable().label("Comment"),
});

function ReviewModal() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { getPendingReviewsObj, updateReviewObj } = useSelector(reviewSelector);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [rateStage, setRateStage] = useState(false);
  const formRef = useRef();
  const createSnackBar = useContext(SnackbarContext);

  useEffect(() => {
    if (
      getPendingReviewsObj.status === "succeeded" &&
      getPendingReviewsObj.data &&
      getPendingReviewsObj.data.length > 0
    ) {
      setShowModal(true);
    }
  }, [getPendingReviewsObj.status]);

  const handleDismiss = (reviewId) => {
    setRateStage(false);
    dispatch(updateReview({ reviewId, rating: null, comment: null, dismissed: true }));
  };

  const handleSubmit = (values) => {
    dispatch(updateReview({ ...values, dismissed: false })).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setRateStage(false);
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  return (
    getPendingReviewsObj.status === "succeeded" &&
    getPendingReviewsObj.data &&
    getPendingReviewsObj.data.length > 0 && (
      <Modal open={showModal}>
        <ModalBox isMobile={isMobile}>
          {!rateStage && (
            <>
              <Grid container display="flex" justifyContent="center">
                <Grid item>
                  <img
                    alt="businessImage"
                    src={getPendingReviewsObj.data[0].location.imagePath}
                    style={{
                      width: 220,
                      height: 200,
                      borderRadius: `${theme.shape.borderRadius[2]}px`,
                      objectFit: "cover",
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography textAlign="center">How was your workout session?</Typography>
              </Grid>
              <Spacer size="l" />
            </>
          )}

          <Grid item xs={12}>
            <Typography
              textTransform="capitalize"
              fontWeight="bold"
              sx={{ color: theme.palette.colors.brand.primary }}
            >
              {getPendingReviewsObj.data[0].session.type === "merchant_class_session"
                ? "Fitness Class Session"
                : "Gym Session"}
            </Typography>
            {getPendingReviewsObj.data[0].session.type === "merchant_class_session" && (
              <Typography sx={{ color: theme.palette.colors.text.primary }}>
                {getPendingReviewsObj.data[0].session.class.name}
              </Typography>
            )}
            <Typography sx={{ color: theme.palette.colors.text.secondary }}>
              {getPendingReviewsObj.data[0].session.date}
            </Typography>
            <Typography sx={{ color: theme.palette.colors.text.secondary }}>
              {getPendingReviewsObj.data[0].session.time}
            </Typography>
            <Grid item display="flex" alignContent="center">
              <PlaceIcon color="primary" />
              <Spacer position="left" />
              <Typography>{getPendingReviewsObj.data[0].location.name}</Typography>
            </Grid>
          </Grid>
          {rateStage ? (
            <>
              <Spacer />
              <Grid container display="flex" justifyContent="center">
                <Form
                  initialValues={{
                    rating: 5,
                    comment: "",
                    reviewId: getPendingReviewsObj.data[0].id,
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                  innerRef={formRef}
                >
                  <Grid item>
                    <Rating
                      name="rating"
                      size="large"
                      defaultValue={5}
                      onChange={(event, newValue) => {
                        formRef.current.setFieldValue("rating", newValue);
                      }}
                      sx={{ color: theme.palette.colors.brand.primary }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormFieldTextWithCounter
                      maxLength={250}
                      name="comment"
                      placeholder="Share your experience"
                      multiline
                      height="150px"
                    />
                  </Grid>
                </Form>
              </Grid>
              <Spacer size="l" />
              <Grid container columnSpacing={2}>
                <Grid item xs={6}>
                  <CustomButton
                    isLoading={updateReviewObj.status === "pending"}
                    variant="outlined"
                    onClick={() => handleDismiss(getPendingReviewsObj.data[0].id)}
                  >
                    Dismiss
                  </CustomButton>
                </Grid>
                <Grid item xs={6}>
                  <CustomButton
                    isLoading={updateReviewObj.status === "pending"}
                    onClick={() => formRef.current.handleSubmit()}
                  >
                    Submit
                  </CustomButton>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Spacer size="l" />
              <Grid container columnSpacing={2}>
                <Grid item xs={6}>
                  <CustomButton
                    isLoading={updateReviewObj.status === "pending"}
                    variant="outlined"
                    onClick={() => handleDismiss(getPendingReviewsObj.data[0].id)}
                  >
                    Dismiss
                  </CustomButton>
                </Grid>
                <Grid item xs={6}>
                  <CustomButton onClick={() => setRateStage(true)}>Rate</CustomButton>
                </Grid>
              </Grid>
            </>
          )}
        </ModalBox>
      </Modal>
    )
  );
}

export default ReviewModal;
