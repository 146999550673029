import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAPI } from "../../infrastructure/utils";
import { getReq } from "../api.services";

export const getCategorySegments = createAsyncThunk("category_segment/index", async () => {
  const response = getReq(`${getAPI()}/category_segments`);
  return response;
});

const categorySegmentSlice = createSlice({
  name: "categorySegment",
  initialState: {
    getCategorySegmentsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [getCategorySegments.pending]: (state) => {
      state.getCategorySegmentsObj.status = "pending";
    },
    [getCategorySegments.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getCategorySegmentsObj.status = "succeeded";
      state.getCategorySegmentsObj.data = data;
      state.getCategorySegmentsObj.successMessage = message;
    },
    [getCategorySegments.rejected]: (state, action) => {
      const { message } = action.error;

      state.getCategorySegmentsObj.status = "failed";
      state.getCategorySegmentsObj.errorMessage = message;
    },
  },
});

export default categorySegmentSlice.reducer;

// state
export const categorySegmentSelector = (state) => state.categorySegment;
