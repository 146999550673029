import { Box, Button, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import Loader from "react-js-loader";

function CustomButton({
  children,
  width,
  height,
  isLoading,
  onClick,
  buttonColor,
  textColor,
  variant,
  startIcon,
  endIcon,
  fontPadding,
  fontSize,
  fontWeight,
  whiteSpace,
  disabled,
  backgroundColor,
}) {
  const theme = useTheme();

  return isLoading ? (
    <Button
      disabled
      variant="contained"
      color={buttonColor}
      sx={{
        borderRadius: `${theme.shape.borderRadius[3]}px`,
        textTransform: "none",
        width,
        height,
      }}
    >
      <Box sx={{ height: "18px", justifyContent: "center", display: "flex", alignItems: "center" }}>
        <Loader type="bubble-loop" bgColor={theme.palette.colors.loading.white} size={30} />
      </Box>
    </Button>
  ) : (
    <Button
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      variant={variant}
      onClick={onClick}
      color={buttonColor}
      sx={{
        borderRadius: `${theme.shape.borderRadius[3]}px`,
        textTransform: "none",
        width,
        height,
        whiteSpace,
        backgroundColor,
      }}
    >
      <Typography
        fontSize={fontSize}
        color={textColor}
        fontWeight={fontWeight}
        padding={fontPadding}
      >
        {children}
      </Typography>
    </Button>
  );
}

CustomButton.defaultProps = {
  variant: "contained",
  width: "100%",
  height: null,
  isLoading: false,
  disabled: false,
  onClick: null,
  buttonColor: "primary",
  textColor: "white",
  startIcon: null,
  endIcon: null,
  fontPadding: "0",
  fontSize: "12px",
  fontWeight: "bold",
  whiteSpace: null,
  backgroundColor: null,
};

CustomButton.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  buttonColor: PropTypes.string,
  textColor: PropTypes.string,
  variant: PropTypes.string,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  fontPadding: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  whiteSpace: PropTypes.string,
  backgroundColor: PropTypes.string,
};

export default CustomButton;
