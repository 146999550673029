import AppleIcon from "@mui/icons-material/Apple";
import { Box, Grid, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
// import appstore from "../../../assets/images/appstore.png";
// import huaweiAppGallery from "../../../assets/images/huawei-app-gallery.png";
// import playstore from "../../../assets/images/playstore.png";
import BottomBannerImage from "../../../assets/images/bottom-banner.png";
import { ReactComponent as HuaweiLogo } from "../../../assets/images/huawei.svg";
import { ReactComponent as AndroidLogo } from "../../../assets/images/android.svg";

function BottomBanner() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    !isMobile && (
      <Box
        sx={{
          display: "flex",
          position: "relative",
        }}
      >
        <img src={BottomBannerImage} width="100%" alt="bottom-banner" />
        {/* {!isMobile && (
    <Box sx={{ position: "absolute", width: "100%", height: "68%", bottom: 0 }}>
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        sx={{ bottom: 0, position: "absolute", overflow: "hidden" }}
      >
        <Grid
          container
          flexDirection="column"
          sx={{ position: "absolute", height: "95%", left: "65%", top: "10%" }}
        >
          <Grid
            item
            height="30%"
            sx={{ cursor: "pointer" }}
            onClick={() =>
              window.open(
                `https://play.google.com/store/apps/details?id=com.engageapp.mobile&hl=en`,
                "_blank",
              )
            }
          >
            <img src={playstore} alt="playstore_logo" width="15%" />
          </Grid>
          <Grid
            height="30%"
            sx={{ cursor: "pointer" }}
            onClick={() =>
              window.open(`https://apps.apple.com/my/app/katch/id1606341748`, "_blank")
            }
          >
            <img src={appstore} alt="appstore_logo" width="15%" />
          </Grid>
          <Grid
            height="30%"
            sx={{ cursor: "pointer" }}
            onClick={() =>
              window.open(`https://appgallery.huawei.com/app/C106856797`, "_blank")
            }
          >
            <img src={huaweiAppGallery} alt="huawei_logo" width="15%" />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )} */}
        <Box sx={{ position: "absolute", width: "100%", height: "68%", bottom: 0 }}>
          <Grid
            container
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              margin: "auto",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              fontWeight="bold"
              fontSize={theme.fonts.fontSizes.size32}
              sx={{ color: "#fff" }}
            >
              Get Katch App Now
            </Typography>
          </Grid>
        </Box>

        <Box sx={{ position: "absolute", width: "100%", height: "100%" }}>
          <Grid
            container
            display="flex"
            justifyContent="center"
            sx={{ position: "absolute", bottom: "10%" }}
          >
            <Grid item>
              <IconButton
                onClick={() =>
                  window.open(
                    `https://play.google.com/store/apps/details?id=com.engageapp.mobile&hl=en`,
                    "_blank",
                  )
                }
              >
                <AndroidLogo width="3vw" />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                onClick={() =>
                  window.open(`https://apps.apple.com/my/app/katch/id1606341748`, "_blank")
                }
              >
                <AppleIcon sx={{ fontSize: "3vw", color: "#fff" }} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                onClick={() =>
                  window.open(`https://appgallery.huawei.com/app/C106856797`, "_blank")
                }
              >
                <HuaweiLogo width="3vw" />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
    )
  );
}

export default BottomBanner;
