import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postReq } from "../../../api.services";

export const checkReceivingUserAllowToPurchaseSession = createAsyncThunk(
  "packages/sessions/{sessionId}/bookings/allow_booking",
  async (payload) => {
    const { userPhoneNo, sessionId } = payload;
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/packages/sessions/${sessionId}/bookings/allow_booking`,
      { userPhoneNo },
    );
    return response;
  },
);

export const bookSessionViaPackageCode = createAsyncThunk(
  "packages/sessions/bookings/create",
  async (payload) => {
    const { sessionId, packageCode, userPhoneNo } = payload;
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/packages/sessions/${sessionId}/bookings`,
      {
        packageCode,
        userPhoneNo,
      },
    );
    return response;
  },
);

const packageFitnessSessionBookingSlice = createSlice({
  name: "packageFitnessSessionBooking",
  initialState: {
    bookSessionViaPackageCodeObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    checkReceivingUserAllowToPurchaseSessionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [bookSessionViaPackageCode.pending]: (state) => {
      state.bookSessionViaPackageCodeObj.status = "pending";
    },
    [bookSessionViaPackageCode.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.bookSessionViaPackageCodeObj.status = "succeeded";
      state.bookSessionViaPackageCodeObj.data = data;
      state.bookSessionViaPackageCodeObj.successMessage = message;
    },
    [bookSessionViaPackageCode.rejected]: (state, action) => {
      const { message } = action.error;

      state.bookSessionViaPackageCodeObj.status = "failed";
      state.bookSessionViaPackageCodeObj.errorMessage = message;
    },
    [checkReceivingUserAllowToPurchaseSession.pending]: (state) => {
      state.checkReceivingUserAllowToPurchaseSessionObj.status = "pending";
    },
    [checkReceivingUserAllowToPurchaseSession.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.checkReceivingUserAllowToPurchaseSessionObj.status = "succeeded";
      state.checkReceivingUserAllowToPurchaseSessionObj.data = data;
      state.checkReceivingUserAllowToPurchaseSessionObj.successMessage = message;
    },
    [checkReceivingUserAllowToPurchaseSession.rejected]: (state, action) => {
      const { message } = action.error;

      state.checkReceivingUserAllowToPurchaseSessionObj.status = "failed";
      state.checkReceivingUserAllowToPurchaseSessionObj.errorMessage = message;
    },
  },
});

export default packageFitnessSessionBookingSlice.reducer;

// state
export const packageFitnessSessionBookingSelector = (state) => state.packageFitnessSessionBooking;
