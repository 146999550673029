import { Grid, List, ListItem, styled, Typography } from "@mui/material";
import React from "react";
import HorizontalDivider from "../../../components/utils/horizontal-divider.component";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";

const SectionText = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size18,
  fontWeight: "bold",
}));

const StyledListItem = styled(ListItem)({
  display: "list-item",
  textAlign: "justify",
});

function TermsScreen() {
  return (
    <PaddedView multiples={2}>
      <Spacer size="xl" />

      <Grid container>
        <Grid item>
          <Grid item>
            <SectionText>Last Updated: November 2023</SectionText>
          </Grid>
        </Grid>
        <HorizontalDivider spacerSize="l" />
        <Grid item>
          <Grid item>
            <SectionText>1. These Terms and Conditions</SectionText>
          </Grid>
          <Spacer size="m" />
          <Grid item>
            <List sx={{ listStyleType: "lower-roman", pl: 4 }}>
              <StyledListItem>
                The following terms and conditions apply to all visitors to or users of this Website
                owned by Katch International Sdn Bhd (“Katch”), both now and in the future. By
                accessing this website, user acknowledges acceptance of these terms and conditions.
                KATCH reserves the right to change these terms and conditions from time to time at
                its sole discretion. It is your responsibility to review the Terms of Service
                regularly. Your continued use of our service after any such amendments, whether or
                not reviewed by you, shall constitute your agreement to be bound by such amendments.
                In the case of any violation, KATCH reserves the right to seek all remedies
                available by law and in equity for such violations.
              </StyledListItem>
              <StyledListItem>
                These Terms and Conditions should be read with our Privacy Policy (together referred
                to as the “Terms”).
              </StyledListItem>
              <StyledListItem>
                Please read these Terms carefully before you subscribe to our products and/or
                services. Prior to registering or purchasing our or Partner’s products and/or
                services, we will also ask you to accept the terms and conditions that apply to the
                specific products and/or services that you are applying for. If you do not agree
                with these Terms, you must not use our website or apply for our products and/or
                services.
              </StyledListItem>
              <StyledListItem>
                “<b>Partner</b>” means the independent third parties (who are not Katch) who provide
                the relevant products and services to consumers through the Katch mobile app,
                including (a) wellness partners, , (b) fitness trainers/gym partners and (c) any
                other relevant goods and services providers in the healthy, fitness and wellness
                industry approved by Katch from time to time.
              </StyledListItem>
            </List>
          </Grid>
        </Grid>
        <HorizontalDivider spacerSize="l" />
        <Grid item>
          <Grid item>
            <SectionText>2. About KATCH</SectionText>
          </Grid>
          <Spacer size="m" />
          <Grid item>
            <Typography textAlign="justify">
              When we refer to “KATCH”, “we”, “our” or “us” in these Terms, we mean Katch
              International Sdn Bhd and each of its group companies (including parent, subsidiary
              and joint venture undertakings). Katch owns and manage this website/ mobile
              application.
            </Typography>
            <Spacer size="m" />
            <Typography textAlign="justify">
              KATCH IS A TECHNOLOGY COMPANY WHICH PROVIDES A PLATFORM FOR CONSUMERS TO VIEW, OBTAIN
              OR PROCURE GOODS AND SERVICES PROVIDED BY PARTNER OR US. WHERE THE GOODS/SERVICES ARE
              OFFERED AND/OR PROVIDED BY PARTNER, KATCH’S ROLE IS MERELY TO LINK THE USER WITH SUCH
              PARTNERS OR TO ENABLE CONSUMERS TO VIEW INFORMATION IN RESPECT OF THE PARTNER’S
              GOODS/SERVICES. KATCH IS NOT RESPONSIBLE FOR THE ACTS AND/OR OMISSIONS OF ANY PARTNERS
              OR INFORMATION RELATING TO THE SAME. PARTNERS SHALL BEAR ALL LIABILITY IN RELATION TO
              SUCH GOODS AND SERVICES. PARTNERS ARE NOT, AND SHALL NOT HOLD THEMSELVES TO BE, AN
              AGENT, EMPLOYEE OR STAFF OF KATCH AND THE SERVICES OR PRODUCTS PROVIDED BY PARTNERS
              SHALL NOT BE DEEMED TO BE PROVIDED BY KATCH.
            </Typography>
          </Grid>
        </Grid>
        <HorizontalDivider spacerSize="l" />
        <Grid item>
          <Grid item>
            <SectionText>3. Eligibility</SectionText>
          </Grid>
          <Spacer size="m" />
          <Grid item>
            <Typography textAlign="justify">
              Our platform is intended solely for users who are 18 years of age or older. By using
              our platform, you represent and warrant that you are 18 years of age or older, and
              that you are approved by your hiring company to participate in our products and
              services.
            </Typography>
          </Grid>
        </Grid>
        <HorizontalDivider spacerSize="l" />
        <Grid item>
          <Grid item>
            <SectionText>4. Account registration</SectionText>
          </Grid>
          <Spacer size="m" />
          <Grid item>
            <Typography textAlign="justify">
              To enjoy the Products/Services that we offer in our mobile application, you will be
              required to create an account and you will be asked to provide certain personal
              information and related details. As part of the registration process, you agree to us
              processing your personal data and us and third parties (including our processing
              agents, if any) sharing relevant information between each other for validation
              purpose.
            </Typography>
          </Grid>
        </Grid>
        <HorizontalDivider spacerSize="l" />
        <Grid item>
          <Grid item>
            <SectionText>4A. Cancellation and Refund</SectionText>
          </Grid>
          <Spacer size="m" />
          <Grid item>
            <Typography textAlign="justify">
              Any change or cancellation of sessions or purchase due to your own mistake or
              oversight shall not be eligible for any refund.
            </Typography>
          </Grid>
        </Grid>
        <HorizontalDivider spacerSize="l" />
        <Grid item>
          <Grid item>
            <SectionText>5. Accuracy of information</SectionText>
          </Grid>
          <Spacer size="m" />
          <Grid item>
            <Typography textAlign="justify">
              When creating an account or applying for a product or service on our website, you
              agree to provide current, complete and accurate information about yourself. If any
              information you provide is untrue, inaccurate, not current or incomplete, we may deny
              your application and terminate your account and/or access to our website. You must
              promptly notify us of any changes to your personal details from time to time (for
              example, if your bank account details change).
            </Typography>
          </Grid>
        </Grid>
        <HorizontalDivider spacerSize="l" />
        <Grid item>
          <Grid item>
            <SectionText>6. Access to our website/mobile systems and your account</SectionText>
          </Grid>
          <Spacer size="m" />
          <Grid item>
            <List sx={{ listStyleType: "lower-roman", pl: 4 }}>
              <StyledListItem>
                We do not guarantee that our site/mobile application, or any content on it, will
                always be available or be uninterrupted.
              </StyledListItem>
              <StyledListItem>
                We reserve the right at any time to modify or discontinue, temporarily or
                permanently, our website with or without notice. You agree that we shall not be
                liable to you or to any third party for any modification, suspension or
                discontinuance of our website.
              </StyledListItem>
              <StyledListItem>
                We may terminate, suspend, restrict or block your access to our website/mobile
                application or your account if we reasonably think it is necessary or because we
                believe there is a legitimate reason to do so. Such reasons include, for example:
                <List sx={{ listStyleType: "lower-alpha", pl: 4 }}>
                  <StyledListItem>
                    the security of your account is at risk (for example, if you have not updated
                    your internet browser to the latest release);
                  </StyledListItem>
                  <StyledListItem>
                    we suspect unauthorised or fraudulent use of your account;
                  </StyledListItem>
                  <StyledListItem>
                    to comply with any legal or regulatory requirement;
                  </StyledListItem>
                  <StyledListItem>
                    you have given us false information about yourself;
                  </StyledListItem>
                  <StyledListItem>
                    for any reason beyond our reasonable control, including (without limitation)
                    where our website and/or platform becomes unavailable;
                  </StyledListItem>
                  <StyledListItem>
                    for any actual or suspected breach of these Terms;
                  </StyledListItem>
                  <StyledListItem>for any actual or suspected criminal activity; or</StyledListItem>
                  <StyledListItem>
                    for any actual or suspected misuse of, or damage to, our website
                  </StyledListItem>
                </List>
              </StyledListItem>
              <StyledListItem>
                Where possible we will contact you before suspending, restricting or blocking your
                access to tell you that we are doing so and why. We may do this by displaying a
                message the next time you try to log on or perform an action on your account.
                However, we may not always be able to contact you, for example because of legal or
                regulatory restrictions, or because of unexpected interruptions to our service
                availability.
              </StyledListItem>
            </List>
          </Grid>
        </Grid>
        <HorizontalDivider spacerSize="l" />
        <Grid item>
          <Grid item>
            <SectionText>7. Closing your account</SectionText>
          </Grid>
          <Spacer size="m" />
          <Grid item>
            <Typography textAlign="justify">
              If you no longer wish to use the account, you may cancel your registration by
              notifying us via email. Following closure of your account, you will no longer be able
              to access your account portal under the said company. Following cancellation, we will
              continue to maintain records in line with our Privacy Policy. Please do note that
              following your account deletion, any remaining balance or any unused credit or slots
              in your account shall be forfeited without further notice to you. You are required to
              utilise any credit or sessions before you close the account.
            </Typography>
          </Grid>
        </Grid>
        <HorizontalDivider spacerSize="l" />
        <Grid item>
          <Grid item>
            <SectionText>8. Security</SectionText>
          </Grid>
          <Spacer size="m" />
          <Grid item>
            <Typography textAlign="justify">
              You are responsible for safeguarding your login details. You should not share them
              with anyone. You must contact us immediately if you believe that your account has been
              compromised or if you believe that the security of your account is at risk. By
              accepting these Terms, you expressly agree that you will not, and will not attempt to:
              (a) access data outside of the public aspects of our website or your Salary Finance
              customer account portal; (b) access any loan information or non-public personal
              information relating to any other user of the our website; (c) probe, scan or test the
              vulnerability of our website; or (d) interfere with or disrupt our website or any
              aspect therein, including, without limitation, via means of submitting a virus to our
              website or overloading, “flooding,” “spamming,” “mail bombing,” or “crashing” our
              website. Violations of system or network security may result in civil or criminal
              liability.
            </Typography>
          </Grid>
        </Grid>
        <HorizontalDivider spacerSize="l" />
        <Grid item>
          <Grid item>
            <SectionText>9. Acceptable use of our website/system</SectionText>
          </Grid>
          <Spacer size="m" />
          <Grid item>
            <List sx={{ listStyleType: "lower-roman", pl: 4 }}>
              <StyledListItem>
                You may use our site only for lawful purposes. You warrant that you will not use our
                site:
                <List sx={{ listStyleType: "lower-alpha", pl: 4 }}>
                  <StyledListItem>
                    in any way that breaches any applicable local, national or international law or
                    regulation;
                  </StyledListItem>
                  <StyledListItem>
                    in any way that is unlawful or fraudulent, or has any unlawful or fraudulent
                    purpose or effect;
                  </StyledListItem>
                  <StyledListItem>
                    to transmit, or procure the sending of, any unsolicited or unauthorised
                    advertising or promotional material or any other form of similar solicitation
                    (spam); or
                  </StyledListItem>
                  <StyledListItem>
                    to knowingly transmit any data, send or upload any material that contains
                    viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or
                    any other harmful programs or similar computer code designed to adversely affect
                    the operation of any computer software or hardware.
                  </StyledListItem>
                  <StyledListItem>
                    to attempt to commercially exploit any part of the application/system without
                    our permission. For the avoidance of doubt, you are not permitted to modify or
                    make derivative works based on the platform/application, its content or any part
                    thereof in any way, or copy, reproduce, publicly display, distribute or
                    otherwise use or communicate them for any public or commercial purpose save
                    where specifically permitted in writing by Katch. This includes without
                    limitation not to:
                    <List sx={{ listStyleType: "disc", pl: 4 }}>
                      <StyledListItem>
                        create or compile, directly or indirectly, any collection, compilation or
                        other directory from any content displayed on the platform except for your
                        personal, non-commercial use;
                      </StyledListItem>
                      <StyledListItem>
                        copy any content displayed through the platform or “mirror” the platform or
                        any parts thereof on any other server or wireless or internet-based device,
                        including reproducing any third party product content and reviews, for
                        republication in any format or media; or navigational structure or
                        presentation of the platform or its content elsewhere;
                      </StyledListItem>
                      <StyledListItem>
                        conduct data mining or scraping activities; and
                      </StyledListItem>
                      <StyledListItem>
                        disassemble, decompile, reverse engineer, decrypt or attempt to derive and
                        code or extract software from, the platform or any software or services made
                        available on or through the platform
                      </StyledListItem>
                    </List>
                  </StyledListItem>
                </List>
              </StyledListItem>
              <StyledListItem>
                When we consider that a breach of these provisions has occurred, we may take such
                action as we deem appropriate and this may result in us taking all or any of the
                following actions:
                <List sx={{ listStyleType: "lower-alpha", pl: 4 }}>
                  <StyledListItem>
                    immediate, temporary or permanent withdrawal of your right to use our site;
                  </StyledListItem>
                  <StyledListItem>issue of a warning to you;</StyledListItem>
                  <StyledListItem>
                    legal proceedings against you for reimbursement of all costs on an indemnity
                    basis (including, but not limited to, reasonable administrative and legal costs)
                    resulting from the breach; or
                  </StyledListItem>
                  <StyledListItem>
                    further legal action against you.
                    <Spacer size="m" />
                    Different models or versions of routers, browsers and devices may have firmware
                    or settings that are not compatible with the application, software and systems.
                    While we continuously develop the our systems and applications in order to, as
                    far as possible, support all commonly used devices and models in markets and all
                    browsers, we do not warrant full compatibility with specific mobile devices or
                    other hardware.
                  </StyledListItem>
                </List>
              </StyledListItem>
            </List>
          </Grid>
        </Grid>
        <HorizontalDivider />
        <Grid item>
          <Grid item>
            <SectionText>10. Communications</SectionText>
          </Grid>
          <Spacer size="m" />
          <Grid item>
            <Typography textAlign="justify">
              When you visit our website or send emails to us, you are communicating with us
              electronically. By accepting these Terms, you expressly consent to receive
              communications from us electronically to the extent permissible by law. Such
              electronic communications may include emails, messages posted to your customer account
              portal or other electronic communications. Your consent to receive electronic
              communications from us does not mean that we must provide all communications
              electronically. It is your responsibility to provide us with current and accurate
              contact information. You represent that you are permitted to receive calls at each of
              the telephone numbers you have provided to us. You agree to promptly alert us whenever
              you stop using a particular telephone number. You are aware that when requesting
              solutions by SMS or by using the services offered by Katch of the Partner or accessing
              the platform, standard telecommunication charges may apply.
            </Typography>
          </Grid>
        </Grid>
        <HorizontalDivider />
        <Grid item>
          <Grid item>
            <SectionText>11. Links</SectionText>
          </Grid>
          <Spacer size="m" />
          <Grid item>
            <List sx={{ listStyleType: "lower-roman", pl: 4 }}>
              <StyledListItem>
                Our website/mobile application may contain links to websites which are owned or
                controlled by parties other than us. These links are provided for your convenience
                and do not imply that we have reviewed or condone the third-party sites, their
                content or their privacy policies.
              </StyledListItem>
              <StyledListItem>
                When using other sites, you must be aware that any personal information you supply
                will be handled according to their privacy policies. We recommend that you read and
                consider these websites’ privacy policies and terms and conditions before providing
                any of your personal information. We are not responsible for the privacy practices
                or content on third party websites.
              </StyledListItem>
              <StyledListItem>
                We shall not be responsible for the content of any other sites that are linked to or
                from our website or our platform and we exclude all warranties and all liability for
                any loss or damage you incur as a result of your use of such sites.
              </StyledListItem>
            </List>
          </Grid>
        </Grid>
        <HorizontalDivider />
        <Grid item>
          <Grid item>
            <SectionText>11A. Payments</SectionText>
          </Grid>
          <Spacer size="m" />
          <Grid item>
            <List sx={{ listStyleType: "lower-roman", pl: 4 }}>
              <StyledListItem>
                <b>Payment Terms for Partners (fitness trainers/ gym owners/wellness providers)</b>
                <List sx={{ listStyleType: "lower-alpha", pl: 4 }}>
                  <StyledListItem>
                    Katch charges a fee for your use of the Service (“Service Fee”). The Service Fee
                    may range from 6% to 20% of the consumer charges unless otherwise communicated
                    to and accepted by you before you commence provision of the services/offerings.
                    The Service Fees are payable by you immediately and are non-refundable. This
                    no-refund policy shall apply at all times regardless of your decision to
                    terminate your access to the application / platform.
                  </StyledListItem>
                  <StyledListItem>
                    YOU ACKNOWLEDGE AND CONFIRM THAT KATCH MAY ADMINISTER AND ACT AS YOUR COLLECTION
                    AGENT TO PAY TO YOU THE TOTAL AMOUNT OF CONSUMER CHARGES DUE TO YOU IN RESPECT
                    OF YOUR PROVISION OF THE SOLUTION.
                  </StyledListItem>
                  <StyledListItem>
                    Katch retains the right to suspend the processing of any transaction where it
                    reasonably believes that the transaction may be fraudulent, illegal or involves
                    any criminal activity or where you and/or the cconsumer have breached any of the
                    Terms in this Agreement. In such an event, you shall not hold Katch liable for
                    any withholding of, delay in, suspension, forfeiture or cancellation of, any
                    payment(s) to you.
                  </StyledListItem>
                  <StyledListItem>
                    Katch may, at its sole discretion, make promotional offers with different
                    features and different rates on the Solutions to any of the consumer whereby
                    these promotional offers shall accordingly be honored by you. Katch may change
                    the Service Fee at any time at its sole discretion.
                  </StyledListItem>
                  <StyledListItem>
                    Katch shall administer payments to you and from you by way of bank transfer or a
                    wallet system. Katch reserves the right to make such deductions, or as are
                    notified to you via the application. You agree Katch may retain, apply, or set
                    off any sum due and owing, monies, deposits or balances held in, or standing to
                    the credit of any account towards the satisfaction of any obligations and
                    service quality due from you to Katch, whether such obligation be present or
                    future, actual or contingent, primary or collateral and several or joint.
                  </StyledListItem>
                </List>
              </StyledListItem>
              <StyledListItem>
                <b> Payment Terms for Consumers:</b>
                <List sx={{ listStyleType: "lower-alpha", pl: 4 }}>
                  <StyledListItem>
                    Consumers are required to make full payment of the Consumers Charges for all
                    services offered in the Application by the method selected at the time of
                    booking, which may be cash, or one of the automated payment methods available to
                    you on the Application. Any payment pursuant to such selection is non-refundable
                    and irrevocable.
                  </StyledListItem>
                  <StyledListItem>
                    If the tipping feature is available, you may have the option to pre-set a
                    default “tip” amount of your choosing when you register for the Service. This
                    will automatically be included in the Consumer Charges after the Solution has
                    been provided and given to the Partners unless you choose to change the amount
                    or remove the tip
                  </StyledListItem>
                </List>
              </StyledListItem>
            </List>
          </Grid>
        </Grid>
        <HorizontalDivider />
        <Grid item>
          <Grid item>
            <SectionText>12. Our liability</SectionText>
          </Grid>
          <Spacer size="m" />
          <Typography textAlign="justify">
            Whether you are a consumer or a business user:
          </Typography>
          <Spacer size="m" />
          <Grid item>
            <List sx={{ listStyleType: "lower-roman", pl: 4 }}>
              <StyledListItem>
                We do not limit or exclude our liability for death or personal injury resulting from
                our negligence, fraud or fraudulent misrepresentation and any other liability that
                cannot be limited or excluded by the law of Malaysia.
              </StyledListItem>
              <StyledListItem>
                The content on our site/application is provided for general information only. It is
                not intended to amount to advice on which you should rely. You must obtain
                professional or specialist advice before taking, or refraining from, any action on
                the basis of the content on our site.
              </StyledListItem>
              <StyledListItem>
                Although we make reasonable efforts to update the information on our site/mobile
                application, we make no representations, warranties or guarantees, whether express
                or implied, that the content is accurate, complete or up-to-date.
              </StyledListItem>
              <StyledListItem>
                While we take all reasonable precautions to keep the website and its server free
                from viruses, corrupt files and other malicious software, we cannot guarantee this.
                We will not be liable for any loss or damage caused by a virus, distributed
                denial-of-service attack, or other technologically harmful material that may infect
                your computer equipment, computer programs, data or other proprietary material due
                to your use of our website or to your downloading of any content on it, or on any
                website linked to it. You should ensure that you have appropriate anti-virus
                software installed on any computer or mobile devices that you use to access the
                website.
              </StyledListItem>
              <StyledListItem>
                We will not be liable to you for any loss or damage, whether in contract, tort
                (including negligence), breach of statutory duty, or otherwise, even if foreseeable,
                arising under or in connection with:
                <List sx={{ listStyleType: "disc", pl: 4 }}>
                  <StyledListItem>use of, or inability to use, our site; or</StyledListItem>
                  <StyledListItem>
                    use of or reliance on any content displayed on our site.
                  </StyledListItem>
                </List>
              </StyledListItem>
              <StyledListItem>
                During use of the platform/mobile application, you may enter into correspondence or
                transactions with third parties who display or offer their goods and/or service
                through the platform or application. Any such communication or agreement is strictly
                between you and the applicable third party and Katch and its licensors shall have no
                liability or obligation for any such communication or agreement. The quality of the
                solutions scheduled through the use of the service is entirely the responsibility of
                the partners who ultimately provides such solution to consumers. you understand,
                therefore, that by using the service, you may be exposed to transportation that is
                potentially dangerous, offensive, harmful to minors, unsafe or otherwise
                objectionable, and that you use the service or purchase a product8r at your own
                risk.
              </StyledListItem>
              <StyledListItem>
                THE SERVICE, PLATFORM, APPLICATION AND/OR THE SOFTWARE MAY BE SUBJECT TO
                LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE OF THE INTERNET AND
                ELECTRONIC COMMUNICATIONS INCLUDING THE DEVICE USED BY YOU OR THE Partners BEING
                FAULTY, NOT CONNECTED, OUT OF RANGE, SWITCHED OFF OR NOT FUNCTIONING. KATCH IS NOT
                RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES, DAMAGES OR LOSSES RESULTING FROM SUCH
                PROBLEMS.
              </StyledListItem>
            </List>
          </Grid>
        </Grid>
        <HorizontalDivider />
        <Grid item>
          <Grid item>
            <SectionText>13. Your acts and compliance with law</SectionText>
          </Grid>
          <Spacer size="m" />
          <Grid item>
            <Typography textAlign="justify">
              You are solely responsible for your acts and omissions in connection with your use of
              our website, and we will not be responsible or liable for any claim, action or
              liability that arises out of or is in any way connected with such acts or omissions.
              You agree to use our website in compliance with all applicable laws, and not to use
              our website for any fraudulent purposes.
            </Typography>
          </Grid>
        </Grid>
        <HorizontalDivider />
        <Grid item>
          <Grid item>
            <SectionText>14. Intellectual Property</SectionText>
          </Grid>
          <Spacer size="m" />
          <Grid item>
            <List sx={{ listStyleType: "lower-roman", pl: 4 }}>
              <StyledListItem>
                All intellectual property in our site and our platform, and all rights in any
                information which appears on our site and our platform (including the screen
                displays, the content, the text, graphics and look and feel of the site and
                platform), belongs to us or our licensors. All trademarks, service marks, company
                names or logos are the property of their respective holders. Any use by you of these
                marks, names and logos may constitute an infringement of the holders’ rights. We do
                not warrant that our site does not infringe any intellectual property rights of
                third parties
              </StyledListItem>
              <StyledListItem>
                We grant to you a limited right to use our platform on any computers, smartphones or
                tablets (as applicable) which are under your control, solely for your internal,
                private, non-commercial informational purposes.
              </StyledListItem>
              <StyledListItem>
                You may not reproduce, republish, transmit or distribute any material, information
                or content on our website, or that form part of our services, without our prior
                written consent. However, you are granted a limited right to access and use our
                website and our services, and retrieve, display and print content pages, for your
                own personal, non-commercial use and to the extent necessary for use of our website
                and our services only. We reserve the right, in our sole discretion and without
                notice to you, to terminate your account and to prevent future access by you to our
                website.
                <Spacer size="m" />
                ALL CONTENTS ON THIS SITE ARE PROTECTED BY COPYRIGHT. EXCEPT AS SPECIFICALLY
                PERMITTED HEREIN, NO PORTION OF THE INFORMATION ON THIS WEB SITE MAY BE REPRODUCED
                IN ANY FORM, OR BY ANY MEANS, WITHOUT PRIOR WRITTEN PERMISSION FROM US. VISITORS OR
                USERS ARE NOT PERMITTED TO MODIFY, DISTRIBUTE, PUBLISH, TRANSMIT OR CREATE
                DERIVATIVE WORKS OF ANY MATERIAL FOUND ON THIS SITE FOR ANY PUBLIC OR COMMERCIAL
                PURPOSES.
                <Spacer size="m" />
                Where you upload or provide us with any content that is covered by intellectual
                property rights, you (a) irrevocably grant Katch a royalty-free, worldwide,
                perpetual, non-exclusive, unrestricted right and license to host, store, use,
                reproduce, modify, edit, adapt, translate, create derivative works of, publish,
                publicly perform or display, distribute, sublicense, or otherwise provide to others,
                your content (including without limitation name and/or likeness) in any and all
                media, formats, and channels (now in existence or hereinafter developed) for any
                purpose; and (b) undertake that you have the right to grant us such license and
                shall remain responsible for the content you provided.
              </StyledListItem>
            </List>
          </Grid>
        </Grid>
        <HorizontalDivider />
        <Grid item>
          <Grid item>
            <SectionText>15. Amendment and Updates</SectionText>
          </Grid>
          <Spacer size="m" />
          <Grid item>
            <Typography textAlign="justify">
              We reserve the right to make changes to these Terms from time to time. If we do this,
              we will post the changed Terms on our website and will indicate at the top of this
              page the date the Terms were last revised. You understand and agree that your
              continued use of our website after we have made any such changes constitutes your
              acceptance of the new Terms. We may update and change our site from time to time to
              reflect changes to our products, our users’ needs and our business priorities.
            </Typography>
          </Grid>
        </Grid>
        <HorizontalDivider />
        <Grid item>
          <Grid item>
            <SectionText>16. No waiver</SectionText>
          </Grid>
          <Spacer size="m" />
          <Grid item>
            <Typography textAlign="justify">
              The waiver of a breach of or default under these Terms, or the failure or delay by us
              to exercise any right in respect of any breach of or default under these Terms, shall
              in no event constitute a waiver of any other breach or default of these Terms, whether
              similar or dissimilar in nature, or operate as a waiver of any other right or remedy
              available to us.
            </Typography>
          </Grid>
        </Grid>
        <HorizontalDivider />
        <Grid item>
          <Grid item>
            <SectionText>17. Severability</SectionText>
          </Grid>
          <Spacer size="m" />
          <Grid item>
            <Typography textAlign="justify">
              If any provision of these Terms is held invalid or unenforceable for any reason, the
              remainder of these Terms shall continue in full force and effect as if the invalid or
              unenforceable parts of these Terms have been severed from these Terms.
            </Typography>
          </Grid>
        </Grid>
        <HorizontalDivider />
        <Grid item>
          <Grid item>
            <SectionText>18. Taxes</SectionText>
          </Grid>
          <Spacer size="m" />
          <Grid item>
            <Typography textAlign="justify">
              You agree that this Agreement is subject to all prevailing statutory taxes, duties,
              fees, charges and/or costs, however denominated, as may be applicable from time to
              time. You shall comply with all applicable laws and take all steps required to enable,
              assist and/or defend Katch to claim or verify any input tax credit, set off, rebate or
              refund in respect of any taxes paid or payable in connection with the
              services/products.
              <List sx={{ listStyleType: "lower-roman", pl: 4 }}>
                <StyledListItem>
                  If you are a Partner, you are accountable for paying any tax and statutory
                  contributions due in respect of sums payable to you under or in connection with
                  this Agreement.
                </StyledListItem>
              </List>
            </Typography>
          </Grid>
        </Grid>
        <HorizontalDivider />
        <Grid item>
          <Grid item>
            <SectionText>19. Complaints and feedback</SectionText>
          </Grid>
          <Spacer size="m" />
          <Grid item>
            <Typography textAlign="justify">
              We welcome all feedback and suggestions. Should you wish to provide feedback, please
              contact us by email at{" "}
              <a href="mailto:support@katch.asia" target="_top" rel="noopener noreferrer">
                support@katch.asia
              </a>
              . If you do require a quicker response time, please do contact us at{" "}
              <a href="https://wa.me/+60102042866" target="_blank" rel="noopener noreferrer">
                +60 10-204 2866
              </a>
              .
            </Typography>
          </Grid>
        </Grid>
        <HorizontalDivider />
        <Grid item>
          <Grid item>
            <SectionText>20. Third party rights</SectionText>
          </Grid>
          <Spacer size="m" />
          <Grid item>
            <Typography textAlign="justify">
              If applicable, your employer may rely on and enforce against you the relevant parts of
              these Terms pursuant to which you give your consent or agreement to your employer in
              connection with your use of our services. Notwithstanding that your employer may rely
              on and enforce against you the relevant parts of these Terms if applicable, we may
              vary, waive, rescind or terminate these Terms (in each case) without your employer’s
              consent.
            </Typography>
          </Grid>
        </Grid>
        <HorizontalDivider />
        <Grid item>
          <Grid item>
            <SectionText>21. Governing law and jurisdiction</SectionText>
          </Grid>
          <Spacer size="m" />
          <Grid item>
            <Typography textAlign="justify">
              These Terms, including the privacy policy, shall be governed and construed in
              accordance with law of Malaysia, and the Courts of Malaysia (shall have exclusive
              jurisdiction to determine the interpretation and application of these conditions if
              any dispute arises. No party shall refer the dispute to the court without first
              attempt to settle this matter amicably by giving the other a dispute notice.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </PaddedView>
  );
}

export default TermsScreen;
