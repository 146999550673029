import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAPIV2 } from "../../../infrastructure/utils";
import { getReq } from "../../api.services";

export const getEventSessionDetails = createAsyncThunk("event/session/details", async (payload) => {
  const response = await getReq(`${getAPIV2()}/events/sessions/${payload}`);
  return response;
});

const eventSessionSlice = createSlice({
  name: "event",
  initialState: {
    getEventSessionDetailsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getEventSessionDetails.pending]: (state) => {
      state.getEventSessionDetailsObj.status = "pending";
    },
    [getEventSessionDetails.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getEventSessionDetailsObj.status = "succeeded";
      state.getEventSessionDetailsObj.data = data;
      state.getEventSessionDetailsObj.successMessage = message;
    },
    [getEventSessionDetails.rejected]: (state, action) => {
      const { message } = action.error;

      state.getEventSessionDetailsObj.status = "failed";
      state.getEventSessionDetailsObj.errorMessage = message;
    },
  },
});

export default eventSessionSlice.reducer;

// state
export const eventSessionSelector = (state) => state.eventSession;
