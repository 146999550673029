import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq, putReq } from "../../api.services";

export const cancelMembershipBill = createAsyncThunk(
  "memberships/bills/{billId}/cancel",
  async (payload) => {
    const { billId } = payload;
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/memberships/bills/${billId}/cancel`,
    );
    return response;
  },
);

export const getMembershipBillViaRemoteBillId = createAsyncThunk(
  "memberships/bills/{billId}",
  async (payload) => {
    const { remoteBillId } = payload;
    const response = getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/memberships/bills/${remoteBillId}`,
      payload,
    );
    return response;
  },
);

const membershipBillSlice = createSlice({
  name: "membershipBill",
  initialState: {
    cancelMembershipBillObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getMembershipBillViaRemoteBillIdObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [cancelMembershipBill.pending]: (state) => {
      state.cancelMembershipBillObj.status = "pending";
    },
    [cancelMembershipBill.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.cancelMembershipBillObj.status = "succeeded";
      state.cancelMembershipBillObj.data = data;
      state.cancelMembershipBillObj.successMessage = message;
    },
    [cancelMembershipBill.rejected]: (state, action) => {
      const { message } = action.error;

      state.cancelMembershipBillObj.status = "failed";
      state.cancelMembershipBillObj.errorMessage = message;
    },
    [getMembershipBillViaRemoteBillId.pending]: (state) => {
      state.getMembershipBillViaRemoteBillIdObj.status = "pending";
    },
    [getMembershipBillViaRemoteBillId.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getMembershipBillViaRemoteBillIdObj.status = "succeeded";
      state.getMembershipBillViaRemoteBillIdObj.data = data;
      state.getMembershipBillViaRemoteBillIdObj.successMessage = message;
    },
    [getMembershipBillViaRemoteBillId.rejected]: (state, action) => {
      const { message } = action.error;

      state.getMembershipBillViaRemoteBillIdObj.status = "failed";
      state.getMembershipBillViaRemoteBillIdObj.errorMessage = message;
    },
  },
});

export default membershipBillSlice.reducer;

// state
export const membershipBillSelector = (state) => state.membershipBill;
