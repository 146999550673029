import { Grid } from "@mui/material";
import React from "react";
import HorizontalDivider from "../../../components/utils/horizontal-divider.component";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import Spacer from "../../../components/utils/spacer.component";

function ReviewLoader() {
  return (
    <Grid container item xs={12} rowSpacing={1}>
      <Grid item container xs={12} justifyContent="space-between">
        <CustomSkeleton />
        <CustomSkeleton />
      </Grid>

      <Grid container alignItems="center" item xs={12}>
        <Grid item>
          <CustomSkeleton />
        </Grid>
        <Spacer position="left" />
        <Grid item>
          <CustomSkeleton />
        </Grid>
      </Grid>
      <Grid item>
        <CustomSkeleton />
      </Grid>
      <HorizontalDivider spacerSize="s" />
    </Grid>
  );
}

export default ReviewLoader;
