import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postReq } from "../api.services";
import { getAPI } from "../../infrastructure/utils";

export const getUserExclusiveReferralStatus = createAsyncThunk(
  "exclusive_referrals/check?businessId={businessId}",
  async (payload) => {
    const { businessId } = payload;
    const response = await postReq(
      `${getAPI()}/exclusive_referrals/check?businessId=${businessId}`,
    );
    return response;
  },
);

export const validateExclusiveReferralCode = createAsyncThunk(
  "exclusive_referrals/validate",
  async (payload) => {
    const { businessId, exclusiveReferralCode } = payload;
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/exclusive_referrals/validate?businessId=${businessId}&exclusiveReferralCode=${exclusiveReferralCode}`,
    );
    return response;
  },
);

const exclusiveReferralSlice = createSlice({
  name: "exclusiveReferral",
  initialState: {
    getUserExclusiveReferralStatus: {
      status: "idle",
      success: false,
      message: null,
      data: null,
    },
    validateExclusiveReferralCode: {
      status: "idle",
      success: false,
      message: null,
      exclusiveReferralCode: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getUserExclusiveReferralStatus.pending]: (state) => {
      state.getUserExclusiveReferralStatus.status = "pending";
    },
    [getUserExclusiveReferralStatus.fulfilled]: (state, action) => {
      const { message, data } = action.payload;
      state.getUserExclusiveReferralStatus.status = "succeeded";
      state.getUserExclusiveReferralStatus.success = true;
      state.getUserExclusiveReferralStatus.data = data;
      state.getUserExclusiveReferralStatus.message = message;
    },
    [getUserExclusiveReferralStatus.rejected]: (state, action) => {
      const { message } = action.error;
      state.getUserExclusiveReferralStatus.status = "failed";
      state.getUserExclusiveReferralStatus.success = false;
      state.getUserExclusiveReferralStatus.message = message;
    },
    [validateExclusiveReferralCode.pending]: (state) => {
      state.validateExclusiveReferralCode.status = "pending";
    },
    [validateExclusiveReferralCode.fulfilled]: (state, action) => {
      const { message, success } = action.payload;
      const { exclusiveReferralCode } = action.meta.arg;
      state.validateExclusiveReferralCode.status = "succeeded";
      state.validateExclusiveReferralCode.success = success;
      state.validateExclusiveReferralCode.message = message;
      state.validateExclusiveReferralCode.exclusiveReferralCode = exclusiveReferralCode;
    },
    [validateExclusiveReferralCode.rejected]: (state, action) => {
      const { message, success } = action.error;
      state.validateExclusiveReferralCode.status = "failed";
      state.validateExclusiveReferralCode.success = success;
      state.validateExclusiveReferralCode.message = message;
      state.validateExclusiveReferralCode.exclusiveReferralCode = null;
    },
  },
});

export default exclusiveReferralSlice.reducer;

// state
export const exclusiveReferralSelector = (state) => state.exclusiveReferral;
