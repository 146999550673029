import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import { referralSelector } from "../../../services/referral/referral-slice.service";

function ReferedTable() {
  const theme = useTheme();
  const { getRefereesObj } = useSelector(referralSelector);

  const renderRefereesRow = () => {
    if (getRefereesObj.status === "succeeded") {
      if (getRefereesObj.data.length > 0) {
        return getRefereesObj.data.map((item) => (
          <TableRow key={item.id}>
            <TableCell>
              {item.firstName} {item.lastName}
            </TableCell>
            <TableCell
              sx={{
                color:
                  item.status === "successful"
                    ? theme.palette.colors.ui.success
                    : theme.palette.colors.ui.error,
                textTransform: "capitalize",
              }}
            >
              {item.status}
            </TableCell>
          </TableRow>
        ));
      }
      return (
        <TableRow>
          <TableCell colSpan={2} sx={{ textAlign: "center" }}>
            No Referees.
          </TableCell>
        </TableRow>
      );
    }

    return (
      <TableRow>
        <TableCell>
          <CustomSkeleton />
        </TableCell>
        <TableCell>
          <CustomSkeleton />
        </TableCell>
      </TableRow>
    );
  };
  return (
    <TableContainer component={Paper} sx={{ borderRadius: `${theme.shape.borderRadius[1]}px` }}>
      <Table sx={{ border: "1px" }}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              <Typography fontWeight="bold">Reffered</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{renderRefereesRow()}</TableBody>
      </Table>
    </TableContainer>
  );
}

export default ReferedTable;
