import { Grid, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import routes from "../../../components/navigation/routes";
import { RequireMembershipModal } from "./require-membership-modal.component";

function PackagePlanCard({ businessId, itemDetails, requireMembershipValidation }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [showRequireMembershipWarningModal, setShowRequireMembershipWarningModal] = useState(false);

  return (
    <>
      <RequireMembershipModal
        showModal={showRequireMembershipWarningModal}
        setShowModal={setShowRequireMembershipWarningModal}
      />
      <Grid
        onClick={() => {
          if (requireMembershipValidation) {
            setShowRequireMembershipWarningModal(true);
          } else {
            navigate({
              pathname: routes.PACKAGEPLANSUMMARY,
              search: `?businessId=${businessId}&planId=${itemDetails.id}`,
            });
          }
        }}
        container
        alignContent="center"
        sx={{
          backgroundColor: theme.palette.colors.bg.primary,
          borderRadius: `${theme.shape.borderRadius[2]}px`,
          cursor: "pointer",
        }}
      >
        <Grid item alignSelf="center" alignItems="center" display="flex" xs={4}>
          <img
            src={itemDetails.imagePath}
            alt="planImage"
            width="100%"
            style={{
              borderTopLeftRadius: `${theme.shape.borderRadius[2]}px`,
              borderBottomLeftRadius: `${theme.shape.borderRadius[2]}px`,
              objectFit: "cover",
            }}
          />
        </Grid>
        <Grid item xs={8} display="flex" alignItems="center" paddingLeft="10px">
          <Grid item marginY="10px">
            <Grid item>
              <Typography fontWeight="bold" sx={{ color: theme.palette.colors.brand.primary }}>
                {itemDetails.title}
              </Typography>
            </Grid>
            {itemDetails.memberPrice && (
              <Grid item display="flex" alignContent="center">
                <Typography fontWeight="bold" color="primary">
                  RM {itemDetails.memberPrice} (MEMBER)
                </Typography>
              </Grid>
            )}

            <Grid item display="flex" alignContent="center">
              <Typography fontWeight="bold" color={itemDetails.memberPrice ? "grey" : "primary"}>
                RM {itemDetails.price} {itemDetails.memberPrice && "(NON-MEMBER)"}
              </Typography>
            </Grid>
            <Grid item display="flex" alignContent="center">
              <Typography>{itemDetails.noOfSessions} sessions</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

PackagePlanCard.defaultProps = {
  itemDetails: PropTypes.shape({
    id: null,
    title: "",
    noOfSessions: 0,
    price: "",
    imagePath: "",
    purchasable: false,
    requireMembership: false,
  }),
};

PackagePlanCard.propTypes = {
  requireMembershipValidation: PropTypes.bool.isRequired,
  businessId: PropTypes.number.isRequired,
  itemDetails: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    noOfSessions: PropTypes.number,
    price: PropTypes.string,
    memberPrice: PropTypes.string,
    imagePath: PropTypes.string,
    purchasable: PropTypes.bool,
    requireMembership: PropTypes.bool,
  }),
};

export default PackagePlanCard;
