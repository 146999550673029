import { Divider, Grid } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import Spacer from "./spacer.component";

function HorizontalDivider({ spacerSize }) {
  return (
    <Grid item xs={12}>
      <Spacer size={spacerSize} />
      <Divider light />
      <Spacer size={spacerSize} />
    </Grid>
  );
}

HorizontalDivider.defaultProps = {
  spacerSize: "m",
};

HorizontalDivider.propTypes = {
  spacerSize: PropTypes.string,
};

export default HorizontalDivider;
