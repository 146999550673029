export const colors = {
  brand: {
    primary: "#1A7B64",
    secondary: "#25273C",
    white: "#FFFFFF",
    linearGreen: ["#1A7B64", "#0D3E32"],
    linearBlack: ["#313131", "#414040"],
  },
  ui: {
    primary: "#262626",
    secondary: "#6B6B6B",
    disabled: "#AAAAAA",
    error: "#FF2525",
    success: "#138000",
    white: "#FFFFFF",
    border: "#C2C2C2",
    lightBorder: "#dfdfdf",
    indicator: "#AAAAAA",
    pending: "#e49c1c",
    star: "#FFCC00",
    darkYellow: "#B28F00 ",
    favourite: "#FF7777",
    notification: "#FF2525",
  },
  bg: {
    brand: "#1A7B64",
    lightGreen: "#cae1dc",
    primary: "#F2F5FA",
    secondary: "#C2C2C2",
    white: "#FFFFFF",
    dark: "#1F1F23",
    footer: ["#313131", "#414040"],
  },
  timeSlot: {
    selected: "#E60013",
    unselected: "#AAAAAA",
  },
  tab: {
    selected: "#E60013",
    unselected: "#AAAAAA",
    bg: "#FAF8F8",
  },
  loading: {
    backgroundColor: "#FFFFFF",
    foregroundColor: "#AAAAAA",
    imageBg: "#AAAAAA",
    dot1: "#262626",
    dot2: "#6B6B6B",
    dot3: "#FFFFFF",
  },
  text: {
    primary: "#25273C",
    secondary: "#6B6B6B",
    tertiary: "#414141",
    placeholder: "#AAAAAA",
    white: "#FFFFFF",
    disabled: "#9e9fa3",
    error: "#D0421B",
    success: "#138000",
    inputField: "#F6F6F9",
  },
  button: {
    switch: "#26BC50",
    disabled: "#d5d7dc",
  },
};
