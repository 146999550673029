import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ExploreFitness from "../../../assets/images/explore-fitness.png";
import ExploreHealth from "../../../assets/images/explore-health.png";
import ExploreWellness from "../../../assets/images/explore-wellness.png";
import ExploreEvent from "../../../assets/images/explore-event.png";
import routes from "../../../components/navigation/routes";

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  color: theme.palette.colors.brand.primary,
  // fontSize: "15px",
}));

function ExploreSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  // const isTabletHorizontal = useMediaQuery(theme.breakpoints.down("lg"));
  const navigate = useNavigate();

  return (
    <Box sx={{ overflowX: isMobile && "auto" }}>
      <Grid container columnSpacing={1} width={isMobile ? "800px" : "auto"}>
        <Grid
          item
          xs={3}
          sx={{ maxHeight: "200px", cursor: "pointer" }}
          onClick={() => navigate(`${routes.EXPLORE}?tag=fitness`)}
        >
          <Grid
            container
            sx={{
              backgroundImage: "linear-gradient(#d9e2b1, #ccdc82)",
              borderRadius: `${theme.shape.borderRadius[2]}px`,
              height: "100%",
              paddingY: "20px",
            }}
          >
            <Grid item xs={12}>
              <StyledTypography textAlign="center">Fitness near you</StyledTypography>
            </Grid>
            <Grid item display="flex" justifyContent="center">
              <img width="80%" alt="imgs" src={ExploreFitness} style={{ objectFit: "contain" }} />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={3}
          sx={{ maxHeight: "200px", cursor: "pointer" }}
          onClick={() => navigate(`${routes.EXPLORE}?searchFor=event`)}
        >
          <Grid
            container
            sx={{
              backgroundImage: "linear-gradient(#FFF1D4, #FFD883)",
              borderRadius: `${theme.shape.borderRadius[2]}px`,
              height: "200px",
              paddingY: "20px",
            }}
          >
            <Grid item xs={12}>
              <StyledTypography textAlign="center">Events near you</StyledTypography>
            </Grid>
            <Grid
              item
              display="flex"
              justifyContent="center"
              xs={12}
              height="90%"
              paddingTop="10px"
            >
              <img width="80%" alt="imgs" src={ExploreEvent} style={{ objectFit: "contain" }} />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={3}
          sx={{ maxHeight: "200px", cursor: "pointer" }}
          onClick={() => navigate(`${routes.EXPLORE}?tag=wellness`)}
        >
          <Grid
            container
            sx={{
              backgroundImage: "linear-gradient(#edf2e1, #dbf1ad)",
              borderRadius: `${theme.shape.borderRadius[2]}px`,
              height: "200px",
              paddingY: "20px",
            }}
          >
            <Grid item xs={12}>
              <StyledTypography textAlign="center">Wellness near you</StyledTypography>
            </Grid>
            <Grid
              item
              display="flex"
              justifyContent="center"
              xs={12}
              height="90%"
              paddingTop="10px"
            >
              <img width="80%" alt="imgs" src={ExploreWellness} style={{ objectFit: "contain" }} />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={3}
          sx={{ maxHeight: "200px", cursor: "pointer" }}
          onClick={() => navigate(`${routes.EXPLORE}?tag=health`)}
        >
          <Grid
            container
            sx={{
              backgroundImage: "linear-gradient(#f4e9d0, #f1d79c)",
              borderRadius: `${theme.shape.borderRadius[2]}px`,
              height: "100%",
              paddingY: "20px",
            }}
          >
            <Grid item xs={12}>
              <StyledTypography textAlign="center">Health near you</StyledTypography>
            </Grid>
            <Grid
              item
              display="flex"
              justifyContent="center"
              xs={12}
              height="90%"
              paddingTop="10px"
            >
              <img width="80%" alt="imgs" src={ExploreHealth} style={{ objectFit: "contain" }} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ExploreSection;
