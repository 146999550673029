import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import RestrictedRoute from "./restricted.route";

import LoginScreen from "../../features/auth/screens/login.screen";
import SignUpScreen from "../../features/auth/screens/sign-up.screen";
import PublicRoute from "./public.route";
import ForgotPassword from "../../features/auth/screens/forgot-password.screen";
import CustomizedAppBar from "../../infrastructure/layout/appbar.layout";
import UserProfileScreen from "../../features/profile/screens/user-profile.screen";
import PrivateRoute from "./private.route";
import CreditScreen from "../../features/credits/screens/credit/credits.screen";
import BillPaymentCheckScreen from "../../screens/payment/bill-check.screen";
import BillPaymentFailed from "../../screens/payment/bill-failed.screen";
import BillPaymentSuccessful from "../../screens/payment/bill-successful.screen";
import RegisterVerificationCodeScreen from "../../features/auth/screens/register-verification-code.screen";
import ForgotPasswordVerificationCodeScreen from "../../features/auth/screens/forgot-password-verification-code.screen";
import ReferralScreen from "../../features/referral/screens/referral.screen";
import ExploreSearchScreen from "../../features/explore/screens/explore-search.screen";
import BusinessScreen from "../../features/business/screens/business.screen";
import PreviewSummaryScreen from "../../features/gym/session/booking/screens/preview-summary.screen";
import FitnessClassSessionScreen from "../../features/fitness-classes/session/screens/fitness-class-session.screen";
import FitnessClassPreviewSummaryScreen from "../../features/fitness-classes/session/booking/screens/fitness-class-preview-summary.screen";
import PreviewSummarySubscriptionScreen from "../../features/gym/subscription/screen/preview-summary-subscription.screen";
import PreviewSummaryPackageScreen from "../../features/package/screens/preview-summary-package.screen";
import PurchasesScreen from "../../features/purchases/screens/purchases.screen";
import HomeScreen from "../../features/dashboard/screens/home.screen";
import LandingScreen from "../../features/landing/screen/landing.screen";
import EventScreen from "../../features/event/screens/event.screen";
import EventPreviewSummaryScreen from "../../features/event/booking/screens/event-preview-summary.screen";
import PrivacyPolicyScreen from "../../features/privacy-policy/screens/privacy-policy.screen";
import FitnessClassBookingRequestPreviewSummaryScreen from "../../features/fitness-classes/session/booking/screens/fitness-class-booking-request-preview-summary.screen";
import TermsScreen from "../../features/terms/screens/terms.screen";
import ContactScreen from "../../features/contact/screens/contact.screen";
import PaymentMethodsScreen from "../../features/payment-methods/screens/payment-methods.screen";
import TokenizedPaymentRedirectScreen from "../../features/payment-methods/screens/tokenized-payment-redirect.screen";
import PreviewSummaryAppointmentScreen from "../../features/appointment/screens/preview-summary-appointment.screen";
import PreviewSummaryMembershipScreen from "../../features/membership/screen/preview-summary-membership.screen";
import MembershipsScreen from "../../features/memberships/screens/memberships.screen";

function index() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path="/" element={<LandingScreen />} />
          <Route element={<CustomizedAppBar />}>
            <Route path="/home" element={<HomeScreen />} />
            <Route path="explore" element={<ExploreSearchScreen />} />
            <Route path="business" element={<BusinessScreen />} />
            <Route
              path="subscription_plan_summary"
              element={<PreviewSummarySubscriptionScreen />}
            />
            <Route path="membership_plan_summary" element={<PreviewSummaryMembershipScreen />} />
            <Route path="package_plan_summary" element={<PreviewSummaryPackageScreen />} />
            <Route path="fitness_class" element={<FitnessClassSessionScreen />} />
            <Route path="event" element={<EventScreen />} />
            <Route path="privacy_policy" element={<PrivacyPolicyScreen />} />
            <Route path="contact" element={<ContactScreen />} />
            <Route path="terms" element={<TermsScreen />} />

            <Route path="event_booking_summary" element={<EventPreviewSummaryScreen />} />
            <Route path="event_booking_bill_check" element={<BillPaymentCheckScreen />} />
            <Route path="event_booking_bill_failed" element={<BillPaymentFailed />} />
            <Route path="event_booking_bill_success" element={<BillPaymentSuccessful />} />
          </Route>
        </Route>

        <Route element={<PrivateRoute />}>
          <Route path="phone_verification_code" element={<RegisterVerificationCodeScreen />} />
          <Route element={<CustomizedAppBar />}>
            <Route path="user_profile" element={<UserProfileScreen />} />
            <Route path="credits" element={<CreditScreen />} />
            <Route path="payment_methods" element={<PaymentMethodsScreen />} />
            <Route path="tokenizedPayment" element={<TokenizedPaymentRedirectScreen />} />
            <Route path="top_up_bill_check" element={<BillPaymentCheckScreen />} />
            <Route path="top_up_bill_failed" element={<BillPaymentFailed remoteBillId />} />
            <Route path="top_up_bill_success" element={<BillPaymentSuccessful />} />
            <Route path="referrals" element={<ReferralScreen />} />
            <Route path="hourly_booking_summary" element={<PreviewSummaryScreen />} />
            <Route path="hourly_booking_bill_check" element={<BillPaymentCheckScreen />} />
            <Route path="hourly_booking_bill_failed" element={<BillPaymentFailed />} />
            <Route path="hourly_booking_bill_success" element={<BillPaymentSuccessful />} />
            <Route path="subscription_bill_check" element={<BillPaymentCheckScreen />} />
            <Route path="subscription_plan_failed" element={<BillPaymentFailed />} />
            <Route path="subscription_plan_success" element={<BillPaymentSuccessful />} />
            <Route path="membership_bill_check" element={<BillPaymentCheckScreen />} />
            <Route path="membership_plan_failed" element={<BillPaymentFailed />} />
            <Route path="membership_plan_success" element={<BillPaymentSuccessful />} />
            <Route path="package_bill_check" element={<BillPaymentCheckScreen />} />
            <Route path="package_bill_failed" element={<BillPaymentFailed />} />
            <Route path="package_bill_success" element={<BillPaymentSuccessful />} />
            <Route path="appointment_bill_success" element={<BillPaymentSuccessful />} />
            <Route path="appointment_summary" element={<PreviewSummaryAppointmentScreen />} />
            <Route
              path="fitness_class_session_booking_summary"
              element={<FitnessClassPreviewSummaryScreen />}
            />
            <Route
              path="fitness_class_session_booking_request_summary"
              element={<FitnessClassBookingRequestPreviewSummaryScreen />}
            />
            <Route
              path="fitness_class_session_booking_bill_check"
              element={<BillPaymentCheckScreen />}
            />
            <Route
              path="fitness_class_session_booking_bill_failed"
              element={<BillPaymentFailed />}
            />

            <Route
              path="fitness_class_session_booking_bill_success"
              element={<BillPaymentSuccessful />}
            />
            <Route path="purchases" element={<PurchasesScreen />} />
            <Route path="memberships" element={<MembershipsScreen />} />
          </Route>
        </Route>

        <Route element={<RestrictedRoute />}>
          <Route path="login" element={<LoginScreen />} />
          <Route path="sign_up" element={<SignUpScreen />} />
          <Route path="forgot_password" element={<ForgotPassword />} />
          <Route
            path="forgot_password_verification_code"
            element={<ForgotPasswordVerificationCodeScreen />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default index;
