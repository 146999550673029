import { Box, Grid, Pagination, Typography, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import CustomButton from "../../../../components/button/custom-button.component";
import CustomSkeleton from "../../../../components/utils/skeleton.component";
import Spacer from "../../../../components/utils/spacer.component";
import { purchasedPackageSelector } from "../../../../services/package/purchased-package/purchased-package-slice.service";
import AvailablePackageSessionModal from "./available-package-session-modal.component";
import PackageDetailsCard from "./package-details-card.component";
import PackageDetailsModal from "./package-details-modal.component";
import NoPackageImage from "../../../../assets/images/no-package.png";

function PackageTabPanel({ getPurchasedPackages }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [status, setStatus] = useState("ongoing");
  const { getPurchasedPackagesObj } = useSelector(purchasedPackageSelector);
  const [page, setPage] = useState(1);
  const [packageDetails, setPackageDetails] = useState(null);
  const [showPackageDetailsModal, setShowPackageDetailsModal] = useState(false);
  const [showAvailablePackageSessionModal, setShowAvailablePackageSessionModal] = useState(false);

  const onPageChange = (event, newPage) => {
    setPage(newPage);
    getPurchasedPackages(status, newPage);
  };

  return (
    <Box>
      <AvailablePackageSessionModal
        packageDetails={packageDetails}
        showModal={showAvailablePackageSessionModal}
        setShowModal={setShowAvailablePackageSessionModal}
      />
      <PackageDetailsModal
        itemDetails={packageDetails}
        showModal={showPackageDetailsModal}
        setShowModal={setShowPackageDetailsModal}
        setShowAvailablePackageSessionModal={setShowAvailablePackageSessionModal}
      />
      <Grid container columnSpacing={2}>
        <Grid item xs={isMobile ? 6 : 2}>
          <CustomButton
            buttonColor={status === "ongoing" ? "primary" : "grey"}
            textColor={status === "ongoing" ? "primary" : "grey"}
            variant="outlined"
            fontSize={theme.fonts.fontSizes.size16}
            onClick={() => {
              setStatus("ongoing");
              getPurchasedPackages("ongoing", 1);
            }}
          >
            Ongoing
          </CustomButton>
        </Grid>
        <Grid item xs={isMobile ? 6 : 2}>
          <CustomButton
            buttonColor={status === "completed" ? "primary" : "grey"}
            textColor={status === "completed" ? "primary" : "grey"}
            variant="outlined"
            fontSize={theme.fonts.fontSizes.size16}
            onClick={() => {
              setStatus("completed");
              getPurchasedPackages("completed", 1);
            }}
          >
            Completed
          </CustomButton>
        </Grid>
      </Grid>
      <Spacer size="l" />
      {getPurchasedPackagesObj.status === "succeeded" ? (
        <Grid container columnSpacing={1} rowSpacing={2}>
          {getPurchasedPackagesObj.data.items.length > 0 ? (
            <>
              {getPurchasedPackagesObj.data.items.map((item) => (
                <Grid
                  item
                  xs={isMobile ? 12 : 4}
                  key={item.id}
                  onClick={() => {
                    setPackageDetails(item);
                    setShowPackageDetailsModal(true);
                  }}
                >
                  <PackageDetailsCard itemDetails={item} />
                </Grid>
              ))}
              <Grid item container justifyContent="center">
                <Pagination
                  page={page}
                  onChange={onPageChange}
                  count={getPurchasedPackagesObj.data.pagination.totalPages}
                  variant="outlined"
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Spacer size="m" />
              <Typography textAlign="center">No Packages</Typography>
              <Spacer size="m" />
              <Grid container display="flex" justifyContent="center">
                <img
                  width={350}
                  src={NoPackageImage}
                  alt="no-package"
                  style={{ objectFit: "contain" }}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid container columnSpacing={1} rowSpacing={1}>
          <Grid item xs={isMobile ? 12 : 4}>
            <CustomSkeleton width="100%" height={138} />
          </Grid>
          <Grid item xs={isMobile ? 12 : 4}>
            <CustomSkeleton width="100%" height={138} />
          </Grid>
          <Grid item xs={isMobile ? 12 : 4}>
            <CustomSkeleton width="100%" height={138} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

PackageTabPanel.propTypes = {
  getPurchasedPackages: PropTypes.func.isRequired,
};

export default PackageTabPanel;
