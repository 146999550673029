import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq, postReq, putReq } from "../api.services";

export const getBookingSlots = createAsyncThunk("bookings/slots", async (payload) => {
  const response = getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/bookings/slots?type=${payload.type}&page=${payload.page}`,
  );
  return response;
});

export const cancelBookingSlot = createAsyncThunk(
  "bookings/slots/{slot_id}/cancel",
  async (payload) => {
    const response = postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/bookings/slots/${payload.id}/cancel`,
      null,
    );
    return response;
  },
);

export const updateBookingSlot = createAsyncThunk("bookings/slots/{slot_id}", async (payload) => {
  const response = putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/bookings/slots/${payload.id}`,
    payload,
  );
  return response;
});

const bookingSlice = createSlice({
  name: "booking",
  initialState: {
    dataList: [],
    selectedSessionsId: [],
    getBookingSlotsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    cancelBookingSlotObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateBookingSlotObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getBookingSlots.pending]: (state) => {
      state.getBookingSlotsObj.status = "pending";
    },
    [getBookingSlots.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getBookingSlotsObj.status = "succeeded";
      state.getBookingSlotsObj.data = data;
      state.getBookingSlotsObj.successMessage = message;
    },
    [getBookingSlots.rejected]: (state, action) => {
      const { message } = action.error;

      state.getBookingSlotsObj.status = "failed";
      state.getBookingSlotsObj.errorMessage = message;
    },
    [cancelBookingSlot.pending]: (state) => {
      state.cancelBookingSlotObj.status = "pending";
    },
    [cancelBookingSlot.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.cancelBookingSlotObj.status = "succeeded";
      state.cancelBookingSlotObj.data = data;
      state.cancelBookingSlotObj.successMessage = message;
    },
    [cancelBookingSlot.rejected]: (state, action) => {
      const { message } = action.error;

      state.cancelBookingSlotObj.status = "rejected";
      state.cancelBookingSlotObj.errorMessage = message;
    },
    [updateBookingSlot.pending]: (state) => {
      state.updateBookingSlotObj.status = "pending";
    },
    [updateBookingSlot.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateBookingSlotObj.status = "succeeded";
      state.updateBookingSlotObj.data = data;
      state.updateBookingSlotObj.successMessage = message;
    },
    [updateBookingSlot.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateBookingSlotObj.status = "failed";
      state.updateBookingSlotObj.errorMessage = message;
    },
  },
});

export default bookingSlice.reducer;

// state
export const bookingSelector = (state) => state.booking;
