import MailOutlineIcon from "@mui/icons-material/MailOutline";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Grid, styled, Tab, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import KatchLogo from "../../../assets/images/katch_logo";
import TopLeftLink from "../../../components/button/top-left-link.component";
import FormFieldTextWithIcon from "../../../components/forms/form-field-text-with-icon.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import PhoneNumberTextField from "../../../components/forms/phone-number-text-field.component";
import routes from "../../../components/navigation/routes";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import TopTitleBox from "../../../components/utils/top-title-box.component";
import { forgotPassword } from "../../../services/auth/auth-slice.service";

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  fontSize: theme.fonts.fontSizes.size15,
}));

const emailValidationSchema = Yup.object().shape({
  typeInfo: Yup.string().required().email().label("Email"),
});

const phoneNumberValidationSchema = Yup.object().shape({
  typeInfo: Yup.number()
    .when({
      is: (value) => value?.length,
      then: (rule) => rule.min(9),
    })
    .required()
    .label("Phone Number"),
});

function ForgotPassword() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isLoading, setIsLoading] = useState(false);
  const createSnackBar = useContext(SnackbarContext);
  const [tabs, setTabs] = useState("email");

  const handleChange = (event, newValue) => {
    setTabs(newValue);
  };

  const handleSubmitForgotPassword = (values) => {
    setIsLoading(true);
    dispatch(forgotPassword(values)).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        navigate(routes.FORGOTPASSWORDVERIFICATIONCODE, { state: { ...values } });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const renderEmailForm = () => (
    <Form
      initialValues={{ type: "email", typeInfo: "" }}
      validationSchema={emailValidationSchema}
      onSubmit={handleSubmitForgotPassword}
    >
      <Spacer size="l" />
      <FormFieldTextWithIcon
        name="typeInfo"
        placeholder="Email"
        showIcon={true}
        icon={<MailOutlineIcon color="primary" />}
      />
      <Spacer size="xxl" />
      <Grid item textAlign="center" xs={12}>
        <FormSubmitButton isLoading={isLoading} borderRadius={3}>
          <Typography>Continue</Typography>
        </FormSubmitButton>
      </Grid>
    </Form>
  );

  const renderPhoneNumberForm = () => (
    <Form
      initialValues={{ type: "phoneNumber", typeInfo: "" }}
      validationSchema={phoneNumberValidationSchema}
      onSubmit={handleSubmitForgotPassword}
    >
      <Spacer size="l" />
      <PhoneNumberTextField name="typeInfo" />
      <Spacer size="xxl" />
      <Grid item textAlign="center" xs={12}>
        <FormSubmitButton isLoading={isLoading} borderRadius={3}>
          <Typography>Continue</Typography>
        </FormSubmitButton>
      </Grid>
    </Form>
  );

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.colors.bg.primary,
        opacity: 1,
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <TopLeftLink label="< Back to login" pushTo={routes.LOGIN} />
      <Grid
        container
        sx={{
          width: "100%",
          flexDirection: "column",
        }}
      >
        <PaddedView multiples={isMobile ? 2 : 4}>
          <Grid item>
            <Grid
              container
              sx={{
                justifyContent: "center",
                height: "100%",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Grid item xs={12} sx={{ height: isMobile ? 45 : 60, textAlign: "center" }}>
                <KatchLogo color="green" />
              </Grid>
              <Spacer size="m" />
              <Grid
                item
                xs={12}
                sx={{
                  backgroundColor: theme.palette.colors.bg.white,
                  borderRadius: `${theme.shape.borderRadius[2]}px`,
                }}
              >
                <TopTitleBox title="Forgot Password?" requireCloseButton={false} />
                <PaddedView multiples={4}>
                  <Typography>We will send you an OTP code to reset you password</Typography>
                  <Spacer size="l" />

                  <TabContext value={tabs}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList onChange={handleChange} variant="fullWidth">
                        <StyledTab label="Email" value="email" />
                        <StyledTab label="Phone Number" value="phoneNumber" />
                      </TabList>
                    </Box>
                    <TabPanel value="email">{renderEmailForm()}</TabPanel>
                    <TabPanel value="phoneNumber">{renderPhoneNumberForm()}</TabPanel>
                  </TabContext>
                </PaddedView>
              </Grid>
            </Grid>
          </Grid>
        </PaddedView>
      </Grid>
    </Box>
  );
}

export default ForgotPassword;
