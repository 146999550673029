import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import MembershipList from "../components/membership-list.component";

export default function MembershipsScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box marginTop="20px" paddingX={isMobile ? "20px" : ""}>
      <MembershipList />
    </Box>
  );
}
