import { Skeleton, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

function CustomSkeleton({ variant, width, height }) {
  const theme = useTheme();
  return (
    <Skeleton
      variant={variant}
      width={width}
      height={height}
      sx={{ borderRadius: variant === "circular" ? "50%" : `${theme.shape.borderRadius[2]}px` }}
    />
  );
}

CustomSkeleton.defaultProps = {
  variant: "rounded",
  width: "100px",
  height: "24px",
};

CustomSkeleton.propTypes = {
  variant: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CustomSkeleton;
