import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { Grid, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import CustomButton from "../../../../components/button/custom-button.component";
import Form from "../../../../components/forms/form.component";
import PaddedView from "../../../../components/utils/padded-view.component";
import { createTopUpBill } from "../../../../services/credit/credit-slice.service";
import TopUpModal from "./top-up-modal.component";
import { SnackbarContext } from "../../../../components/notification/snackbar.context";
import { profileSelector } from "../../../../services/profile/profile-slice.service";

export default function UserCredits({ setIsLoading }) {
  const theme = useTheme();
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { getProfileDetailObj } = useSelector(profileSelector);

  const handleTopUp = (values) => {
    setShowModal(false);
    setIsLoading(true);
    const amountCents = values.amount * 100;
    const paymentMethod = "revenue_monster";
    dispatch(createTopUpBill({ amountCents, paymentMethod, platform: "web" })).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
          setIsLoading(false);
        }
        if (meta.requestStatus === "fulfilled") {
          window.open(payload.data.bill.remoteUrl, "_self");
        }
      },
    );
  };

  return (
    <Grid
      container
      sx={{
        backgroundColor: theme.palette.colors.bg.dark,
        borderRadius: `${theme.shape.borderRadius[1]}px`,
      }}
    >
      <PaddedView multiples={2}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              sx={{ color: theme.palette.colors.text.white }}
              fontSize={theme.fonts.fontSizes.size13}
            >
              Your Credits
            </Typography>
            <Typography
              fontWeight="bold"
              sx={{ color: theme.palette.colors.text.white }}
              fontSize={theme.fonts.fontSizes.size20}
            >
              {getProfileDetailObj.status === "succeeded"
                ? `RM ${getProfileDetailObj.data.creditBalance}`
                : "RM 0.00"}
            </Typography>
          </Grid>
          <Grid item alignSelf="center">
            <Form
              initialValues={{
                amount: 50,
              }}
              validationSchema={Yup.object().shape({
                amount: Yup.number()
                  .required()
                  .min(50, "Minimum top-up amount is RM 50")
                  .label("Amount"),
              })}
              onSubmit={handleTopUp}
            >
              <TopUpModal showModal={showModal} setShowModal={setShowModal} />
            </Form>
            <CustomButton
              startIcon={<AddCircleOutlinedIcon color="white" />}
              onClick={() => setShowModal(true)}
            >
              Top-up
            </CustomButton>
          </Grid>
        </Grid>
      </PaddedView>
    </Grid>
  );
}

UserCredits.propTypes = {
  setIsLoading: PropTypes.func.isRequired,
};
