import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { putReq, getReq } from "../../../../api.services";

export const cancelFitnessClassSessionBookingBill = createAsyncThunk(
  "fitness_classes/sessions/bookings/bill/{billId}/cancel",
  async (payload) => {
    const { billId } = payload;
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/fitness_classes/sessions/bookings/bills/${billId}/cancel`,
    );
    return response;
  },
);

export const getFitnessClassSessionBookingViaRemoteBillId = createAsyncThunk(
  "fitness_classes/sessions/bookings/bill/{remoteBillId}",
  async (payload) => {
    const { remoteBillId } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/fitness_classes/sessions/bookings/bills/${remoteBillId}`,
    );
    return response;
  },
);

const fitnessClassSessionBookingBillSlice = createSlice({
  name: "fitnessClassSessionBookingBill",
  initialState: {
    cancelFitnessClassSessionBookingBillObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getFitnessClassSessionBookingViaRemoteBillIdObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [cancelFitnessClassSessionBookingBill.pending]: (state) => {
      state.cancelFitnessClassSessionBookingBillObj.status = "pending";
    },
    [cancelFitnessClassSessionBookingBill.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.cancelFitnessClassSessionBookingBillObj.status = "succeeded";
      state.cancelFitnessClassSessionBookingBillObj.data = data;
      state.cancelFitnessClassSessionBookingBillObj.successMessage = message;
    },
    [cancelFitnessClassSessionBookingBill.rejected]: (state, action) => {
      const { message } = action.error;

      state.cancelFitnessClassSessionBookingBillObj.status = "rejected";
      state.cancelFitnessClassSessionBookingBillObj.errorMessage = message;
    },
    [getFitnessClassSessionBookingViaRemoteBillId.pending]: (state) => {
      state.getFitnessClassSessionBookingViaRemoteBillIdObj.status = "pending";
    },
    [getFitnessClassSessionBookingViaRemoteBillId.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getFitnessClassSessionBookingViaRemoteBillIdObj.status = "succeeded";
      state.getFitnessClassSessionBookingViaRemoteBillIdObj.data = data;
      state.getFitnessClassSessionBookingViaRemoteBillIdObj.successMessage = message;
    },
    [getFitnessClassSessionBookingViaRemoteBillId.rejected]: (state, action) => {
      const { message } = action.error;

      state.getFitnessClassSessionBookingViaRemoteBillIdObj.status = "rejected";
      state.getFitnessClassSessionBookingViaRemoteBillIdObj.errorMessage = message;
    },
  },
});

export default fitnessClassSessionBookingBillSlice.reducer;

// state
export const fitnessClassSessionBookingBillSelector = (state) =>
  state.fitnessClassSessionBookingBill;
