import { Box, styled, TextField, Typography, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";

const SelectionContainer = styled(Box)(({ theme, selected }) => ({
  borderRadius: `${theme.shape.borderRadius[2]}px`,
  borderWidth: selected ? "2px" : "1px",
  marginBottom: "4px",
  padding: "8px",
  justifyContent: "center",
  border: selected ? `2px solid ${theme.palette.colors.brand.primary}` : "1px solid #6B6B6B",
  cursor: "pointer",
  backgroundColor: "white",
}));

function QuestionResponse({ items, setAllQuestionsAnswered }) {
  const theme = useTheme();
  const { values, setFieldValue } = useFormikContext();

  const handleSelect = (item, response) => {
    let newValueQuestions = values.questions.filter((question) => question.questionId !== item.id);
    newValueQuestions = [...newValueQuestions, { questionId: item.id, responseId: response.id }];
    setFieldValue("questions", newValueQuestions);
  };

  useEffect(() => {
    if (values.questions.length === items.length) setAllQuestionsAnswered(true);
  }, [values.questions]);

  return (
    <PaddedView>
      {items.map((item) => (
        <Box key={`${item.id}-${item.question}`}>
          <Typography>{item.question}</Typography>
          <Spacer />
          {item.open_ended && (
            <Box
              sx={{
                "& .MuiTextField-root": {
                  borderRadius: `${theme.shape.borderRadius[2]}px`,
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: `${theme.shape.borderRadius[2]}px`,
                    backgroundColor: theme.palette.colors.bg.white,
                  },
                },
              }}
            >
              <TextField
                size="small"
                variant="outlined"
                placeholder="Your Answer"
                onChange={(e) => {
                  let newValueQuestions = values.questions.filter(
                    (question) => question.questionId !== item.id,
                  );

                  if (e.target.value) {
                    newValueQuestions = [
                      ...newValueQuestions,
                      { questionId: item.id, answer: e.target.value },
                    ];
                  } else {
                    setAllQuestionsAnswered(false);
                  }
                  setFieldValue("questions", newValueQuestions);
                }}
                value={values.questions.find((q) => q.questionId === item.id)?.answer || ""}
              />
            </Box>
          )}
          {!item.open_ended &&
            item.responses.map((response) => (
              <Box
                key={`${response.id}-${response.option}`}
                onClick={() => handleSelect(item, response)}
              >
                <SelectionContainer
                  selected={
                    values.questions.filter(
                      (question) =>
                        question.questionId === item.id && question.responseId === response.id,
                    ).length > 0
                  }
                >
                  <Typography
                    style={{
                      color:
                        values.questions.filter(
                          (question) =>
                            question.questionId === item.id && question.responseId === response.id,
                        ).length > 0
                          ? theme.palette.colors.text.primary
                          : theme.palette.colors.text.secondary,
                    }}
                  >
                    {response.option}
                  </Typography>
                </SelectionContainer>
              </Box>
            ))}
          <Spacer />
        </Box>
      ))}
    </PaddedView>
  );
}

QuestionResponse.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      question: PropTypes.string.isRequired,
      open_ended: PropTypes.bool.isRequired,
      responses: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          option: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  setAllQuestionsAnswered: PropTypes.func.isRequired,
};

export default QuestionResponse;
