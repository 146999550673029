import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";

function FormSelectFieldText({
  name,
  selections,
  disabled,
  backgroundColor,
  submitOnChange,
  placeholder,
}) {
  const theme = useTheme();
  const { setFieldValue, values, setFieldTouched, touched, errors, handleSubmit } =
    useFormikContext();
  const showError = touched[name] && typeof errors[name] === "string";

  const handleChange = (e) => {
    setFieldValue(name, e.target.value);
    if (submitOnChange) {
      handleSubmit();
    }
  };

  return (
    <FormControl
      fullWidth
      sx={{
        "& .MuiOutlinedInput-root": {
          borderTopLeftRadius: theme.shape.borderRadius[1],
          borderTopRightRadius: theme.shape.borderRadius[1],
          borderBottomLeftRadius: theme.shape.borderRadius[1],
          borderBottomRightRadius: theme.shape.borderRadius[1],
          backgroundColor,
        },
      }}
      size="small"
    >
      <InputLabel sx={{}}>{placeholder}</InputLabel>
      <Select
        size="small"
        onBlur={() => setFieldTouched(name)}
        disabled={disabled}
        label={placeholder}
        value={values[name]}
        onChange={handleChange}
        error={showError}
        displayEmpty
      >
        {selections.map((selection) => (
          <MenuItem value={selection.value} key={`${selection.type}-${selection.value}`}>
            {selection.type}
          </MenuItem>
        ))}
      </Select>
      {showError && <FormHelperText error>{errors[name]}</FormHelperText>}
    </FormControl>
  );
}

FormSelectFieldText.defaultProps = {
  disabled: false,
  backgroundColor: "#F2F5FA",
  submitOnChange: false,
  placeholder: "",
};

FormSelectFieldText.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  selections: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ).isRequired,
  backgroundColor: PropTypes.string,
  submitOnChange: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default FormSelectFieldText;
