import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAPI } from "../../infrastructure/utils";
import { getReq, postReq } from "../api.services";

export const getAvailableHourlyBooking = createAsyncThunk(
  "hourly_bookings?businessId={businessId}&date={date}",
  async (payload) => {
    const { businessId, date, preselectedStartAt, preselectedEndAt } = payload;
    const url = `${getAPI()}/hourly_bookings?date=${date}&businessId=${businessId}${
      preselectedEndAt ? "&preselectedEndAt=".concat(preselectedEndAt) : ""
    }${preselectedStartAt ? "&preselectedStartAt=".concat(preselectedStartAt) : ""}`;
    const response = await getReq(url);
    return response;
  },
);

export const previewAvailableHourlyBooking = createAsyncThunk(
  "hourly_bookings/preview",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/hourly_bookings/preview`,
      payload,
    );
    return response;
  },
);

export const createHourlyBooking = createAsyncThunk("hourly_bookings/create", async (payload) => {
  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/hourly_bookings`, {
    ...payload,
    platform: "web",
  });
  return response;
});

const hourlyBookingSlice = createSlice({
  name: "hourlyBooking",
  initialState: {
    getAvailableHourlyBookingObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    previewAvailableHourlyBookingObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createHourlyBookingObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [getAvailableHourlyBooking.pending]: (state) => {
      state.getAvailableHourlyBookingObj.status = "pending";
    },
    [getAvailableHourlyBooking.fulfilled]: (state, action) => {
      const { data, message } = action.payload;
      state.getAvailableHourlyBookingObj.status = "succeeded";
      state.getAvailableHourlyBookingObj.data = data;
      state.getAvailableHourlyBookingObj.successMessage = message;
    },
    [getAvailableHourlyBooking.rejected]: (state, action) => {
      const { message } = action.error;
      state.getAvailableHourlyBookingObj.status = "failed";
      state.getAvailableHourlyBookingObj.errorMessage = message;
    },
    [previewAvailableHourlyBooking.pending]: (state) => {
      state.previewAvailableHourlyBookingObj.status = "pending";
    },
    [previewAvailableHourlyBooking.fulfilled]: (state, action) => {
      const { data, message } = action.payload;
      state.previewAvailableHourlyBookingObj.status = "succeeded";
      state.previewAvailableHourlyBookingObj.data = data;
      state.previewAvailableHourlyBookingObj.successMessage = message;
    },
    [previewAvailableHourlyBooking.rejected]: (state, action) => {
      const { message } = action.error;
      state.previewAvailableHourlyBookingObj.status = "failed";
      state.previewAvailableHourlyBookingObj.errorMessage = message;
    },
    [createHourlyBooking.pending]: (state) => {
      state.createHourlyBookingObj.status = "pending";
    },
    [createHourlyBooking.fulfilled]: (state, action) => {
      const { data, message } = action.payload;
      state.createHourlyBookingObj.status = "succeeded";
      state.createHourlyBookingObj.data = data;
      state.createHourlyBookingObj.successMessage = message;
    },
    [createHourlyBooking.rejected]: (state, action) => {
      const { message } = action.error;
      state.createHourlyBookingObj.status = "failed";
      state.createHourlyBookingObj.errorMessage = message;
    },
  },
});

export default hourlyBookingSlice.reducer;

// state
export const hourlyBookingSelector = (state) => state.hourlyBooking;
