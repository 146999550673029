import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq } from "../api.services";

export const userSearchByPhoneNo = createAsyncThunk(
  "socials/user/search?phoneNo",
  async (payload) => {
    const { phoneNo } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/socials/users/search?phoneNo=${phoneNo}`,
    );
    return response;
  },
);

const socialSlice = createSlice({
  name: "social",
  initialState: {
    userSearchByPhoneNoObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [userSearchByPhoneNo.pending]: (state) => {
      state.userSearchByPhoneNoObj.status = "pending";
    },
    [userSearchByPhoneNo.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.userSearchByPhoneNoObj.status = "succeeded";
      state.userSearchByPhoneNoObj.data = data;
      state.userSearchByPhoneNoObj.successMessage = message;
    },
    [userSearchByPhoneNo.rejected]: (state, action) => {
      const { message } = action.error;

      state.userSearchByPhoneNoObj.status = "rejected";
      state.userSearchByPhoneNoObj.errorMessage = message;
    },
  },
});

export default socialSlice.reducer;

// state
export const socialSelector = (state) => state.social;
