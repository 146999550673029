import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq, postReq } from "../api.services";
import { getAPI } from "../../infrastructure/utils";

export const getMemberships = createAsyncThunk("memberships/index", async () => {
  const response = await getReq(`${process.env.REACT_APP_API_PRIVATE_V1}/memberships/`);
  return response;
});

export const checkUserMembership = createAsyncThunk(
  "memberships/check?businessId={businessId}",
  async (payload) => {
    const { businessId } = payload;
    const response = await postReq(`${getAPI()}/memberships/check?businessId=${businessId}`);
    return response;
  },
);

export const previewMembership = createAsyncThunk("memberships/preview", async (payload) => {
  const { planId, businessId } = payload;
  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/memberships/preview`, {
    id: planId,
    businessId,
  });
  return response;
});

export const createMembership = createAsyncThunk("memberships/create", async (payload) => {
  const { planId, businessId, paymentMethod } = payload;
  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/memberships`, {
    id: planId,
    businessId,
    paymentMethod,
    platform: "web",
  });
  return response;
});

const membershipSlice = createSlice({
  name: "membership",
  initialState: {
    getMembershipsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    checkUserMembershipObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    previewMembershipObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createMembershipObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [getMemberships.pending]: (state) => {
      state.getMembershipsObj.status = "pending";
    },
    [getMemberships.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getMembershipsObj.status = "succeeded";
      state.getMembershipsObj.data = data;
      state.getMembershipsObj.successMessage = message;
    },
    [getMemberships.rejected]: (state, action) => {
      const { message } = action.error;

      state.getMembershipsObj.status = "failed";
      state.getMembershipsObj.errorMessage = message;
    },
    [checkUserMembership.pending]: (state) => {
      state.checkUserMembershipObj.status = "pending";
    },
    [checkUserMembership.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.checkUserMembershipObj.status = "succeeded";
      state.checkUserMembershipObj.data = data;
      state.checkUserMembershipObj.successMessage = message;
    },
    [checkUserMembership.rejected]: (state, action) => {
      const { message } = action.error;

      state.checkUserMembershipObj.status = "failed";
      state.checkUserMembershipObj.errorMessage = message;
    },
    [previewMembership.pending]: (state) => {
      state.previewMembershipObj.status = "pending";
    },
    [previewMembership.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.previewMembershipObj.status = "succeeded";
      state.previewMembershipObj.data = data;
      state.previewMembershipObj.successMessage = message;
    },
    [previewMembership.rejected]: (state, action) => {
      const { message } = action.error;

      state.previewMembershipObj.status = "failed";
      state.previewMembershipObj.errorMessage = message;
    },
    [createMembership.pending]: (state) => {
      state.createMembershipObj.status = "pending";
    },
    [createMembership.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createMembershipObj.status = "succeeded";
      state.createMembershipObj.data = data;
      state.createMembershipObj.successMessage = message;
    },
    [createMembership.rejected]: (state, action) => {
      const { message } = action.error;

      state.createMembershipObj.status = "failed";
      state.createMembershipObj.errorMessage = message;
    },
  },
});

export default membershipSlice.reducer;

// state
export const membershipSelector = (state) => state.membership;
