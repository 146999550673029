import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import routes from "../../../components/navigation/routes";
import Spacer from "../../../components/utils/spacer.component";
import { isLogin } from "../../../infrastructure/utils";

function MembershipPlanCard({ itemDetails }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const [params] = useSearchParams();

  return (
    <Grid
      onClick={() => {
        if (isLogin()) {
          if (!itemDetails.purchased) {
            navigate({
              pathname: routes.MEMBERSHIP_PLAN_SUMMARY,
              search: `?planId=${itemDetails.id}&businessId=${params.get("businessId")}`,
            });
          }
        } else {
          navigate({
            pathname: routes.LOGIN,
          });
        }
      }}
      container
      alignContent="center"
      sx={{
        backgroundColor: theme.palette.colors.bg.primary,
        borderRadius: `${theme.shape.borderRadius[2]}px`,
        objectFit: "cover",
        cursor: itemDetails.purchased ? undefined : "pointer",
        "&:hover .planTitle": { color: theme.palette.colors.brand.primary },
      }}
    >
      <Grid item alignSelf="center" alignItems="center" display="flex" xs={4}>
        <img
          src={itemDetails.imagePath}
          alt="planImage"
          width="100%"
          height={isMobile ? "135px" : "auto"}
          style={{
            borderTopLeftRadius: `${theme.shape.borderRadius[2]}px`,
            borderBottomLeftRadius: `${theme.shape.borderRadius[2]}px`,
            objectFit: "cover",
          }}
        />
      </Grid>
      {itemDetails.purchased ? (
        <Grid item display="flex" alignItems="center" xs={8} paddingLeft="10px">
          <Grid item>
            <Grid item xs={11}>
              <Typography
                fontWeight="bold"
                color="primary"
                sx={{
                  border: "1.5px solid",
                  textAlign: "center",
                  borderColor: theme.palette.colors.brand.primary,
                  borderRadius: `${theme.shape.borderRadius[2]}px`,
                }}
              >
                ACTIVE MEMBER
              </Typography>
            </Grid>
            <Spacer size="m" />
            <Grid item>
              <Typography>since {itemDetails.purchasedSince}</Typography>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item display="flex" alignItems="center" xs={8} paddingLeft="10px">
          <Grid item>
            <Grid item>
              <Typography fontWeight="bold" className="planTitle">
                {itemDetails.identifier}
              </Typography>
            </Grid>
            <Spacer size="xs" />
            <Grid item display="flex" alignContent="center">
              <Typography fontSize={theme.fonts.fontSizes.size14}>
                {itemDetails.shortDescription}
              </Typography>
            </Grid>
            <Spacer size="xs" />
            <Grid item display="flex" alignContent="center">
              <Spacer size="xs" position="left" />
              <Typography fontWeight="bold" color="primary">
                RM {itemDetails.price}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

MembershipPlanCard.defaultProps = {
  itemDetails: PropTypes.shape({
    id: null,
    identifier: "",
    shortDescription: "",
    price: "",
    imagePath: "",
    purchased: false,
    purchasedSince: "",
  }),
};

MembershipPlanCard.propTypes = {
  itemDetails: PropTypes.shape({
    id: PropTypes.number,
    identifier: PropTypes.string,
    shortDescription: PropTypes.string,
    price: PropTypes.string,
    imagePath: PropTypes.string,
    purchased: PropTypes.bool,
    purchasedSince: PropTypes.string,
  }),
};

export default MembershipPlanCard;
