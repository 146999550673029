import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { patchReq, postReq } from "../api.services";

export const previewSubscription = createAsyncThunk("subscriptions/preview", async (payload) => {
  const { planId, businessId, promoCode } = payload;
  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/subscriptions/preview`, {
    planId,
    businessId,
    promoCode,
  });
  return response;
});

export const activateSubscription = createAsyncThunk(
  "subscription/{subscription_id}/activate",
  async (payload) => {
    const { subscriptionId } = payload;
    const response = await patchReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/subscriptions/${subscriptionId}/activate`,
    );
    return response;
  },
);

export const createSubscription = createAsyncThunk("subscriptions/create", async (payload) => {
  const { planId, businessId, paymentMethod, promoCode, tokenizedRemoteCustomerId } = payload;
  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/subscriptions`, {
    planId,
    businessId,
    promoCode,
    tokenizedRemoteCustomerId,
    paymentMethod,
    platform: "web",
  });
  return response;
});

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    previewSubscriptionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createSubscriptionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    activateSubscriptionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [previewSubscription.pending]: (state) => {
      state.previewSubscriptionObj.status = "pending";
    },
    [previewSubscription.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.previewSubscriptionObj.status = "succeeded";
      state.previewSubscriptionObj.data = data;
      state.previewSubscriptionObj.successMessage = message;
    },
    [previewSubscription.rejected]: (state, action) => {
      const { message } = action.error;

      state.previewSubscriptionObj.status = "failed";
      state.previewSubscriptionObj.errorMessage = message;
    },
    [activateSubscription.pending]: (state) => {
      state.activateSubscriptionObj.status = "pending";
    },
    [activateSubscription.fulfilled]: (state, action) => {
      const { message } = action.payload;

      state.activateSubscriptionObj.status = "succeeded";
      state.activateSubscriptionObj.successMessage = message;
    },
    [activateSubscription.rejected]: (state, action) => {
      const { message } = action.error;

      state.activateSubscriptionObj.status = "failed";
      state.activateSubscriptionObj.errorMessage = message;
    },
    [createSubscription.pending]: (state) => {
      state.createSubscriptionObj.status = "pending";
    },
    [createSubscription.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createSubscriptionObj.status = "succeeded";
      state.createSubscriptionObj.data = data;
      state.createSubscriptionObj.successMessage = message;
    },
    [createSubscription.rejected]: (state, action) => {
      const { message } = action.error;

      state.createSubscriptionObj.status = "failed";
      state.createSubscriptionObj.errorMessage = message;
    },
  },
});

export default subscriptionSlice.reducer;

// state
export const subscriptionSelector = (state) => state.subscription;
