import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../api.services";

export const requestAccountDeletion = createAsyncThunk(
  "users/account_deletions",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/users/account_deletions`,
      payload,
    );
    return response;
  },
);

export const verifyAccountDeletion = createAsyncThunk(
  "users/account_deletions/verify",
  async (payload) => {
    const { deleteId } = payload;
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/users/account_deletions/${deleteId}/verify`,
      payload,
    );
    return response;
  },
);

export const cancelAccountDeletion = createAsyncThunk(
  "users/account_deletions/cancel",
  async (payload) => {
    const { deleteId } = payload;
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/users/account_deletions/${deleteId}`,
      payload,
    );
    return response;
  },
);

export const resendVerificationAccountDeletion = createAsyncThunk(
  "users/account_deletions/resend_verification_code",
  async (payload) => {
    const { deleteId } = payload;
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/users/account_deletions/${deleteId}/resend_verification_code`,
      payload,
    );
    return response;
  },
);

export const sendAccountDeletionReason = createAsyncThunk(
  "users/account_deletions/reason/send",
  async (payload) => {
    const { deleteId } = payload;
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/users/account_deletions/${deleteId}`,
      payload,
    );
    return response;
  },
);

export const getAccountDeletionReasons = createAsyncThunk(
  "users/account_deletions/reasons/get",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/users/account_deletions/reasons`,
      payload,
    );
    return response;
  },
);

const accountDeletionSlice = createSlice({
  name: "accountDeletion",
  initialState: {
    requestAccountDeletionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    verifyAccountDeletionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    cancelAccountDeletionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    resendVerificationAccountDeletionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    sendAccountDeletionReasonObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getAccountDeletionReasonsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [requestAccountDeletion.pending]: (state) => {
      state.requestAccountDeletionObj.status = "pending";
    },
    [requestAccountDeletion.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.requestAccountDeletionObj.status = "succeeded";
      state.requestAccountDeletionObj.data = data;
      state.requestAccountDeletionObj.successMessage = message;
    },
    [requestAccountDeletion.rejected]: (state, action) => {
      const { message } = action.error;

      state.requestAccountDeletionObj.status = "failed";
      state.requestAccountDeletionObj.errorMessage = message;
    },
    [verifyAccountDeletion.pending]: (state) => {
      state.verifyAccountDeletionObj.status = "pending";
    },
    [verifyAccountDeletion.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.verifyAccountDeletionObj.status = "succeeded";
      state.verifyAccountDeletionObj.data = data;
      state.verifyAccountDeletionObj.successMessage = message;
    },
    [verifyAccountDeletion.rejected]: (state, action) => {
      const { message } = action.error;

      state.verifyAccountDeletionObj.status = "failed";
      state.verifyAccountDeletionObj.errorMessage = message;
    },
    [cancelAccountDeletion.pending]: (state) => {
      state.cancelAccountDeletionObj.status = "pending";
    },
    [cancelAccountDeletion.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.cancelAccountDeletionObj.status = "succeeded";
      state.cancelAccountDeletionObj.data = data;
      state.cancelAccountDeletionObj.successMessage = message;
    },
    [cancelAccountDeletion.rejected]: (state, action) => {
      const { message } = action.error;

      state.cancelAccountDeletionObj.status = "failed";
      state.cancelAccountDeletionObj.errorMessage = message;
    },
    [resendVerificationAccountDeletion.pending]: (state) => {
      state.resendVerificationAccountDeletionObj.status = "pending";
    },
    [resendVerificationAccountDeletion.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.resendVerificationAccountDeletionObj.status = "succeeded";
      state.resendVerificationAccountDeletionObj.data = data;
      state.resendVerificationAccountDeletionObj.successMessage = message;
    },
    [resendVerificationAccountDeletion.rejected]: (state, action) => {
      const { message } = action.error;

      state.resendVerificationAccountDeletionObj.status = "failed";
      state.resendVerificationAccountDeletionObj.errorMessage = message;
    },
    [sendAccountDeletionReason.pending]: (state) => {
      state.sendAccountDeletionReasonObj.status = "pending";
    },
    [sendAccountDeletionReason.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.sendAccountDeletionReasonObj.status = "succeeded";
      state.sendAccountDeletionReasonObj.data = data;
      state.sendAccountDeletionReasonObj.successMessage = message;
    },
    [sendAccountDeletionReason.rejected]: (state, action) => {
      const { message } = action.error;

      state.sendAccountDeletionReasonObj.status = "failed";
      state.sendAccountDeletionReasonObj.errorMessage = message;
    },
    [getAccountDeletionReasons.pending]: (state) => {
      state.getAccountDeletionReasonsObj.status = "pending";
    },
    [getAccountDeletionReasons.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getAccountDeletionReasonsObj.status = "succeeded";
      state.getAccountDeletionReasonsObj.data = data;
      state.getAccountDeletionReasonsObj.successMessage = message;
    },
    [getAccountDeletionReasons.rejected]: (state, action) => {
      const { message } = action.error;

      state.getAccountDeletionReasonsObj.status = "failed";
      state.getAccountDeletionReasonsObj.errorMessage = message;
    },
  },
});

export default accountDeletionSlice.reducer;

// state
export const accountDeletionSelector = (state) => state.accountDeletion;
