import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { putReq } from "../api.services";

export const updateNewsletterPreferences = createAsyncThunk(
  "message_settings/newsletter_preferences",
  async () => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/message_settings/newsletter`,
    );
    return response;
  },
);

const messageSettingSlice = createSlice({
  name: "messageSetting",
  initialState: {
    newsletterPreferences: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [updateNewsletterPreferences.pending]: (state) => {
      state.newsletterPreferences.status = "pending";
    },
    [updateNewsletterPreferences.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.newsletterPreferences.status = "succeeded";
      state.newsletterPreferences.data = data;
      state.newsletterPreferences.successMessage = message;
      // userStorageService.storeUser(data);
    },
    [updateNewsletterPreferences.rejected]: (state, action) => {
      const { message } = action.error;

      state.newsletterPreferences.status = "rejected";
      state.newsletterPreferences.errorMessage = message;
    },
  },
});

export default messageSettingSlice.reducer;

// state
export const messageSettingSliceSelector = (state) => state.messageSetting;
