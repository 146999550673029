import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import HorizontalDivider from "../../../components/utils/horizontal-divider.component";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import Spacer from "../../../components/utils/spacer.component";

const SectionText = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size18,
  fontWeight: "bold",
}));

const RowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
});

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.h5,
  fontWeight: theme.fonts.fontWeights.bold,
}));

function EventScreenLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container>
      <Grid container columnSpacing={2}>
        <Grid item xs={isMobile ? 12 : 6.5} display="flex" alignItems="center">
          <CustomSkeleton height="315px" width="100%" />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4} alignItems="center" display="flex">
          <Grid
            item
            display="grid"
            flexDirection="column"
            alignContent="space-between"
            height="98%"
            xs={12}
          >
            <Grid item>
              <Grid item>
                <CustomSkeleton height="25px" width={isMobile ? "300px" : "500px"} />
              </Grid>
              <Spacer />
              <Grid item display="flex" alignContent="center">
                <CustomSkeleton width="100px" />
              </Grid>
              <Spacer />
              <Grid item display="flex" alignContent="center">
                <CustomSkeleton width="200px" />
              </Grid>
              <Spacer />
              <Grid item display="flex" alignContent="center">
                <CustomSkeleton width="150px" />
              </Grid>
              <Spacer />
              <Grid item container>
                <CustomSkeleton width="180px" />
              </Grid>
            </Grid>
            <Spacer />
            <Grid item container columnSpacing={1}>
              <Grid item xs={isMobile ? 12 : 4}>
                <CustomSkeleton height="30px" width="100%" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <HorizontalDivider spacerSize="l" />
      <Grid item xs={12}>
        <RowBox>
          <Box sx={{ width: "30%" }}>
            <StyledTitle>Highlights</StyledTitle>
          </Box>
          <Box sx={{ width: "70%" }}>
            <CustomSkeleton width="100%" height="30px" />
            <Spacer />
            <CustomSkeleton width="100%" height="30px" />
            <Spacer />
            <CustomSkeleton width="100%" height="30px" />
          </Box>
        </RowBox>
      </Grid>
      <HorizontalDivider spacerSize="l" />
      <Grid item xs={12}>
        <RowBox>
          <Box sx={{ width: "30%" }}>
            <StyledTitle>Summary</StyledTitle>
          </Box>
          <Box sx={{ width: "70%" }}>
            <CustomSkeleton width="100%" height="30px" />
            <Spacer />
            <CustomSkeleton width="100%" height="30px" />
            <Spacer />
            <CustomSkeleton width="100%" height="30px" />
          </Box>
        </RowBox>
      </Grid>
      <HorizontalDivider spacerSize="l" />
      <Grid item xs={12}>
        <Grid item>
          <SectionText>Location</SectionText>
        </Grid>
        <Spacer size="m" />
        <Grid item>
          <CustomSkeleton height="40vh" width="100%" />
        </Grid>
        <Spacer size="m" />
        <Grid item>
          <CustomSkeleton width="100%" />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default EventScreenLoader;
