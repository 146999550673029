import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, IconButton, Modal, styled, Typography, useTheme } from "@mui/material";
import { Formik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import FormFieldTextWithCounter from "../../../components/forms/form-field-text-with-counter.component";
import FormSelectFieldText from "../../../components/forms/form-select-field-text.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import {
  accountDeletionSelector,
  getAccountDeletionReasons,
} from "../../../services/account-deletion/account-deletion-slice.service";

const validationSchema = Yup.object().shape({
  reasonId: Yup.number().required().label("Reason"),
  reasonOthers: Yup.string().nullable(),
});

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.brand.white,
  borderRadius: theme.shape.borderRadius[2],
  boxShadow: 24,
  padding: "50px",
  width: "90%",
  maxWidth: "450px",
  outline: "none",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.text.primary,
  strokeWidth: 2,
  stroke: theme.palette.colors.text.primary,
}));

function AccountDeletionModal({
  showModal,
  setShowModal,
  onSubmit,
  message,
  buttonText,
  title,
  isDisabled,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getAccountDeletionReasonsObj } = useSelector(accountDeletionSelector);

  useEffect(() => {
    dispatch(getAccountDeletionReasons());
  }, []);

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <ModalBox>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <CloseIconButton onClick={() => setShowModal(false)}>
            <CloseIcon />
          </CloseIconButton>
        </Box>

        <Grid container spacing={4} sx={{ textAlign: "center" }}>
          {title && (
            <Grid item xs={12}>
              <Typography variant="h5">{title}</Typography>
            </Grid>
          )}

          {message && (
            <Grid item xs={12}>
              <Typography sx={{ color: theme.palette.colors.text.secondary }} textAlign="center">
                {message}
              </Typography>
            </Grid>
          )}

          {getAccountDeletionReasonsObj.status === "succeeded" && (
            <Formik
              initialValues={{ reasonId: "", reasonOthers: "" }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ values }) => (
                <>
                  <Grid item xs={12}>
                    <FormSelectFieldText
                      name="reasonId"
                      placeholder="Reasons"
                      selections={getAccountDeletionReasonsObj?.data?.map((item) => ({
                        type: item.reason,
                        value: item.id,
                      }))}
                    />
                  </Grid>

                  {values.reasonId === 1 && (
                    <Grid item xs={12}>
                      <FormFieldTextWithCounter
                        maxLength={250}
                        name="reasonOthers"
                        placeholder="Share your reason"
                        multiline
                        height="150px"
                      />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <FormSubmitButton isLoading={isDisabled} width="60%" borderRadius={1}>
                      <Typography>{buttonText}</Typography>
                    </FormSubmitButton>
                  </Grid>
                </>
              )}
            </Formik>
          )}
        </Grid>
      </ModalBox>
    </Modal>
  );
}

AccountDeletionModal.defaultProps = {
  title: null,
  message: null,
  buttonText: "Confirm",
  isDisabled: false,
};

AccountDeletionModal.propTypes = {
  isDisabled: PropTypes.bool,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  message: PropTypes.string,
  buttonText: PropTypes.string,
  title: PropTypes.string,
};

export default AccountDeletionModal;
