import {
  Box,
  Grid,
  Modal,
  Pagination,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import FormPickerDate from "../../../../components/forms/form-picker-date.component";
import Form from "../../../../components/forms/form.component";
import { SnackbarContext } from "../../../../components/notification/snackbar.context";
import PaddedView from "../../../../components/utils/padded-view.component";
import CustomSkeleton from "../../../../components/utils/skeleton.component";
import Spacer from "../../../../components/utils/spacer.component";
import TopTitleBox from "../../../../components/utils/top-title-box.component";
import {
  getPackageAvailableFitnessClassSessions,
  packageFitnessSessionSelector,
} from "../../../../services/package/session/package-fitness-session-slice.service";
import FitnessClassCard from "./fitness-class-card.component";

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "absolute",
  top: !isMobile && "50%",
  left: !isMobile && "50%",
  transform: !isMobile && "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.white,
  borderRadius: !isMobile && theme.shape.borderRadius[2],
  boxShadow: 24,
  maxWidth: !isMobile && "550px",
  width: isMobile ? "100%" : "90%",
  height: isMobile && "100%",
  minHeight: "550px",
  outline: "none",
  overflowY: isMobile && "auto",
}));

const availableSessionValidationSchema = Yup.object().shape({
  date: Yup.date().required().label("Date"),
});

function AvailablePackageSessionModal({ packageDetails, showModal, setShowModal }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const { getPackageAvailableFitnessClassSessionsObj } = useSelector(packageFitnessSessionSelector);
  const createSnackBar = useContext(SnackbarContext);
  const [page, setPage] = useState(1);
  const formRef = useRef();

  const getAvailablePackageSession = (values, newPage) => {
    let startAt = format(new Date(values.date), "yyyy-MM-dd 04:00:00");
    if (values.date === format(new Date(), "yyyy-MM-dd")) {
      startAt = format(new Date(), "yyyy-MM-dd HH:mm:ss");
    }
    dispatch(
      getPackageAvailableFitnessClassSessions({
        startAt,
        endAt: format(new Date(values.date), "yyyy-MM-dd 23:30:00"),
        businessesId: packageDetails.businesses.map((business) => business.id).join(","),
        packageCode: packageDetails.code,
        categoryType: packageDetails.category.type,
        categoriesId: packageDetails.category.items.map((category) => category.id).join(","),
        page: newPage,
      }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const onPageChange = (event, newPage) => {
    setPage(newPage);
    getAvailablePackageSession(formRef.current.values, newPage);
  };

  useEffect(() => {
    if (packageDetails && showModal) {
      getAvailablePackageSession({ date: format(new Date(), "yyyy-MM-dd") }, 1);
    }
  }, [showModal]);

  const renderAvailableSession = () => {
    if (getPackageAvailableFitnessClassSessionsObj.status === "succeeded") {
      if (getPackageAvailableFitnessClassSessionsObj.data.items.length > 0) {
        return getPackageAvailableFitnessClassSessionsObj.data.items.map((item) => (
          <Grid item key={item.id}>
            <FitnessClassCard itemDetails={item} packageCode={packageDetails.code} />
            <Spacer />
          </Grid>
        ));
      }

      return (
        <Grid
          item
          display="flex"
          textAlign="center"
          alignItems="center"
          sx={{
            backgroundColor: theme.palette.colors.bg.primary,
            borderRadius: `${theme.shape.borderRadius[2]}px`,
          }}
          height={isMobile ? 142 : 192}
        >
          <PaddedView multiples={4}>
            <Typography fontSize={theme.fonts.fontSizes.size20} fontWeight="bold">
              No results found.
            </Typography>
            <Typography>Please choose another date.</Typography>
          </PaddedView>
        </Grid>
      );
    }
    return (
      <Grid item>
        <Grid item>
          <CustomSkeleton width="100%" height={isMobile ? 142 : 192} />
        </Grid>
        <Spacer />
        <Grid item>
          <CustomSkeleton width="100%" height={isMobile ? 142 : 192} />
        </Grid>
      </Grid>
    );
  };

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <ModalBox isMobile={isMobile}>
        {packageDetails && (
          <PaddedView multiples={2}>
            <TopTitleBox
              title="Available Session"
              requireCloseButton={true}
              setShowModal={setShowModal}
            />
            <Spacer position="top" size="l" />
            <Grid container item xs={12} justifyContent="center" rowSpacing={3} padding="10px">
              <Form
                innerRef={formRef}
                initialValues={{
                  date: format(new Date(), "yyyy-MM-dd"),
                }}
                validationSchema={availableSessionValidationSchema}
                onSubmit={getAvailablePackageSession}
              >
                <Grid item xs={8}>
                  <FormPickerDate name="date" submitOnChange={true} />
                </Grid>
              </Form>
            </Grid>
            <Spacer size="l" />

            <Grid item sx={{ overflowY: "auto", height: !isMobile && "400px" }}>
              {renderAvailableSession()}
            </Grid>
            {getPackageAvailableFitnessClassSessionsObj.status === "succeeded" &&
              getPackageAvailableFitnessClassSessionsObj.data.items.length > 0 && (
                <>
                  <Spacer />
                  <Grid container justifyContent="center">
                    <Pagination
                      page={page}
                      onChange={onPageChange}
                      count={getPackageAvailableFitnessClassSessionsObj.data.pagination.totalPages}
                      variant="outlined"
                    />
                  </Grid>
                </>
              )}
          </PaddedView>
        )}
      </ModalBox>
    </Modal>
  );
}

AvailablePackageSessionModal.defaultProps = {
  packageDetails: PropTypes.shape({
    id: null,
    code: "",
    plan: PropTypes.shape({
      title: "",
      imagePath: "",
    }),
    sessions: PropTypes.shape({
      claimedCount: 0,
      noOfSessions: 0,
    }),
    businesses: PropTypes.arrayOf(
      PropTypes.shape({
        id: null,
        name: "",
      }),
    ),
    category: PropTypes.shape({
      type: "",
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: null,
          label: "",
        }),
      ),
    }),
    paymentTransaction: PropTypes.shape({
      method: "",
      paidAt: "",
      total: "",
    }),
  }),
};

AvailablePackageSessionModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  packageDetails: PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string,
    plan: PropTypes.shape({
      title: PropTypes.string,
      imagePath: PropTypes.string,
    }),
    sessions: PropTypes.shape({
      claimedCount: PropTypes.number,
      noOfSessions: PropTypes.number,
    }),
    businesses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
    category: PropTypes.shape({
      type: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          label: PropTypes.string,
        }),
      ),
    }),
    paymentTransaction: PropTypes.shape({
      method: PropTypes.string,
      paidAt: PropTypes.string,
      total: PropTypes.string,
    }),
  }),
};

export default AvailablePackageSessionModal;
