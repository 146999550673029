import PlaceIcon from "@mui/icons-material/Place";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import routes from "../../../components/navigation/routes";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import { isLogin } from "../../../infrastructure/utils";
import { profileSelector } from "../../../services/profile/profile-slice.service";

function UpcomingClassCard({ itemDetails }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const { getProfileDetailObj } = useSelector(profileSelector);

  const handleClick = (event, buttonClick) => {
    if (!buttonClick) {
      navigate({ pathname: routes.FITNESSCLASS, search: `?sessionId=${itemDetails.id}` });
    } else {
      if (
        isLogin() &&
        getProfileDetailObj.status === "succeeded" &&
        getProfileDetailObj.data &&
        getProfileDetailObj.data.status === "verified"
      ) {
        navigate(routes.FITNESSCLASSSESSIONBOOKINGSUMMARY, {
          state: { sessionId: itemDetails.id },
        });
      } else {
        navigate({ pathname: routes.FITNESSCLASS, search: `?sessionId=${itemDetails.id}` });
      }

      event.stopPropagation();
    }
  };

  return (
    <Grid
      onClick={(event) => handleClick(event, false)}
      item
      sx={{
        backgroundColor: theme.palette.colors.bg.primary,
        borderRadius: `${theme.shape.borderRadius[2]}px`,
        objectFit: "cover",
        cursor: "pointer",
        "&:hover .fitnessTitle": { color: theme.palette.colors.brand.primary },
      }}
    >
      <PaddedView multiples={1}>
        <Grid container columnSpacing={2} alignContent="center">
          <Grid item xs={4} alignSelf="center" alignItems="center" display="flex">
            <img
              src={itemDetails.business.imagePath}
              alt="hightlight"
              width="100%"
              height="120px"
              style={{ borderRadius: `${theme.shape.borderRadius[2]}px`, objectFit: "cover" }}
            />
          </Grid>
          <Grid item xs={8} display="grid" flexDirection="column" alignContent="space-between">
            <Grid item>
              <Grid item>
                <Typography
                  fontWeight="bold"
                  maxWidth={isMobile ? "180px" : "240px"}
                  sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
                >
                  {itemDetails.class.name}
                </Typography>
              </Grid>
              <Grid item display="flex" alignContent="center">
                <Typography>
                  {itemDetails.startTime} ({itemDetails.durationMins}mins)
                </Typography>
              </Grid>
              <Grid item display="flex" alignContent="center">
                <Typography>{itemDetails.date}</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item>
                <Typography color="text.secondary" sx={{ fontSize: theme.fonts.fontSizes.size14 }}>
                  w/ {itemDetails.staffs[0].name}
                  {itemDetails.staffs.length > 1 && " and others"}
                </Typography>
              </Grid>
              <Grid item display="flex" alignContent="center">
                <PlaceIcon color="primary" />
                <Spacer position="left" />
                <Typography
                  width="100%"
                  maxWidth={isMobile ? "180px" : "240px"}
                  color="text.secondary"
                  sx={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    fontSize: theme.fonts.fontSizes.size14,
                  }}
                >
                  {itemDetails.business.name}
                </Typography>
              </Grid>
              {(itemDetails.booked || itemDetails.maxCapacity) && (
                <Grid item display="flex" alignContent="center" paddingTop="10px">
                  {itemDetails.booked && (
                    <Typography width="100%" textAlign="right" fontWeight="bold" color="primary">
                      Booked
                    </Typography>
                  )}
                  {!itemDetails.booked && itemDetails.maxCapacity && (
                    <Typography
                      width="100%"
                      textAlign="right"
                      fontWeight="bold"
                      sx={{ color: theme.palette.colors.ui.error }}
                    >
                      Fully Booked
                    </Typography>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </PaddedView>
    </Grid>
  );
}

UpcomingClassCard.defaultProps = {
  itemDetails: PropTypes.shape({
    id: null,
    date: "",
    durationMins: 0,
    startTime: "",
    startAt: "",
    maxCapacity: false,
    price: "",
    class: PropTypes.shape({
      name: "",
      category: PropTypes.shape({ label: "" }),
    }),
    staffs: PropTypes.arrayOf(
      PropTypes.shape({
        id: null,
        name: "",
        imagePath: "",
      }),
    ),
    business: PropTypes.shape({
      id: null,
      name: "",
      city: "",
      reviews: PropTypes.shape({
        averageRatings: 0,
      }),
      imagePath: "",
    }),
    booked: false,
  }),
};

UpcomingClassCard.propTypes = {
  itemDetails: PropTypes.shape({
    id: PropTypes.number,
    distanceKM: PropTypes.string,
    date: PropTypes.string,
    durationMins: PropTypes.number,
    startTime: PropTypes.string,
    startAt: PropTypes.string,
    maxCapacity: PropTypes.bool,
    price: PropTypes.string,
    class: PropTypes.shape({
      name: PropTypes.string,
      category: PropTypes.shape({ label: PropTypes.string }),
    }),
    staffs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        imagePath: PropTypes.string,
      }),
    ),
    business: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      city: PropTypes.string,
      reviews: PropTypes.shape({
        averageRatings: PropTypes.string,
      }),
      imagePath: PropTypes.string,
    }),
    booked: PropTypes.bool,
  }),
};

export default UpcomingClassCard;
