import { Grid, LinearProgress, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

function PackageDetailsCard({ itemDetails }) {
  const theme = useTheme();

  return (
    <Grid
      container
      sx={{
        backgroundColor: theme.palette.colors.bg.primary,
        borderRadius: `${theme.shape.borderRadius[2]}px`,
        cursor: "pointer",
      }}
    >
      <Grid item xs={4}>
        <img
          src={itemDetails.plan.imagePath}
          width="100%"
          height="100%"
          alt="subscription_img"
          style={{
            borderRadius: `${theme.shape.borderRadius[2]}px`,
            borderTopRightRadius: "0px",
            borderBottomRightRadius: "0px",
            objectFit: "cover",
          }}
        />
      </Grid>
      <Grid item xs={8} padding="15px">
        <Grid item display="grid" flexDirection="column" alignContent="space-between" height="100%">
          <Grid item>
            <Grid item>
              <Typography fontWeight="bold">{itemDetails.plan.title}</Typography>
            </Grid>
          </Grid>
          {itemDetails.redemptionExpiryDate && (
            <Grid item>
              <Grid item>
                <Typography fontSize={theme.fonts.fontSizes.size14}>
                  Redeem By:{" "}
                  <span style={{ color: theme.palette.colors.brand.primary, fontWeight: "bold" }}>
                    {itemDetails.redemptionExpiryDate}
                  </span>
                </Typography>
              </Grid>
            </Grid>
          )}

          <Grid item>
            <Grid item>
              <Typography>
                Code:{" "}
                <span style={{ color: theme.palette.colors.brand.primary, fontWeight: "bold" }}>
                  {itemDetails.code}
                </span>
              </Typography>
            </Grid>
            <Grid container item alignItems="center">
              <Grid item xs={10}>
                <LinearProgress
                  variant="determinate"
                  value={
                    (itemDetails.sessions.claimedCount / itemDetails.sessions.noOfSessions) * 100
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <Typography fontSize={theme.fonts.fontSizes.size14} textAlign="center">
                  {itemDetails.sessions.claimedCount}/{itemDetails.sessions.noOfSessions}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

PackageDetailsCard.propTypes = {
  itemDetails: PropTypes.shape({
    id: PropTypes.number,
    redemptionExpiryDate: PropTypes.string,
    code: PropTypes.string,
    plan: PropTypes.shape({
      title: PropTypes.string,
      imagePath: PropTypes.string,
    }),
    sessions: PropTypes.shape({
      claimedCount: PropTypes.number,
      noOfSessions: PropTypes.number,
    }),
    businesses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
    category: PropTypes.shape({
      type: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          label: PropTypes.string,
        }),
      ),
    }),
    paymentTransaction: PropTypes.shape({
      method: PropTypes.string,
      paidAt: PropTypes.string,
      total: PropTypes.string,
    }),
  }).isRequired,
};

export default PackageDetailsCard;
