import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq, postReq } from "../api.services";

export const payRequestedAppointment = createAsyncThunk("appointments/pay", async (payload) => {
  const { slotId, packageCode } = payload;

  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/appointments/pay`, {
    slotId,
    packageCode,
    platform: "web",
  });
  return response;
});

export const rejectAppointment = createAsyncThunk("appointments/reject", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/appointments/reject_request`,
    payload,
  );
  return response;
});

export const getAppointmentSchedule = createAsyncThunk(
  "appointments/schedules",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/appointments/schedule?date=${payload.date}&categoryType=${payload.categoryType}&packageId=${payload.packageId}&businessId=${payload.businessId}`,
    );
    return response;
  },
);

export const createAppointment = createAsyncThunk("appointments/create", async (payload) => {
  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/appointments`, payload);
  return response;
});

export const previewAppointment = createAsyncThunk("appointments/preview", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/appointments/preview`,
    payload,
  );
  return response;
});

const appointmentSlice = createSlice({
  name: "appointment",
  initialState: {
    payRequestedAppointmentObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    rejectAppointmentObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getAppointmentScheduleObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createAppointmentObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    previewAppointmentObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [payRequestedAppointment.pending]: (state) => {
      state.payRequestedAppointmentObj.status = "pending";
    },
    [payRequestedAppointment.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.payRequestedAppointmentObj.status = "succeeded";
      state.payRequestedAppointmentObj.data = data;
      state.payRequestedAppointmentObj.successMessage = message;
    },
    [payRequestedAppointment.rejected]: (state, action) => {
      const { message } = action.error;

      state.payRequestedAppointmentObj.status = "rejected";
      state.payRequestedAppointmentObj.errorMessage = message;
    },
    [rejectAppointment.pending]: (state) => {
      state.rejectAppointmentObj.status = "pending";
    },
    [rejectAppointment.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.rejectAppointmentObj.status = "succeeded";
      state.rejectAppointmentObj.data = data;
      state.rejectAppointmentObj.successMessage = message;
    },
    [rejectAppointment.rejected]: (state, action) => {
      const { message } = action.error;

      state.rejectAppointmentObj.status = "rejected";
      state.rejectAppointmentObj.errorMessage = message;
    },
    [getAppointmentSchedule.pending]: (state) => {
      state.getAppointmentScheduleObj.status = "pending";
    },
    [getAppointmentSchedule.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getAppointmentScheduleObj.status = "succeeded";
      state.getAppointmentScheduleObj.data = data;
      state.getAppointmentScheduleObj.successMessage = message;
    },
    [getAppointmentSchedule.rejected]: (state, action) => {
      const { message } = action.error;

      state.getAppointmentScheduleObj.status = "rejected";
      state.getAppointmentScheduleObj.errorMessage = message;
    },
    [createAppointment.pending]: (state) => {
      state.createAppointmentObj.status = "pending";
    },
    [createAppointment.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createAppointmentObj.status = "succeeded";
      state.createAppointmentObj.data = data;
      state.createAppointmentObj.successMessage = message;
    },
    [createAppointment.rejected]: (state, action) => {
      const { message } = action.error;

      state.createAppointmentObj.status = "rejected";
      state.createAppointmentObj.errorMessage = message;
    },
    [previewAppointment.pending]: (state) => {
      state.previewAppointmentObj.status = "pending";
    },
    [previewAppointment.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.previewAppointmentObj.status = "succeeded";
      state.previewAppointmentObj.data = data;
      state.previewAppointmentObj.successMessage = message;
    },
    [previewAppointment.rejected]: (state, action) => {
      const { message } = action.error;

      state.previewAppointmentObj.status = "rejected";
      state.previewAppointmentObj.errorMessage = message;
    },
  },
});

export default appointmentSlice.reducer;

// state
export const appointmentSelector = (state) => state.appointment;
