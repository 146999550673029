import React, { useEffect, useState } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";

function ResendButton({ handleSubmit }) {
  const theme = useTheme();
  const [countDown, setCountDown] = useState(60);

  useEffect(() => {
    let interval = 0;
    let timer = 0;
    if (countDown > 0) {
      timer = setTimeout(() => {
        interval = setInterval(() => {
          setCountDown((prev) => {
            if (prev === 1) clearInterval(interval);
            return prev - 1;
          });
        });
      }, 1000);
    }
    return () => {
      clearTimeout(timer);
      clearInterval(interval);
    };
  }, [countDown]);

  return (
    <Box
      sx={{
        "& .MuiButton-root": {
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          textTransform: "none",
          padding: 1,
          "&:hover": {
            backgroundColor: "transparent",
            borderColor: "transparent",
            boxShadow: "none",
          },
        },
      }}
    >
      <Button
        disabled={countDown > 0}
        variant="contained"
        onClick={() => {
          setCountDown(60);
          handleSubmit();
        }}
        sx={{
          backgroundColor: "transparent",
          boxShadow: "none",
          "&:disabled": { backgroundColor: "transparent" },
        }}
      >
        <Typography
          textAlign="center"
          sx={{
            color:
              countDown > 0
                ? theme.palette.colors.text.disabled
                : theme.palette.colors.brand.primary,
          }}
        >
          {countDown > 0 ? `Resend Code in ${countDown}` : "Resend Code"}
        </Typography>
      </Button>
    </Box>
  );
}

ResendButton.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default ResendButton;
