import { Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import CustomSkeleton from "../../../../components/utils/skeleton.component";

function PreviewLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container columnSpacing={2} rowSpacing={2}>
      <Grid container item xs={isMobile ? 12 : 7} flexDirection="column" rowSpacing={2}>
        <Grid item>
          <CustomSkeleton width={698} height={192} />
        </Grid>
        <Grid item>
          <CustomSkeleton width={698} height={192} />
        </Grid>
      </Grid>
      <Grid item xs={isMobile ? 12 : 5}>
        <CustomSkeleton width={494} height={333} />
      </Grid>
    </Grid>
  );
}

export default PreviewLoader;
