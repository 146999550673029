import { Grid, Typography } from "@mui/material";
import React from "react";
import CustomSkeleton from "../../../../components/utils/skeleton.component";
import Spacer from "../../../../components/utils/spacer.component";

function TransactionRowLoader() {
  return (
    <>
      <Grid item>
        <Typography>
          <CustomSkeleton />
        </Typography>
        <Spacer />
        <Typography>
          <CustomSkeleton width="150px" />
        </Typography>
      </Grid>
      <Grid item textAlign="right">
        <Typography>
          <CustomSkeleton />
        </Typography>
        <Spacer />
        <Typography>
          <CustomSkeleton />
        </Typography>
      </Grid>
    </>
  );
}

export default TransactionRowLoader;
