import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  Grid,
  IconButton,
  Link,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
} from "@mui/material";
import GoogleMapReact from "google-map-react";
import React from "react";
import { useSelector } from "react-redux";
import Spacer from "../../../components/utils/spacer.component";
import { eventSessionSelector } from "../../../services/event/session/event-session-slice.service";

const MarkerBox = styled(Grid)({
  width: "100px",
  height: "50px",
  flexDirection: "row",
});

const StyledIcon = styled(IconButton)({
  width: "30px",
  height: "30px",
  color: "red",
});

const StyledTypography = styled(Link)(({ theme }) => ({
  color: theme.palette.colors.brand.white,
  backgroundColor: theme.palette.colors.brand.secondary,
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  textAlign: "center",
  marginTop: "-10px",
  borderRadius: theme.shape.borderRadius[0],
  paddingLeft: "5px",
  paddingRight: "5px",
}));

const StyledLocationOnIcon = styled(LocationOnIcon)({
  width: "30px",
  height: "30px",
});

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size18,
  fontWeight: theme.fonts.fontWeights.bold,
}));

const StyledTableCell = styled(TableCell)({
  padding: "0px",
  border: "0px",
});

const StyledHeaderTableCell = styled(TableCell)({
  backgroundColor: "transparent",
  border: "0px",
  padding: "0px",
});

function Marker(mapProps) {
  const { text, lat, lng } = mapProps;
  return (
    <MarkerBox container>
      <Grid item xs={3}>
        <StyledIcon
          onClick={() =>
            window.open(`https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}`, "_blank")
          }
        >
          <StyledLocationOnIcon />
        </StyledIcon>
      </Grid>
      <Grid item xs={9}>
        <StyledTypography
          target="_blank"
          href={`https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}`}
        >
          {text}
        </StyledTypography>
      </Grid>
    </MarkerBox>
  );
}

function LocationSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { getEventSessionDetailsObj } = useSelector(eventSessionSelector);

  if (!isMobile) {
    return (
      <Grid item xs={12}>
        <Table stickyHeader>
          <TableHead sx={{ position: "sticky", top: theme.dimensions.headerHeight }}>
            <TableRow>
              <StyledHeaderTableCell sx={{ width: "40%" }}>
                <StyledTitle sx={{ height: "10px", fontSize: theme.fonts.fontSizes.size32 }}>
                  Location
                </StyledTitle>
              </StyledHeaderTableCell>
              <StyledHeaderTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ padding: "0px" }}>
              <StyledTableCell />
              <StyledTableCell>
                <div style={{ height: "60vh", width: "100%" }}>
                  <GoogleMapReact
                    defaultCenter={{
                      lat: parseFloat(getEventSessionDetailsObj.data.event.location.lat),
                      lng: parseFloat(getEventSessionDetailsObj.data.event.location.long),
                    }}
                    defaultZoom={17}
                  >
                    <Marker
                      lat={parseFloat(getEventSessionDetailsObj.data.event.location.lat)}
                      lng={parseFloat(getEventSessionDetailsObj.data.event.location.long)}
                      text={getEventSessionDetailsObj.data.event.location.name}
                    />
                  </GoogleMapReact>
                </div>
                <Spacer />

                {getEventSessionDetailsObj.data.event.location.address}
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Spacer size="xl" position="top" />
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <StyledTitle sx={{ fontSize: theme.fonts.fontSizes.size24 }}>Location</StyledTitle>
      <Spacer size="m" position="top" />
      <Box sx={{ width: "100%" }}>
        <div style={{ height: "40vh", width: "100%" }}>
          <GoogleMapReact
            defaultCenter={{
              lat: parseFloat(getEventSessionDetailsObj.data.event.location.lat),
              lng: parseFloat(getEventSessionDetailsObj.data.event.location.long),
            }}
            defaultZoom={17}
          >
            <Marker
              lat={parseFloat(getEventSessionDetailsObj.data.event.location.lat)}
              lng={parseFloat(getEventSessionDetailsObj.data.event.location.long)}
              text={getEventSessionDetailsObj.data.event.location.name}
            />
          </GoogleMapReact>
        </div>
        <Spacer />
        <Typography textAlign="justify">
          {getEventSessionDetailsObj.data.event.location.address}
        </Typography>
      </Box>
    </Grid>
  );
}

export default LocationSection;
