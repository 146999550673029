import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq, putReq } from "../../api.services";

export const cancelPackageBill = createAsyncThunk(
  "packages/bills/{billId}/cancel",
  async (payload) => {
    const { billId } = payload;
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/packages/bills/${billId}/cancel`,
    );
    return response;
  },
);

export const getPackageBillViaRemoteBillId = createAsyncThunk(
  "packages/bills/{billId}",
  async (payload) => {
    const { remoteBillId } = payload;
    const response = getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/packages/bills/${remoteBillId}`,
      payload,
    );
    return response;
  },
);

const packageBillSlice = createSlice({
  name: "packageBill",
  initialState: {
    cancelPackageBillObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getPackageBillViaRemoteBillIdObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [cancelPackageBill.pending]: (state) => {
      state.cancelPackageBillObj.status = "pending";
    },
    [cancelPackageBill.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.cancelPackageBillObj.status = "succeeded";
      state.cancelPackageBillObj.data = data;
      state.cancelPackageBillObj.successMessage = message;
    },
    [cancelPackageBill.rejected]: (state, action) => {
      const { message } = action.error;

      state.cancelPackageBillObj.status = "failed";
      state.cancelPackageBillObj.errorMessage = message;
    },
    [getPackageBillViaRemoteBillId.pending]: (state) => {
      state.getPackageBillViaRemoteBillIdObj.status = "pending";
    },
    [getPackageBillViaRemoteBillId.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getPackageBillViaRemoteBillIdObj.status = "succeeded";
      state.getPackageBillViaRemoteBillIdObj.data = data;
      state.getPackageBillViaRemoteBillIdObj.successMessage = message;
    },
    [getPackageBillViaRemoteBillId.rejected]: (state, action) => {
      const { message } = action.error;

      state.getPackageBillViaRemoteBillIdObj.status = "failed";
      state.getPackageBillViaRemoteBillIdObj.errorMessage = message;
    },
  },
});

export default packageBillSlice.reducer;

// state
export const packageBillSelector = (state) => state.packageBill;
