import { Box, Grid, Modal, Pagination, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import PaddedView from "../../../components/utils/padded-view.component";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import Spacer from "../../../components/utils/spacer.component";
import TopTitleBox from "../../../components/utils/top-title-box.component";
import {
  getHighlights,
  highlightSelector,
} from "../../../services/highlight/highlight-slice.service";
import HighlightCard from "./highlight-card.component";

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "absolute",
  top: !isMobile && "50%",
  left: !isMobile && "50%",
  transform: !isMobile && "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.white,
  borderRadius: !isMobile && theme.shape.borderRadius[2],
  boxShadow: 24,
  maxWidth: !isMobile && "550px",
  width: isMobile ? "100%" : "90%",
  height: isMobile ? "100%" : "600px",
  outline: "none",
  overflowY: isMobile ? "scroll" : "visible",
}));

function HighlightModal({ showModal, setShowModal }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { getHighlightsObj } = useSelector(highlightSelector);
  const [page, setPage] = useState(1);
  const createSnackBar = useContext(SnackbarContext);
  const dispatch = useDispatch();

  const renderLoader = () => {
    const loaders = [];
    for (let i = 0; i < 3; i += 1) {
      loaders.push(
        <Grid item xs={12} key={i} position="relative">
          <CustomSkeleton width="100%" height="150px" />
        </Grid>,
      );
    }
    return loaders;
  };

  const onPageChange = (event, newPage) => {
    setPage(newPage);
    dispatch(getHighlights({ page: newPage })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <ModalBox isMobile={isMobile}>
        <PaddedView multiples={2}>
          <Box
            sx={{
              overflowY: !isMobile && "auto",
              height: !isMobile && "530px",
            }}
          >
            <TopTitleBox title="Highlights" requireCloseButton={true} setShowModal={setShowModal} />
            <Spacer position="top" size="l" />
            <Grid container item xs={12} justifyContent="center" rowSpacing={3} padding="10px">
              {getHighlightsObj.status === "succeeded" ? (
                <Grid container item columnSpacing={1} rowSpacing={1.5}>
                  {getHighlightsObj.data.items.map((item) => (
                    <Grid item xs={12} key={item.id} position="relative">
                      <HighlightCard itemDetails={item} />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Grid container item columnSpacing={1} rowSpacing={1.5}>
                  {renderLoader()}
                </Grid>
              )}
            </Grid>
          </Box>
          {getHighlightsObj.status === "succeeded" && getHighlightsObj.data.items.length > 0 && (
            <>
              <Spacer />
              <Grid container justifyContent="center">
                <Pagination
                  page={page}
                  onChange={onPageChange}
                  count={getHighlightsObj.data.pagination.totalPages}
                  variant="outlined"
                />
              </Grid>
            </>
          )}
        </PaddedView>
      </ModalBox>
    </Modal>
  );
}

HighlightModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default HighlightModal;
