import { createTheme } from "@mui/material/styles";
import { colors } from "./colors";
import { darkColors } from "./dark-colors";
import { borderSizes } from "./borders";
import { fontSizes, fontWeights, fontFamily } from "./fonts";
import {
  PCMaxWidth,
  heightWithoutAppBar,
  ScreenPaddingX,
  headerHeight,
  PCExperiencesPadding,
  MobileExperiencesPadding,
} from "./dimensions";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1A7B64",
    },
    white: {
      main: "#ffffff",
    },
    black: {
      main: "#000000",
    },
    grey: {
      main: "#6B6B6B",
    },
    red: {
      main: "#FF2525",
    },
    colors,
    darkColors,
  },
  shape: {
    borderRadius: borderSizes,
  },
  mixins: {
    toolbar: {
      minHeight: 76,
      maxHeight: 80,
    },
  },
  fonts: {
    fontSizes,
    fontWeights,
  },
  dimensions: {
    PCMaxWidth,
    heightWithoutAppBar,
    ScreenPaddingX,
    headerHeight,
    MobileExperiencesPadding,
    PCExperiencesPadding,
  },
  typography: {
    fontFamily: fontFamily.inter,
  },
});

export default theme;
