import {
  Box,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import HTMLRenderer from "react-html-renderer";
import { useSelector } from "react-redux";
import Spacer from "../../../components/utils/spacer.component";
import { eventSessionSelector } from "../../../services/event/session/event-session-slice.service";

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  fontSize: theme.fonts.fontSizes.size18,
  fontWeight: theme.fonts.fontWeights.bold,
}));

const StyledTableCell = styled(TableCell)({
  padding: "0px",
  border: "0px",
});

const StyledHeaderTableCell = styled(TableCell)({
  backgroundColor: "transparent",
  border: "0px",
  padding: "0px",
});

function Highlights() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("990"));
  const { getEventSessionDetailsObj } = useSelector(eventSessionSelector);

  if (!isMobile) {
    return (
      <Grid item xs={12}>
        <Table stickyHeader>
          <TableHead sx={{ position: "sticky", top: theme.dimensions.headerHeight }}>
            <TableRow>
              <StyledHeaderTableCell sx={{ width: "40%" }}>
                <StyledTitle sx={{ height: "10px", fontSize: theme.fonts.fontSizes.size32 }}>
                  Highlights
                </StyledTitle>
              </StyledHeaderTableCell>
              <StyledHeaderTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ padding: "0px" }}>
              <StyledTableCell />
              <StyledTableCell>
                <HTMLRenderer html={getEventSessionDetailsObj.data.event.highlights} />
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Spacer size="xl" position="top" />
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <StyledTitle sx={{ fontSize: theme.fonts.fontSizes.size24 }}>Highlights</StyledTitle>
      <Spacer size="m" position="top" />
      <Box sx={{ width: "95%" }}>
        <HTMLRenderer html={getEventSessionDetailsObj.data.event.highlights} />
      </Box>
    </Grid>
  );
}

export default Highlights;
