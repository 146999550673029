export const PCMaxWidth = "1240px";

export const heightWithoutAppBar = "89vh";

export const ScreenPaddingX = "15px";

export const PCExperiencesPadding = "15px";
export const MobileExperiencesPadding = "5px";

export const headerHeight = "80px";
