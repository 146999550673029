import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { isLogin } from "../../infrastructure/utils";
import { getReq, putReq } from "../api.services";

export const getReviews = createAsyncThunk("reviews", async (payload) => {
  const response = await getReq(
    `${
      isLogin() ? process.env.REACT_APP_API_PRIVATE_V2 : process.env.REACT_APP_API_PUBLIC_V1
    }/reviews/${payload}`,
  );
  return response;
});

export const getPendingReviews = createAsyncThunk("reviews/pending", async () => {
  const response = await getReq(`${process.env.REACT_APP_API_PRIVATE_V2}/reviews/pending`);
  return response;
});

export const updateReview = createAsyncThunk("reviews/{review_id}/update", async (payload) => {
  const { rating, comment, dismissed, reviewId } = payload;
  const response = await putReq(`${process.env.REACT_APP_API_PRIVATE_V2}/reviews/${reviewId}`, {
    rating,
    comment,
    dismissed,
  });
  return response;
});

const reviewSlice = createSlice({
  name: "review",
  initialState: {
    getReviewsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getPendingReviewsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateReviewObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getReviews.pending]: (state) => {
      state.getReviewsObj.status = "pending";
    },
    [getReviews.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getReviewsObj.status = "succeeded";
      state.getReviewsObj.data = data;
      state.getReviewsObj.successMessage = message;
    },
    [getReviews.rejected]: (state, action) => {
      const { message } = action.error;

      state.getReviewsObj.status = "failed";
      state.getReviewsObj.errorMessage = message;
    },
    [getPendingReviews.pending]: (state) => {
      state.getPendingReviewsObj.status = "pending";
    },
    [getPendingReviews.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getPendingReviewsObj.status = "succeeded";
      state.getPendingReviewsObj.data = data;
      state.getPendingReviewsObj.successMessage = message;
    },
    [getPendingReviews.rejected]: (state, action) => {
      const { message } = action.error;

      state.getPendingReviewsObj.status = "failed";
      state.getPendingReviewsObj.errorMessage = message;
    },
    [updateReview.pending]: (state) => {
      state.updateReviewObj.status = "pending";
    },
    [updateReview.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateReviewObj.status = "succeeded";
      state.updateReviewObj.data = data;
      state.getPendingReviewsObj.data = data.pending;
      state.updateReviewObj.successMessage = message;
    },
    [updateReview.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateReviewObj.status = "failed";
      state.updateReviewObj.errorMessage = message;
    },
  },
});

export default reviewSlice.reducer;

// state
export const reviewSelector = (state) => state.review;
