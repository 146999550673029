import {
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";

import KatchLogo from "../../assets/images/katch_logo";
import appstore from "../../assets/images/appstore.png";
import playstore from "../../assets/images/playstore.png";
import huaweiAppGallery from "../../assets/images/huawei-app-gallery.png";

import routes from "../../components/navigation/routes";
import Spacer from "../../components/utils/spacer.component";
import BottomBanner from "./components/bottom-banner.component";
import MerchantPricing from "./components/merchant-pricing.component";

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.white,
  fontSize: theme.fonts.fontSizes.size16,
  fontWeight: theme.fonts.fontWeights.bold,
}));

const MainContainer = styled(Box)(() => ({
  backgroundImage: "linear-gradient(#313131, #414040)",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  width: "100%",
}));

const StyledLink = styled(Link)`
  text-decoration: none;
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
  &:hover {
    color: white;
    text-decoration: underline;
  }
`;

const StyledListBox = styled(Box)({
  paddingTop: "10px",
  display: "flex",
  flexDirection: "column",
  "& .MuiButton-root": {
    paddingLeft: 0,
    textTransform: "none",
    justifyContent: "flex-start",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
});

const StyledText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.white,
  fontSize: theme.fonts.fontSizes.size14,
  textAlign: "start",
  "&:hover": {
    textDecoration: "underline",
  },
}));

function Footer() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();

  return (
    <>
      {location.pathname === routes.HOME && <BottomBanner />}
      {location.pathname === routes.HOME && <MerchantPricing />}

      <MainContainer paddingTop="30px">
        <Box sx={{ maxWidth: theme.dimensions.PCMaxWidth, width: "100%" }}>
          <Grid container spacing={2} paddingX={theme.dimensions.ScreenPaddingX}>
            <Grid item xs={isMobile ? 12 : 2.4}>
              <KatchLogo color="white" />
              <StyledListBox>
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    window.open(
                      `https://play.google.com/store/apps/details?id=com.engageapp.mobile&hl=en`,
                      "_blank",
                    );
                  }}
                >
                  <img src={playstore} alt="playstore_logo" width="150px" />
                </Box>
                <Spacer position="bottom" />
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    window.open(`https://apps.apple.com/my/app/katch/id1606341748`, "_blank");
                  }}
                >
                  <img src={appstore} alt="appstore_logo" width="150px" />
                </Box>
                <Spacer position="bottom" />
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    window.open(`https://appgallery.huawei.com/app/C106856797`, "_blank");
                  }}
                >
                  <img src={huaweiAppGallery} alt="huawei_logo" width="150px" />
                </Box>
              </StyledListBox>
              <Spacer size="xl" position="bottom" />
            </Grid>
            <Grid item xs={isMobile ? 12 : 2.4}>
              <StyledTitle variant="h5">KATCH</StyledTitle>
              <StyledListBox>
                <StyledLink href={routes.HOME}>
                  <Button disableRipple>
                    <StyledText>Home</StyledText>
                  </Button>
                </StyledLink>
                <StyledLink>
                  <Button disableRipple>
                    <StyledText>About</StyledText>
                  </Button>
                </StyledLink>
                <StyledLink>
                  <Button disableRipple>
                    <StyledText>Become a partner</StyledText>
                  </Button>
                </StyledLink>
                <StyledLink href={routes.PRIVACYPOLICY}>
                  <Button disableRipple>
                    <StyledText>Privacy Policy</StyledText>
                  </Button>
                </StyledLink>
              </StyledListBox>
            </Grid>
            <Grid item xs={isMobile ? 12 : 2.4}>
              <StyledTitle variant="h5">EXPLORE</StyledTitle>
              <StyledListBox>
                <StyledLink href={routes.EXPLORE}>
                  <Button disableRipple>
                    <StyledText>Find a business</StyledText>
                  </Button>
                </StyledLink>
                <StyledLink href={`${routes.EXPLORE}?searchFor=classes`}>
                  <Button disableRipple>
                    <StyledText>Find a session</StyledText>
                  </Button>
                </StyledLink>
              </StyledListBox>
            </Grid>
            <Grid item xs={isMobile ? 12 : 2.4}>
              <StyledTitle variant="h5">SUPPORT</StyledTitle>
              <StyledListBox>
                <StyledLink>
                  <Button disableRipple>
                    <StyledText>FAQ</StyledText>
                  </Button>
                </StyledLink>
                <StyledLink href={routes.CONTACT}>
                  <Button disableRipple>
                    <StyledText>Contact</StyledText>
                  </Button>
                </StyledLink>
                <StyledLink href={routes.TERMS}>
                  <Button>
                    <StyledText>Terms</StyledText>
                  </Button>
                </StyledLink>
              </StyledListBox>
            </Grid>
            <Grid item xs={isMobile ? 12 : 2.4}>
              <StyledTitle variant="h5">FOLLOW US</StyledTitle>
              <Grid container>
                <Grid item>
                  <IconButton
                    sx={{ paddingLeft: 0 }}
                    onClick={() => window.open(`https://www.facebook.com/katchmalaysia`, "_blank")}
                  >
                    <FacebookOutlinedIcon color="white" sx={{ fontSize: 35 }} />
                  </IconButton>
                  <IconButton
                    onClick={() => window.open(`https://www.instagram.com/katch_my/`, "_blank")}
                  >
                    <InstagramIcon color="white" sx={{ fontSize: 35 }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} textAlign="center" paddingBottom="20px">
              <Typography fontSize="12px" sx={{ color: "#ffffff" }}>
                Copyright &copy; {new Date().getFullYear()} Katch
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </MainContainer>
    </>
  );
}

export default Footer;
