import { Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import HorizontalDivider from "../../../../components/utils/horizontal-divider.component";
import CustomSkeleton from "../../../../components/utils/skeleton.component";
import Spacer from "../../../../components/utils/spacer.component";

function FitnessClassSessionScreenLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Grid container flexDirection="column" rowSpacing={1}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid item>
              <CustomSkeleton height="21px" />
            </Grid>
            <Spacer />
            <Grid item>
              <CustomSkeleton height="32px" width="350px" />
            </Grid>
          </Grid>
          {!isMobile && (
            <Grid item xs={2}>
              <CustomSkeleton height="39px" width="100%" />
            </Grid>
          )}
        </Grid>
        <Spacer size="m" />
        <Grid item>
          <CustomSkeleton width="280px" />
        </Grid>
        <Grid item>
          <CustomSkeleton width="250px" />
        </Grid>
        <Spacer size="m" />
        <Grid item container rowSpacing={1}>
          <Grid item xs={12}>
            <CustomSkeleton />
          </Grid>
          <Grid container item columnSpacing={2}>
            <Grid item>
              <Grid container alignItems="center">
                <CustomSkeleton variant="circular" width={40} height={40} />
                <Spacer position="left" />
                <CustomSkeleton width={150} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {isMobile && (
          <>
            <Spacer size="m" />
            <Grid item xs={12}>
              <CustomSkeleton width="100%" height={33} />
            </Grid>
          </>
        )}
      </Grid>
      <HorizontalDivider spacerSize="l" />
      <Grid item>
        <Grid item>
          <CustomSkeleton height="32px" />
        </Grid>
        <Spacer size="m" />
        <Grid item>
          <CustomSkeleton width="100%" />
          <Spacer />
          <CustomSkeleton width="100%" />
        </Grid>
      </Grid>
      <HorizontalDivider spacerSize="l" />
      <Grid container flexDirection="column" rowSpacing={2}>
        <Grid item>
          <CustomSkeleton height="32px" width={250} />
        </Grid>
        <Grid item container columnSpacing={2}>
          <Grid item xs={isMobile ? 12 : 2}>
            <CustomSkeleton width="100%" height={isMobile ? "250px" : "150px"} />
          </Grid>
          <Grid item xs={isMobile ? 12 : 8} alignItems="center" display="flex">
            <Grid
              item
              display="grid"
              flexDirection="column"
              alignContent="space-between"
              height="90%"
            >
              <Grid item>
                <Grid item>
                  <CustomSkeleton height="32px" width={280} />
                </Grid>
                <Spacer />
                <Grid item container>
                  <CustomSkeleton width={230} />
                </Grid>
              </Grid>
              {isMobile && <Spacer size="m" />}

              <Grid item display="flex" alignContent="center">
                <CustomSkeleton width={180} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {isMobile && <Spacer size="m" />}
        <Grid item>
          <CustomSkeleton height="40vh" width="100%" />
        </Grid>
        <Grid item>
          <CustomSkeleton width="100%" />
        </Grid>
      </Grid>
    </>
  );
}

export default FitnessClassSessionScreenLoader;
