import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import PlaceIcon from "@mui/icons-material/Place";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";

function EventCard({ itemDetails }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Grid
      item
      sx={{
        backgroundColor: theme.palette.colors.bg.primary,
        borderRadius: `${theme.shape.borderRadius[2]}px`,
        objectFit: "cover",
      }}
    >
      <PaddedView multiples={2}>
        <Grid container columnSpacing={2} alignContent="center">
          <Grid item alignSelf="center" paddingY="5px" alignItems="center" display="flex">
            <img
              src={itemDetails.session.event.images[0].imagePath}
              alt="eventImage"
              width={isMobile ? 100 : 200}
              height={isMobile ? 100 : 150}
              style={{ borderRadius: `${theme.shape.borderRadius[2]}px`, objectFit: "cover" }}
            />
          </Grid>
          <Grid
            item
            display="grid"
            flexDirection="column"
            alignContent="space-between"
            marginY="10px"
          >
            <Grid item>
              <Grid item>
                <Typography
                  fontWeight="bold"
                  maxWidth={width < 450 ? "180px" : "none"}
                  sx={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    textTransform: "capitalize",
                  }}
                >
                  {itemDetails.session.title
                    ? itemDetails.session.title
                    : itemDetails.session.event.title}
                </Typography>
              </Grid>
              <Spacer />
              <Grid item display="flex" alignContent="center">
                <CalendarMonthOutlinedIcon color="primary" />
                <Spacer position="left" />
                <Typography>{itemDetails.session.date}</Typography>
              </Grid>
              <Grid item display="flex" alignContent="center">
                <AccessTimeOutlinedIcon color="primary" />
                <Spacer position="left" />
                <Typography>{itemDetails.session.time}</Typography>
              </Grid>
              <Grid item display="flex" alignContent="center">
                <PlaceIcon color="primary" />
                <Spacer position="left" />
                <Typography
                  maxWidth={width < 450 ? "160px" : "none"}
                  sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
                >
                  {itemDetails.session.event.location.name}
                </Typography>
              </Grid>
            </Grid>
            <Grid item alignContent="center">
              {!itemDetails.session.multipleCategories && (
                <>
                  <Spacer />
                  {itemDetails.session.memberPrice && (
                    <Grid item display="flex" alignContent="center">
                      <Typography fontWeight="bold" color="primary">
                        RM {itemDetails.session.memberPrice} (MEMBER)
                      </Typography>
                    </Grid>
                  )}

                  <Grid item display="flex" alignContent="center">
                    <Typography
                      fontWeight="bold"
                      color={itemDetails.session.memberPrice ? "grey" : "primary"}
                    >
                      RM {itemDetails.session.price}{" "}
                      {itemDetails.session.memberPrice && "(NON-MEMBER)"}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        {itemDetails.sessionCategories.length > 0 && (
          <>
            <Spacer size="l" />
            <Grid item>
              <Grid item>
                <Typography fontWeight="bold">Selected Categories</Typography>
              </Grid>
              <Spacer />
              {itemDetails.sessionCategories.map((item) => (
                <Grid item key={item.id}>
                  <Grid item>
                    <Typography fontWeight={600}>{item.label}</Typography>
                  </Grid>
                  <Grid item container justifyContent="space-between">
                    <Grid item>
                      <Grid item>
                        <Typography sx={{ color: theme.palette.colors.text.tertiary }}>
                          {item.date}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography sx={{ color: theme.palette.colors.text.tertiary }}>
                          {item.time}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      {isMobile && <Spacer />}
                      {item.memberPrice && (
                        <Grid item display="flex" alignContent="center">
                          <Typography fontWeight="bold" color="primary">
                            RM {item.memberPrice} (MEMBER)
                          </Typography>
                        </Grid>
                      )}

                      <Grid item display="flex" alignContent="center">
                        <Typography fontWeight="bold" color={item.memberPrice ? "grey" : "primary"}>
                          RM {item.price} {item.memberPrice && "(NON-MEMBER)"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Spacer />
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </PaddedView>
    </Grid>
  );
}

EventCard.defaultProps = {
  itemDetails: PropTypes.shape({
    session: PropTypes.shape({
      id: 0,
      booked: false,
      title: "",
      price: "",
      memberPrice: "",
      multipleCategories: false,
      unlimited: false,
      date: "",
      time: "",
      maxCapacity: false,
      event: PropTypes.shape({
        id: 0,
        title: "",
        highlights: "",
        summary: "",
        location: PropTypes.shape({
          name: "",
          address: "",
          lat: "",
          long: "",
        }),
        images: [],
      }),
    }),
    sessionCategories: PropTypes.arrayOf(
      PropTypes.shape({
        id: 0,
        label: "",
        price: "",
        date: "",
        time: "",
      }),
    ),
  }),
};

EventCard.propTypes = {
  itemDetails: PropTypes.shape({
    session: PropTypes.shape({
      id: PropTypes.number,
      booked: PropTypes.bool,
      title: PropTypes.string,
      price: PropTypes.string,
      memberPrice: PropTypes.string,
      multipleCategories: PropTypes.bool,
      unlimited: PropTypes.bool,
      date: PropTypes.string,
      time: PropTypes.string,
      maxCapacity: PropTypes.bool,
      event: PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        highlights: PropTypes.string,
        summary: PropTypes.string,
        location: PropTypes.shape({
          name: PropTypes.string,
          address: PropTypes.string,
          lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          long: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
        images: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            imagePath: PropTypes.string,
          }),
        ),
      }),
    }),
    sessionCategories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        label: PropTypes.string,
        price: PropTypes.string,
        date: PropTypes.string,
        time: PropTypes.string,
      }),
    ),
  }),
};

export default EventCard;
