import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {
  Box,
  Card,
  ClickAwayListener,
  Grid,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import CustomButton from "../../../components/button/custom-button.component";
import FormAddressAutoComplete from "../../../components/forms/form-address-auto-complete.component";
import FormDebounceFieldText from "../../../components/forms/form-debounce-field-text.component";
import Form from "../../../components/forms/form.component";
import routes from "../../../components/navigation/routes";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import {
  getPopularSearches,
  getRecentSearches,
} from "../../../services/search/search-slice.service";
import { isLogin } from "../../utils";
import PhoneViewSearchBarModal from "./phone-view-search-bars-modal.component";
import SearchNameDropDownCard from "./search-name-drop-down-card.component";

const AppBarTitle = styled(Typography)(() => ({
  fontWeight: "bold",
  fontSize: "20px",
}));

const SearchBarDropDownCard = styled(Card)(({ theme }) => ({
  position: "absolute",
  display: "flex",
  borderRadius: "0 0 8px 8px",
  border: "2px solid",
  borderColor: theme.palette.colors.brand.primary,
  borderTop: "0",
  width: "100%",
  zIndex: 99,
}));

function SearchBars() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const SearchFormRef = useRef();
  const navigate = useNavigate();
  const [nameSearchBarFocus, setNameSearchBarFocus] = useState(false);
  const [showSearchNameDropDownCard, setShowSearchNameDropDownCard] = useState(false);
  const [showPhoneViewSearchBarModal, setShowPhoneViewSearchBarModal] = useState(false);

  const handleSearch = (values) => {
    const params = `?${values.q ? "q=".concat(values.q) : ""}${
      values.location ? "&location=".concat(values.location) : ""
    }${values.lat ? "&lat=".concat(values.lat) : ""}${
      values.long ? "&long=".concat(values.long) : ""
    }`;
    navigate(`${routes.EXPLORE}${params}`);
  };

  useEffect(() => {
    if (isLogin()) {
      dispatch(getRecentSearches());
    }
    dispatch(getPopularSearches());
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <AppBarTitle>Find your best local fitness, wellness, and health services</AppBarTitle>
      </Grid>
      <Grid container item xs={12} alignItems="center">
        <Form
          innerRef={SearchFormRef}
          initialValues={{ location: "", q: "" }}
          validationSchema={Yup.object().shape({
            location: Yup.string().nullable().label("Location"),
            q: Yup.string().nullable().label("Name"),
          })}
          onSubmit={handleSearch}
        >
          <PhoneViewSearchBarModal
            showModal={showPhoneViewSearchBarModal}
            setShowModal={setShowPhoneViewSearchBarModal}
          />
          <Grid item xs={isMobile ? 12 : 4}>
            <Box sx={{ position: "relative" }}>
              <ClickAwayListener onClickAway={() => setShowSearchNameDropDownCard(false)}>
                <div>
                  <FormDebounceFieldText
                    submitOnChange={false}
                    name="q"
                    placeholder="Search for anything"
                    icon={<SearchOutlinedIcon color="primary" />}
                    focus={isMobile ? null : nameSearchBarFocus || showSearchNameDropDownCard}
                    setFocus={isMobile ? null : setNameSearchBarFocus}
                    onClick={() => {
                      setShowSearchNameDropDownCard(true);
                      if (isMobile) {
                        setShowPhoneViewSearchBarModal(true);
                      }
                    }}
                    readOnly={isMobile}
                  />
                  {!isMobile && (
                    <SearchBarDropDownCard
                      sx={{
                        visibility: showSearchNameDropDownCard ? "visible" : "hidden",
                        opacity: showSearchNameDropDownCard ? "1" : "0",
                        transition: "all 0.4s",
                      }}
                    >
                      <Box sx={{ maxHeight: "400px", overflowY: "auto", width: "100%" }}>
                        <PaddedView multiples={2}>
                          <SearchNameDropDownCard />
                        </PaddedView>
                      </Box>
                    </SearchBarDropDownCard>
                  )}
                </div>
              </ClickAwayListener>
            </Box>
          </Grid>
          {!isMobile && (
            <>
              <Spacer position="left" />
              <Grid item xs={4}>
                <FormAddressAutoComplete name="location" />
              </Grid>
            </>
          )}

          {!isMobile && (
            <>
              <Spacer position="left" />
              <Grid item xs={2}>
                <CustomButton
                  variant="contained"
                  textColor="primary"
                  buttonColor="white"
                  fontSize={theme.fonts.fontSizes.size13}
                  width={isMobile ? "100%" : "60%"}
                  onClick={() => SearchFormRef.current.handleSubmit()}
                >
                  Search
                </CustomButton>
              </Grid>
            </>
          )}
        </Form>
      </Grid>
    </>
  );
}

export default SearchBars;
