import { setAxiosToken } from "../../services/api.services";

export const isLogin = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const authToken = searchParams.get("authToken");

  if (authToken) {
    setAxiosToken(authToken);
    localStorage.setItem("katchUserAuthToken", authToken);
    window.history.replaceState({}, "", window.location.href.split("?")[0]);
  }

  const storedAuthToken = localStorage.getItem("katchUserAuthToken");
  if (storedAuthToken) {
    setAxiosToken(storedAuthToken);
    return true;
  }
  return false;
};

export const getExpireAt = () => localStorage.getItem("katchUserExpireAt");

export const getAPI = () => {
  const storedAuthToken = localStorage.getItem("katchUserAuthToken");
  if (storedAuthToken) {
    return process.env.REACT_APP_API_PRIVATE_V1;
  }
  return process.env.REACT_APP_API_PUBLIC_V1;
};

export const getAPIV2 = () => {
  const storedAuthToken = localStorage.getItem("katchUserAuthToken");
  if (storedAuthToken) {
    return process.env.REACT_APP_API_PRIVATE_V2;
  }
  return process.env.REACT_APP_API_PUBLIC_V2;
};
