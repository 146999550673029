import React, { useContext, useEffect } from "react";
import { Grid, Typography, Box, useTheme } from "@mui/material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  getUpcomingEvents,
  searchEventSelector,
} from "../../../services/search/event/search-event-slice.service";

import routes from "../../../components/navigation/routes";

import { SnackbarContext } from "../../../components/notification/snackbar.context";

import UpcomingEventCard from "./upcoming-event-card.component";

function UpcomingEventSection() {
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const { getUpcomingEventsObj } = useSelector(searchEventSelector);

  const handleNavigate = (item) =>
    navigate(`${routes.EXPLORE}?searchFor=event`, { state: { date: item.date } });

  useEffect(() => {
    dispatch(getUpcomingEvents({ lat: 3.000031, long: 101.5330035, page: 1 })).then(
      ({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  }, []);

  return (
    <Box sx={{ marginY: "15px" }}>
      {getUpcomingEventsObj.status === "succeeded" && getUpcomingEventsObj.data.length > 0 && (
        <Grid container>
          <Grid item xs={12}>
            <Typography fontSize={theme.fonts.fontSizes.size24} fontWeight="bold">
              Upcoming Events
            </Typography>
          </Grid>

          <Grid item xs={12}>
            {getUpcomingEventsObj.data.map((event) => (
              <Box
                key={`${event.date}`}
                onClick={() => handleNavigate(event)}
                sx={{
                  cursor: "pointer",
                }}
              >
                <Box marginY="15px">
                  <Typography
                    fontWeight="bold"
                    variant="h6"
                    color={theme.palette.colors.text.secondary}
                  >
                    {moment(new Date(event.date)).format("DD MMM YYYY")}
                  </Typography>
                </Box>
                <Box>
                  <Grid container spacing={2}>
                    {event.sessions.map((session) => (
                      <Grid item key={`${session.id}-${session.title}`} sm={12} md={4}>
                        <UpcomingEventCard itemDetails={session} />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            ))}
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default UpcomingEventSection;
