import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq } from "../../api.services";

export const getPackageAvailableFitnessClassSessions = createAsyncThunk(
  "packages/sessions/available?startAt&endAt&businessId&packageCode&categoryType&categoriesId&page",
  async (payload) => {
    const { startAt, endAt, businessesId, packageCode, categoryType, categoriesId, page } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/packages/sessions/available?startAt=${startAt}&endAt=${endAt}&businessesId=${businessesId}&packageCode=${packageCode}&categoryType=${categoryType}&categoriesId=${categoriesId}&page=${page}`,
    );
    return response;
  },
);

const packageFitnessSessionSlice = createSlice({
  name: "packageFitnessSession",
  initialState: {
    reduxStoreFitnessClassSessions: [],
    reduxStorePayload: {
      startAt: null,
      endAt: null,
      businessesId: null,
      packageCode: null,
      categoryType: "fitness",
      categoriesId: null,
      page: 1,
    },
    getPackageAvailableFitnessClassSessionsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},

  extraReducers: {
    [getPackageAvailableFitnessClassSessions.pending]: (state) => {
      state.getPackageAvailableFitnessClassSessionsObj.status = "pending";
    },
    [getPackageAvailableFitnessClassSessions.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getPackageAvailableFitnessClassSessionsObj.status = "succeeded";
      state.getPackageAvailableFitnessClassSessionsObj.data = data;
      state.getPackageAvailableFitnessClassSessionsObj.successMessage = message;
    },
    [getPackageAvailableFitnessClassSessions.rejected]: (state, action) => {
      const { message } = action.error;

      state.getPackageAvailableFitnessClassSessionsObj.status = "failed";
      state.getPackageAvailableFitnessClassSessionsObj.errorMessage = message;
    },
  },
});

export default packageFitnessSessionSlice.reducer;

// state
export const packageFitnessSessionSelector = (state) => state.packageFitnessSession;
