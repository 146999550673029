import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  Box,
  Card,
  ClickAwayListener,
  Grid,
  Modal,
  Select,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import CustomButton from "../../../components/button/custom-button.component";
import TimeFilterContent from "./time-filter-content.component";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  backgroundColor: theme.palette.colors.brand.white,
  boxShadow: 24,
  width: "100%",
  height: "100%",
  paddingBottom: "100px",
}));

function TimeFilter() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [showTimeFilterContent, setShowTimeFilterContent] = useState(false);
  const { setFieldValue, values } = useFormikContext();
  const [showPhoneViewTimeFilter, setShowPhoneViewTimeFilter] = useState(false);

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (showTimeFilterContent) {
          setFieldValue("selectedStartTime", values.tempSelectedStartTime);
          setFieldValue("selectedTimeLabel", values.tempSelectedTimeLabel);
          setFieldValue("selectedTimePeriod", values.tempSelectedTimePeriod);
          setShowTimeFilterContent(false);
        }
      }}
    >
      <div>
        <Select
          IconComponent={showTimeFilterContent ? ArrowDropUpIcon : ArrowDropDownIcon}
          onClick={() => {
            if (isMobile) {
              setShowPhoneViewTimeFilter(true);
            } else {
              if (showTimeFilterContent) {
                setFieldValue("selectedStartTime", values.tempSelectedStartTime);
                setFieldValue("selectedTimeLabel", values.tempSelectedTimeLabel);
                setFieldValue("selectedTimePeriod", values.tempSelectedTimePeriod);
              }

              setShowTimeFilterContent(!showTimeFilterContent);
            }
          }}
          size="small"
          multiple
          value={values.selectedTimeLabel}
          displayEmpty
          open={false}
          name="selectedStartTime"
          renderValue={() => {
            let output = "Time";
            if (values.selectedTimeLabel) {
              output = values.selectedTimeLabel;
            } else if (values.selectedTimePeriod) {
              output = values.selectedTimePeriod;
            }
            return <Typography color={output !== "Time" && "primary"}>{output}</Typography>;
          }}
          sx={{
            borderRadius: `${theme.shape.borderRadius[1]}px`,
            display: "flex",
            borderBottomLeftRadius: !isMobile && showTimeFilterContent && "0px",
            borderBottomRightRadius: !isMobile && showTimeFilterContent && "0px",
            backgroundColor:
              (values.selectedTimeLabel || values.selectedTimePeriod) &&
              theme.palette.colors.bg.lightGreen,
            ".MuiOutlinedInput-notchedOutline": {
              border: (values.selectedTimeLabel || values.selectedTimePeriod) && "2px solid",
              borderColor:
                (values.selectedTimeLabel || values.selectedTimePeriod) &&
                theme.palette.colors.brand.primary,
              borderBottom: !isMobile && showTimeFilterContent && "0px",
            },
          }}
        />

        <Box position="relative">
          <Card
            sx={{
              width: "40%",
              height: isMobile ? "0px" : "auto",
              minWidth: "450px",
              visibility: !isMobile && showTimeFilterContent ? "visible" : "hidden",
              position: "absolute",
              right: "0",
              borderRadius: `${theme.shape.borderRadius[2]}px`,
              borderTopRightRadius: "0px",
              border: "1px solid #c4c4c4",
              opacity: showTimeFilterContent ? "1" : "0",
              transition: "all 0.4s",
              zIndex: 99,
            }}
          >
            <TimeFilterContent
              setShowPhoneViewTimeFilter={setShowPhoneViewTimeFilter}
              setShowTimeFilterContent={setShowTimeFilterContent}
            />
          </Card>
        </Box>

        <Modal
          open={showPhoneViewTimeFilter}
          sx={{ visibility: showPhoneViewTimeFilter ? "visible" : "hidden" }}
        >
          <ModalBox>
            <Grid item xs={12}>
              <CustomButton
                width="auto"
                variant="text"
                fontSize={theme.fonts.fontSizes.size15}
                onClick={() => {
                  setShowPhoneViewTimeFilter(false);
                  setFieldValue("selectedStartTime", values.tempSelectedStartTime);
                  setFieldValue("selectedTimeLabel", values.tempSelectedTimeLabel);
                  setFieldValue("selectedTimePeriod", values.tempSelectedTimePeriod);
                }}
              >
                {"< Back"}
              </CustomButton>
            </Grid>
            <TimeFilterContent
              showPhoneViewTimeFilter={showPhoneViewTimeFilter}
              setShowPhoneViewTimeFilter={setShowPhoneViewTimeFilter}
              setShowTimeFilterContent={setShowTimeFilterContent}
            />
          </ModalBox>
        </Modal>
      </div>
    </ClickAwayListener>
  );
}

export default TimeFilter;
