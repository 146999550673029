import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, styled } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import Slider from "react-slick";

const StyledArrowIconBox = styled(Box)(() => ({
  ".MuiSvgIcon-root": {
    fontSize: "30px",
    strokeWidth: 2,
  },
  ":hover": {
    color: "#000",
  },
  color: "#000",
  zIndex: 1,
  width: "20px",
  display: "flex",
  "&.slick-arrow::before": {
    display: "none",
  },
  "&.slick-arrow": {
    alignItems: "center",
    justifyContent: "center",
  },
  "&.slick-prev": {
    marginRight: "20px",
  },
}));

function PrevArrow(arrowProps) {
  const { className, onClick, currentSlide } = arrowProps;
  return (
    <StyledArrowIconBox onClick={onClick} className={className}>
      <ArrowBackIosNewIcon color={currentSlide === 0 ? "disabled" : ""} />
    </StyledArrowIconBox>
  );
}

function NextArrow(arrowProps) {
  const { className, onClick, currentSlide, slideCount, slidesToShow } = arrowProps;
  return (
    <StyledArrowIconBox onClick={onClick} className={`slick-disabled ${className}`}>
      <ArrowForwardIosIcon color={currentSlide === slideCount - slidesToShow ? "disabled" : ""} />
    </StyledArrowIconBox>
  );
}

export default function ItemCarousel({ children, slidesToShow, slidesToScroll, paddingAfterItem }) {
  const settings = {
    infinite: false,
    dots: false,
    autoplay: false,
    slidesToShow,
    slidesToScroll,
    nextArrow: <NextArrow slidesToShow={slidesToShow} />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Box sx={{ "& .slick-slide > div": { paddingX: paddingAfterItem } }}>
      <Slider {...settings}>{children}</Slider>
    </Box>
  );
}

ItemCarousel.defaultProps = {
  paddingAfterItem: "5px",
};

ItemCarousel.propTypes = {
  children: PropTypes.node.isRequired,
  slidesToShow: PropTypes.number.isRequired,
  slidesToScroll: PropTypes.number.isRequired,
  paddingAfterItem: PropTypes.string,
};
