import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq } from "../../api.services";
import { getAPI } from "../../../infrastructure/utils";

export const getMembershipPlans = createAsyncThunk(
  "memberships/plans/business/{businessId}",
  async (payload) => {
    const { businessId } = payload;
    const response = await getReq(`${getAPI()}/memberships/plans/business/${businessId}`);
    return response;
  },
);

const membershipPlanSlice = createSlice({
  name: "membershipPlan",
  initialState: {
    reduxStoreMembershipPlans: [],
    getMembershipPlansObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getMembershipPlans.pending]: (state) => {
      state.getMembershipPlansObj.status = "pending";
    },
    [getMembershipPlans.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getMembershipPlansObj.status = "succeeded";
      state.getMembershipPlansObj.data = data;
      state.getMembershipPlansObj.successMessage = message;
    },
    [getMembershipPlans.rejected]: (state, action) => {
      const { message } = action.error;

      state.getMembershipPlansObj.status = "failed";
      state.getMembershipPlansObj.errorMessage = message;
    },
  },
});

export default membershipPlanSlice.reducer;

// state
export const membershipPlanSelector = (state) => state.membershipPlan;
