import React, { useState } from "react";
import { Grid, useTheme, useMediaQuery, styled, Box, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageFullViewModal from "./image-full-view-modal.component";

const ImagePopUpIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: 10,
  bottom: 20,
  backgroundColor: "rgba(255,255,255,0.7)",
  color: theme.palette.colors.brand.secondary,
  ":hover": {
    backgroundColor: theme.palette.colors.ui.white,
  },
}));

const CarouselContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  borderTopLeftRadius: isMobile ? 0 : theme.shape.borderRadius[0],
  borderTopRightRadius: isMobile ? 0 : theme.shape.borderRadius[0],
  borderBottomLeftRadius: isMobile ? 0 : theme.shape.borderRadius[0],
  borderBottomRightRadius: isMobile ? 0 : theme.shape.borderRadius[0],
  overflow: "hidden",
  position: "relative",
}));

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format&dpr=2 2x`,
  };
}

function EventImageGrid({ itemList }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showImageFullView, setShowImageFullView] = useState(false);

  const getImageList = () => {
    const imageList = [];
    const slicedArray = itemList.slice(0, 3);
    slicedArray.map((item, index) =>
      imageList.push({
        img: item.imagePath,
        id: index,
        rows: index === 0 ? 2 : 1,
        cols: index === 0 ? 4 : 3,
      }),
    );
    return imageList;
  };

  const renderImageBanner = () => {
    if (isMobile) {
      return (
        <Carousel
          showThumbs={false}
          infiniteLoop={true}
          showIndicators={false}
          swipeable={true}
          autoPlay={true}
          showArrows={!isMobile}
          showStatus={false}
        >
          {itemList.map((item) => (
            <Box onClick={() => setShowImageFullView(true)} key={item}>
              <img src={item.imagePath} alt="BannerImage" />
            </Box>
          ))}
        </Carousel>
      );
    }

    return itemList.length === 1 ? (
      <Box onClick={() => setShowImageFullView(true)}>
        <img src={itemList[0].imagePath} width="100%" alt="exp-img" />
      </Box>
    ) : (
      <Box onClick={() => setShowImageFullView(true)} sx={{ maxHeight: "360px" }}>
        <ImageList variant="quilted" cols={7} gap={8}>
          {getImageList().map((item) => (
            <ImageListItem key={item.id} cols={item.cols} rows={item.rows}>
              <img {...srcset(item.img, 121, item.rows, item.cols)} alt={item.img} />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    );
  };

  return (
    <Grid item xs={12}>
      <ImageFullViewModal
        imageList={itemList}
        showModal={showImageFullView}
        setShowModal={setShowImageFullView}
      />
      <CarouselContainer isMobile={isMobile}>
        {renderImageBanner()}
        <ImagePopUpIconButton onClick={() => setShowImageFullView(true)}>
          <ZoomOutMapIcon />
        </ImagePopUpIconButton>
      </CarouselContainer>
    </Grid>
  );
}

EventImageGrid.propTypes = {
  itemList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      imagePath: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default EventImageGrid;
