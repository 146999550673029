import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq } from "../../api.services";
import { getAPI } from "../../../infrastructure/utils";

export const getFitnessClasses = createAsyncThunk("searches/fitness_class", async (payload) => {
  const {
    lat,
    long,
    distance,
    q,
    fitnessCategoriesId,
    startAt,
    endAt,
    businessesId,
    page,
    sortBy,
  } = payload;
  const response = await getReq(
    `${getAPI()}/searches/fitness_classes?lat=${lat}&long=${long}${
      distance ? "&distance=".concat(distance) : ""
    }&startAt=${startAt}&endAt=${endAt}${
      fitnessCategoriesId && fitnessCategoriesId.length > 0
        ? "&fitnessCategoriesId=".concat(fitnessCategoriesId)
        : ""
    }${q ? "&q=".concat(q) : ""}${sortBy ? "&sortBy=".concat(sortBy) : ""}${
      businessesId ? "&businessesId=".concat(businessesId) : ""
    }&page=${page}`,
  );
  return response;
});

export const getNearbyFitnessClasses = createAsyncThunk(
  "searches/fitness_class/nearby",
  async (payload) => {
    const { lat, long, startAt, endAt, page } = payload;
    const response = await getReq(
      `${getAPI()}/searches/fitness_classes/nearby?lat=${lat}&long=${long}&startAt=${startAt}&endAt=${endAt}&page=${page}`,
    );
    return response;
  },
);

const searchFitnessClassSlice = createSlice({
  name: "searchFitnessClass",
  initialState: {
    getFitnessClassesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getNearbyFitnessClassesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    reduxStoreFitnessClasses: [],
    reduxStoreNearbyFitnessClasses: [],
  },
  reducers: {},
  extraReducers: {
    [getFitnessClasses.pending]: (state) => {
      state.getFitnessClassesObj.status = "pending";
    },
    [getFitnessClasses.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getFitnessClassesObj.status = "succeeded";
      state.getFitnessClassesObj.data = data;
      state.getFitnessClassesObj.successMessage = message;
    },
    [getFitnessClasses.rejected]: (state, action) => {
      const { message } = action.error;

      state.getFitnessClassesObj.status = "failed";
      state.getFitnessClassesObj.errorMessage = message;
    },
    [getNearbyFitnessClasses.pending]: (state) => {
      state.getNearbyFitnessClassesObj.status = "pending";
    },
    [getNearbyFitnessClasses.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getNearbyFitnessClassesObj.status = "succeeded";
      state.getNearbyFitnessClassesObj.data = data;
      state.getNearbyFitnessClassesObj.successMessage = message;
    },
    [getNearbyFitnessClasses.rejected]: (state, action) => {
      const { message } = action.error;

      state.getNearbyFitnessClassesObj.status = "failed";
      state.getNearbyFitnessClassesObj.errorMessage = message;
    },
  },
});

export default searchFitnessClassSlice.reducer;

// state
export const searchFitnessClassSelector = (state) => state.searchFitnessClass;
