import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import WifiRoundedIcon from "@mui/icons-material/WifiRounded";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { profileSelector } from "../../services/profile/profile-slice.service";
import { isLogin } from "../../infrastructure/utils";

const LabelContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: "5px",
});

const LabelText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontWeight: "lighter",
  marginLeft: "15px",
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
  marginTop: 15,
  marginBottom: 15,
}));

const StyledRadio = styled(Radio)(({ theme }) => ({
  color: theme.palette.colors.brand.primary,
  "&.Mui-checked": {
    color: theme.palette.colors.brand.primary,
  },
}));

function PaymentMethodCheckbox() {
  const { values, setFieldValue } = useFormikContext();
  const [value, setValue] = useState(values.paymentMethod);
  const { getProfileDetailObj } = useSelector(profileSelector);

  const handleChange = (event) => {
    setValue(event.target.value);
    setFieldValue("paymentMethod", event.target.value);
  };
  return (
    <FormControl sx={{ width: "100%" }}>
      <RadioGroup name="controlled-radio-buttons-group" value={value} onChange={handleChange}>
        <FormControlLabel
          value="others"
          control={<StyledRadio />}
          label={
            <LabelContainer>
              <WifiRoundedIcon />
              <LabelText>Online Banking / E-Wallet</LabelText>
            </LabelContainer>
          }
        />
        {isLogin() && (
          <>
            <SeperateLine />
            <FormControlLabel
              value="credits"
              control={<StyledRadio />}
              label={
                <LabelContainer>
                  <AccountBalanceWalletOutlinedIcon />
                  {getProfileDetailObj.status === "succeeded" && (
                    <LabelText>{`Account Credits (Balance RM ${getProfileDetailObj.data.creditBalance})`}</LabelText>
                  )}
                </LabelContainer>
              }
            />
          </>
        )}
      </RadioGroup>
    </FormControl>
  );
}

export default PaymentMethodCheckbox;
