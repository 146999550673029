import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import {
  Box,
  Button,
  Grid,
  Icon,
  Modal,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import CustomButton from "../../../../components/button/custom-button.component";
import FormPickerDate from "../../../../components/forms/form-picker-date.component";
import Form from "../../../../components/forms/form.component";
import DialogBox from "../../../../components/notification/dialog-box.component";
import { SnackbarContext } from "../../../../components/notification/snackbar.context";
import PaddedView from "../../../../components/utils/padded-view.component";
import CustomSkeleton from "../../../../components/utils/skeleton.component";
import Spacer from "../../../../components/utils/spacer.component";
import TopTitleBox from "../../../../components/utils/top-title-box.component";
import { bookingSelector } from "../../../../services/booking/booking-slice.service";
import {
  getAvailableHourlyBooking,
  hourlyBookingSelector,
} from "../../../../services/hourly-booking/hourly-booking-slice.service";

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "absolute",
  top: !isMobile && "50%",
  left: !isMobile && "50%",
  transform: !isMobile && "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.white,
  borderRadius: !isMobile && theme.shape.borderRadius[2],
  boxShadow: 24,
  maxWidth: !isMobile && "550px",
  width: isMobile ? "100%" : "90%",
  height: isMobile && "100%",
  outline: "none",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: "100%",
  border: "1px solid",
  borderColor: theme.palette.colors.ui.border,
  borderRadius: `${theme.shape.borderRadius[1]}px`,
  color: theme.palette.colors.text.primary,
}));

const availableSessionValidationSchema = Yup.object().shape({
  date: Yup.date().required().label("Date"),
});

function EditSessionModal({ bookingDetails, showModal, setShowModal, updateBooking }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { updateBookingSlotObj } = useSelector(bookingSelector);
  const { getAvailableHourlyBookingObj } = useSelector(hourlyBookingSelector);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const formRef = useRef();

  const renderLoader = () => {
    const loaders = [];
    for (let i = 0; i < 24; i += 1) {
      loaders.push(
        <Grid item xs={4} key={i} position="relative">
          <StyledButton disabled>
            <CustomSkeleton width="100%" />
          </StyledButton>
        </Grid>,
      );
    }
    return loaders;
  };

  const getAvailableSession = (values) => {
    dispatch(
      getAvailableHourlyBooking({
        businessId: bookingDetails.booking.location.id,
        ...values,
      }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  useEffect(() => {
    if (bookingDetails) {
      setSelectedSlot({
        startAt: bookingDetails.booking.startAt,
        endAt: bookingDetails.booking.endAt,
      });
      getAvailableSession({ date: bookingDetails.booking.date });
    }
  }, [showModal]);

  useEffect(() => {
    if (bookingDetails) {
      setSelectedSlot({
        startAt: bookingDetails.booking.startAt,
        endAt: bookingDetails.booking.endAt,
      });
    }
  }, [formRef.current?.values.date]);

  const handleSelectSlot = (item) => {
    setSelectedSlot({
      startAt: item.startAt,
      endAt: item.endAt,
    });
  };

  const showNextDay = (startAt, endAt) => {
    if (!startAt || !endAt) {
      return false;
    }
    const startDate = new Date(startAt);
    const endDate = new Date(endAt);

    // Compare the two dates
    if (startDate.getDate() !== endDate.getDate()) {
      return true;
    }

    return false;
  };

  const renderAvailableSession = () => {
    if (getAvailableHourlyBookingObj.status === "succeeded") {
      if (getAvailableHourlyBookingObj.data.length > 0) {
        return (
          <>
            <Grid container item columnSpacing={1} rowSpacing={1.5}>
              {getAvailableHourlyBookingObj.data.map((item) => (
                <Grid item xs={4} key={item.startAt} position="relative">
                  <StyledButton
                    onClick={() => handleSelectSlot(item)}
                    disabled={!item.available || item.booked}
                  >
                    <Typography>{format(new Date(item.startAt), "hh:00a")}</Typography>
                    {(!item.available || item.booked) && (
                      <Icon
                        sx={{
                          height: "100%",
                          position: "absolute",
                          margin: "auto",
                          left: 0,
                          right: 0,
                        }}
                      >
                        <DoDisturbAltIcon sx={{ color: theme.palette.colors.text.secondary }} />
                      </Icon>
                    )}
                  </StyledButton>
                  {selectedSlot.startAt === item.startAt && (
                    <Icon
                      sx={{
                        position: "absolute",
                        right: -5,
                        top: 0,
                        backgroundColor: theme.palette.colors.bg.white,
                        borderRadius: "50%",
                      }}
                    >
                      <CheckCircleRoundedIcon sx={{ color: theme.palette.colors.text.success }} />
                    </Icon>
                  )}
                </Grid>
              ))}
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item xs={6}>
                {isMobile && <Spacer size="l" />}
                <CustomButton
                  isLoading={updateBookingSlotObj.status === "pending"}
                  disabled={selectedSlot.startAt === bookingDetails.booking.startAt}
                  fontSize={theme.fonts.fontSizes.size18}
                  onClick={() => setShowConfirmModal(true)}
                >
                  Update
                </CustomButton>
              </Grid>
            </Grid>
          </>
        );
      }
      return (
        <Grid container item justifyContent="center" alignContent="center" height="400px">
          <Typography>No available sessions.</Typography>
        </Grid>
      );
    }

    return (
      <Grid container item columnSpacing={1} rowSpacing={1.5}>
        {renderLoader()}
      </Grid>
    );
  };

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <ModalBox isMobile={isMobile}>
        <DialogBox
          title="Confirm your changes?"
          isShow={showConfirmModal}
          setIsShowAlert={setShowConfirmModal}
          message={
            <Grid item>
              <Typography sx={{ textAlign: "center" }}>
                Date: {formRef.current && formRef.current.values.date}
              </Typography>
              {selectedSlot && (
                <Typography sx={{ textAlign: "center" }}>
                  Time: {format(new Date(selectedSlot.startAt), "hh:00a")} -{" "}
                  {format(new Date(selectedSlot.endAt), "hh:00a")}{" "}
                  {showNextDay(selectedSlot.startAt, selectedSlot.endAt) ? "(next day)" : ""}
                </Typography>
              )}
            </Grid>
          }
          isConfirmHandle={() => {
            setShowConfirmModal(false);
            updateBooking(bookingDetails.id, selectedSlot);
          }}
          buttonText="Ok"
        />
        {bookingDetails && selectedSlot && bookingDetails.booking.type !== "gym_session" && (
          <PaddedView multiples={2}>
            <TopTitleBox
              title="Available Session"
              requireCloseButton={true}
              setShowModal={setShowModal}
            />
            <Spacer position="top" size="l" />
            <Grid container item xs={12} justifyContent="center" rowSpacing={3} padding="10px">
              <Form
                innerRef={formRef}
                initialValues={{
                  date: format(new Date(bookingDetails.booking.date), "yyyy-MM-dd"),
                }}
                validationSchema={availableSessionValidationSchema}
                onSubmit={getAvailableSession}
              >
                <Grid item xs={8}>
                  <FormPickerDate name="date" submitOnChange={true} />
                </Grid>
              </Form>

              {renderAvailableSession()}
            </Grid>
          </PaddedView>
        )}
      </ModalBox>
    </Modal>
  );
}

EditSessionModal.defaultProps = {
  bookingDetails: PropTypes.shape({
    id: null,
    booking: PropTypes.shape({
      type: "",
      date: "",
      time: "",
      price: "",
      paymentMethod: "",
      package: PropTypes.shape({
        code: "",
      }),
      session: PropTypes.shape({
        id: null,
      }),
      location: PropTypes.shape({
        id: null,
        name: "",
      }),
    }),
    editable: false,
    editableBy: "",
    nonRefundable: false,
  }),
};

EditSessionModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  updateBooking: PropTypes.func.isRequired,
  bookingDetails: PropTypes.shape({
    id: PropTypes.number,
    booking: PropTypes.shape({
      type: PropTypes.string,
      date: PropTypes.string,
      startAt: PropTypes.string,
      endAt: PropTypes.string,
      time: PropTypes.string,
      price: PropTypes.string,
      paymentMethod: PropTypes.string,
      package: PropTypes.shape({
        code: PropTypes.string,
      }),
      session: PropTypes.shape({
        id: PropTypes.number,
      }),
      location: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    }),
    editable: PropTypes.bool,
    editableBy: PropTypes.string,
    nonRefundable: PropTypes.bool,
  }),
};

export default EditSessionModal;
