import { Grid, Typography, useTheme, useMediaQuery, Avatar } from "@mui/material";
import React from "react";
import PaddedView from "../../../components/utils/padded-view.component";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import Spacer from "../../../components/utils/spacer.component";
import UserProfileRowLoader from "./user-profile-row-loader.component";

function UserProfileLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid
      container
      sx={{
        flexDirection: "column",
      }}
    >
      <PaddedView multiples={2}>
        <Grid
          container
          spacing={2}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Grid item>
            <Grid
              container
              sx={{
                alignItems: "center",
                flexDirection: "row",
              }}
              spacing={2}
            >
              <Grid item>
                <Avatar
                  sx={{
                    width: "50px",
                    height: "50px",
                  }}
                />
              </Grid>

              <Grid item>
                <Typography>
                  <CustomSkeleton width="200px" height="30px" />
                </Typography>
                <Spacer />
                <Typography>
                  <CustomSkeleton height="16px" />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CustomSkeleton height="100px" width="200px" />
              </Grid>
              <Grid item xs={6}>
                <CustomSkeleton height="100px" width="200px" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Spacer size="l" />
        <Grid
          container
          sx={{
            backgroundColor: theme.palette.colors.bg.primary,
            borderRadius: `${theme.shape.borderRadius[2]}px`,
          }}
        >
          <PaddedView multiples={isMobile ? 2 : 4}>
            <Grid
              container
              sx={{
                flexDirection: "column",
              }}
            >
              <Typography>
                <CustomSkeleton />
              </Typography>
              <Spacer size="l" />

              <UserProfileRowLoader />
              <Spacer size="m" />

              <UserProfileRowLoader />
              <Spacer size="m" />

              <UserProfileRowLoader />
              <Spacer size="m" />

              <UserProfileRowLoader />
              <Spacer size="m" />

              <UserProfileRowLoader />
            </Grid>
          </PaddedView>
        </Grid>
        <Spacer size="l" />
      </PaddedView>
    </Grid>
  );
}

export default UserProfileLoader;
