import { FormControlLabel, FormGroup, Typography, useTheme } from "@mui/material";
import CheckBox from "@mui/material/Checkbox";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";

function FormCheckBox({ name, label }) {
  const theme = useTheme();
  const { setFieldValue, errors } = useFormikContext();
  const handleOnChange = (event) => {
    setFieldValue(name, event.target.checked);
  };
  const showError = typeof errors[name] === "string";

  return (
    <FormGroup sx={{ marginTop: -0.7 }}>
      <FormControlLabel
        name={name}
        control={
          <CheckBox
            onChange={handleOnChange}
            size="small"
            sx={{
              color: showError ? theme.palette.colors.ui.error : theme.palette.colors.brand.primary,
              "&.Mui-checked": {
                color: theme.palette.colors.brand.primary,
              },
            }}
          />
        }
        label={
          <Typography
            variant="body2"
            fontSize={theme.fonts.fontSizes.size12}
            sx={{
              color: showError ? theme.palette.colors.ui.error : theme.palette.colors.brand.primary,
            }}
          >
            {label}
          </Typography>
        }
      />
    </FormGroup>
  );
}

FormCheckBox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default FormCheckBox;
