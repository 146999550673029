import { Grid, styled, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import * as Yup from "yup";
import FormFieldTextWithIcon from "../../../../../components/forms/form-field-text-with-icon.component";
import FormSubmitButton from "../../../../../components/forms/form-submit-button.component";
import Form from "../../../../../components/forms/form.component";
import Spacer from "../../../../../components/utils/spacer.component";

const SectionTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size18,
  fontWeight: "bold",
}));

function PromoSection({ itemIndex, previewBooking }) {
  const { setFieldValue, values } = useFormikContext();
  const promoCodeFormRef = useRef();

  const clearPromoForm = () => {
    setFieldValue(`slots[${itemIndex}].promoCode`, "");
    promoCodeFormRef.current.setFieldValue("promoCode", "");
    const payload = { ...values };
    payload.slots[itemIndex].promoCode = "";
    previewBooking(payload, true, itemIndex);
  };
  return (
    <>
      <SectionTypography>Promos & Vouchers</SectionTypography>
      <Spacer size="s" />

      <Form
        innerRef={promoCodeFormRef}
        initialValues={{
          promoCode: values.slots[itemIndex].promoCode || "",
        }}
        validationSchema={Yup.object().shape({
          promoCode: Yup.string().required().label("Promo Code"),
        })}
        onSubmit={(promoFormValues) => {
          setFieldValue(`slots[${itemIndex}].promoCode`, promoFormValues.promoCode);

          const payload = { ...values };
          payload.slots[itemIndex].promoCode = promoFormValues.promoCode;
          previewBooking(payload, true, itemIndex);
        }}
      >
        <Grid container columnSpacing={2}>
          <Grid item xs={8}>
            <FormFieldTextWithIcon
              name="promoCode"
              placeholder="Promo Code"
              toUpper={true}
              clearPromoForm={clearPromoForm}
            />
          </Grid>
          <Grid item xs={4}>
            <FormSubmitButton width="100%" borderRadius={3}>
              <Typography>Redeem</Typography>
            </FormSubmitButton>
          </Grid>
        </Grid>
      </Form>
    </>
  );
}

PromoSection.propTypes = {
  itemIndex: PropTypes.number.isRequired,
  previewBooking: PropTypes.func.isRequired,
};

export default PromoSection;
