import { Grid, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import Spacer from "../../../../components/utils/spacer.component";
import { fitnessClassSessionSelector } from "../../../../services/fitness_class/session/fitness-class-session-slice.services";

function Description() {
  const { getFitnessClassSessionObj } = useSelector(fitnessClassSessionSelector);

  return (
    <Grid item>
      <Grid item>
        <Typography variant="h6" fontWeight="bold">
          Description
        </Typography>
      </Grid>
      <Spacer size="m" />
      <Grid item>
        <Typography textAlign="justify">
          {getFitnessClassSessionObj.data.business.description}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Description;
