import { Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CustomButton from "../../../components/button/custom-button.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import Spacer from "../../../components/utils/spacer.component";
import { getHighlights } from "../../../services/highlight/highlight-slice.service";
import HighlightCard from "./highlight-card.component";
import HighlightModal from "./highlight-modal.component";

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: theme.fonts.fontSizes.size24,
}));

function HighlightSection() {
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [showModal, setShowModal] = useState(false);
  const [highlights, setHighlights] = useState(null);

  useEffect(() => {
    dispatch(getHighlights({ page: 1 })).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setHighlights(payload.data);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const renderHighlights = () => {
    if (highlights) {
      if (highlights.items.length > 0) {
        return highlights.items.slice(0, isMobile ? 1 : 3).map((item) => (
          <Grid item key={item.id} xs={isMobile ? 12 : 4}>
            <HighlightCard itemDetails={item} />
          </Grid>
        ));
      }
      return (
        <Grid item xs={12}>
          <Typography textAlign="center">No Highlights</Typography>
        </Grid>
      );
    }

    return (
      <>
        <Grid item xs={isMobile ? 12 : 4}>
          <CustomSkeleton width="100%" height={146} />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <CustomSkeleton width="100%" height={146} />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <CustomSkeleton width="100%" height={146} />
        </Grid>
      </>
    );
  };

  return highlights && highlights.items && highlights.items.length > 0 ? (
    <>
      <HighlightModal showModal={showModal} setShowModal={setShowModal} />
      <Spacer size="l" />
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <StyledTypography>Highlights</StyledTypography>
        </Grid>
        {highlights && highlights.items.length > (isMobile ? 1 : 3) && (
          <Grid item>
            <CustomButton
              variant="outlined"
              fontSize={theme.fonts.fontSizes.size14}
              onClick={() => setShowModal(true)}
            >
              See More
            </CustomButton>
          </Grid>
        )}
      </Grid>
      <Spacer size="m" />
      <Grid container spacing={1}>
        {renderHighlights()}
      </Grid>
    </>
  ) : null;
}

export default HighlightSection;
