import React from "react";
import { Box, Modal, styled } from "@mui/material";
import PropTypes from "prop-types";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { v4 as uuidv4 } from "uuid";

const StyledModal = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "rgba(0,0,0,0.7)",
});

const CenterBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: "900px",
}));

function ImageFullViewModal({ showModal, setShowModal, imageList, selectedItem }) {
  return (
    <StyledModal open={showModal} onClose={() => setShowModal(false)}>
      <CenterBox sx={{ width: "100%" }}>
        <Box sx={{ width: "100%" }}>
          <Carousel
            showStatus={false}
            showThumbs={true}
            infiniteLoop={true}
            showIndicators={false}
            swipeable={true}
            selectedItem={selectedItem}
          >
            {imageList.map((item) => (
              <img src={item.imagePath} alt="BannerImage" key={uuidv4()} width="100%" />
            ))}
          </Carousel>
        </Box>
      </CenterBox>
    </StyledModal>
  );
}

ImageFullViewModal.defaultProps = {
  selectedItem: 0,
};

ImageFullViewModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  imageList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      imagePath: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selectedItem: PropTypes.number,
};

export default ImageFullViewModal;
