import { Grid } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import HorizontalDivider from "../../../components/utils/horizontal-divider.component";
import PaddedView from "../../../components/utils/padded-view.component";
import {
  eventSessionSelector,
  getEventSessionDetails,
} from "../../../services/event/session/event-session-slice.service";
import Highlights from "../components/highlights.component";
import ImageTitleSection from "../components/image-title-section.component";
import LocationSection from "../components/location-section.component";
import Summary from "../components/summary.component";
import EventScreenLoader from "../loaders/event-screen-loader.component";

function EventScreen() {
  const [params] = useSearchParams();
  const dispatch = useDispatch();
  const { getEventSessionDetailsObj } = useSelector(eventSessionSelector);
  const createSnackBar = useContext(SnackbarContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getEventSessionDetails(params.get("eventSessionId"))).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  return (
    <PaddedView multiples={2}>
      {getEventSessionDetailsObj.status === "succeeded" ? (
        <Grid item>
          <ImageTitleSection />
          <HorizontalDivider spacerSize="l" />
          <Highlights />
          <HorizontalDivider spacerSize="l" />
          <Summary />
          <HorizontalDivider spacerSize="l" />
          <LocationSection />
        </Grid>
      ) : (
        <EventScreenLoader />
      )}
    </PaddedView>
  );
}
export default EventScreen;
