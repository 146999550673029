import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Grid,
  IconButton,
  Modal,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import CustomButton from "../../../../components/button/custom-button.component";
import Form from "../../../../components/forms/form.component";
import routes from "../../../../components/navigation/routes";
import DialogBox from "../../../../components/notification/dialog-box.component";
import { SnackbarContext } from "../../../../components/notification/snackbar.context";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";
import {
  activateSubscription,
  subscriptionSelector,
} from "../../../../services/subscription/subscription-slice.service";
import {
  chargeTokenizedPayments,
  tokenizedPaymentSelector,
} from "../../../../services/tokenized-payment/tokenized-payment-slice.service";
import BusinessCard from "./business-card.component";
import PaymentMethodCheckbox from "./payment-method-checkbox.component";

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "absolute",
  top: !isMobile && "50%",
  left: !isMobile && "50%",
  transform: !isMobile && "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.white,
  borderRadius: !isMobile && theme.shape.borderRadius[2],
  boxShadow: 24,
  maxWidth: !isMobile && "550px",
  width: isMobile ? "100%" : "90%",
  height: isMobile ? "100%" : "90vh",
  maxHeight: "700px",
  outline: "none",
  overflowY: "auto",
}));

const SectionContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.colors.bg.primary,
  borderRadius: `${theme.shape.borderRadius[2]}px`,
}));

function SubscriptionDetailsModal({ getSubscriptions, itemDetails, showModal, setShowModal }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { activateSubscriptionObj } = useSelector(subscriptionSelector);
  const { chargeTokenizedPaymentsObj } = useSelector(tokenizedPaymentSelector);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const navigate = useNavigate();
  const formRef = useRef();

  const validationSchema = Yup.object().shape({
    recurrencePurchaseItemId: Yup.number().required(),
    recurrencePurchaseItemType: Yup.string().required(),
    paymentMethod: Yup.string().required(),
    tokenizedRemoteCustomerId: Yup.string().required(),
  });

  const settlePaymentFailedSubscription = (values) => {
    dispatch(chargeTokenizedPayments(values)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
      if (meta.requestStatus === "fulfilled") {
        setShowModal(false);
        getSubscriptions("ongoing", 1);
      }
    });
  };

  const activateSubscriptionPlan = () => {
    dispatch(activateSubscription({ subscriptionId: itemDetails.id })).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          getSubscriptions("ongoing", 1);
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
          setShowModal(false);
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <ModalBox isMobile={isMobile}>
        <DialogBox
          title={itemDetails.plan.title}
          isShow={showConfirmModal}
          setIsShowAlert={setShowConfirmModal}
          message={
            <Grid item>
              <Typography sx={{ textAlign: "center" }}>
                Confirming to proceed will activate the subscription right away.
              </Typography>
            </Grid>
          }
          isConfirmHandle={() => {
            setShowConfirmModal(false);
            activateSubscriptionPlan();
          }}
          buttonText="Proceed"
        />
        <Grid
          item
          display="grid"
          flexDirection="column"
          alignContent="space-between"
          height="100%"
          xs={12}
          padding="20px"
          paddingBottom="0px"
        >
          <Grid item container flexDirection="column" rowSpacing={1}>
            <Grid item container justifyContent="space-between">
              <Grid item>
                <Typography fontWeight="bold" fontSize={theme.fonts.fontSizes.size18}>
                  Subscription Details
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={() => setShowModal(false)}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid item container justifyContent="space-between">
              <Grid item>
                <Typography>Plan</Typography>
              </Grid>
              <Grid item>
                <Typography fontWeight="bold">{itemDetails.plan.title}</Typography>
              </Grid>
            </Grid>

            <Grid item container justifyContent="space-between">
              <Grid item>
                <Typography>Status</Typography>
              </Grid>
              <Grid item>
                {itemDetails.status === "expired_non_activated" ? (
                  <Typography textTransform="capitalize" fontWeight="bold">
                    Not Activated
                  </Typography>
                ) : (
                  <Typography
                    textTransform="capitalize"
                    fontWeight="bold"
                    sx={{
                      color:
                        itemDetails.status === "active"
                          ? theme.palette.colors.ui.success
                          : theme.palette.colors.ui.error,
                    }}
                  >
                    {itemDetails.status.replace("_", " ")}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item container justifyContent="space-between">
              <Grid item>
                <Typography>Unlimited Access</Typography>
              </Grid>
              <Grid item>
                <Typography fontWeight="bold">{itemDetails.plan.numberOfDays} Days</Typography>
              </Grid>
            </Grid>
            <Grid item container justifyContent="space-between">
              <Grid item>
                <Typography>Activate Before</Typography>
              </Grid>
              <Grid item>
                <Typography fontWeight="bold">{itemDetails.activationExpiryDate || "-"}</Typography>
              </Grid>
            </Grid>
            <Grid item container justifyContent="space-between">
              <Grid item>
                <Typography>Start at</Typography>
              </Grid>
              <Grid item>
                <Typography fontWeight="bold">{itemDetails.startAt || "-"}</Typography>
              </Grid>
            </Grid>
            <Grid item container justifyContent="space-between">
              <Grid item>
                <Typography>
                  {itemDetails.earlierEndAt ? "Expired early at" : "Expired at"}
                </Typography>
              </Grid>
              <Grid item>
                <Typography fontWeight="bold">
                  {itemDetails.earlierEndAt
                    ? itemDetails.earlierEndAt || "-"
                    : itemDetails.endAt || "-"}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container justifyContent="space-between">
              {itemDetails.package ? (
                <Grid item>
                  <Typography>
                    Bundle purchased with <b>{itemDetails.package.title}</b>
                  </Typography>
                </Grid>
              ) : (
                <>
                  <Grid item xs={6}>
                    <Typography>Total Price</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontWeight="bold" textAlign="right">
                      RM {itemDetails.paymentTransaction.totalPrice}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={theme.fonts.fontSizes.size14}
                      color={theme.palette.colors.text.secondary}
                    >
                      Subtotal
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontWeight="bold"
                      textAlign="right"
                      fontSize={theme.fonts.fontSizes.size14}
                      color={theme.palette.colors.text.secondary}
                    >
                      RM {itemDetails.paymentTransaction.price}
                    </Typography>
                  </Grid>
                  {itemDetails.paymentTransaction.membershipDiscount !== "0.00" && (
                    <>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={theme.fonts.fontSizes.size14}
                          color={theme.palette.colors.text.secondary}
                        >
                          Membership Discount
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontWeight="bold"
                          textAlign="right"
                          fontSize={theme.fonts.fontSizes.size14}
                          color={theme.palette.colors.text.secondary}
                        >
                          -RM {itemDetails.paymentTransaction.membershipDiscount}
                        </Typography>
                      </Grid>
                    </>
                  )}
                  {itemDetails.paymentTransaction.voucherDiscount !== "0.00" && (
                    <>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={theme.fonts.fontSizes.size14}
                          color={theme.palette.colors.text.secondary}
                        >
                          Voucher Discount
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontWeight="bold"
                          textAlign="right"
                          fontSize={theme.fonts.fontSizes.size14}
                          color={theme.palette.colors.text.secondary}
                        >
                          -RM {itemDetails.paymentTransaction.voucherDiscount}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>

            <Spacer />
            <Grid item>
              <Typography>Unlimited Access To:</Typography>
            </Grid>
            <Grid item sx={{ overflowY: !isMobile && "auto", height: !isMobile && "150px" }}>
              <Grid item container flexDirection="column" rowSpacing={1}>
                {itemDetails.plan.businesses.map((item) => (
                  <Grid item key={item.id}>
                    <BusinessCard itemDetails={item} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            {itemDetails.status === "payment_failed" && (
              <Grid item>
                <Form
                  initialValues={{
                    recurrencePurchaseItemId: itemDetails.recurrencePurchaseItem.id,
                    recurrencePurchaseItemType: itemDetails.recurrencePurchaseItem.type,
                    paymentMethod: "tokenized_payment",
                    tokenizedRemoteCustomerId: "",
                  }}
                  innerRef={formRef}
                  validationSchema={validationSchema}
                  onSubmit={settlePaymentFailedSubscription}
                >
                  <SectionContainer item>
                    <PaddedView multiples={2}>
                      <Grid
                        container
                        justifyContent="space-between"
                        display="flex"
                        alignItems="center"
                      >
                        <Grid item>
                          <Typography>Payment Method</Typography>
                        </Grid>
                        <Grid item>
                          <CustomButton
                            variant="outlined"
                            fontSize="16px"
                            onClick={() => navigate(routes.PAYMENT_METHODS)}
                          >
                            Add Card
                          </CustomButton>
                        </Grid>
                      </Grid>
                      <Spacer size="m" />
                      <PaymentMethodCheckbox />
                    </PaddedView>
                  </SectionContainer>
                </Form>
              </Grid>
            )}
          </Grid>
          {itemDetails.status === "payment_failed" && (
            <Grid item padding="10px">
              <CustomButton
                isLoading={chargeTokenizedPaymentsObj.status === "pending"}
                fontSize={theme.fonts.fontSizes.size16}
                onClick={() => {
                  formRef.current.handleSubmit();
                }}
              >
                Continue
              </CustomButton>
            </Grid>
          )}
          {itemDetails.status === "pending_activation" && (
            <Grid item padding="10px">
              <CustomButton
                isLoading={activateSubscriptionObj.status === "pending"}
                fontSize={theme.fonts.fontSizes.size16}
                onClick={() => setShowConfirmModal(true)}
              >
                Activate
              </CustomButton>
            </Grid>
          )}
        </Grid>
      </ModalBox>
    </Modal>
  );
}

SubscriptionDetailsModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  itemDetails: PropTypes.shape({
    id: PropTypes.number,
    activationExpiryDate: PropTypes.string,
    startAt: PropTypes.string,
    endAt: PropTypes.string,
    earlierEndAt: PropTypes.string,
    status: PropTypes.string,
    recurrencePurchaseItem: PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
    }),
    paymentTransaction: PropTypes.shape({
      price: PropTypes.string,
      totalPrice: PropTypes.string,
      voucherDiscount: PropTypes.string,
      membershipDiscount: PropTypes.string,
    }),
    package: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
    plan: PropTypes.shape({
      imagePath: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      shortDescription: PropTypes.string,
      numberOfDays: PropTypes.number,
      businesses: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          city: PropTypes.string,
          reviews: PropTypes.shape({
            averageRatings: PropTypes.string,
            totalRatings: PropTypes.number,
          }),

          images: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number,
              imagePath: PropTypes.string,
            }),
          ),
        }),
      ),
    }),
  }).isRequired,
  getSubscriptions: PropTypes.func.isRequired,
};

export default SubscriptionDetailsModal;
