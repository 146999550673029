import { Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import { isLogin } from "../../../infrastructure/utils";
import { getBanners } from "../../../services/banner/banner-slice.service";
import { getCategorySegments } from "../../../services/category-segment/category-segment.service";
import { getPendingReviews } from "../../../services/review/review-slice.service";
import BannerImageCarousel from "../components/banner-image-carousel.component";
import CategorySegmentSection from "../components/category-segment-section.component";
import ExploreSection from "../components/explore-section.component";
import HighlightSection from "../components/highlight-section.component";
import ReviewModal from "../components/review-modal.component";
import UpcomingClassSection from "../components/upcoming-class-section.component";
import UpcomingEventSection from "../components/upcoming-event-section.component";

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: theme.fonts.fontSizes.size24,
}));

function HomeScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTabletHorizontal = useMediaQuery(theme.breakpoints.down("lg"));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBanners());
    dispatch(getCategorySegments());
    if (isLogin()) {
      dispatch(getPendingReviews());
    }
  }, []);

  const getBannerGridRatio = () => {
    if (isMobile) {
      return 12;
    }
    if (isTabletHorizontal) {
      return 6;
    }
    return 5;
  };

  const getExploreGridRatio = () => {
    if (isMobile) {
      return 12;
    }
    if (isTabletHorizontal) {
      return 6;
    }
    return 7;
  };

  return (
    <PaddedView multiples={2}>
      <ReviewModal />
      <Spacer size="m" />
      <Grid container columnSpacing={2} direction="row-reverse">
        <Grid item xs={getBannerGridRatio()}>
          <BannerImageCarousel />
        </Grid>
        <Grid item xs={getExploreGridRatio()}>
          {isMobile && <Spacer size="l" />}
          <Grid item xs={12}>
            <StyledTypography>Explore Katch</StyledTypography>
          </Grid>
          <Spacer size="m" />
          <Grid item xs={12}>
            <ExploreSection />
          </Grid>
        </Grid>
      </Grid>
      <HighlightSection />
      <UpcomingClassSection />
      <UpcomingEventSection />
      <Grid item>
        <Spacer size="l" />
        <StyledTypography>Explore categories</StyledTypography>
      </Grid>
      <Spacer size="m" />
      <Grid item xs={12}>
        <CategorySegmentSection />
      </Grid>
    </PaddedView>
  );
}

export default HomeScreen;
