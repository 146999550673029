import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReferralImage from "../../../assets/images/referrals.png";
import CustomButton from "../../../components/button/custom-button.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import PaddedView from "../../../components/utils/padded-view.component";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import Spacer from "../../../components/utils/spacer.component";
import {
  getReferees,
  getReferral,
  referralSelector,
} from "../../../services/referral/referral-slice.service";
import ReferedTable from "../components/referred-table.component";

function ReferralScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const { getReferralObj } = useSelector(referralSelector);
  const createSnackBar = useContext(SnackbarContext);
  const [copy, setCopy] = useState("Copy");

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getReferral()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
    dispatch(getReferees()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const renderHowItWorks = () => (
    <Grid
      item
      xs={isMobile ? 12 : 5}
      sx={{
        backgroundColor: theme.palette.colors.bg.primary,
        borderRadius: `${theme.shape.borderRadius[1]}px`,
      }}
    >
      <PaddedView multiples={isMobile ? 2 : 4}>
        <Typography fontWeight="bold" fontSize={theme.fonts.fontSizes.size20}>
          How it works?
        </Typography>
        <Spacer size="m" />
        <Table
          sx={{
            ".MuiTableCell-root": { borderBottom: "none", padding: "5px", verticalAlign: "top" },
          }}
        >
          <TableBody>
            <TableRow>
              <TableCell>1.</TableCell>
              <TableCell>Refer a friend NEW to Katch App</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>2.</TableCell>
              <TableCell>
                Share your unique code and get RM5 reward when they get verified on Katch.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>3.</TableCell>
              <TableCell>They get to enjoy the app and you will earn RM5</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Spacer size="l" />
        <CustomButton
          fontSize={theme.fonts.fontSizes.size16}
          variant="text"
          onClick={() =>
            window.open("https://engagefitness.asia/refer-a-friend-terms-conditions/", "_blank")
          }
        >
          TERMS & CONDITIONS
        </CustomButton>
      </PaddedView>
    </Grid>
  );

  return (
    <Box marginTop={5} paddingX={isMobile ? "20px" : ""}>
      <Grid container alignItems="center">
        <Grid item xs={isMobile ? 12 : 3} display="flex" justifyContent="center">
          <img src={ReferralImage} alt="referralImage" width={isMobile ? "50%" : "80%"} />
        </Grid>
        <Grid item xs={isMobile ? 12 : 3}>
          <Grid item>
            <Typography>
              Help your friends discover the
              {isMobile ? " " : <br />}
              Katch App and earn RM5
            </Typography>
          </Grid>
          {getReferralObj.status === "succeeded" ? (
            <>
              <Spacer size="l" />
              <Tooltip title={copy} placement="bottom" arrow>
                <Grid item>
                  <CustomButton
                    onClick={() => {
                      navigator.clipboard.writeText(getReferralObj.data.referralCode);
                      setCopy("Copied");
                    }}
                  >
                    Share Your Code <br />
                    <span style={{ fontSize: theme.fonts.fontSizes.size24 }}>
                      {getReferralObj.data.referralCode}
                    </span>
                  </CustomButton>
                </Grid>
              </Tooltip>
            </>
          ) : (
            <Box paddingTop="20px">
              <CustomSkeleton height={66} width="280px" />
            </Box>
          )}
        </Grid>
        <Grid item xs={isMobile ? 12 : 1}>
          <Spacer position={isMobile ? "top" : "left"} size="m" />
        </Grid>
        {renderHowItWorks()}
      </Grid>
      <Spacer size="m" />
      <Grid>
        <ReferedTable />
      </Grid>
    </Box>
  );
}

export default ReferralScreen;
