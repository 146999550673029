import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ReactCodeInput from "react-verification-code-input";
import KatchLogo from "../../../assets/images/katch_logo";
import CustomButton from "../../../components/button/custom-button.component";
import ResendButton from "../../../components/button/resend-button.component";
import routes from "../../../components/navigation/routes";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import TopTitleBox from "../../../components/utils/top-title-box.component";
import {
  verifyIdentifier,
  resendIdentifierVerificationCode,
} from "../../../services/auth/auth-slice.service";

function RegisterVerificationCodeScreen() {
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleVerifyCode = (value) => {
    setIsLoading(true);
    dispatch(verifyIdentifier({ method: location.state.method, code: value })).then(
      ({ meta, error, payload }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
          navigate(routes.HOME);
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  const handleSubmitResendCodes = () => {
    const values = {};
    values.method = location.state.method;

    dispatch(resendIdentifierVerificationCode(values)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.colors.bg.primary,
        minHeight: "100vh",
        opacity: 1,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        container
        sx={{
          width: "100%",
          flexDirection: "column",
        }}
      >
        <PaddedView multiples={isMobile ? 0 : 4}>
          <Grid item>
            <Grid
              container
              sx={{
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Grid item xs={12} sx={{ height: isMobile ? 45 : 60, textAlign: "center" }}>
                <KatchLogo color="green" />
              </Grid>
              <Spacer size="m" />
              <Box>
                <Box
                  sx={{
                    backgroundColor: theme.palette.colors.bg.white,
                    borderRadius: `${theme.shape.borderRadius[2]}px`,
                    flex: 1,
                    maxWidth: 500,
                    minWidth: isMobile ? null : 450,
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: 2,
                    overflow: "hidden",
                  }}
                >
                  <TopTitleBox
                    title={
                      location.state.method === "phone"
                        ? "Phone Verification"
                        : "Email Verification"
                    }
                    requireCloseButton={false}
                  />

                  <PaddedView multiples={isMobile ? 2 : 4}>
                    <Grid item xs={12}>
                      <Typography
                        sx={{ color: theme.palette.colors.text.primary }}
                        fontWeight="bold"
                      >
                        Enter verification code
                      </Typography>
                    </Grid>
                    <Spacer />
                    <Grid item xs={12}>
                      <Typography sx={{ color: theme.palette.colors.text.primary }}>
                        A verification code has been sent to {location.state.identifier}
                      </Typography>
                    </Grid>
                    <Spacer size="l" />
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                      <ReactCodeInput
                        fieldWidth={50}
                        onComplete={(value) => handleVerifyCode(value)}
                      />
                    </Grid>
                    <Spacer size="s" />
                    <Grid item xs={12} textAlign="center">
                      <ResendButton handleSubmit={handleSubmitResendCodes} />
                    </Grid>
                    <Spacer size="xl" />
                    <Grid item xs={12}>
                      <CustomButton
                        isLoading={isLoading}
                        fontPadding="5px"
                        fontSize={theme.fonts.fontSizes.size16}
                      >
                        Verify
                      </CustomButton>
                    </Grid>
                  </PaddedView>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </PaddedView>
      </Grid>
    </Box>
  );
}

export default RegisterVerificationCodeScreen;
