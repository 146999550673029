import { Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { addDays, addHours, format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CustomButton from "../../../components/button/custom-button.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import Spacer from "../../../components/utils/spacer.component";
import { getFitnessClasses } from "../../../services/search/fitness-class/search-fitness-class-slice.service";
import UpcomingClassCard from "./upcoming-class-card.component";
import UpcomingClassModal from "./upcoming-class-modal.component";

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: theme.fonts.fontSizes.size24,
}));

function UpcomingClassSection() {
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [showModal, setShowModal] = useState(false);
  const [items, setItems] = useState(null);

  useEffect(() => {
    dispatch(
      getFitnessClasses({
        startAt: format(addHours(new Date(), 1), "yyyy-MM-dd HH:mm:00"),
        endAt: format(addDays(new Date(), 30), "yyyy-MM-dd 00:00:00"),
        lat: 3.000031,
        long: 101.5330035,
        sortBy: "distance_nearest_to_farthest",
        page: 1,
      }),
    ).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setItems(payload.data);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const renderItems = () => {
    if (items) {
      if (items.items.length > 0) {
        return items.items.slice(0, isMobile ? 1 : 3).map((item) => (
          <Grid item key={item.id} xs={isMobile ? 12 : 4}>
            <UpcomingClassCard itemDetails={item} />
          </Grid>
        ));
      }
      return (
        <Grid item xs={12}>
          <Typography textAlign="center">No Upcoming Classes</Typography>
        </Grid>
      );
    }

    return (
      <>
        <Grid item xs={isMobile ? 12 : 4}>
          <CustomSkeleton width="100%" height={136} />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <CustomSkeleton width="100%" height={136} />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <CustomSkeleton width="100%" height={136} />
        </Grid>
      </>
    );
  };

  return items && items.items && items.items.length > 0 ? (
    <>
      <UpcomingClassModal showModal={showModal} setShowModal={setShowModal} />
      <Spacer size="l" />
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <StyledTypography>Try something new</StyledTypography>
        </Grid>
        {items && items.items.length > (isMobile ? 1 : 3) && (
          <Grid item>
            <CustomButton
              variant="outlined"
              fontSize={theme.fonts.fontSizes.size14}
              onClick={() => setShowModal(true)}
            >
              See More
            </CustomButton>
          </Grid>
        )}
      </Grid>
      <Spacer size="m" />
      <Grid container spacing={1}>
        {renderItems()}
      </Grid>
    </>
  ) : null;
}

export default UpcomingClassSection;
