import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq } from "../api.services";
import { getAPI } from "../../infrastructure/utils";

export const getCategory = createAsyncThunk("category/index", async () => {
  const response = await getReq(`${getAPI()}/categories`);
  return response;
});

const categorySlice = createSlice({
  name: "referral",
  initialState: {
    getCategoryObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [getCategory.pending]: (state) => {
      state.getCategoryObj.status = "pending";
    },
    [getCategory.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getCategoryObj.status = "succeeded";
      state.getCategoryObj.data = data;
      state.getCategoryObj.successMessage = message;
    },
    [getCategory.rejected]: (state, action) => {
      const { message } = action.error;

      state.getCategoryObj.status = "failed";
      state.getCategoryObj.errorMessage = message;
    },
  },
});

export default categorySlice.reducer;

// state
export const categorySelector = (state) => state.category;
