import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAPI } from "../../infrastructure/utils";
import { getReq } from "../api.services";

export const getHighlights = createAsyncThunk("highlights/index", async (payload) => {
  const response = getReq(
    `${getAPI()}/highlights?${payload.page ? "page=".concat(payload.page) : ""}`,
  );
  return response;
});

const highlightSlice = createSlice({
  name: "highlight",
  initialState: {
    dataList: [],
    selectedSessionsId: [],
    getHighlightsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getHighlights.pending]: (state) => {
      state.getHighlightsObj.status = "pending";
    },
    [getHighlights.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getHighlightsObj.status = "succeeded";
      state.getHighlightsObj.data = data;
      state.getHighlightsObj.successMessage = message;
    },
    [getHighlights.rejected]: (state, action) => {
      const { message } = action.error;

      state.getHighlightsObj.status = "failed";
      state.getHighlightsObj.errorMessage = message;
    },
  },
});

export default highlightSlice.reducer;

// state
export const highlightSelector = (state) => state.highlight;
