import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import routes from "../navigation/routes";
import Spacer from "../utils/spacer.component";

function FormFieldTextWithIcon({
  name,
  placeholder,
  type,
  width,
  showIcon,
  isTransparent,
  icon,
  chip,
  toUpper,
  readOnly,
  clearPromoForm,
  label,
}) {
  const theme = useTheme();
  const location = useLocation();
  const { setFieldTouched, setFieldValue, errors, touched, values, handleSubmit } =
    useFormikContext();
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [urlParams, setURLParams] = useSearchParams();

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  const showError = touched[name] && typeof errors[name] === "string";

  const getType = (inputType) => {
    const tempType = inputType;
    if (tempType === "password" && isShowPassword) {
      return "string";
    }
    return tempType;
  };

  return (
    <Box
      sx={{
        "& .MuiTextField-root": {
          borderRadius: `${theme.shape.borderRadius[1]}px`,
          width,
          "& .MuiOutlinedInput-root": {
            borderRadius: `${theme.shape.borderRadius[1]}px`,
            backgroundColor: isTransparent ? "transparent" : theme.palette.colors.bg.white,
          },
        },
      }}
    >
      <TextField
        size="small"
        label={label}
        onKeyDown={keyPress}
        error={showError}
        variant="outlined"
        helperText={showError ? errors[name] : null}
        onBlur={() => setFieldTouched(name)}
        name={name}
        type={getType(type || name)}
        placeholder={placeholder}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
          if (toUpper) {
            setFieldValue(name, e.target.value.toUpperCase());
          }

          if (name === "phoneNumber" || placeholder.toLowerCase().includes("phone number")) {
            setFieldValue(name, `+60${parseInt(e.target.value.replace("+60", ""), 10) || ""}`);
          }

          if (location.pathname === routes.EXPLORE) {
            if (values.tag !== "event") {
              urlParams.delete("tag");
              setURLParams(urlParams);
              setFieldValue("tag", "");
            }
          }
        }}
        InputProps={{
          readOnly,
          style: {
            fontWeight:
              name === "pointRedemption" && !values[name].includes("Redeem Points")
                ? "bold"
                : "normal",
          },
          startAdornment: showIcon && (
            <InputAdornment position="start">
              {icon}
              <Spacer position="right" />
              {chip}
              {(name === "phoneNumber" || placeholder.toLowerCase().includes("phone number")) &&
                "+60"}
            </InputAdornment>
          ),
          endAdornment:
            ((name === "password" ||
              name === "confirmPassword" ||
              name === "newPassword" ||
              name === "currentPassword") && (
              <InputAdornment position="start">
                <IconButton
                  onClick={() => {
                    setIsShowPassword(!isShowPassword);
                  }}
                >
                  {isShowPassword ? (
                    <VisibilityOffIcon color="primary" />
                  ) : (
                    <VisibilityIcon color="primary" />
                  )}
                </IconButton>
              </InputAdornment>
            )) ||
            (name === "promoCode" && values.promoCode !== "" && (
              <InputAdornment position="end">
                <IconButton onClick={clearPromoForm}>
                  <HighlightOffIcon />
                </IconButton>
              </InputAdornment>
            )),
        }}
        value={
          name === "phoneNumber" || placeholder.toLowerCase().includes("phone number")
            ? values[name].replace("+60", "")
            : values[name]
        }
      />
    </Box>
  );
}

FormFieldTextWithIcon.defaultProps = {
  type: null,
  width: "100%",
  showIcon: true,
  isTransparent: false,
  toUpper: false,
  icon: null,
  chip: null,
  readOnly: false,
  clearPromoForm: null,
  label: null,
};

FormFieldTextWithIcon.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
  width: PropTypes.string,
  showIcon: PropTypes.bool,
  readOnly: PropTypes.bool,
  isTransparent: PropTypes.bool,
  toUpper: PropTypes.bool,
  icon: PropTypes.node,
  chip: PropTypes.node,
  clearPromoForm: PropTypes.func,
  label: PropTypes.string,
};

export default FormFieldTextWithIcon;
