import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { isLogin } from "../../infrastructure/utils/index";
import routes from "./routes";

function RestrictedRoute() {
  return isLogin() ? <Navigate to={routes.HOME} /> : <Outlet />;
}

export default RestrictedRoute;
