import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import PlaceIcon from "@mui/icons-material/Place";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import routes from "../../../../components/navigation/routes";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";

function EventResultCard({ itemDetails }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Grid
      onClick={() =>
        navigate({ pathname: routes.EVENT, search: `?eventSessionId=${itemDetails.id}` })
      }
      item
      sx={{
        backgroundColor: theme.palette.colors.bg.primary,
        borderRadius: `${theme.shape.borderRadius[2]}px`,
        objectFit: "cover",
        cursor: "pointer",
        "&:hover .eventTitle": { color: theme.palette.colors.brand.primary },
      }}
    >
      <PaddedView multiples={isMobile ? 1 : 2}>
        <Grid container columnSpacing={2} alignContent="center">
          <Grid item alignSelf="center" paddingY="5px" alignItems="center" display="flex">
            <img
              src={itemDetails.event.images[0].imagePath}
              alt="eventImage"
              width={isMobile ? 100 : 200}
              height={isMobile ? 100 : 150}
              style={{ borderRadius: `${theme.shape.borderRadius[2]}px`, objectFit: "cover" }}
            />
          </Grid>
          <Grid
            item
            display="grid"
            flexDirection="column"
            alignContent="space-between"
            marginY="10px"
          >
            <Grid item>
              <Grid item>
                <Typography
                  fontWeight="bold"
                  className="eventTitle"
                  maxWidth={width < 450 ? "180px" : "none"}
                  sx={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    textTransform: "capitalize",
                  }}
                >
                  {itemDetails.title ? itemDetails.title : itemDetails.event.title}
                </Typography>
              </Grid>
              <Spacer />
              <Grid item display="flex" alignContent="center">
                <CalendarMonthOutlinedIcon color="primary" />
                <Spacer position="left" />
                <Typography>{itemDetails.date}</Typography>
              </Grid>
              <Grid item display="flex" alignContent="center">
                <AccessTimeOutlinedIcon color="primary" />
                <Spacer position="left" />
                <Typography>{itemDetails.time}</Typography>
              </Grid>
            </Grid>
            <Grid item display="flex" alignContent="center">
              <PlaceIcon color="primary" />
              <Spacer position="left" />
              <Grid container>
                <Typography
                  maxWidth={width < 450 ? (width * 0.52) / 2 : "none"}
                  sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
                >
                  {itemDetails.event.location.name}
                </Typography>
                <Typography>&nbsp;- {itemDetails.distanceKM}KM</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {itemDetails.multipleCategories && (
          <Grid item xs={12} sx={{ position: "relative", textAlign: "right" }}>
            <Grid
              item
              sx={{
                position: isMobile ? "relative" : "absolute",
                right: 0,
                bottom: isMobile ? 0 : 10,
              }}
            >
              <Typography color="primary" fontWeight="bold" textTransform="uppercase">
                Multiple Categories
              </Typography>
            </Grid>
          </Grid>
        )}
        {(itemDetails.booked || itemDetails.maxCapacity) && !itemDetails.multipleCategories && (
          <Grid item xs={12} sx={{ position: "relative", textAlign: "right" }}>
            <Grid
              item
              sx={{
                position: isMobile ? "relative" : "absolute",
                right: 0,
                bottom: isMobile ? 0 : 10,
              }}
            >
              {itemDetails.booked && (
                <Typography width="100%" textAlign="right" fontWeight="bold" color="primary">
                  Booked
                </Typography>
              )}
              {!itemDetails.booked && itemDetails.maxCapacity && (
                <Typography
                  width="100%"
                  textAlign="right"
                  fontWeight="bold"
                  sx={{ color: theme.palette.colors.ui.error }}
                >
                  Fully Booked
                </Typography>
              )}
            </Grid>
          </Grid>
        )}
      </PaddedView>
    </Grid>
  );
}

EventResultCard.defaultProps = {
  itemDetails: PropTypes.shape({
    id: 0,
    booked: false,
    title: "",
    price: "",
    unlimited: false,
    date: "",
    time: "",
    maxCapacity: false,
    multipleCategories: false,
    event: PropTypes.shape({
      id: 0,
      title: "",
      highlights: "",
      summary: "",
      location: PropTypes.shape({
        name: "",
        address: "",
        lat: "",
        long: "",
      }),
      images: [],
    }),
  }),
};

EventResultCard.propTypes = {
  itemDetails: PropTypes.shape({
    id: PropTypes.number,
    distanceKM: PropTypes.string,
    booked: PropTypes.bool,
    title: PropTypes.string,
    price: PropTypes.string,
    unlimited: PropTypes.bool,
    date: PropTypes.string,
    time: PropTypes.string,
    maxCapacity: PropTypes.bool,
    multipleCategories: PropTypes.bool,
    event: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      highlights: PropTypes.string,
      summary: PropTypes.string,
      location: PropTypes.shape({
        name: PropTypes.string,
        address: PropTypes.string,
        lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        long: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      images: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          imagePath: PropTypes.string,
        }),
      ),
    }),
  }),
};

export default EventResultCard;
