import { Grid, Link, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

function LabelLink({ link, label, pushTo }) {
  return (
    <Grid container justifyContent="center">
      <Typography variant="body2">
        {label}
        <Link color="rgba(0,0,0,1.0)" underline="always" key="1" variant="body2" href={pushTo}>
          {link}
        </Link>
      </Typography>
    </Grid>
  );
}

LabelLink.propTypes = {
  link: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  pushTo: PropTypes.string.isRequired,
};

export default LabelLink;
