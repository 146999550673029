import { Grid, MenuItem, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/button/custom-button.component";
import routes from "../../../components/navigation/routes";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import Spacer from "../../../components/utils/spacer.component";
import { searchSelector } from "../../../services/search/search-slice.service";
import { isLogin } from "../../utils";

function SearchNameDropDownCard() {
  const navigate = useNavigate();
  const { getRecentSearchesObj, getPopularSearchesObj } = useSelector(searchSelector);
  return (
    <>
      <Grid item>
        <Typography fontWeight="bold">Explore Katch</Typography>
      </Grid>
      <Spacer size="s" />
      <Grid container spacing={1}>
        <Grid item>
          <CustomButton
            variant="outlined"
            onClick={() => navigate(`${routes.EXPLORE}?tag=fitness`)}
          >
            FITNESS
          </CustomButton>
        </Grid>
        <Grid item>
          <CustomButton variant="outlined" onClick={() => navigate(`${routes.EXPLORE}?tag=health`)}>
            HEALTH
          </CustomButton>
        </Grid>
        <Grid item>
          <CustomButton
            variant="outlined"
            onClick={() => navigate(`${routes.EXPLORE}?tag=wellness`)}
          >
            WELLNESS
          </CustomButton>
        </Grid>
      </Grid>
      <Spacer size="m" />
      {isLogin() &&
        (getRecentSearchesObj.status === "succeeded" ? (
          <Grid item>
            {getRecentSearchesObj.data.length > 0 && (
              <Typography fontWeight="bold">Recent Searches</Typography>
            )}
            {getRecentSearchesObj.data.map((item) => (
              <MenuItem key={item.id} onClick={() => navigate(`${routes.EXPLORE}?q=${item.q}`)}>
                {item.q}
              </MenuItem>
            ))}
          </Grid>
        ) : (
          <Grid item>
            <CustomSkeleton width={200} />
            <Spacer />
          </Grid>
        ))}
      {getPopularSearchesObj.status === "succeeded" ? (
        <Grid item>
          {getPopularSearchesObj.data.length > 0 && (
            <>
              <Typography fontWeight="bold">Popular Searches</Typography>
              <Spacer size="s" />
              <Grid item container spacing={1}>
                {getPopularSearchesObj.data.map((item) => (
                  <Grid item key={item}>
                    <CustomButton
                      variant="outlined"
                      onClick={() => navigate(`${routes.EXPLORE}?q=${item}`)}
                    >
                      {item}
                    </CustomButton>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </Grid>
      ) : (
        <Grid item>
          <CustomSkeleton width={200} />
        </Grid>
      )}
    </>
  );
}

export default SearchNameDropDownCard;
