import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CircleTick from "../../../assets/images/circle_tick";
import WarningIcon from "../../../assets/images/warning_icon.png";
import CustomButton from "../../../components/button/custom-button.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Spacer from "../../../components/utils/spacer.component";
import { showTokenizedPayment } from "../../../services/tokenized-payment/tokenized-payment-slice.service";
import routes from "../../../components/navigation/routes";

export default function TokenizedPaymentRedirectScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const location = useLocation();
  const remoteCustomerId = new URLSearchParams(location.search).get("customerId");
  const status = new URLSearchParams(location.search).get("status");
  const navigate = useNavigate();

  const addSuccessCreditCard = () => {
    dispatch(showTokenizedPayment(remoteCustomerId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
      if (meta.requestStatus === "fulfilled") {
        navigate(routes.PAYMENT_METHODS);
      }
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        paddingBottom: "50px",
      }}
    >
      <Spacer size="xxl" />
      {status === "SUCCESS" ? (
        <>
          <CircleTick />
          <Spacer size="xl" />
          <Typography fontWeight="bold" variant={isMobile ? "h4" : "h3"}>
            Success
          </Typography>
          <Spacer size="l" />
          <Typography color={theme.palette.colors.text.secondary} variant="h6">
            Please click <span style={{ fontWeight: "bold", color: "#000" }}>Continue</span> to add
            your validated credit card as your payment option on Katch.
          </Typography>
          <Spacer size="xl" />
          <Grid container justifyContent="center">
            <Grid item xs={4}>
              <CustomButton fontSize="18px" onClick={() => addSuccessCreditCard()}>
                Continue
              </CustomButton>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <img src={WarningIcon} alt="warningIcon" width={170} />
          <Spacer size="xl" />
          <Typography fontWeight="bold" variant={isMobile ? "h4" : "h3"}>
            Failed
          </Typography>
          <Spacer size="l" />
          <Typography color={theme.palette.colors.text.secondary} variant="h6">
            Your credit card was declined, please try again.
          </Typography>
          <Spacer size="xl" />
          <Grid container justifyContent="center">
            <Grid item xs={4}>
              <CustomButton fontSize="18px" onClick={() => navigate(routes.PAYMENT_METHODS)}>
                Try Again
              </CustomButton>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
}
