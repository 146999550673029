import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { Grid } from "@mui/material";
import React from "react";
import FormFieldTextWithIcon from "../../../../components/forms/form-field-text-with-icon.component";
import PaddedView from "../../../../components/utils/padded-view.component";
import PhoneNumberTextField from "../../../../components/forms/phone-number-text-field.component";

function GuestDetail() {
  return (
    <PaddedView>
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <FormFieldTextWithIcon
            name="email"
            label="Email"
            placeholder="Enter your email address"
            showIcon={true}
            icon={<MailOutlineIcon color="primary" />}
          />
        </Grid>
        <Grid item xs={12}>
          <PhoneNumberTextField name="phone" />
        </Grid>
        <Grid item xs={12}>
          <FormFieldTextWithIcon
            name="firstName"
            label="First Name"
            placeholder="First Name"
            showIcon={true}
            icon={<PersonOutlineOutlinedIcon color="primary" />}
          />
        </Grid>
        <Grid item xs={12}>
          <FormFieldTextWithIcon
            name="lastName"
            label="Last Name"
            placeholder="Last Name"
            showIcon={true}
            icon={<PersonOutlineOutlinedIcon color="primary" />}
          />
        </Grid>
      </Grid>
    </PaddedView>
  );
}

export default GuestDetail;
