import { Box, Grid, Modal, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../button/custom-button.component";
import routes from "../navigation/routes";
import Spacer from "../utils/spacer.component";
import TopTitleBox from "../utils/top-title-box.component";

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.brand.white,
  borderTopLeftRadius: theme.shape.borderRadius[2],
  borderTopRightRadius: theme.shape.borderRadius[2],
  borderBottomLeftRadius: theme.shape.borderRadius[2],
  borderBottomRightRadius: theme.shape.borderRadius[2],
  boxShadow: 24,
  paddingTop: "25px",
  paddingBottom: "25px",
  paddingLeft: "50px",
  paddingRight: "50px",
  width: isMobile ? "350px" : "450px",
  outline: "none",
}));

export default function InsufficientCreditModal({ isShow, setIsShowAlert }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const handleCancel = () => {
    setIsShowAlert(false);
  };

  return (
    <Modal open={isShow} onClose={handleCancel}>
      <ModalBox isMobile={isMobile}>
        <Grid container>
          <TopTitleBox title="" requireCloseButton={true} setShowModal={setIsShowAlert} />
          <Grid item xs={12}>
            <Spacer size="l" />

            <Typography textAlign="justify">
              Sorry, you don&apos;t have enough balance in your account credits for this payment.
              Please choose another payment method or top up your credits to continue.
            </Typography>
          </Grid>
          <Grid item xs={12} paddingTop="50px">
            <CustomButton fontSize="16px" onClick={() => navigate(routes.CREDIT)}>
              Top-up
            </CustomButton>
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

InsufficientCreditModal.propTypes = {
  isShow: PropTypes.bool.isRequired,
  setIsShowAlert: PropTypes.func.isRequired,
};
