import { Grid, Rating, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import HorizontalDivider from "../../../components/utils/horizontal-divider.component";
import Spacer from "../../../components/utils/spacer.component";

function ReviewRow({ itemDetails }) {
  const theme = useTheme();
  return (
    <Grid item xs={12}>
      <Grid item container xs={12} justifyContent="space-between">
        <Typography
          textTransform="capitalize"
          fontSize={theme.fonts.fontSizes.size14}
          sx={{ color: theme.palette.colors.text.secondary }}
        >
          {itemDetails.session.type === "merchant_class_session"
            ? `${itemDetails.session.class.name} - Fitness Class Session`
            : "Gym Session"}
        </Typography>
        <Typography
          fontSize={theme.fonts.fontSizes.size14}
          sx={{ color: theme.palette.colors.text.secondary }}
        >
          {itemDetails.session.date}
        </Typography>
      </Grid>
      <Grid container alignItems="center" item xs={12}>
        <Grid item>
          <Typography>{itemDetails.user.name}</Typography>
        </Grid>
        <Spacer position="left" />
        <Grid item>
          <Rating
            value={itemDetails.rating}
            readOnly
            sx={{ color: theme.palette.colors.brand.primary }}
          />
        </Grid>
      </Grid>
      <Grid item>
        <Typography textAlign="justify"> {itemDetails.comment}</Typography>
      </Grid>
      <HorizontalDivider />
    </Grid>
  );
}

ReviewRow.defaultProps = {
  itemDetails: PropTypes.shape({
    id: null,
    rating: 5,
    user: PropTypes.shape({
      name: "",
    }),
    comment: "",
    session: PropTypes.shape({
      type: "",
      date: "",
    }),
  }),
};

ReviewRow.propTypes = {
  itemDetails: PropTypes.shape({
    id: PropTypes.number,
    rating: PropTypes.number,
    user: PropTypes.shape({
      name: PropTypes.string,
    }),
    comment: PropTypes.string,
    session: PropTypes.shape({
      type: PropTypes.string,
      date: PropTypes.string,
      class: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  }),
};

export default ReviewRow;
