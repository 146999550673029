import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq } from "../../api.services";

export const getPurchasedSubscriptionTransactions = createAsyncThunk(
  "subscriptions/purchases/transactions?page={page}&status={status}",
  async (payload) => {
    const { page, status } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/subscriptions/purchases/transactions?page=${page}&status=${status}`,
    );
    return response;
  },
);

const purchasedSubscriptionSlice = createSlice({
  name: "purchasedSubscription",
  initialState: {
    reduxStorePurchasedSubscriptionTransactions: [],
    getPurchasedSubscriptionTransactionsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getPurchasedSubscriptionTransactions.pending]: (state) => {
      state.getPurchasedSubscriptionTransactionsObj.status = "pending";
    },
    [getPurchasedSubscriptionTransactions.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getPurchasedSubscriptionTransactionsObj.status = "succeeded";
      state.getPurchasedSubscriptionTransactionsObj.data = data;
      state.getPurchasedSubscriptionTransactionsObj.successMessage = message;
    },
    [getPurchasedSubscriptionTransactions.rejected]: (state, action) => {
      const { message } = action.error;

      state.getPurchasedSubscriptionTransactionsObj.status = "failed";
      state.getPurchasedSubscriptionTransactionsObj.errorMessage = message;
    },
  },
});

export default purchasedSubscriptionSlice.reducer;

// state
export const purchasedSubscriptionSelector = (state) => state.purchasedSubscription;
