import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq } from "../api.services";
import { getAPI } from "../../infrastructure/utils";

export const getBusinessDetails = createAsyncThunk("business/details", async (payload) => {
  const response = await getReq(`${getAPI()}/businesses/${payload}`);
  return response;
});

const businessSlice = createSlice({
  name: "business",
  initialState: {
    getBusinessDetailsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getBusinessDetails.pending]: (state) => {
      state.getBusinessDetailsObj.status = "pending";
    },
    [getBusinessDetails.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getBusinessDetailsObj.status = "succeeded";
      state.getBusinessDetailsObj.data = data;
      state.getBusinessDetailsObj.successMessage = message;
    },
    [getBusinessDetails.rejected]: (state, action) => {
      const { message } = action.error;

      state.getBusinessDetailsObj.status = "failed";
      state.getBusinessDetailsObj.errorMessage = message;
    },
  },
});

export default businessSlice.reducer;

// state
export const businessSelector = (state) => state.business;
