import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAPI } from "../../infrastructure/utils";
import { getReq } from "../api.services";

export const getBanners = createAsyncThunk("marketing/banners/index", async () => {
  const response = await getReq(`${getAPI()}/marketings/banners`);
  return response;
});

export const bannerSlice = createSlice({
  name: "banner",
  initialState: {
    getBannersObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getBanners.pending]: (state) => {
      state.getBannersObj.status = "pending";
    },
    [getBanners.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getBannersObj.status = "succeeded";
      state.getBannersObj.data = data;
      state.getBannersObj.successMessage = message;
    },
    [getBanners.rejected]: (state, action) => {
      const { message } = action.error;

      state.getBannersObj.status = "rejected";
      state.getBannersObj.errorMessage = message;
    },
  },
});

export default bannerSlice.reducer;

// state
export const bannerSelector = (state) => state.banner;
