import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAPIV2 } from "../../../infrastructure/utils";
import { getReq } from "../../api.services";

export const getEvents = createAsyncThunk("searches/events/index", async (payload) => {
  const { page, q, startAt, endAt, eventCategoryIds, lat, long, distance, sortBy } = payload;
  const response = await getReq(
    `${getAPIV2()}/searches/events?page=${page}${q ? "&q=".concat(q) : ""}${
      sortBy ? "&sortBy=".concat(sortBy) : ""
    }&startAt=${startAt}&endAt=${endAt}${
      eventCategoryIds.length > 0 ? "&eventCategoryIds=".concat(eventCategoryIds) : ""
    }&lat=${lat}&long=${long}&distance=${distance}`,
  );
  return response;
});

export const getNearbyEvents = createAsyncThunk("searches/events/nearby", async (payload) => {
  const { lat, long, page, startAt, endAt } = payload;
  const response = await getReq(
    `${getAPIV2()}/searches/events/nearby?lat=${lat}&long=${long}&startAt=${startAt}&endAt=${endAt}&page=${page}`,
  );
  return response;
});

export const getUpcomingEvents = createAsyncThunk("searches/events/upcoming", async (payload) => {
  const { lat, long, businessIds, page } = payload;
  const response = await getReq(
    `${getAPIV2()}/searches/events/upcoming?lat=${lat}&long=${long}&page=${page}${
      businessIds ? "&businessIds=".concat(businessIds) : ""
    }`,
  );
  return response;
});

const searchEventSlice = createSlice({
  name: "searchEvent",
  initialState: {
    getEventsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getNearbyEventsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getUpcomingEventsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    reduxStoreEvents: [],
    reduxStoreNearbyEvents: [],
  },
  reducers: {},
  extraReducers: {
    [getEvents.pending]: (state) => {
      state.getEventsObj.status = "pending";
    },
    [getEvents.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getEventsObj.status = "succeeded";
      state.getEventsObj.data = data;
      state.getEventsObj.successMessage = message;
    },
    [getEvents.rejected]: (state, action) => {
      const { message } = action.error;

      state.getEventsObj.status = "failed";
      state.getEventsObj.errorMessage = message;
    },
    [getNearbyEvents.pending]: (state) => {
      state.getNearbyEventsObj.status = "pending";
    },
    [getNearbyEvents.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getNearbyEventsObj.status = "succeeded";
      state.getNearbyEventsObj.data = data;
      state.getNearbyEventsObj.successMessage = message;
    },
    [getNearbyEvents.rejected]: (state, action) => {
      const { message } = action.error;

      state.getNearbyEventsObj.status = "failed";
      state.getNearbyEventsObj.errorMessage = message;
    },
    [getUpcomingEvents.pending]: (state) => {
      state.getUpcomingEventsObj.status = "pending";
    },
    [getUpcomingEvents.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getUpcomingEventsObj.status = "succeeded";
      state.getUpcomingEventsObj.data = data;
      state.getUpcomingEventsObj.successMessage = message;
    },
    [getUpcomingEvents.rejected]: (state, action) => {
      const { message } = action.error;

      state.getUpcomingEventsObj.status = "failed";
      state.getUpcomingEventsObj.errorMessage = message;
    },
  },
});

export default searchEventSlice.reducer;

// state
export const searchEventSelector = (state) => state.searchEvent;
