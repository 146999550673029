import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../components/button/custom-button.component";
import Loading from "../../../components/notification/backdrop-loading.component";
import DialogBox from "../../../components/notification/dialog-box.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import PaddedView from "../../../components/utils/padded-view.component";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import Spacer from "../../../components/utils/spacer.component";
import { profileSelector } from "../../../services/profile/profile-slice.service";
import {
  createTokenizedPayments,
  getTokenizedPayments,
  tokenizedPaymentSelector,
  updateTokenizedPayment,
} from "../../../services/tokenized-payment/tokenized-payment-slice.service";

function CreditCardList() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { getTokenizedPaymentsObj, updateTokenizedPaymentObj } =
    useSelector(tokenizedPaymentSelector);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { getProfileDetailObj } = useSelector(profileSelector);

  const getCreditCards = () => {
    dispatch(getTokenizedPayments()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  useEffect(() => {
    getCreditCards();
  }, []);

  const addCreditCard = () => {
    setIsLoading(true);
    dispatch(createTokenizedPayments()).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
      if (meta.requestStatus === "fulfilled") {
        window.open(payload.data.paymentUrl, "_self");
      }
    });
  };

  const removeCreditCard = (remoteCustomerId) => {
    dispatch(updateTokenizedPayment(remoteCustomerId)).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
      if (meta.requestStatus === "fulfilled") {
        getCreditCards();
      }
    });
  };

  const renderCreditCards = () => {
    if (getTokenizedPaymentsObj.status === "succeeded") {
      if (getTokenizedPaymentsObj.data.length > 0) {
        return getTokenizedPaymentsObj.data.map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item.remoteCustomerId}>
            <Box
              sx={{
                border: "2px solid",
                borderColor: theme.palette.colors.text.secondary,
                borderRadius: `${theme.shape.borderRadius[1]}px`,
                width: "100%",
                height: "200px",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  marginTop: "30px",
                  height: "30px",
                  backgroundColor: "#000",
                }}
              />
              <Box
                sx={{ marginTop: "auto", position: "absolute", bottom: 10, paddingLeft: "10px" }}
              >
                {getProfileDetailObj.status === "succeeded" && (
                  <Typography>
                    {getProfileDetailObj.data.firstName} {getProfileDetailObj.data.lastName}
                  </Typography>
                )}
                <Typography>{item.label}</Typography>
              </Box>
              <Box
                sx={{
                  marginTop: "auto",
                  position: "absolute",
                  right: 10,
                  bottom: 10,
                  paddingLeft: "10px",
                }}
              >
                <CustomButton
                  isLoading={updateTokenizedPaymentObj.status === "pending"}
                  variant="outlined"
                  buttonColor="red"
                  onClick={() => removeCreditCard(item.remoteCustomerId)}
                >
                  Remove
                </CustomButton>
              </Box>
            </Box>
          </Grid>
        ));
      }
      return (
        <Grid item xs={12} textAlign="center">
          <Typography fontWeight="bold">No saved credit cards</Typography>
          <Spacer size="m" />
          <Typography>Store your credit cards, to make purchases for recurring plans</Typography>
          <Spacer size="m" />
        </Grid>
      );
    }
    return (
      <>
        <Grid item xs={12} sm={6} md={4}>
          <CustomSkeleton width="100%" height={200} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <CustomSkeleton width="100%" height={200} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <CustomSkeleton width="100%" height={200} />
        </Grid>
      </>
    );
  };

  return (
    <Grid
      container
      sx={{
        backgroundColor: theme.palette.colors.bg.primary,
        borderRadius: `${theme.shape.borderRadius[1]}px`,
      }}
    >
      <DialogBox
        title="Adding credit card"
        isShow={showModal}
        setIsShowAlert={setShowModal}
        message={
          <Grid item>
            <Typography sx={{ textAlign: "justify" }}>
              Please ensure that you do not refresh or close the website during this process and
              follow through with the steps provided.
            </Typography>
          </Grid>
        }
        isConfirmHandle={() => {
          setShowModal(false);
          addCreditCard();
        }}
        buttonText="Continue"
      />
      <PaddedView multiples={2}>
        <Loading isLoading={isLoading} />
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography fontWeight="bold" fontSize={theme.fonts.fontSizes.size20}>
              Credit Cards
            </Typography>
          </Grid>
          <Grid item alignSelf="center">
            <CustomButton
              startIcon={<AddCircleOutlinedIcon color="white" />}
              onClick={() => setShowModal(true)}
            >
              Add Card
            </CustomButton>
          </Grid>
        </Grid>
        <Spacer position="top" size="m" />
        <Grid container spacing={2}>
          {renderCreditCards()}
        </Grid>
      </PaddedView>
    </Grid>
  );
}

export default CreditCardList;
