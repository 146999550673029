import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/button/custom-button.component";
import routes from "../../../components/navigation/routes";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import {
  authSelector,
  verifyIdentifier,
  logout,
  resendIdentifierVerificationCode,
  resetUser,
} from "../../../services/auth/auth-slice.service";
import {
  getProfileDetail,
  profileSelector,
  resetProfile,
  updateDOB,
  updateEmail,
  updateGender,
  updatePassword,
  updatePhoneNumber,
} from "../../../services/profile/profile-slice.service";
import { getReferral, referralSelector } from "../../../services/referral/referral-slice.service";
import AccountCreditsButton from "../components/account-credits-button.component";
import EditDOBModal from "../components/edit-dob-modal.component";
import EditEmailModal from "../components/edit-email-modal.component";
import EditGenderModal from "../components/edit-gender-modal.component";
import EditPasswordModal from "../components/edit-password.modal.component";
import EditPhoneNumberModal from "../components/edit-phone-number-modal.component";
import PhotoUpload from "../components/photo-upload.component";
import ReferralsButton from "../components/refferals-button.component";
import VerificationModal from "../components/verification-modal.component";
import UserProfileLoader from "../loader/user-profile-loader.component";
import AccountDeletionModal from "../components/account-deletion-modal.component";
import DialogBox from "../../../components/notification/dialog-box.component";
import {
  cancelAccountDeletion,
  requestAccountDeletion,
  resendVerificationAccountDeletion,
  sendAccountDeletionReason,
  verifyAccountDeletion,
} from "../../../services/account-deletion/account-deletion-slice.service";

function UserProfileScreen() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const {
    getProfileDetailObj,
    updatePhoneNumberObj,
    updateEmailObj,
    updateGenderObj,
    updateDOBObj,
    updatePasswordObj,
  } = useSelector(profileSelector);
  const { verifyIdentifierObj } = useSelector(authSelector);
  const { getReferralObj } = useSelector(referralSelector);
  const createSnackBar = useContext(SnackbarContext);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showEmailVerificationModal, setShowEmailVerificationModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showPhoneNumberModal, setShowPhoneNumberModal] = useState(false);
  const [showPhoneNumberVerificationModal, setShowPhoneNumberVerificationModal] = useState(false);
  const [showGenderModal, setShowGenderModal] = useState(false);
  const [showDOBModal, setShowDOBModal] = useState(false);
  const [deleteID, setDeleteID] = useState("");
  const [showAccountDeleteModal, setShowAccountDeleteModal] = useState(false);
  const [showAccountDeleteVerificationModal, setShowAccountDeleteVerificationModal] =
    useState(false);
  const [showAccountDeleteReasonModal, setShowAccountDeleteReasonModal] = useState(false);

  const handleUpdatePassword = (value) => {
    dispatch(updatePassword(value)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
        setShowPasswordModal(false);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleUpdatePhoneNumber = (value) => {
    dispatch(updatePhoneNumber(value)).then(({ meta, error }) => {
      if (meta.requestStatus === "fulfilled") {
        setShowPhoneNumberModal(false);
        setShowPhoneNumberVerificationModal(true);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleVerifyPhoneNumber = (value) => {
    dispatch(verifyIdentifier({ method: "phone", code: value })).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
          setShowPhoneNumberVerificationModal(false);
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  const handleResendVerifyPhoneNumber = () => {
    dispatch(resendIdentifierVerificationCode({ method: "phone" })).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  const handleUpdateEmail = (value) => {
    dispatch(updateEmail(value)).then(({ meta, error }) => {
      if (meta.requestStatus === "fulfilled") {
        setShowEmailModal(false);
        setShowEmailVerificationModal(true);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleVerifyEmail = (value) => {
    dispatch(verifyIdentifier({ method: "email", code: value })).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          setShowEmailVerificationModal(false);
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  const handleResendVerifyEmail = () => {
    dispatch(
      resendIdentifierVerificationCode({
        method: "email",
      }),
    ).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleUpdateGender = (value) => {
    dispatch(updateGender(value)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
        setShowGenderModal(false);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleUpdateDOB = (value) => {
    dispatch(updateDOB(value)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
        setShowDOBModal(false);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleRequestAccountDeletion = () => {
    dispatch(requestAccountDeletion({ verificationMethod: "phone" })).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
          setShowAccountDeleteModal(false);
          setDeleteID(payload.data.id);
          setShowAccountDeleteVerificationModal(true);
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  const handleVerifyAccountDeletion = (value) => {
    dispatch(verifyAccountDeletion({ deleteId: deleteID, verificationCode: value })).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
          setShowAccountDeleteVerificationModal(false);
          setShowAccountDeleteReasonModal(true);
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  const handleResendVerifyAccountDeletion = () => {
    dispatch(resendVerificationAccountDeletion({ deleteId: deleteID })).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  const handleCancelAccountDeletion = () => {
    dispatch(cancelAccountDeletion({ deleteId: deleteID })).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
        setShowAccountDeleteVerificationModal(false);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleLogout = () => {
    dispatch(logout()).then(({ meta }) => {
      dispatch(resetProfile());
      if (meta.requestStatus === "fulfilled") {
        navigate(routes.LOGIN);
      }
      if (meta.requestStatus === "rejected") {
        dispatch(resetUser());
        navigate(routes.LOGIN);
      }
    });
  };

  const handleSendAccountDeletionReason = (value) => {
    dispatch(sendAccountDeletionReason({ deleteId: deleteID, ...value })).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
          setShowAccountDeleteReasonModal(false);
          handleLogout();
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  useEffect(() => {
    dispatch(getProfileDetail());
    dispatch(getReferral());
  }, []);

  const renderProfile = () => {
    if (getProfileDetailObj.status === "succeeded" && getReferralObj.status === "succeeded") {
      return (
        <Grid
          container
          sx={{
            width: "100%",
            flexDirection: "column",
          }}
        >
          <PaddedView multiples={2}>
            <Grid
              container
              spacing={2}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Grid item>
                <Grid
                  container
                  sx={{
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                  spacing={2}
                >
                  <Grid item>
                    <PhotoUpload imagePath={getProfileDetailObj.data.imagePath} name="photo" />
                  </Grid>

                  <Grid
                    item
                    sx={{
                      flexDirection: "column",
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <Typography
                        fontWeight="bold"
                        fontSize="20px"
                        sx={{ textTransform: "capitalize" }}
                      >
                        {`${getProfileDetailObj.data.firstName} ${getProfileDetailObj.data.lastName}`}
                      </Typography>
                      <Spacer position="left" size="xs" />
                      {getProfileDetailObj.data.status === "verified" && (
                        <CheckCircleIcon
                          fontSize="small"
                          sx={{ color: theme.palette.colors.brand.primary }}
                        />
                      )}
                    </Box>

                    <Typography color={theme.palette.colors.ui.secondary} fontSize="11px">
                      Joined since {getProfileDetailObj.data.joinDate}
                    </Typography>
                    {getProfileDetailObj.data.reject_reason && (
                      <Typography color={theme.palette.colors.text.error} fontSize="14px">
                        {getProfileDetailObj.data.reject_reason}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                {getProfileDetailObj.data.status !== "verified" && isMobile && (
                  <Grid container item xs={12}>
                    <Typography color={theme.palette.colors.ui.error} fontSize="14px">
                      Secure your profile with a quick selfie! Verify your identity, prevent fraud,
                      and ensure smooth account recovery. Your safety is our priority.
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <AccountCreditsButton onClick={() => navigate(routes.CREDIT)}>
                      RM{getProfileDetailObj.data.creditBalance}
                    </AccountCreditsButton>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ReferralsButton onClick={() => navigate(routes.REFERRALS)}>
                      {getReferralObj.data.referralCode}
                    </ReferralsButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {getProfileDetailObj.data.status !== "verified" && !isMobile && (
              <Grid container item xs={6}>
                <Typography color={theme.palette.colors.ui.error} fontSize="14px">
                  Secure your profile with a quick selfie! Verify your identity, prevent fraud, and
                  ensure smooth account recovery. Your safety is our priority.
                </Typography>
              </Grid>
            )}
            <Spacer size="l" />
            <Grid
              container
              sx={{
                backgroundColor: theme.palette.colors.bg.primary,
                borderRadius: `${theme.shape.borderRadius[2]}px`,
              }}
            >
              <PaddedView multiples={isMobile ? 2 : 4}>
                <Grid
                  container
                  sx={{
                    flexDirection: "column",
                  }}
                >
                  <Grid container>
                    <Grid item>
                      <Typography fontWeight="bold">Profile</Typography>
                    </Grid>
                  </Grid>
                  <Spacer size="l" />

                  <Grid
                    container
                    sx={{
                      justifyContent: "space-between",
                      alignItems: isMobile ? "space-between" : "center",
                      flexDirection: isMobile ? "column" : "row",
                    }}
                  >
                    <Grid item xs={4}>
                      <Typography>Email</Typography>
                    </Grid>

                    <Grid
                      item
                      container
                      xs={isMobile ? 12 : 8}
                      justifyContent={!isMobile && "flex-end"}
                      alignItems="center"
                    >
                      <Grid
                        item
                        sx={{
                          alignItems: "center",
                        }}
                      >
                        <Typography color={theme.palette.colors.text.secondary}>
                          {getProfileDetailObj.data.email}
                        </Typography>
                      </Grid>
                      <Spacer position="left" size="m" />
                      <Grid item>
                        {!getProfileDetailObj.data.email ||
                        getProfileDetailObj.data.emailVerified ||
                        verifyIdentifierObj.data?.emailVerified ? (
                          <CustomButton variant="text" onClick={() => setShowEmailModal(true)}>
                            Edit
                          </CustomButton>
                        ) : (
                          <CustomButton
                            variant="text"
                            onClick={() => setShowEmailVerificationModal(true)}
                          >
                            Verify
                          </CustomButton>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Spacer size="m" />
                  <Grid
                    container
                    sx={{
                      justifyContent: "space-between",
                      alignItems: isMobile ? "space-between" : "center",
                      flexDirection: isMobile ? "column" : "row",
                    }}
                  >
                    <Grid item xs={4}>
                      <Typography>Password</Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={isMobile ? 12 : 8}
                      justifyContent={!isMobile && "flex-end"}
                      alignItems="center"
                    >
                      <Grid
                        item
                        sx={{
                          alignItems: "center",
                        }}
                      >
                        <Typography color={theme.palette.colors.text.secondary}>
                          ********
                        </Typography>
                      </Grid>
                      <Spacer position="left" size="m" />
                      <Grid item>
                        <CustomButton variant="text" onClick={() => setShowPasswordModal(true)}>
                          Edit
                        </CustomButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Spacer size="m" />
                  <Grid
                    container
                    sx={{
                      justifyContent: "space-between",
                      alignItems: isMobile ? "space-between" : "center",
                      flexDirection: isMobile ? "column" : "row",
                    }}
                  >
                    <Grid item>
                      <Typography>Phone Number</Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={isMobile ? 12 : 8}
                      justifyContent={!isMobile && "flex-end"}
                      alignItems="center"
                    >
                      <Grid
                        item
                        sx={{
                          alignItems: "center",
                        }}
                      >
                        <Typography color={theme.palette.colors.text.secondary}>
                          {getProfileDetailObj.data.phoneNumber}
                        </Typography>
                      </Grid>
                      <Spacer position="left" size="m" />
                      <Grid item>
                        {!getProfileDetailObj.data.phoneNumber ||
                        getProfileDetailObj.data.phoneVerified ||
                        verifyIdentifierObj.data?.phoneVerified ? (
                          <CustomButton
                            variant="text"
                            onClick={() => setShowPhoneNumberModal(true)}
                          >
                            Edit
                          </CustomButton>
                        ) : (
                          <CustomButton
                            variant="text"
                            onClick={() => setShowPhoneNumberVerificationModal(true)}
                          >
                            Verify
                          </CustomButton>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Spacer size="m" />
                  <Grid
                    container
                    sx={{
                      justifyContent: "space-between",
                      alignItems: isMobile ? "space-between" : "center",
                      flexDirection: isMobile ? "column" : "row",
                    }}
                  >
                    <Grid item>
                      <Typography>Gender</Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={isMobile ? 12 : 8}
                      justifyContent={!isMobile && "flex-end"}
                      alignItems="center"
                    >
                      <Grid
                        item
                        sx={{
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          color={theme.palette.colors.text.secondary}
                          sx={{ textTransform: "capitalize" }}
                        >
                          {getProfileDetailObj.data.gender}
                        </Typography>
                      </Grid>
                      <Spacer position="left" size="m" />
                      <Grid item>
                        <CustomButton variant="text" onClick={() => setShowGenderModal(true)}>
                          Edit
                        </CustomButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Spacer size="m" />
                  <Grid
                    container
                    sx={{
                      justifyContent: "space-between",
                      alignItems: isMobile ? "space-between" : "center",
                      flexDirection: isMobile ? "column" : "row",
                    }}
                  >
                    <Grid item>
                      <Typography>Date of Birth</Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={isMobile ? 12 : 8}
                      justifyContent={!isMobile && "flex-end"}
                      alignItems="center"
                    >
                      <Grid
                        item
                        sx={{
                          alignItems: "center",
                        }}
                      >
                        <Typography color={theme.palette.colors.text.secondary}>
                          {getProfileDetailObj.data.dateOfBirth}
                        </Typography>
                      </Grid>
                      <Spacer position="left" size="m" />
                      <Grid item>
                        <CustomButton variant="text" onClick={() => setShowDOBModal(true)}>
                          Edit
                        </CustomButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Spacer size="m" />
                  <Grid
                    container
                    sx={{
                      justifyContent: "space-between",
                      alignItems: isMobile ? "space-between" : "center",
                      flexDirection: isMobile ? "column" : "row",
                    }}
                  >
                    <Grid item>
                      <Typography>Account Deletion</Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={isMobile ? 12 : 8}
                      justifyContent={!isMobile && "flex-end"}
                      alignItems="center"
                    >
                      <Grid item>
                        <CustomButton
                          variant="text"
                          onClick={() => setShowAccountDeleteModal(true)}
                        >
                          Request
                        </CustomButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </PaddedView>
            </Grid>
          </PaddedView>
        </Grid>
      );
    }

    return <UserProfileLoader />;
  };

  return (
    <>
      <EditEmailModal
        showModal={showEmailModal}
        setShowModal={setShowEmailModal}
        onSubmit={handleUpdateEmail}
        title="Edit Email"
        buttonText="Update"
        isDisabled={updateEmailObj.status === "pending"}
        prevEmail={getProfileDetailObj.data?.email}
      />
      <VerificationModal
        showModal={showEmailVerificationModal}
        setShowModal={setShowEmailVerificationModal}
        onSubmit={handleVerifyEmail}
        title="Email Verification"
        message={`Enter the Verification Code sent to ${getProfileDetailObj.data?.email}`}
        buttonText="Resend Code"
        onResend={handleResendVerifyEmail}
      />
      <EditPasswordModal
        showModal={showPasswordModal}
        setShowModal={setShowPasswordModal}
        onSubmit={handleUpdatePassword}
        title="Edit Password"
        buttonText="Change Password"
        isDisabled={updatePasswordObj.status === "pending"}
      />
      <EditPhoneNumberModal
        showModal={showPhoneNumberModal}
        setShowModal={setShowPhoneNumberModal}
        onSubmit={handleUpdatePhoneNumber}
        title="Edit Phone Number"
        buttonText="Update"
        isDisabled={updatePhoneNumberObj.status === "pending"}
        prevPhoneNum={getProfileDetailObj.data?.phoneNumber}
      />
      <VerificationModal
        showModal={showPhoneNumberVerificationModal}
        setShowModal={setShowPhoneNumberVerificationModal}
        onSubmit={handleVerifyPhoneNumber}
        title="Phone Number Verification"
        message={`Enter the Verification Code sent to ${getProfileDetailObj.data?.phoneNumber}`}
        buttonText="Resend Code"
        onResend={handleResendVerifyPhoneNumber}
      />
      <EditGenderModal
        showModal={showGenderModal}
        setShowModal={setShowGenderModal}
        onSubmit={handleUpdateGender}
        title="Edit Gender"
        buttonText="Update"
        isDisabled={updateGenderObj.status === "pending"}
        prevGender={getProfileDetailObj.data?.gender}
      />
      <EditDOBModal
        showModal={showDOBModal}
        setShowModal={setShowDOBModal}
        onSubmit={handleUpdateDOB}
        title="Edit Date of Birth"
        buttonText="Update"
        isDisabled={updateDOBObj.status === "pending"}
        prevDOB={getProfileDetailObj.data?.dateOfBirth}
      />
      <DialogBox
        title="Confirm Account Deletion?"
        isShow={showAccountDeleteModal}
        setIsShowAlert={setShowAccountDeleteModal}
        message={
          <Grid item>
            <Typography sx={{ textAlign: "center" }}>
              Confirming to proceed will send a verification code to the registered phone number.
            </Typography>
          </Grid>
        }
        isConfirmHandle={handleRequestAccountDeletion}
        buttonText="Proceed"
      />

      <VerificationModal
        showModal={showAccountDeleteVerificationModal}
        setShowModal={setShowAccountDeleteVerificationModal}
        onSubmit={handleVerifyAccountDeletion}
        title="Account Deletion Verification"
        message={`Enter the Verification Code sent to ${getProfileDetailObj.data?.phoneNumber}`}
        buttonText="Resend Code"
        onResend={handleResendVerifyAccountDeletion}
        onCancel={handleCancelAccountDeletion}
      />
      <AccountDeletionModal
        showModal={showAccountDeleteReasonModal}
        setShowModal={setShowAccountDeleteReasonModal}
        onSubmit={handleSendAccountDeletionReason}
        title="Account Deletion Reason"
        buttonText="Submit"
      />
      {renderProfile()}
    </>
  );
}

export default UserProfileScreen;
