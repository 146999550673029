import { Box, FormHelperText, InputAdornment, TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { DebounceInput } from "react-debounce-input";
import Spacer from "../utils/spacer.component";

function FormDebounceFieldText({
  name,
  placeholder,
  width,
  showIcon,
  errorMessage,
  icon,
  focus,
  setFocus,
  onClick,
  readOnly,
  submitOnChange,
}) {
  const theme = useTheme();
  const { setFieldValue, errors, touched, values, handleSubmit } = useFormikContext();

  const keyPress = (e) => {
    if (e.keyCode === 13 || e.keyCode === 9) {
      setFieldValue(name, e.target.value);
      setTimeout(() => {
        handleSubmit();
      }, 50);
    }
  };

  const onChangeText = (e) => {
    setFieldValue(name, e.target.value);
    if (submitOnChange) {
      setTimeout(() => {
        handleSubmit();
      }, 50);
    }
  };

  const showError = touched[name] && typeof errors[name] === "string";

  return (
    <Box
      sx={{
        "& .MuiTextField-root": {
          borderTopLeftRadius: `${theme.shape.borderRadius[1]}px`,
          borderTopRightRadius: `${theme.shape.borderRadius[1]}px`,
          borderBottomLeftRadius: `${theme.shape.borderRadius[1]}px`,
          borderBottomRightRadius: `${theme.shape.borderRadius[1]}px`,
          width,
          "& .MuiOutlinedInput-root": {
            borderTopLeftRadius: `${theme.shape.borderRadius[1]}px`,
            borderTopRightRadius: `${theme.shape.borderRadius[1]}px`,
            borderBottomLeftRadius: focus ? "0px" : `${theme.shape.borderRadius[1]}px`,
            borderBottomRightRadius: focus ? "0px" : `${theme.shape.borderRadius[1]}px`,
            backgroundColor: theme.palette.colors.bg.white,
            fieldset: {
              border: focus && "2px solid",
              borderColor: focus && theme.palette.colors.brand.primary,
            },
            focus: {
              "&:hover fieldset": {
                borderColor: "#c4c4c4",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#c4c4c4",
              },
            },
            transition: "all 0.4s",
          },
        },
      }}
    >
      <DebounceInput
        autoFocus={false}
        onClick={onClick}
        autoComplete="off"
        onFocus={() => {
          if (setFocus) setFocus(true);
        }}
        onBlur={() => {
          if (setFocus) setFocus(false);
        }}
        size="small"
        variant="outlined"
        debounceTimeout={500}
        onChange={onChangeText}
        element={TextField}
        value={values.name}
        onKeyDown={keyPress}
        InputProps={{
          readOnly,
          error: showError || errorMessage !== "",
          startAdornment: showIcon && (
            <InputAdornment position="start">
              {icon}
              <Spacer position="right" />
            </InputAdornment>
          ),
        }}
        placeholder={placeholder}
      />
      {(showError || errorMessage) && (
        <FormHelperText
          sx={{ margin: "3px 14px 0px 14px" }}
          error={showError || errorMessage !== ""}
        >
          {errors[name] || errorMessage}
        </FormHelperText>
      )}
    </Box>
  );
}

FormDebounceFieldText.defaultProps = {
  width: "100%",
  showIcon: true,
  errorMessage: "",
  icon: null,
  focus: false,
  setFocus: null,
  onClick: null,
  readOnly: false,
  submitOnChange: false,
};

FormDebounceFieldText.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  width: PropTypes.string,
  showIcon: PropTypes.bool,
  errorMessage: PropTypes.string,
  icon: PropTypes.node,
  focus: PropTypes.bool,
  setFocus: PropTypes.func,
  onClick: PropTypes.func,
  readOnly: PropTypes.bool,
  submitOnChange: PropTypes.bool,
};

export default FormDebounceFieldText;
