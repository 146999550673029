import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postReq } from "../api.services";

export const createLead = createAsyncThunk("lead/create", async (payload) => {
  const response = postReq(`${process.env.REACT_APP_API_PUBLIC_V1}/leads`, {
    ...payload,
    type: "merchant",
  });
  return response;
});

const leadSlice = createSlice({
  name: "leads",
  initialState: {
    createLeadObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [createLead.pending]: (state) => {
      state.createLeadObj.status = "pending";
    },
    [createLead.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createLeadObj.status = "succeeded";
      state.createLeadObj.data = data;
      state.createLeadObj.successMessage = message;
    },
    [createLead.rejected]: (state, action) => {
      const { message } = action.error;

      state.createLeadObj.status = "failed";
      state.createLeadObj.errorMessage = message;
    },
  },
});

export default leadSlice.reducer;

// state
export const leadSelector = (state) => state.leads;
