import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import routes from "../../../components/navigation/routes";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import { categorySegmentSelector } from "../../../services/category-segment/category-segment.service";

function CategorySegmentSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const { getCategorySegmentsObj } = useSelector(categorySegmentSelector);

  const renderLoader = () => {
    const loader = [];
    for (let i = 0; i < 5; i += 1) {
      loader.push(
        <Grid item xs={isMobile ? 2.4 : 2} key={uuidv4()}>
          <CustomSkeleton width="100%" height={isMobile ? 154 : 188} />
        </Grid>,
      );
    }
    return loader;
  };
  return (
    <Box overflow={isMobile && "auto"}>
      <Grid container columnSpacing={2} sx={{ width: isMobile ? "850px" : "auto" }}>
        {getCategorySegmentsObj.status === "succeeded"
          ? getCategorySegmentsObj.data.map((item) => (
              <Grid
                item
                key={item.id}
                xs={isMobile ? 2.4 : 2}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`${routes.EXPLORE}?tag=fitness`, {
                    state: {
                      fitnessCategoriesId: item.fitnessCategoriesId.map((category) => category.id),
                      categoriesLabel: item.fitnessCategoriesId.map((category) => category.label),
                    },
                  });
                }}
              >
                <Grid item display="flex" justifyContent="center">
                  <img
                    width="100%"
                    alt="imgs"
                    src={item.imagePath}
                    style={{
                      objectFit: "contain",
                      borderRadius: `${theme.shape.borderRadius[2]}px`,
                    }}
                  />
                </Grid>
              </Grid>
            ))
          : renderLoader()}
      </Grid>
    </Box>
  );
}

export default CategorySegmentSection;
