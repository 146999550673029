import { Box } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SnackbarContext } from "../../../../components/notification/snackbar.context";
import UnverifiedUserModal from "../../../../components/notification/unverified-user-modal.component";
import HorizontalDivider from "../../../../components/utils/horizontal-divider.component";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";
import {
  fitnessClassSessionSelector,
  getFitnessClassSession,
} from "../../../../services/fitness_class/session/fitness-class-session-slice.services";
import BusinessDetails from "../components/business-details.component";
import Description from "../components/description.component";
import SessionDetails from "../components/session-details.component";
import FitnessClassSessionScreenLoader from "../loader/fitness-class-session-screen-loader.component";

function FitnessClassSessionScreen() {
  const [params] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const createSnackBar = useContext(SnackbarContext);
  const { getFitnessClassSessionObj } = useSelector(fitnessClassSessionSelector);

  useEffect(() => {
    dispatch(getFitnessClassSession({ sessionId: params.get("sessionId") })).then(
      ({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  }, []);

  return (
    <Box>
      <Spacer size="xl" />
      <PaddedView multiples={2}>
        {getFitnessClassSessionObj.status === "succeeded" ? (
          <>
            {getFitnessClassSessionObj.data.requireVerification && (
              <UnverifiedUserModal cancelAction={() => navigate(-1)} />
            )}

            <SessionDetails />
            <HorizontalDivider spacerSize="l" />
            <Description />
            <HorizontalDivider spacerSize="l" />
            <BusinessDetails />
          </>
        ) : (
          <FitnessClassSessionScreenLoader />
        )}
      </PaddedView>
    </Box>
  );
}

export default FitnessClassSessionScreen;
