import { Box, Button, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";

function ReferralButton({ children, onClick, textColor }) {
  const theme = useTheme();

  return (
    <Button
      onClick={onClick}
      color="white"
      sx={{
        width: "100%",
        backgroundImage: `linear-gradient(to right, ${theme.palette.colors.brand.linearGreen[1]}, ${theme.palette.colors.brand.linearGreen[0]})`,
        borderRadius: `${theme.shape.borderRadius[2]}px`,
        textTransform: "none",
        textAlign: "left",
        whiteSpace: "nowrap",
      }}
    >
      <PaddedView multiples={2}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <CardGiftcardIcon />
            <Spacer position="left" />
            <Typography>Refferals</Typography>
          </Box>
          <Spacer position="left" size="xl" />
          <KeyboardArrowRightIcon fontSize="medium" />
        </Box>
        <Typography fontSize={theme.fonts.fontSizes.size24} color={textColor} fontWeight="bold">
          {children}
        </Typography>
      </PaddedView>
    </Button>
  );
}

ReferralButton.defaultProps = {
  onClick: null,
  textColor: "white",
};

ReferralButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  textColor: PropTypes.string,
};

export default ReferralButton;
