import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { isLogin } from "../../infrastructure/utils/index";
import routes from "./routes";

function PrivateRoute() {
  return isLogin() ? <Outlet /> : <Navigate to={routes.LOGIN} />;
}

export default PrivateRoute;
