import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import CustomButton from "../../../components/button/custom-button.component";
import FormFieldTextWithIcon from "../../../components/forms/form-field-text-with-icon.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import PaymentMethodCheckbox from "../../../components/forms/payment-method-checkbox.component";
import routes from "../../../components/navigation/routes";
import Loading from "../../../components/notification/backdrop-loading.component";
import DialogBox from "../../../components/notification/dialog-box.component";
import InsufficientCreditModal from "../../../components/notification/insufficient-credit-modal.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import UnverifiedUserModal from "../../../components/notification/unverified-user-modal.component";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import { isLogin } from "../../../infrastructure/utils";
import {
  createPackage,
  packageSelector,
  previewPackage,
} from "../../../services/package/package-slice.service";
import {
  getPackagePlanDetails,
  packagePlanSelector,
} from "../../../services/package/plan/package-plan-slice.service";
import { getProfileDetail } from "../../../services/profile/profile-slice.service";
import PreviewSummaryPackageLoader from "../loader/preview-summary-package-loader.component";

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size22,
  fontWeight: "bold",
}));

const SectionTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size18,
  fontWeight: "bold",
}));

const BodyTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size16,
}));

const SectionContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.colors.bg.primary,
  borderRadius: `${theme.shape.borderRadius[2]}px`,
}));

const validationSchema = Yup.object().shape({
  planId: Yup.number().required().label("Plan Id"),
  paymentMethod: Yup.string().required().label("Payment Method"),
});

function PreviewSummaryPackageScreen() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const { previewPackageObj } = useSelector(packageSelector);
  const { getPackagePlanDetailsObj } = useSelector(packagePlanSelector);
  const createSnackBar = useContext(SnackbarContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const formRef = useRef();
  const promoCodeFormRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isPaymentStage, setIsPaymentStage] = useState(false);
  const [redeemSuccess, setRedeemSuccess] = useState(false);
  const [showInsufficientCreditModal, setShowInsufficientCreditModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [previewSuccess, setPreviewSuccess] = useState(false);

  const previewPackagePlan = (values, promoRedeem) => {
    if (promoRedeem) {
      setIsLoading(true);
    }

    dispatch(previewPackage(values)).then(({ meta, error }) => {
      setIsLoading(false);

      if (meta.requestStatus === "fulfilled") {
        formRef.current.setFieldValue("promoCode", values.promoCode);
        setRedeemSuccess(true);
      }

      if (meta.requestStatus === "rejected") {
        formRef.current.setFieldValue("promoCode", "");
        promoCodeFormRef.current.setFieldValue("promoCode", "");
        setRedeemSuccess(false);
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const clearPromoForm = () => {
    formRef.current.setFieldValue("promoCode", "");
    promoCodeFormRef.current.setFieldValue("promoCode", "");
    previewPackagePlan({ ...formRef.current.values, promoCode: "" }, true);
  };

  const handleFormSubmission = (values) => {
    setIsLoading(true);
    dispatch(createPackage(values)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        if (
          values.paymentMethod === "credits" ||
          payload.data.paymentTransaction.status === "paid"
        ) {
          dispatch(getProfileDetail());
          navigate(routes.PACKAGEPAYMENTSUCCESS, { state: { ...payload.data } });
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
        } else {
          window.open(payload.data.bill.remoteUrl, "_self");
        }
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleSubmit = (values) => {
    if (values.paymentMethod === "credits" && !previewPackageObj.data.creditPurchasable) {
      setShowInsufficientCreditModal(true);
      return;
    }

    handleFormSubmission(values);
  };

  useEffect(() => {
    if (!params.get("businessId")) {
      navigate(routes.EXPLORE);
      return;
    }

    dispatch(getPackagePlanDetails({ planId: params.get("planId") })).then(
      ({ payload, meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }

        if (meta.requestStatus === "fulfilled") {
          if (!payload.data.planBusinessIds.includes(Number(params.get("businessId")))) {
            navigate(routes.EXPLORE);
          }
        }
      },
    );
    if (isLogin()) {
      dispatch(
        previewPackage({
          planId: params.get("planId"),
          businessId: params.get("businessId"),
        }),
      ).then(({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          setPreviewSuccess(false);
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
        if (meta.requestStatus === "fulfilled") {
          setPreviewSuccess(true);
        }
      });
    }
  }, []);

  return (
    <Box>
      {getPackagePlanDetailsObj.status === "succeeded" &&
        getPackagePlanDetailsObj.data.requireVerification && (
          <UnverifiedUserModal cancelAction={() => navigate(-1)} />
        )}
      <DialogBox
        title="Confirm Purchase?"
        isShow={showConfirmModal}
        setIsShowAlert={setShowConfirmModal}
        message={
          <Grid item>
            <Typography fontSize={theme.fonts.fontSizes.size16} sx={{ textAlign: "center" }}>
              By purchasing the Plan or Sessions, you expressly agree to the Terms and Conditions of
              Purchase.
            </Typography>
          </Grid>
        }
        isConfirmHandle={() => {
          setShowConfirmModal(false);
          setIsPaymentStage(true);
        }}
        buttonText="Yes"
      />
      {previewPackageObj.status === "succeeded" && (
        <InsufficientCreditModal
          isShow={showInsufficientCreditModal}
          setIsShowAlert={setShowInsufficientCreditModal}
        />
      )}
      <Loading isLoading={isLoading} />
      <Spacer size="l" />
      <PaddedView multiples={2}>
        <TitleTypography>Package Details</TitleTypography>
        <Spacer size="l" />
        {getPackagePlanDetailsObj.status === "succeeded" ||
        previewPackageObj.status === "succeeded" ? (
          <Form
            innerRef={formRef}
            initialValues={{
              planId: params.get("planId"),
              businessId: params.get("businessId"),
              paymentMethod: "credits",
              promoCode: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Grid container columnSpacing={2} rowSpacing={2}>
              <Grid container item xs={isMobile ? 12 : 6} flexDirection="column" rowSpacing={2}>
                <Grid item>
                  <SectionContainer item>
                    <PaddedView multiples={isMobile ? 3 : 4}>
                      <Grid
                        item
                        display="flex"
                        alignContent="center"
                        justifyContent="space-between"
                      >
                        <Grid item>
                          <SectionTypography>Package Details</SectionTypography>
                        </Grid>
                        {!isPaymentStage && !isMobile && (
                          <Grid item>
                            <CustomButton
                              fontSize={theme.fonts.fontSizes.size16}
                              isLoading={getPackagePlanDetailsObj.status === "pending"}
                              fontPadding="3px"
                              disabled={!previewSuccess}
                              onClick={() => {
                                if (!isLogin()) {
                                  navigate(routes.LOGIN);
                                } else {
                                  setShowConfirmModal(true);
                                }
                              }}
                            >
                              Get this plan
                            </CustomButton>
                          </Grid>
                        )}
                      </Grid>
                      <Spacer size="m" />

                      <Grid container columnSpacing={2} alignContent="center">
                        <Grid
                          item
                          alignSelf="center"
                          paddingY="5px"
                          alignItems="center"
                          display="flex"
                          xs={4}
                        >
                          <img
                            src={getPackagePlanDetailsObj.data?.imagePath}
                            alt="planImage"
                            width="100%"
                            height={isMobile ? 90 : 150}
                            style={{
                              borderRadius: `${theme.shape.borderRadius[2]}px`,
                              objectFit: "cover",
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          display="grid"
                          flexDirection="column"
                          alignContent="center"
                          marginY="10px"
                          xs={8}
                        >
                          <Grid item>
                            <Grid item>
                              <Typography fontWeight="bold" className="planTitle">
                                {getPackagePlanDetailsObj.data?.title}
                              </Typography>
                            </Grid>
                            <Spacer />
                            {getPackagePlanDetailsObj.data.memberPrice && (
                              <Grid item display="flex" alignContent="center">
                                <Typography fontWeight="bold" color="primary">
                                  RM {getPackagePlanDetailsObj.data.memberPrice} (MEMBER)
                                </Typography>
                              </Grid>
                            )}

                            <Grid item display="flex" alignContent="center">
                              <Typography
                                fontWeight="bold"
                                color={
                                  getPackagePlanDetailsObj.data.memberPrice ? "grey" : "primary"
                                }
                              >
                                RM {getPackagePlanDetailsObj.data.price}{" "}
                                {getPackagePlanDetailsObj.data.memberPrice && "(NON-MEMBER)"}
                              </Typography>
                            </Grid>
                            <Grid item display="flex" alignContent="center">
                              <Typography>
                                {getPackagePlanDetailsObj.data?.noOfSessions} sessions
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        {!isPaymentStage && isMobile && (
                          <Grid item xs={12}>
                            <Spacer />
                            <CustomButton
                              fontSize={theme.fonts.fontSizes.size16}
                              isLoading={getPackagePlanDetailsObj.status === "pending"}
                              fontPadding="3px"
                              disabled={!previewSuccess}
                              onClick={() => {
                                if (!isLogin()) {
                                  navigate(routes.LOGIN);
                                } else {
                                  setIsPaymentStage(true);
                                }
                              }}
                            >
                              Get this plan
                            </CustomButton>
                          </Grid>
                        )}
                      </Grid>
                    </PaddedView>
                  </SectionContainer>
                  {!isPaymentStage &&
                    getPackagePlanDetailsObj.status === "succeeded" &&
                    getPackagePlanDetailsObj.data.expireNoDaysFromPurchaseDate && (
                      <>
                        <Spacer size="m" />
                        <SectionContainer item>
                          <PaddedView multiples={isMobile ? 3 : 4}>
                            <SectionTypography>Important Info</SectionTypography>
                            <Spacer size="m" />
                            <Typography textAlign="justify" whiteSpace="pre-wrap">
                              Upon purchase, please redeem all the package sessions within{" "}
                              {getPackagePlanDetailsObj.data.expireNoDaysFromPurchaseDate} days.
                            </Typography>
                          </PaddedView>
                        </SectionContainer>
                      </>
                    )}
                </Grid>

                {isPaymentStage && (
                  <>
                    <Grid item>
                      <SectionContainer item>
                        <PaddedView multiples={isMobile ? 3 : 4}>
                          <SectionTypography>Promos & Vouchers</SectionTypography>
                          <Spacer size="m" />

                          <Form
                            innerRef={promoCodeFormRef}
                            initialValues={{
                              promoCode: "",
                            }}
                            validationSchema={Yup.object().shape({
                              promoCode: Yup.string().required().label("Promo Code"),
                            })}
                            onSubmit={(values) => {
                              previewPackagePlan(
                                {
                                  ...formRef.current.values,
                                  ...values,
                                },
                                true,
                              );
                            }}
                          >
                            <Grid container columnSpacing={2}>
                              <Grid item xs={8}>
                                <FormFieldTextWithIcon
                                  name="promoCode"
                                  placeholder="Promo Code"
                                  toUpper={true}
                                  clearPromoForm={clearPromoForm}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <FormSubmitButton width="100%" borderRadius={3}>
                                  <Typography>Redeem</Typography>
                                </FormSubmitButton>
                              </Grid>
                            </Grid>
                          </Form>
                        </PaddedView>
                      </SectionContainer>
                    </Grid>
                    <Grid item>
                      <SectionContainer item>
                        <PaddedView multiples={isMobile ? 3 : 4}>
                          <SectionTypography>Payment Method</SectionTypography>
                          <Spacer size="m" />
                          <PaymentMethodCheckbox />
                        </PaddedView>
                      </SectionContainer>
                    </Grid>
                  </>
                )}
              </Grid>

              <Grid item xs={isMobile ? 12 : 6}>
                <SectionContainer item>
                  {!isPaymentStage ? (
                    <PaddedView multiples={isMobile ? 3 : 4}>
                      <SectionTypography>What&apos;s in Your Plan</SectionTypography>
                      <Spacer size="m" />
                      <List sx={{ listStyleType: "disc", pl: 3 }}>
                        {getPackagePlanDetailsObj.data?.details.map((item) => (
                          <ListItem
                            key={uuidv4()}
                            sx={{ display: "list-item", textAlign: "justify" }}
                          >
                            {item}
                          </ListItem>
                        ))}
                      </List>
                      <Grid item>
                        <Spacer />
                        <CustomButton
                          textAlign="center"
                          fontWeight="bold"
                          variant="text"
                          fontSize={theme.fonts.fontSizes.size16}
                          onClick={() => window.open("https://katch.asia/terms/", "_blank")}
                        >
                          TERMS & CONDITIONS
                        </CustomButton>
                      </Grid>
                    </PaddedView>
                  ) : (
                    <PaddedView multiples={isMobile ? 3 : 4}>
                      <TitleTypography>Payment Summary</TitleTypography>
                      <Spacer size="m" />
                      <Grid
                        item
                        display="grid"
                        flexDirection="column"
                        alignContent="space-between"
                        minHeight="220px"
                      >
                        <Grid
                          container
                          item
                          dispaly="flex"
                          justifyContent="space-between"
                          rowSpacing={1}
                        >
                          <Grid item xs={6}>
                            <BodyTypography>Subtotal</BodyTypography>
                          </Grid>
                          <Grid item xs={6} textAlign="right">
                            <BodyTypography>
                              RM {previewPackageObj.data?.paymentSummary.subtotal}
                            </BodyTypography>
                          </Grid>
                          {previewPackageObj.data?.paymentSummary.membershipDiscount !== "0.00" && (
                            <>
                              <Grid item xs={6}>
                                <BodyTypography>Membership Discount</BodyTypography>
                              </Grid>
                              <Grid item xs={6} textAlign="right">
                                <BodyTypography>
                                  -RM {previewPackageObj.data?.paymentSummary.membershipDiscount}
                                </BodyTypography>
                              </Grid>
                            </>
                          )}
                          {previewPackageObj.data?.paymentSummary.voucherDiscount !== "0.00" &&
                            redeemSuccess && (
                              <>
                                <Grid item xs={6}>
                                  <BodyTypography>Voucher Discount</BodyTypography>
                                </Grid>
                                <Grid item xs={6} textAlign="right">
                                  <BodyTypography>
                                    -RM {previewPackageObj.data?.paymentSummary.voucherDiscount}
                                  </BodyTypography>
                                </Grid>
                              </>
                            )}
                        </Grid>
                        <Grid item>
                          <Grid item>
                            <Spacer size="l" />
                            <Divider />
                            <Spacer size="l" />
                          </Grid>
                          <Grid
                            container
                            item
                            dispaly="flex"
                            justifyContent="space-between"
                            rowSpacing={1}
                          >
                            <SectionTypography>Total Price</SectionTypography>
                            <SectionTypography>
                              RM {previewPackageObj.data.paymentSummary.totalPrice}
                            </SectionTypography>
                          </Grid>
                          <Spacer size="xl" />
                          <Grid item>
                            <FormSubmitButton width="100%" borderRadius={3}>
                              Confirm
                            </FormSubmitButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </PaddedView>
                  )}
                </SectionContainer>
                {isPaymentStage &&
                  getPackagePlanDetailsObj.status === "succeeded" &&
                  getPackagePlanDetailsObj.data.expireNoDaysFromPurchaseDate && (
                    <>
                      <Spacer size="m" />
                      <SectionContainer item>
                        <PaddedView multiples={isMobile ? 3 : 4}>
                          <SectionTypography>Important Info</SectionTypography>
                          <Spacer size="m" />
                          <Typography textAlign="justify" whiteSpace="pre-wrap">
                            Upon purchase, please redeem all the package sessions within{" "}
                            {getPackagePlanDetailsObj.data.expireNoDaysFromPurchaseDate} days.
                          </Typography>
                        </PaddedView>
                      </SectionContainer>
                    </>
                  )}
              </Grid>
            </Grid>
          </Form>
        ) : (
          <PreviewSummaryPackageLoader />
        )}
      </PaddedView>
    </Box>
  );
}

export default PreviewSummaryPackageScreen;
