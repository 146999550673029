import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import HorizontalDivider from "../../../../../components/utils/horizontal-divider.component";
import PaddedView from "../../../../../components/utils/padded-view.component";
import CustomSkeleton from "../../../../../components/utils/skeleton.component";
import Spacer from "../../../../../components/utils/spacer.component";

const SectionContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.colors.bg.primary,
  borderRadius: `${theme.shape.borderRadius[2]}px`,
}));

function PreviewSummaryLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box>
      <Grid container columnSpacing={2} rowSpacing={2}>
        <Grid container item xs={isMobile ? 12 : 7} flexDirection="column" rowSpacing={2}>
          <Grid item>
            <SectionContainer item>
              <PaddedView multiples={isMobile ? 3 : 4}>
                <CustomSkeleton height="20px" />
                <Spacer size="m" />
                <Grid item>
                  <Grid container justifyContent="space-between">
                    <CustomSkeleton height="33px" width={isMobile ? "300px" : "350px"} />
                    <CustomSkeleton />
                  </Grid>
                  <Spacer />
                  <Grid item>
                    <CustomSkeleton width="150px" />
                    <Spacer />
                    <CustomSkeleton width="150px" />
                    <Spacer />
                    <CustomSkeleton width="250px" />
                  </Grid>
                  <Spacer size="l" />
                </Grid>
              </PaddedView>
            </SectionContainer>
          </Grid>
          <Grid item>
            <SectionContainer item>
              <PaddedView multiples={isMobile ? 3 : 4}>
                <CustomSkeleton height="27px" width="250px" />
                <Spacer size="m" />
                <Grid container columnSpacing={2}>
                  <Grid item xs={8}>
                    <CustomSkeleton height="40px" width="100%" />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomSkeleton height="33px" width="100%" />
                  </Grid>
                </Grid>
              </PaddedView>
            </SectionContainer>
          </Grid>

          <Grid item>
            <SectionContainer item>
              <PaddedView multiples={isMobile ? 3 : 4}>
                <CustomSkeleton height="27px" width="250px" />
                <Spacer size="m" />
                <CustomSkeleton height="42px" width={isMobile ? "300px" : "450px"} />
                <Spacer size="m" />
                <CustomSkeleton height="42px" width={isMobile ? "300px" : "450px"} />
              </PaddedView>
            </SectionContainer>
          </Grid>
        </Grid>
        <Grid item xs={isMobile ? 12 : 5}>
          <SectionContainer item>
            <PaddedView multiples={isMobile ? 3 : 4}>
              <CustomSkeleton height="33px" width="250px" />
              <Spacer size="m" />
              <Grid
                item
                display="grid"
                flexDirection="column"
                alignContent="space-between"
                minHeight="220px"
              >
                <Grid container item dispaly="flex" justifyContent="space-between" rowSpacing={1}>
                  <CustomSkeleton />
                  <CustomSkeleton />
                </Grid>
                <Grid item>
                  <HorizontalDivider spacerSize="l" />
                  <Grid container item dispaly="flex" justifyContent="space-between" rowSpacing={1}>
                    <CustomSkeleton height="27px" width={isMobile ? "100px" : "200px"} />
                    <CustomSkeleton height="27px" width={isMobile ? "100px" : "150px"} />
                  </Grid>
                  <Spacer size="xl" />
                  <Grid item>
                    <CustomSkeleton height="40px" width="100%" />
                  </Grid>
                </Grid>
              </Grid>
            </PaddedView>
          </SectionContainer>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PreviewSummaryLoader;
