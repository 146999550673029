import { Divider, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import HorizontalDivider from "../../../components/utils/horizontal-divider.component";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import Spacer from "../../../components/utils/spacer.component";
import ReviewLoader from "./review-loader.component";

const SectionText = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size18,
  fontWeight: "bold",
}));

function BusinessScreenLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container>
      <Grid container columnSpacing={2}>
        <Grid item xs={isMobile ? 12 : 4} display="flex" alignItems="center">
          <CustomSkeleton height="250px" width="100%" />
        </Grid>
        <Grid item xs={isMobile ? 12 : 8} alignItems="center" display="flex">
          <Grid
            item
            display="grid"
            flexDirection="column"
            alignContent="space-between"
            height="98%"
            xs={12}
          >
            <Grid item>
              <Grid item>
                <CustomSkeleton height="25px" width={isMobile ? "300px" : "500px"} />
              </Grid>
              <Spacer />
              <Grid item display="flex" alignContent="center">
                <CustomSkeleton width="200px" />
              </Grid>
              <Spacer />
              <Grid item display="flex" alignContent="center">
                <CustomSkeleton width="150px" />
              </Grid>
              <Spacer />
              <Grid item container>
                <CustomSkeleton width="180px" />
              </Grid>
            </Grid>
            <Spacer />
            <Grid item container columnSpacing={1}>
              <Grid item xs={isMobile ? 12 : 4}>
                <CustomSkeleton height="30px" width="100%" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <HorizontalDivider spacerSize="l" />
      <Grid item xs={12}>
        <Grid item>
          <SectionText>About Us</SectionText>
        </Grid>
        <Spacer size="m" />
        <Grid item xs={12}>
          <CustomSkeleton width="100%" />
          <Spacer />
          <CustomSkeleton width="100%" />
        </Grid>
      </Grid>
      <HorizontalDivider spacerSize="l" />
      <Grid item xs={12}>
        <Grid item>
          <SectionText>Location</SectionText>
        </Grid>
        <Spacer size="m" />
        <Grid item>
          <CustomSkeleton height="40vh" width="100%" />
        </Grid>
        <Spacer size="m" />
        <Grid item>
          <CustomSkeleton width="100%" />
        </Grid>
      </Grid>
      <HorizontalDivider spacerSize="l" />
      <Grid item xs={12}>
        <SectionText>Upcoming Classes</SectionText>
        <Spacer size="m" />
        <Grid container columnSpacing={2} rowSpacing={1}>
          <Grid item xs={isMobile ? 12 : 6}>
            <CustomSkeleton width="100%" height={208} />
          </Grid>
          <Grid item xs={isMobile ? 12 : 6}>
            <CustomSkeleton width="100%" height={208} />
          </Grid>
        </Grid>
      </Grid>
      <HorizontalDivider spacerSize="l" />
      <Grid item xs={12}>
        <SectionText>Upcoming Events</SectionText>
        <Spacer size="m" />
        <Grid container columnSpacing={2} rowSpacing={1}>
          <Grid item xs={isMobile ? 12 : 4}>
            <CustomSkeleton width="100%" height={146} />
          </Grid>
          <Grid item xs={isMobile ? 12 : 4}>
            <CustomSkeleton width="100%" height={146} />
          </Grid>
          <Grid item xs={isMobile ? 12 : 4}>
            <CustomSkeleton width="100%" height={146} />
          </Grid>
        </Grid>
      </Grid>
      <HorizontalDivider spacerSize="l" />
      <Grid item>
        <SectionText>More Amenities</SectionText>
        <Spacer size="m" />
        <Grid container>
          <CustomSkeleton
            width={isMobile ? "100px" : "150px"}
            height={isMobile ? "120px" : "110px"}
          />
          <Spacer size="m" position="left" />
          <CustomSkeleton
            width={isMobile ? "100px" : "150px"}
            height={isMobile ? "120px" : "110px"}
          />
          <Spacer size="m" position="left" />
          <CustomSkeleton
            width={isMobile ? "100px" : "150px"}
            height={isMobile ? "120px" : "110px"}
          />
        </Grid>
      </Grid>

      <HorizontalDivider spacerSize="l" />
      <Grid item xs={12}>
        <Grid container>
          <SectionText>Reviews</SectionText>
        </Grid>
        <Spacer size="m" />
        <Spacer size="s" />
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <Typography>Total Reviews</Typography>
            <Spacer />
            <CustomSkeleton width="100%" />
          </Grid>

          <Grid item xs={2} container justifyContent="center" height={100}>
            <Divider orientation="vertical" variant="middle" />
          </Grid>
          <Grid item xs={2}>
            <Typography>Average Rating</Typography>
            <Spacer />
            <CustomSkeleton width="100%" />
          </Grid>
          <Grid item xs={2} container justifyContent="center" height={100}>
            <Divider orientation="vertical" variant="middle" />
          </Grid>
          <Grid item xs={4}>
            <CustomSkeleton width="80%" height={15} />
            <Spacer />
            <CustomSkeleton width="80%" height={15} />
            <Spacer />

            <CustomSkeleton width="80%" height={15} />
            <Spacer />
            <CustomSkeleton width="80%" height={15} />
            <Spacer />
            <CustomSkeleton width="80%" height={15} />
          </Grid>
        </Grid>
        <HorizontalDivider spacerSize="l" />
        <Grid item xs={12}>
          <ReviewLoader />
          <ReviewLoader />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default BusinessScreenLoader;
