import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkIcon from "@mui/icons-material/Link";
import PlaceIcon from "@mui/icons-material/Place";
import RedditIcon from "@mui/icons-material/Reddit";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Box, Grid, IconButton, Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../../../components/button/custom-button.component";
import routes from "../../../components/navigation/routes";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Spacer from "../../../components/utils/spacer.component";
import { eventSessionSelector } from "../../../services/event/session/event-session-slice.service";
import EventImageGrid from "./event-image-grid.component";
import SessionCategoryModal from "./session-category-modal.component";

function ImageTitleSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const location = useLocation();
  const createSnackBar = useContext(SnackbarContext);
  const { getEventSessionDetailsObj } = useSelector(eventSessionSelector);
  const [clipboardSnackbar, setClipboardSnackbar] = useState(false);
  const ahref = `https://www.katch.asia${location.pathname}${location.search}`;
  const encodedAhref = encodeURIComponent(ahref);
  const [showModal, setShowModal] = useState(false);

  if (clipboardSnackbar) {
    createSnackBar({
      message: "Link copied to clipboard",
      type: "success",
      open: true,
    });
  }

  return (
    <>
      <Helmet>
        <meta property="og:title" content={getEventSessionDetailsObj.data.title} />
        <meta name="description" content={getEventSessionDetailsObj.data.event.title} />
        <meta property="og:description" content={getEventSessionDetailsObj.data.event.title} />
        <meta
          property="og:image"
          content={
            getEventSessionDetailsObj.data.event.images &&
            getEventSessionDetailsObj.data.event.images[0].imagePath
          }
        />
        <meta name="twitter:title" content={getEventSessionDetailsObj.data.title} />
        <meta name="twitter:description" content={getEventSessionDetailsObj.data.event.title} />
        <meta
          name="twitter:image"
          content={
            getEventSessionDetailsObj.data.event.images &&
            getEventSessionDetailsObj.data.event.images[0].imagePath
          }
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="og:image:width" content={1200} />
        <meta property="og:image:height" content={630} />
        <meta property="og:url" content={ahref} />
        <link rel="canonical" href={ahref} />
      </Helmet>
      <SessionCategoryModal showModal={showModal} setShowModal={setShowModal} />
      <Grid container columnSpacing={3}>
        <Grid item xs={12}>
          <Grid item>
            <Typography
              sx={{
                fontSize: theme.fonts.fontSizes.size24,
                fontWeight: theme.fonts.fontWeights.bold,
                overflowWrap: "anywhere",
                lineheight: "20px",
              }}
            >
              {getEventSessionDetailsObj.data.event.title}
            </Typography>
          </Grid>
          {isMobile && <Spacer />}
        </Grid>
        <Grid item xs={isMobile ? 12 : 8}>
          <EventImageGrid itemList={getEventSessionDetailsObj.data.event.images} />
        </Grid>
        <Grid item xs={isMobile ? 12 : 3} paddingY="20px">
          <Grid item>
            <Grid item>
              <Typography
                sx={{
                  fontSize: theme.fonts.fontSizes.size24,
                  fontWeight: theme.fonts.fontWeights.bold,
                  overflowWrap: "anywhere",
                  lineheight: "20px",
                  textTransform: "capitalize",
                }}
              >
                {getEventSessionDetailsObj.data.title
                  ? getEventSessionDetailsObj.data.title
                  : getEventSessionDetailsObj.data.event.title}
              </Typography>
            </Grid>
            <Spacer />
            <Grid container item>
              <CalendarMonthOutlinedIcon color="primary" />
              <Spacer position="left" />
              <Typography>{getEventSessionDetailsObj.data.date}</Typography>
            </Grid>
            <Spacer />
            <Grid container item>
              <AccessTimeOutlinedIcon color="primary" />
              <Spacer position="left" />
              <Typography>{getEventSessionDetailsObj.data.time}</Typography>
            </Grid>
            <Spacer />
            <Grid container item>
              <PlaceIcon color="primary" />
              <Spacer position="left" />
              <Typography>{getEventSessionDetailsObj.data.event.location.name}</Typography>
            </Grid>
            <Spacer size="m" />
            {!getEventSessionDetailsObj.data.multipleCategories && (
              <>
                {getEventSessionDetailsObj.data.memberPrice && (
                  <Grid item display="flex" alignContent="center">
                    <Typography fontWeight="bold" color="primary" variant="h6">
                      RM {getEventSessionDetailsObj.data.memberPrice} (MEMBER)
                    </Typography>
                  </Grid>
                )}

                <Grid item display="flex" alignContent="center">
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    color={getEventSessionDetailsObj.data.memberPrice ? "grey" : "primary"}
                  >
                    RM {getEventSessionDetailsObj.data.price}{" "}
                    {getEventSessionDetailsObj.data.memberPrice && "(NON-MEMBER)"}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
          <Grid item xs={isMobile ? 12 : 4}>
            <Spacer size="xl" />
            {getEventSessionDetailsObj.data.maxCapacity && (
              <Typography
                textAlign="center"
                fontWeight="bold"
                sx={{ color: theme.palette.colors.ui.error }}
              >
                Fully Booked
              </Typography>
            )}
            <CustomButton
              disabled={
                (getEventSessionDetailsObj.data.booked &&
                  !getEventSessionDetailsObj.data.multipleCategories) ||
                getEventSessionDetailsObj.data.maxCapacity
              }
              fontSize={theme.fonts.fontSizes.size16}
              width="100%"
              onClick={() => {
                if (getEventSessionDetailsObj.data.multipleCategories) {
                  setShowModal(true);
                } else {
                  navigate(routes.EVENTBOOKINGSUMMARY, {
                    state: {
                      eventSessionId: getEventSessionDetailsObj.data.id,
                    },
                  });
                }
              }}
            >
              {getEventSessionDetailsObj.data.booked &&
              !getEventSessionDetailsObj.data.multipleCategories
                ? "Booked"
                : "Book"}
            </CustomButton>
          </Grid>
        </Grid>
        <Grid item xs={isMobile ? 12 : 1} paddingY="20px">
          <Typography
            textAlign={!isMobile && "center"}
            fontWeight="bold"
            color="primary"
            fontSize={theme.fonts.fontSizes.size20}
          >
            Share
          </Typography>
          <Box display="flex" flexDirection={isMobile ? "row" : "column"} alignItems="center">
            <Link
              href={`https://www.facebook.com/sharer/sharer.php?u=${ahref}`}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <IconButton>
                <FacebookIcon fontSize="large" />
              </IconButton>
            </Link>
            <Spacer position="left" size="m" />
            <Link
              href={`https://twitter.com/intent/tweet?url=${encodedAhref}`}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <IconButton>
                <TwitterIcon fontSize="large" />
              </IconButton>
            </Link>
            <Spacer position="left" size="m" />
            <Link
              href={`https://www.reddit.com/submit?url=${encodedAhref}`}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <IconButton>
                <RedditIcon fontSize="large" />
              </IconButton>
            </Link>
            <Spacer position="left" size="m" />

            <IconButton
              onClick={() => {
                setClipboardSnackbar(true);
                navigator.clipboard.writeText(ahref);
              }}
            >
              <LinkIcon fontSize="large" />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default ImageTitleSection;
