import React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { Grid, Typography, useTheme } from "@mui/material";

export default function FormToggleButton({ options, name, fullWidth }) {
  const { values, setFieldValue } = useFormikContext();
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    if (newValue !== null) {
      setFieldValue(name, newValue);
    }
  };

  return (
    <Grid container display="flex" justifyContent="center">
      <ToggleButtonGroup
        fullWidth={fullWidth}
        color="primary"
        value={values[name]}
        exclusive
        onChange={handleChange}
        sx={{
          "& .MuiToggleButtonGroup-grouped": {
            borderLeft: "1px solid !important",
          },
        }}
      >
        {options.map((item) => (
          <ToggleButton
            value={item.value}
            key={item.label}
            sx={{
              borderRadius: `${theme.shape.borderRadius[1]}px`,
              minWidth: "150px",
              borderColor:
                values[name] === item.value
                  ? theme.palette.colors.brand.primary
                  : theme.palette.colors.ui.disabled,
              "& .MuiToggleButtonGroup-grouped": {
                borderLeft: "10px solid !important",
              },
            }}
          >
            <Typography textTransform="capitalize">{item.label}</Typography>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Grid>
  );
}

FormToggleButton.defaultProps = {
  fullWidth: true,
};

FormToggleButton.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
};
