import React, { useContext, useEffect, useState } from "react";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { Box, Grid, Rating, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  appointmentSelector,
  createAppointment,
  previewAppointment,
} from "../../../services/appointment/appointment-slice.service";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Loading from "../../../components/notification/backdrop-loading.component";
import Spacer from "../../../components/utils/spacer.component";
import PaddedView from "../../../components/utils/padded-view.component";
import Form from "../../../components/forms/form.component";
import HorizontalDivider from "../../../components/utils/horizontal-divider.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import PreviewSummaryLoader from "../loader/preview-summary-loader.component";
import routes from "../../../components/navigation/routes";

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size22,
  fontWeight: "bold",
}));

const SectionTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size18,
  fontWeight: "bold",
}));

const BodyTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size16,
}));

const SectionContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.colors.bg.primary,
  borderRadius: `${theme.shape.borderRadius[2]}px`,
}));

const validationSchema = Yup.object().shape({
  startAt: Yup.string().required(),
  endAt: Yup.string().required(),
  packageId: Yup.number().required(),
  businessId: Yup.number().required(),
});

export default function PreviewSummaryAppointmentScreen() {
  const location = useLocation();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { previewAppointmentObj, createAppointmentObj } = useSelector(appointmentSelector);
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const previewSchedule = () => {
    dispatch(previewAppointment(location.state)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleSubmit = () => {
    setIsLoading(true);
    dispatch(createAppointment(location.state)).then(({ payload, meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        navigate(routes.APPOINTMENTPAYMENTSUCCESS, { state: { ...payload.data } });
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  useEffect(() => {
    previewSchedule();
  }, []);

  return (
    <Box>
      <Loading isLoading={isLoading} />
      <Spacer size="l" />
      <PaddedView multiples={2}>
        <TitleTypography>Booking Details</TitleTypography>
        <Spacer size="l" />
        {previewAppointmentObj.status === "succeeded" ? (
          <Form
            initialValues={location.state}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Grid container columnSpacing={2} rowSpacing={2}>
              <Grid container item xs={isMobile ? 12 : 7} flexDirection="column" rowSpacing={2}>
                <Grid item>
                  <SectionContainer item>
                    <PaddedView multiples={isMobile ? 3 : 4}>
                      <Grid item display="flex" alignItems="center">
                        <Typography
                          fontSize={theme.fonts.fontSizes.size14}
                          sx={{
                            border: "1px solid",
                            borderColor: theme.palette.colors.ui.error,
                            color: theme.palette.colors.ui.error,
                            paddingX: "10px",
                          }}
                        >
                          NON-REFUNDABLE
                        </Typography>
                      </Grid>
                      <Spacer size="m" />
                      <Typography fontSize={theme.fonts.fontSizes.size14} textTransform="uppercase">
                        {previewAppointmentObj.data.categoryType}
                      </Typography>
                      <Spacer />
                      <Grid item key={uuidv4()}>
                        <Grid container justifyContent="space-between" alignItems="center">
                          <TitleTypography>{previewAppointmentObj.data.plan.title}</TitleTypography>
                        </Grid>
                        <Spacer size="m" />
                        <Grid item>
                          <BodyTypography>
                            {previewAppointmentObj.data.schedule.startTime} (
                            {previewAppointmentObj.data.schedule.durationMins}
                            mins) | {previewAppointmentObj.data.schedule.date}
                          </BodyTypography>
                          <BodyTypography>
                            {previewAppointmentObj.data.business.name}
                          </BodyTypography>
                        </Grid>
                        <Spacer size="m" />
                      </Grid>
                    </PaddedView>
                  </SectionContainer>
                </Grid>

                <Grid item>
                  <SectionContainer item>
                    <PaddedView multiples={isMobile ? 3 : 4}>
                      <SectionTypography>Package Code</SectionTypography>
                      <Spacer size="m" />
                      <Grid item display="flex">
                        <Typography
                          sx={{
                            border: "1px solid",
                            borderRadius: `${theme.shape.borderRadius[1]}px`,
                            padding: "10px",
                            paddingX: "30px",
                            fontWeight: "bold",
                          }}
                        >
                          {previewAppointmentObj.data.package.code}
                        </Typography>
                      </Grid>
                    </PaddedView>
                  </SectionContainer>
                </Grid>
                <Grid item>
                  <SectionContainer item>
                    <PaddedView multiples={isMobile ? 3 : 4}>
                      <SectionTypography>About the business</SectionTypography>
                      <Spacer size="m" />
                      <Grid container spacing={2}>
                        <Grid item xs={isMobile ? 4 : 3}>
                          <img
                            style={{
                              width: "100%",
                              objectFit: "cover",
                              borderRadius: `${theme.shape.borderRadius[1]}px`,
                            }}
                            src={previewAppointmentObj.data.business.imagePath}
                            alt="business_image"
                          />
                        </Grid>
                        <Grid item>
                          <Typography fontWeight="bold">
                            {previewAppointmentObj.data.business.name}
                          </Typography>
                          <Grid container alignItems="center">
                            <Rating
                              value={previewAppointmentObj.data.business.reviews.averageRatings}
                              readOnly
                              sx={{ color: theme.palette.colors.brand.primary }}
                            />
                            <Spacer position="left" />
                            <Typography>
                              ({previewAppointmentObj.data.business.reviews.totalRatings} reviews)
                            </Typography>
                          </Grid>
                          <Spacer size={isMobile ? "s" : "l"} />
                          <Grid container>
                            <LocalPhoneIcon />
                            <Spacer position="left" />
                            <Typography>{previewAppointmentObj.data.business.contactNo}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </PaddedView>
                  </SectionContainer>
                </Grid>
              </Grid>
              <Grid item xs={isMobile ? 12 : 5}>
                <SectionContainer item>
                  <PaddedView multiples={isMobile ? 3 : 4}>
                    <TitleTypography>Payment Summary</TitleTypography>
                    <Spacer size="m" />
                    <Grid
                      item
                      display="grid"
                      flexDirection="column"
                      alignContent="space-between"
                      minHeight="120px"
                    >
                      <Grid
                        container
                        item
                        dispaly="flex"
                        justifyContent="space-between"
                        rowSpacing={1}
                      >
                        <Grid item xs={6}>
                          <BodyTypography>Subtotal</BodyTypography>
                        </Grid>
                        <Grid item xs={6} textAlign="right">
                          <BodyTypography>RM 0.00</BodyTypography>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <HorizontalDivider spacerSize="l" />
                        <Grid
                          container
                          item
                          dispaly="flex"
                          justifyContent="space-between"
                          rowSpacing={1}
                        >
                          <SectionTypography>Total Price</SectionTypography>
                          <SectionTypography>RM 0.00</SectionTypography>
                        </Grid>
                        <Spacer size="xl" />
                        <Grid item>
                          <FormSubmitButton
                            disabled={createAppointmentObj.status === "pending"}
                            borderRadius={3}
                          >
                            <Typography>Confirm</Typography>
                          </FormSubmitButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </PaddedView>
                </SectionContainer>
              </Grid>
            </Grid>
          </Form>
        ) : (
          <PreviewSummaryLoader />
        )}
      </PaddedView>
    </Box>
  );
}
