import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import WifiRoundedIcon from "@mui/icons-material/WifiRounded";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import { v4 as uuidv4 } from "uuid";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { profileSelector } from "../../../../services/profile/profile-slice.service";
import { subscriptionSelector } from "../../../../services/subscription/subscription-slice.service";
import {
  getTokenizedPayments,
  tokenizedPaymentSelector,
} from "../../../../services/tokenized-payment/tokenized-payment-slice.service";
import { SnackbarContext } from "../../../../components/notification/snackbar.context";

const LabelContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: "5px",
});

const LabelText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontWeight: "lighter",
  marginLeft: "15px",
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
  marginTop: 15,
  marginBottom: 15,
}));

const StyledRadio = styled(Radio)(({ theme }) => ({
  color: theme.palette.colors.brand.primary,
  "&.Mui-checked": {
    color: theme.palette.colors.brand.primary,
  },
}));

function PaymentMethodCheckbox() {
  const { values, setFieldValue } = useFormikContext();
  const [value, setValue] = useState(values.paymentMethod);
  const dispatch = useDispatch();
  const { getProfileDetailObj } = useSelector(profileSelector);
  const { previewSubscriptionObj } = useSelector(subscriptionSelector);
  const createSnackBar = useContext(SnackbarContext);
  const { getTokenizedPaymentsObj } = useSelector(tokenizedPaymentSelector);

  const handleChange = (event) => {
    setValue(event.target.value);
    setFieldValue("paymentMethod", event.target.value);
  };

  const getCreditCards = () => {
    dispatch(getTokenizedPayments()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  useEffect(() => {
    getCreditCards();
  }, []);

  return (
    <FormControl sx={{ width: "100%" }}>
      <RadioGroup name="controlled-radio-buttons-group" value={value} onChange={handleChange}>
        {previewSubscriptionObj.data.subscription.plan.type !== "recurrent" ? (
          <>
            <FormControlLabel
              value="others"
              control={<StyledRadio />}
              label={
                <LabelContainer>
                  <WifiRoundedIcon />
                  <LabelText>Online Banking / E-Wallet</LabelText>
                </LabelContainer>
              }
            />
            <SeperateLine />
            <FormControlLabel
              value="credits"
              control={<StyledRadio />}
              label={
                <LabelContainer>
                  <AccountBalanceWalletOutlinedIcon />
                  {getProfileDetailObj.status === "succeeded" && (
                    <LabelText>{`Account Credits (Balance RM ${getProfileDetailObj.data.creditBalance})`}</LabelText>
                  )}
                </LabelContainer>
              }
            />
          </>
        ) : (
          getTokenizedPaymentsObj.status === "succeeded" &&
          getTokenizedPaymentsObj.data.map((item) => (
            <FormControlLabel
              key={uuidv4()}
              value={`tokenized_payment:${item.remoteCustomerId}`}
              control={<StyledRadio />}
              label={
                <LabelContainer>
                  <CreditCardOutlinedIcon />
                  <LabelText>{item.label}</LabelText>
                </LabelContainer>
              }
            />
          ))
        )}
      </RadioGroup>
    </FormControl>
  );
}

export default PaymentMethodCheckbox;
