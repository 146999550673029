import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { patchReq, getReq } from "../../api.services";

export const cancelHourlyBookingBill = createAsyncThunk(
  "hourly_bookings/bills/{bill_id}/cancel",
  async (payload) => {
    const { billId } = payload;
    const response = await patchReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/hourly_bookings/bills/${billId}/cancel`,
    );
    return response;
  },
);

export const getHourlyBookingViaRemoteBillId = createAsyncThunk(
  "hourly_bookings/bills/{bill_id}",
  async (payload) => {
    const { remoteBillId } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/hourly_bookings/bills/${remoteBillId}`,
    );
    return response;
  },
);

const hourlyBookingBillSlice = createSlice({
  name: "hourlyBookingBillSlice",
  initialState: {
    cancelHourlyBookingBillObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getHourlyBookingViaRemoteBillIdObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [cancelHourlyBookingBill.pending]: (state) => {
      state.cancelHourlyBookingBillObj.status = "pending";
    },
    [cancelHourlyBookingBill.fulfilled]: (state, action) => {
      const { data, message } = action.payload;
      state.cancelHourlyBookingBillObj.status = "succeeded";
      state.cancelHourlyBookingBillObj.data = data;
      state.cancelHourlyBookingBillObj.successMessage = message;
    },
    [cancelHourlyBookingBill.rejected]: (state, action) => {
      const { message } = action.error;
      state.cancelHourlyBookingBillObj.status = "failed";
      state.cancelHourlyBookingBillObj.errorMessage = message;
    },
    [getHourlyBookingViaRemoteBillId.pending]: (state) => {
      state.getHourlyBookingViaRemoteBillIdObj.status = "pending";
    },
    [getHourlyBookingViaRemoteBillId.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getHourlyBookingViaRemoteBillIdObj.status = "succeeded";
      state.getHourlyBookingViaRemoteBillIdObj.data = data;
      state.getHourlyBookingViaRemoteBillIdObj.successMessage = message;
    },
    [getHourlyBookingViaRemoteBillId.rejected]: (state, action) => {
      const { message } = action.error;

      state.getHourlyBookingViaRemoteBillIdObj.status = "failed";
      state.getHourlyBookingViaRemoteBillIdObj.errorMessage = message;
    },
  },
});

export default hourlyBookingBillSlice.reducer;

// // state
export const hourlyBookingBillSelector = (state) => state.hourlyBookingBill;
